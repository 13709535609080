import { Component } from '@angular/core';

/**
 * Reset password page
 */
@Component({
  selector: 'prg-auth-reset-password',
  templateUrl: './prg-auth-reset-password.page.html',
  styleUrls: ['./prg-auth-reset-password.page.scss'],
})
export class PrgAuthResetPasswordPage {}
