import { Validators } from '@angular/forms';
import { CheckboxConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/checkbox/checkbox.model';
import { SelectConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/select/select.model';
import { ControlName } from '../../core/components/dynamic-form/models/constants/control-names';
import { ReferenceType } from '../../core/components/dynamic-form/models/constants/reference-type';
/**
 * Dynamic form fields configurations to Entity type property
 */
export const ENTITY_TYPE_PROPERTY_DYNAMIC_FORM = {
  fields: [
    {
      controlName: ControlName.inputText,
      readonly: false,
      key: 'name',
      validators: [Validators.required],
      basePathTranslation: 'entity-type.properties.fields',
    },
    {
      controlName: ControlName.inputText,
      readonly: false,
      key: 'guiSettings',
      validators: [Validators.required],
      basePathTranslation: 'entity-type.properties.fields',
    },
    new SelectConfiguration({
      referenceType: ReferenceType.LookupTable,
      referenceName: 'dataType',
      readonly: false,
      key: 'dataTypeId',
      optionLabel: 'label',
      optionValue: 'id',
      validators: [Validators.required],
      basePathTranslation: 'entity-type.properties.fields',
    }),
    new CheckboxConfiguration({
      key: 'isArray',
      basePathTranslation: 'entity-type.properties.fields',
    }),
    new SelectConfiguration({
      referenceType: ReferenceType.LookupTable,
      readonly: false,
      key: 'reference',
      optionLabel: 'label',
      optionValue: 'name',
      visibleExpression:
        "this.form.get('dataTypeId').getRawValue() && this.form.get('dataTypeId').getRawValue().toLowerCase()==='datatype.lookuptable'",
    }),
    new SelectConfiguration({
      referenceType: ReferenceType.EntityType,
      readonly: false,
      key: 'reference',
      optionLabel: 'name',
      optionValue: 'name',
      visibleExpression:
        "this.form.get('dataTypeId').getRawValue() && this.form.get('dataTypeId').getRawValue().toLowerCase()==='datatype.entitytype'",
    }),
  ],
};
