<div *ngIf="form" [formGroup]="form">
  <p>{{configs.basePathTranslation + '.' + configs.key + '.label' | translate}}</p>
    <p-toggleButton
      (onChange)="onChanged($event)"
      [setToggleButtonConfigs]="configs"
      [formControlName]="configs.key"
      [inputId]="configs.key"
      [onLabel]="configs.hasLabel?(configs.basePathTranslation + '.' + configs.key + '.onLabel' | translate):''"
      [offLabel]="configs.hasLabel?(configs.basePathTranslation + '.' + configs.key + '.offLabel' | translate):''"
      ></p-toggleButton>
</div>


