import {
  BaseAction,
  BaseActionKey,
} from '../../core/components/dynamic-form/models/base-action';
import { ActionName } from '../../core/components/dynamic-form/models/constants/action-names';
import { ToolbarSlot } from '../../core/components/dynamic-form/models/constants/toolbar-slots';

/**
 * Constant that defines the fields and actions for the dynamic form config items
 */
export const CONFIG_ITEMS_DYNAMIC_FORM = {
  fields: [],
  actions: [
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Save,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Cancel,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Edit,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='read'",
    }),
  ],
};
