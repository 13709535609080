import { NgModule } from '@angular/core';
import { RouterModule, Routes, ROUTES } from '@angular/router';
import { PrgLayoutsEmptyComponent } from '../layouts/empty/prg-layouts-empty.component';
import { AuthGuardLogin } from './guards/prg-auth-guard.service';
import { PrgAuthLoginPage } from './pages/login/prg-auth-login.page';
import { PrgAuthRegisterPage } from './pages/register/prg-auth-register.page';
import { PrgAuthRequestPasswordPage } from './pages/request-password/prg-auth-request-password.page';
import { PrgAuthResetPasswordPage } from './pages/reset-password/prg-auth-reset-password.page';
import { authConfig } from './prg-auth.module';

/**
 * Authentication routing module routes
 */
const standardRoutes: Routes = [];

/**
 * Authentication Routing Module
 */
@NgModule({
  imports: [RouterModule.forChild(standardRoutes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        let routes: Routes = [];
        routes.push({
          path: authConfig.authRoutingBasePath,
          component: PrgLayoutsEmptyComponent,
          children: [
            {
              path: authConfig.authRoutingSignupPath,
              component: PrgAuthRegisterPage,
              canActivate: [AuthGuardLogin],
            },
            {
              path: authConfig.authRoutingLoginPath,
              component: PrgAuthLoginPage,
              canActivate: [AuthGuardLogin],
            },

            {
              path: authConfig.authRoutingRequestPasswordPath,
              component: PrgAuthRequestPasswordPage,
            },

            {
              path: authConfig.authRoutingResetPasswordPath,
              /*canActivate: [PrgAuthGuardPassword],*/
              component: PrgAuthResetPasswordPage,
            },
            {
              path: '',
              redirectTo: authConfig.authRoutingLoginPath,
              pathMatch: 'full',
            },
          ],
        });

        return [...routes, ...standardRoutes];
      },
      multi: true,
    },
  ],
})
export class PrgAuthRoutingModule {}
