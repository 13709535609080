import { Directive, Input } from '@angular/core';
import { Password } from 'primeng/password';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Input Text
 */
export class InputPasswordConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.inputPassword;
  /**
   *Text to prompt password entry. Defaults to PrimeNG I18N API configuration.
   */
  public promptLabel: string = null;

  /**
   *^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}).
   */
  public mediumRegex: string = 'Regex for a medium level password.';

  /**
   *^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})
   */
  public strongRegex: string = 'Regex for a strong level password.';

  /**
   *Text for a weak password. Defaults to PrimeNG I18N API configuration.
   */
  public weakLabel: string = null;

  /**
   *Text for a medium password. Defaults to PrimeNG I18N API configuration.
   */
  public mediumLabel: string = null;

  /**
   *Text for a strong password. Defaults to PrimeNG I18N API configuration.
   */
  public strongLabel: string = null;

  /**
   *Whether to show the strength indicator or not.
   */
  public feedback: boolean = true;

  /**
   *Whether to show an icon to display the password as plain text.
   */
  public toggleMask: boolean = false;

  /**
   *Id of the element or body for document where the overlay should be appended to.
   */
  public appendTo: string = null;

  /**
   *Inline style of the input field.
   */
  public inputStyle: any = null;

  /**
   *Style class of the input field.
   */
  public inputStyleClass: string = null;

  /**
   *Identifier of the accessible input element.
   */
  public inputId: string = null;

  /**
   *Inline style of the component.
   */
  public style: string = null;

  /**
   *Style class of the component.
   */
  public styleClass: string = null;

  /**
   *Advisory information to display on input.
   */
  public placeholder: string = null;

  /**
   *Label of the input for accessibility.
   */
  public label: string = null;

  /**
   *Defines a string that labels the input for accessibility.
   */
  public ariaLabel: string = null;

  /**
   *Specifies one or more IDs in the DOM that labels the input field.
   */
  public ariaLabelledBy: string = null;

  /**
   *When enabled, a clear icon is displayed to clear the value.
   */
  public showClear: boolean = false;

  /**
   * constructor
   * @param init init
   */
  constructor(init?: Partial<InputPasswordConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 * */
@Directive({
  selector: '[setInputPasswordConfigs]',
})
export class PrgSetInputPasswordConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: Password) {}

  /**
   * input password configs and assign configs to element
   */
  @Input() set setInputPasswordConfigs(configs: InputPasswordConfiguration) {
    Object.assign(this.element, configs);
  }
}
