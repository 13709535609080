
<div *ngIf="form && configs != null" [formGroup]="form">
  <p>{{configs.basePathTranslation + '.' + configs.key + '.label' | translate}}</p>
  <p-cascadeSelect
    [setCascadeSelectConfigs]="configs"
    [formControlName]="configs.key"
    [placeholder]="
        configs.basePathTranslation + '.' + configs.key + '.placeholder'
          | translate
      "
    [inputId]="configs.key"
    (onChange)="onChanged($event)"
  ></p-cascadeSelect>

</div>
