import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    TreeModule,
    SidebarModule,
    ListboxModule,
    MultiSelectModule,
    ContextMenuModule,
    SelectButtonModule,
    KnobModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    ChipsModule,
    InputSwitchModule,
    InputTextModule,
    RatingModule,
    RadioButtonModule,
    CheckboxModule,
    TreeTableModule,
    TriStateCheckboxModule,
    SplitterModule,
    TabMenuModule,
    DynamicDialogModule,
    OrderListModule,
    FileUploadModule,
    InputNumberModule,
    PasswordModule,
    SpeedDialModule,
    AutoCompleteModule,
    SplitButtonModule,
    ToolbarModule,
    InputMaskModule,
    ImageModule,
    MessageModule,
    RippleModule,
    ColorPickerModule,
    CascadeSelectModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ToggleButtonModule,
    ConfirmDialogModule,
    ChipModule,
    TieredMenuModule,
    MenuModule,
    AccordionModule,
  ],
  exports: [
    MenuModule,
    TieredMenuModule,
    SelectButtonModule,
    RadioButtonModule,
    ChipModule,
    ChipsModule,
    CommonModule,
    KnobModule,
    ProgressSpinnerModule,
    TableModule,
    TreeModule,
    CalendarModule,
    SliderModule,
    CascadeSelectModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ListboxModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    InputSwitchModule,
    RatingModule,
    CheckboxModule,
    TreeTableModule,
    AutoCompleteModule,
    TriStateCheckboxModule,
    SplitterModule,
    TabMenuModule,
    InputMaskModule,
    DynamicDialogModule,
    OrderListModule,
    FileUploadModule,
    SidebarModule,
    InputNumberModule,
    PasswordModule,
    SpeedDialModule,
    SplitButtonModule,
    ToolbarModule,
    ImageModule,
    MessageModule,
    RippleModule,
    TranslateModule,
    RouterModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    ToggleButtonModule,
    ConfirmDialogModule,
    AccordionModule,
    InputTextareaModule,
  ],
})
export class PrgSharedComponentsModule {}
