import { Injectable } from '@angular/core';
import clone from 'just-clone';

/**
 * injectable
 */
@Injectable({
  providedIn: 'root',
})
export class ObjectsUtilityService {
  /**
   * Constructor
   */
  constructor() {}

  /**
   * this function return an cloned object
   * @param objectToClone
   * @returns any
   */
  public cloneObject(objectToClone: any): any {
    // TODO: search a better way to clone object
    return clone(objectToClone);
  }

  /**
   * This function is responsible to transform a nested object into a flat object
   * @param object
   * @param {string} separator
   * @returns {{}}
   */
  public flattenObj(object: object, separator: string): object {
    // The object which contains the
    // final result
    let result = {};

    // loop through the object "ob"
    for (const i in object) {
      // We check the type of the i using
      // typeof() function and recursively
      // call the function again
      if (typeof object[i] === 'object' && !Array.isArray(object[i])) {
        const temp = this.flattenObj(object[i], separator);
        for (const j in temp) {
          // Store temp in result
          result[i + '.' + j] = temp[j];
        }
      }

      // Else store ob[i] in result directly
      else {
        result[i] = object[i];
      }
    }
    return result;
  }

  /**
   * This function is responsible a flat object into a nested object
   *
   * Nested object are define by a separator on properties ex :'.'
   * @param {object} object
   * @param {string} separator
   * @returns {object}
   */
  public unFlattenObj(object: object, separator: string): object {
    const result = {};
    let temp, substrings, property, i;
    for (property in object) {
      substrings = property.split(separator);
      temp = result;
      for (i = 0; i < substrings.length - 1; i++) {
        if (!(substrings[i] in temp)) {
          if (isFinite(substrings[i + 1])) {
            temp[substrings[i]] = [];
          } else {
            temp[substrings[i]] = {};
          }
        }
        temp = temp[substrings[i]];
      }
      temp[substrings[substrings.length - 1]] = object[property];
    }
    return result;
  }
}
