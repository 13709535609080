import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { CheckboxConfiguration } from './checkbox.model';

/**
 * Checkbox Component
 */
@Component({
  selector: 'prg-checkbox-field',
  templateUrl: './checkbox.component.html',
})
export class PrgCheckboxComponent {
  /**
   * Property binding of checkbox values (multiple checkboxes)
   * @type {any}
   */
  public valueChecked: any[] = [];

  /**
   * Checkbox config
   */
  @Input() configs: CheckboxConfiguration;
  /**
   * Form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the output
   */
  public onChanged(event: any) {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event.checked,
          event: event,
        })
      );
    }
  }
}
