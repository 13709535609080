import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { PrgCoreDynamicFormModule } from '../core/components/dynamic-form/prg-core-dynamic-form.module';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';
import { PrgEntityTypeElementComponent } from './components/entity-type-element/prg-entity-type-element.component';
import { PrgEntityTypeConfigsComponent } from './components/entity-type/components/entity-type-configs/prg-entity-type-configs.component';
import { PrgEntityTypeComponent } from './components/entity-type/prg-entity-type.component';
import { PrgListEntityTypesComponent } from './components/list-entity-types/prg-list-entity-types.component';
import { PrgListSpecificEntityTypeComponent } from './components/list-specific-entity-type/prg-list-specific-entity-type.component';
import { MockEntityTypesService } from './mock/services/entity-types/entity-types.service.mock';
import { PrgEntityTypesConfig } from './models/prg-entity-types-config';
import { PrgEntityTypeElementPage } from './pages/entity-type-element/prg-entity-type-element.page';
import { PrgEntityTypesPage } from './pages/entity-types/prg-entity-types.page';
import { PrgSpecificEntityTypePage } from './pages/specific-entity-type/specific-entity-type.page';
import { PrgEntityTypesRoutingModule } from './prg-entity-types-routing.module';
import { PRG_ENTITY_TYPES_CONFIG } from './services/entity-types-configuration/prg-entity-types-configuration.service';
import { AbstractEntityTypeService } from './services/entity-types/abstract-entity-type.service';
import { EntityTypeService } from './services/entity-types/entity-type.service';

@NgModule({
  declarations: [
    PrgEntityTypesPage,
    PrgListEntityTypesComponent,
    PrgEntityTypeComponent,
    PrgEntityTypeConfigsComponent,
    PrgListSpecificEntityTypeComponent,
    PrgSpecificEntityTypePage,
    PrgEntityTypeElementComponent,
    PrgEntityTypeElementPage,
  ],
  imports: [
    PrgSharedComponentsModule,
    PrgEntityTypesRoutingModule,
    PrgCoreDynamicFormModule,
  ],
  exports: [
    PrgListEntityTypesComponent,
    PrgListSpecificEntityTypeComponent,
    PrgEntityTypeElementComponent,
  ],
  entryComponents: [PrgEntityTypeComponent, PrgEntityTypeElementComponent],
  providers: [DynamicDialogConfig, DynamicDialogRef],
})
export class PrgEntityTypesModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgEntityTypesConfig: PrgEntityTypesConfig
  ): ModuleWithProviders<PrgEntityTypesModule> {
    entityTypesConfig = prgEntityTypesConfig;
    return {
      ngModule: PrgEntityTypesModule,
      providers: [
        {
          provide: PRG_ENTITY_TYPES_CONFIG,
          useValue: prgEntityTypesConfig,
        },
        {
          provide: AbstractEntityTypeService,
          useClass: prgEntityTypesConfig.useMockServices
            ? MockEntityTypesService
            : EntityTypeService,
        },
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the EntityTypesConfig object
 */
export let entityTypesConfig: PrgEntityTypesConfig;
