import { Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthStorage } from '../../models/prg-auth-config';
import { User } from '../../models/user.model';

/**
 * AbstractAuthService class that defines an
 * <br />abstract authentication service to
 * <br />be extended by the mock and real
 * <br />authentication services
 */
export abstract class AbstractAuthService {
  /**
   * Currently logged user
   */
  protected loggedUserSubject = new BehaviorSubject<User>(null);

  /**
   * Currently stored username to be used
   * <br />on the login form
   * // CR: devemos explicar melhor para que serve esta propriedade, eu não estava a perceber até olhar para o código do login,
   *        talvez mudar também o nome para algo mais explicito
   */
  protected storedUserNameSubject = new BehaviorSubject<string>('');

  /**
   * constructor
   * @param authStorage
   * @protected
   */
  protected constructor(
    @Inject('AUTHSTORAGE') private authStorage: AuthStorage
  ) {
    this.getUserNameFromStorage();
    this.getUserLoggedFromStorage();
  }

  /**
   * User sign up (Async)
   *
   * @param {string} email The user e-mail
   * @returns Boolean promise, true when successful
   */
  public abstract signupAsync(email: string): Promise<boolean>;

  /**
   * User login (Async)
   *
   * @param {string} email The user e-mail
   * @param {string} password The user password
   * @param {boolean} storeUserName Store username
   * @returns Boolean promise, true when successful
   */
  public abstract loginAsync(
    email: string, // CR: se pode ser email ou username, mudar parametro para emailOrUsername
    password: string,
    storeUserName: boolean
  ): Promise<boolean>;

  /**
   * User logout (Async)
   *
   * @returns Boolean promise, true when successful
   */
  public abstract logoutAsync(): Promise<boolean>;

  /**
   * Request to reset the password (Async)
   *
   * @param {string} email The user e-mail
   * @returns Boolean promise, true when successful
   */
  public abstract requestResetPasswordAsync(email: string): Promise<boolean>;

  /**
   * Getter for token reset password (Async)
   *
   * @param {string} tokenReset The token reset password
   * @returns Boolean promise, true when successful
   */
  public abstract getTokenResetPasswordAsync(
    tokenReset: string
  ): Promise<boolean>;

  /**
   * Method for reset password (Async)
   *
   * @param {string} newPassword The new password
   * @param {string} tokenReset The token reset password
   * @returns Boolean promise, true when successful
   */
  public abstract resetPasswordAsync(
    newPassword: string,
    tokenReset: string
  ): Promise<boolean>;

  /**
   * Setter for loggedUserSubject
   *
   * @param {User} userLogged The User object
   */
  protected setLoggedUser(userLogged: User): void {
    // CR: falta guardar isto na storage (session ou local), não?

    this.authStorage.storage.setItem(
      this.authStorage.keyLoggedUser,
      JSON.stringify(userLogged)
    );

    this.loggedUserSubject.next(userLogged);
  }

  /**
   * Setter for storedUserNameSubject and saving username on local storage
   *
   * @param {string} value The storedUserName string
   */
  protected setStoredUserName(value: string): void {
    // ok CR: falta guardar isto na storage (session ou local), não?
    this.authStorage.storage.setItem(
      this.authStorage.keyStoredUserName,
      JSON.stringify(value)
    );
    this.storedUserNameSubject.next(value);
  }

  /**
   * Remove username from local storage
   *
   */
  protected removeStoredUserName(): void {
    this.authStorage.storage.removeItem(this.authStorage.keyStoredUserName);
    this.storedUserNameSubject.next(null);
  }

  /**
   * Getter for loggedUserSubject
   *
   * @returns The loggedUserSubject as an observable
   */
  public getLoggedUserObservable(): Observable<User> {
    return this.loggedUserSubject.asObservable();
  }

  /**
   * Getter for loggedUserSubject
   *
   * @returns The loggedUserSubject current value
   */
  public getLoggedUser(): User {
    return this.loggedUserSubject.getValue();
  }

  /**
   * Getter for storedUserNameSubject
   *
   * @returns The storedUserNameSubject as an observable
   */
  public getStoredUserNameObservable(): Observable<string> {
    return this.storedUserNameSubject.asObservable();
  }

  /**
   * Getter for storedUserNameSubject
   *
   * @returns The storedUserNameSubject current value
   */
  public getStoredUserName(): string {
    return this.storedUserNameSubject.getValue();
  }

  /**
   * Get username from local storage and if it exists,
   *  the value is emitted on storedUserNameSubject
   */
  private getUserNameFromStorage(): void {
    const userName = JSON.parse(
      this.authStorage.storage.getItem(this.authStorage.keyStoredUserName)
    );
    if (userName == null || userName.length == 0) {
      return;
    } else {
      this.setStoredUserName(userName);
    }
  }

  /**
   * Get the user logged from local storage
   * @private
   */
  private getUserLoggedFromStorage(): void {
    const user: User = JSON.parse(
      this.authStorage.storage.getItem(this.authStorage.keyLoggedUser)
    );

    if (user == null) {
      return;
    } else {
      this.setLoggedUser(user);
    }
  }
  /**
   * User logout Base Method (Async)
   *
   * @returns Boolean promise, true when successful
   */
  protected baseLogoutAsync(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.authStorage.storage.removeItem(this.authStorage.keyLoggedUser);
      this.setLoggedUser(null);
      resolve(true);
    });
  }
}
