<div class="border-1 border-300 shadow-4 text-center">
  <p-image  [src]="termsImage"  alt="" ></p-image>
  <!-- <h1 [innerText]="termsTitle|uppercase"></h1>-->
  <p-accordion  [multiple]="true" >
    <div *ngFor="let item of terms">
      <p-accordionTab  header="{{item.header}}" style="width: 100%">
        <p [innerHTML]="item.content"></p>
      </p-accordionTab>
    </div>
  </p-accordion>
</div>
