import { Injectable } from "@angular/core";
import { AbstractConfigurationItemsService } from "./abstract-configuration-items.service";
import { MockDatabase } from "../../models/database.mock";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * MOCK ConfigurationItemsService implementation
 */
export class MockConfigurationItemsService extends AbstractConfigurationItemsService {
  /**
   * constructor
   */
  constructor() {
    super();
  }

  /**
   * Gets the mock configuration items from
   * <br />the mock database and sets them
   * <br />on the configurationItemsSubject
   *
   * @returns Boolean promise, true when successful
   */
  public getConfigurationItemsAsync(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const items = MockDatabase.ConfigurationItems;
      if (items != null && items.length) {
        this.setConfigurationItems(items);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
}
