import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { RatingConfiguration } from './rating.model';

/**
 * Component Rating
 */
@Component({
  selector: 'prg-rating-field',
  templateUrl: './rating.component.html',
})
export class PrgRatingComponent {
  /**
   * Rating Configs
   */
  @Input() configs: RatingConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;
  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the outpout
   */
  public onChanged(event: any) {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event.value,
          event: event,
        })
      );
    }
  }
}
