<div>
  <p-table
    #dataTableComponent
    [value]="entityTypeAllElements"
    [lazy]="true"
    (onLazyLoad)="lazyLoadElements($event)"
    [columns]="selectedColumnsObject"
    [paginator]="true"
    [rows]="tableConfigs.pagination.totalRows"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="tableConfigs.pagination.rowsPerPageOptions"
    [reorderableColumns]="true"
    [globalFilterFields]="['name']"
    [filterDelay]="1000"
    [sortMode]="'multiple'"
    [multiSortMeta]="tableConfigs.multiSortMeta"
    responsiveLayout="scroll"
  >
    <ng-template pTemplate="caption">
      <div style="text-align: left">
        <p-button
          [label]="'buttons.add.label' | translate"
          (click)="addNewElement(false)"
        ></p-button>
        <p-multiSelect
          [options]="entityGroups"
          [group]="true"
          [(ngModel)]="selectedColumnsObject"
          scrollHeight="250px"
          optionLabel="name"
          [maxSelectedLabels]="1"
          selectedItemsLabel="{0} {{ columnsSelectedLabel }}"
        >
          <ng-template let-group pTemplate="group">
            <div *ngIf="group.items.length" class="flex align-items-center">
              <span>
                {{ 'entity-type.tabs.' + group.value + '.label' | translate }}
              </span>
            </div>
          </ng-template>
        </p-multiSelect>
        <!--we are calling this function on the input to trigger the lazy load function-->
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="
              dataTableComponent.filterGlobal(
                $any($event).target.value,
                'contains'
              )
            "
            [ngModel]="globalFilterValue"
            [disabled]="globalFilterDisabled"
            placeholder="{{
              'components.entity-type.global-search.placeholder' | translate
            }}"
          />
        </span>
        <button
          pButton
          label="{{ 'buttons.reset-table.label' | translate }}"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          (click)="clearAllFilters()"
        ></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 2rem">
          <p-button
            type="button"
            icon="pi pi-plus-circle"
            pAddRow
            (click)="addNewElement(true)"
          ></p-button>
        </th>
        <ng-container *ngFor="let col of selectedColumnsObject">
          <th [pSortableColumn]="col.name" pReorderableColumn>
            {{
              'entities.' + entityType.name + '.fields.' + col.name + '.label'
                | translate
            }}
            <p-sortIcon [field]="col.name"></p-sortIcon>
            <p-columnFilter
              [hidden]="globalFilterIsActive"
              [type]="primeNgTypeToOurTypes[col.dataTypeId]"
              [field]="col.name"
              [matchMode]="
                col.dataTypeId === 'dataType.lookupTable' ? 'equals' : null
              "
              display="menu"
              [showAddButton]="
                col.dataTypeId === 'dataType.lookupTable' ? false : true
              "
            >
              <ng-template
                *ngIf="col.dataTypeId === 'dataType.lookupTable'"
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-dropdown
                  [setSelectConfigs]="{
                    readonly: false,
                    optionLabel: 'label',
                    optionValue: 'id',
                    key: col.name,
                    value: dataTableComponent.filters[col.name][0].value,
                    referenceType: ReferenceType.LookupTable,
                    referenceName: col.reference
                  }"
                  (onChange)="
                    lookupTableFilterChange($event.value, col.name, 'equals')
                  "
                  placeholder="Select any"
                >
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element>
      <tr style="cursor: pointer" (click)="editElement($event, false, element)">
        <td>
          <i class="pi pi-eye" (click)="editElement($event, true, element)"></i>
        </td>
        <td *ngFor="let col of selectedColumnsObject; let i = index">
          <ng-container [ngSwitch]="col.dataTypeId">
            <p *ngSwitchCase="'dataType.lookupTable'">
              <p-dropdown
                [setSelectConfigs]="{
                  readonly: true,
                  optionLabel: 'label',
                  optionValue: 'id',
                  key: col.name,
                  value: element[col.name],
                  referenceType: ReferenceType.LookupTable,
                  referenceName: col.reference
                }"
              ></p-dropdown>
            </p>
            <p *ngSwitchCase="'dataType.date'">
              {{ element[col.name] | date: ('app.date-format' | translate) }}
            </p>
            <p *ngSwitchDefault>{{ element[col.name] }}</p>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
