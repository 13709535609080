import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BaseActionKey,
  DynamicFormActionOutput,
  DynamicFormFieldChangeOutput,
} from '../../../core/components/dynamic-form/models/base-action';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { PrgGridsterConfig } from '../../models/prg-gridster-config';
import { AbstractDashboardService } from '../../services/dashboard/abstract-dashboard.service';
import { DASHBOARD_OPTIONS_DYNAMIC_FORM } from './dashboard-options-dynamic-form-structure/dashboard-options-dynamic-form';

/**
 * Dashboard Options Component
 */
@Component({
  selector: 'prg-dashboard-options',
  templateUrl: './prg-dashboard-options.component.html',
  styleUrls: ['./prg-dashboard-options.component.scss'],
})
export class PrgDashboardOptionsComponent implements OnInit {
  /**
   * The view mode for dynamic form
   * @type {ViewMode}
   */
  public dashboardOptionsViewMode = ViewMode.Read;
  /**
   * The dynamic form fields to build dynamic dashboard options form in template
   */
  public dashboardOptionsDynamicFormFields: any[] =
    this.arrayUtilityService.clone(DASHBOARD_OPTIONS_DYNAMIC_FORM.fields);
  /**
   * The dynamic form actions to build dynamic dashboard options form  in template
   */
  public dashboardOptionsDynamicFormActions: any[] =
    this.arrayUtilityService.clone(DASHBOARD_OPTIONS_DYNAMIC_FORM.actions);

  /**
   * The dashboard settings object flatten object based on Gridster options
   * @type {any}
   */
  public dashboardOptionsFlatten: any;
  /**
   * The dashboard settings object based on Gridster options
   * @type {PrgGridsterConfig}
   * @private
   */
  private dashboardOptions: PrgGridsterConfig;

  /**
   * Constructor
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {DynamicDialogRef} dynamicDialogRef
   * @param {DynamicDialogConfig} dynamicDialogConfig
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {AbstractDashboardService} dashboardService
   */
  constructor(
    private arrayUtilityService: ArrayUtilityService,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private objectsUtilityService: ObjectsUtilityService,
    private dashboardService: AbstractDashboardService
  ) {}

  /**
   * ngOnInit
   *
   * Get the data from parent component
   */
  public ngOnInit() {
    this.dashboardOptions = this.objectsUtilityService.cloneObject(
      this.dynamicDialogConfig.data?.dashboardOptions
    );
    this.dashboardOptionsFlatten = this.objectsUtilityService.cloneObject(
      this.objectsUtilityService.flattenObj(this.dashboardOptions, '.')
    );
  }

  /**
   * This function is responsible to handle actions from the dynamic form
   * @param {DynamicFormActionOutput} event
   */
  public actionOutput(event: DynamicFormActionOutput): void {
    switch (event.action) {
      case BaseActionKey.Save:
        this.dynamicDialogRef.close(
          this.objectsUtilityService.unFlattenObj(event.formEntity, '.')
        );
        this.dashboardOptionsViewMode = ViewMode.Read;
        break;

      case BaseActionKey.Cancel:
        this.dashboardService.setDashboardOptionsChanges({
          gridsterConfig: this.objectsUtilityService.unFlattenObj(
            this.dashboardOptionsFlatten,
            '.'
          ),
          gridsterItems: null,
        });
        this.dashboardOptionsViewMode = ViewMode.Read;
        break;

      case BaseActionKey.Edit:
        this.dashboardOptionsViewMode = ViewMode.Edit;
        break;
      default:
        break;
    }
  }

  /**
   * This function is called whenever is a change on a field and emit a new value for DashboardOptionsChangesSubject
   * @param {DynamicFormFieldChangeOutput} event
   */
  public fieldChanges(event: DynamicFormFieldChangeOutput): void {
    this.dashboardService.setDashboardOptionsChanges({
      gridsterConfig: this.objectsUtilityService.unFlattenObj(
        event.formEntity,
        '.'
      ),
      gridsterItems: null,
    });
  }
}
