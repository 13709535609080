import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

/**
 * layout page
 */
@Component({
  selector: 'prg-layouts-header-sidebar',
  templateUrl: './prg-layouts-header-sidebar.html',
  styleUrls: ['./prg-layouts-header-sidebar.scss'],
})
/**
 * Page Main Layout
 */
export class PrgLayoutsHeaderSidebarComponent implements AfterViewInit {
  /**
   * constructor
   * @param cdr
   */
  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
