import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';
import { PrgLookupTableItemsTableComponent } from './components/lookupTableTable/components/lookupTableItemsTable/prg-lookup-table-items-table.component';
import { PrgLookupTableTableComponent } from './components/lookupTableTable/prg-lookup-table-table.component';
import { MockLookupTableService } from './mock/services/lookup-table/lookup-table.mock.service';
import { PrgLookupTableConfig } from './models/prg-lookup-table-config';
import { PrgLookupTablePage } from './pages/prg-lookup-table.page';
import { PrgLookupTableRoutingModule } from './prg-lookup-table-routing.module';
import { PRG_LOOKUP_TABLE_CONFIG } from './services/lookup-table-configuration/prg-lookup-table-configuration.service';
import { AbstractLookupTableService } from './services/lookup-table/abstract-lookup-table.service';
import { LookupTableService } from './services/lookup-table/lookup-table.service';

@NgModule({
  declarations: [
    PrgLookupTablePage,
    PrgLookupTableTableComponent,
    PrgLookupTableItemsTableComponent,
  ],
  imports: [PrgSharedComponentsModule, PrgLookupTableRoutingModule],
  exports: [PrgLookupTableTableComponent, PrgLookupTableItemsTableComponent],
})
export class PrgLookupTableModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgLookupTableConfig: PrgLookupTableConfig
  ): ModuleWithProviders<PrgLookupTableModule> {
    lookupTableConfig = prgLookupTableConfig;
    return {
      ngModule: PrgLookupTableModule,
      providers: [
        {
          provide: PRG_LOOKUP_TABLE_CONFIG,
          useValue: prgLookupTableConfig,
        },
        {
          provide: AbstractLookupTableService,
          useClass: prgLookupTableConfig.useMockServices
            ? MockLookupTableService
            : LookupTableService,
        },
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the AuthCoreConfig object
 */
export let lookupTableConfig: PrgLookupTableConfig;
