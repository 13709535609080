import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BaseAction,
  BaseActionKey,
  DynamicFormActionOutput,
} from '../../../core/components/dynamic-form/models/base-action';
import { BaseField } from '../../../core/components/dynamic-form/models/base-field';
import { ActionName } from '../../../core/components/dynamic-form/models/constants/action-names';
import { ToolbarSlot } from '../../../core/components/dynamic-form/models/constants/toolbar-slots';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import {
  EntityType,
  EntityTypeOperation,
  EntityTypeProperty,
} from '../../models/entity-type.model';
import { AbstractEntityTypeService } from '../../services/entity-types/abstract-entity-type.service';

@Component({
  selector: 'prg-entity-type-element',
  templateUrl: './prg-entity-type-element.component.html',
  styleUrls: ['./prg-entity-type-element.component.scss'],
})
export class PrgEntityTypeElementComponent implements OnInit {
  /**
   * element aux
   */
  private _element: any;
  /**
   * element input
   */
  @Input() set element(element: any) {
    this._element = element;
  }
  /**
   * element getter
   */
  get element(): any {
    return this._element;
  }

  /**
   * entity type aux
   */
  private _entityType: EntityType;

  /**
   * entity type input
   */
  @Input() set entityType(entityType: EntityType) {
    if (entityType) {
      this._entityType = entityType;
      this.getBaseFieldsFromEntityType(entityType);
      this.getActionsFromEntityType(entityType.operations);
    }
  }

  /**
   * entity type getter
   */
  get entityType(): EntityType {
    return this._entityType;
  }

  /**
   * entity type view mode
   */
  @Input() elementViewMode: ViewMode = ViewMode.Read;

  /**
   * entity type form fields
   */
  public entityTypeDynamicFormElementFields: BaseField[] = [];

  /**
   * entity type form actions
   */
  public entityTypeDynamicFormElementActions: BaseAction[] = [];

  /**
   * variable to save if is a popup
   */
  private isPopup: boolean = false;

  /**
   * constructor
   *
   * @param arrayUtilityService
   * @param utilityService
   * @param entityTypeService
   * @param ref
   * @param config
   * @param objectsUtilityService
   */
  constructor(
    private arrayUtilityService: ArrayUtilityService,
    private utilityService: UtilityService,
    private entityTypeService: AbstractEntityTypeService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private objectsUtilityService: ObjectsUtilityService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  /**
   * ngOnInit
   *
   *  if this component is contained in a dialog it fetches the data from the config
   */
  ngOnInit() {
    if (this.config.hasOwnProperty('data')) {
      this._entityType = this.config.data.entityType;
      this._element = this.config.data.element;
      this.elementViewMode = this.config.data.viewMode;
      this.getBaseFieldsFromEntityType(this.config.data.entityType);
      this.getActionsFromEntityType(this.config.data.entityType.operations);
      this.isPopup = true;
    }

    this.entityTypeDynamicFormElementActions.push(
      new BaseAction({
        controlName: ActionName.Button,
        key: BaseActionKey.Edit,
        disableWhenFormInvalid: false,
        toolbarSlot: ToolbarSlot.Left,
        visibleExpression: "this.viewMode==='read'",
      }),
      new BaseAction({
        controlName: ActionName.Button,
        key: BaseActionKey.Cancel,
        disableWhenFormInvalid: false,
        toolbarSlot: ToolbarSlot.Left,
        visibleExpression: ' this.entity!= null && this.entity.id == null',
      })
    );
  }

  /**
   * this function receives the output and depending on
   * the action clicked performs a certain set of actions
   *
   * @param event
   */
  public async onActionOutputed(event: DynamicFormActionOutput): Promise<void> {
    switch (event.action) {
      case BaseActionKey.Cancel:
        if (event.formEntity.id) {
          this.element = this.objectsUtilityService.cloneObject(this.element);
          this.elementViewMode = ViewMode.Read;
        }
        if (this.isPopup) {
          this.ref.close();
        } else {
          window.location.href = window.location.href.replace('/new', '');
        }

        break;

      case BaseActionKey.Edit:
        this.elementViewMode = ViewMode.Edit;
        break;
      default:
        let actionResult: any;
        const action = this.entityTypeDynamicFormElementActions.find(
          (a) => a.key === event.action
        );

        if (action == null || action['operation'] == null) {
          return;
        }

        if (
          action.hasOwnProperty('confirmationModal') &&
          action.hasOwnProperty('confirmationModal') !== null
        ) {
          if (
            await this.notificationsService.prgConfirmationService(
              'messages.delete-confirmation',
              await this.translateService
                .get('entities.' + this.entityType.name + '.label')
                .toPromise()
            )
          ) {
            actionResult = await this.entityTypeService.executeAction(
              this.entityType.name,
              action['operation'],
              event.formEntity
            );
          }
        } else {
          actionResult = await this.entityTypeService.executeAction(
            this.entityType.name,
            action['operation'],
            event.formEntity
          );
        }

        if (!action.executedActionBehaviour) {
          break;
        }

        if (action.executedActionBehaviour.changeViewModeToRead) {
          this.elementViewMode = ViewMode.Read;
        }
        if (action.executedActionBehaviour.redirectToList) {
          if (this.isPopup) {
            this.ref.close(action.executedActionBehaviour);
          } else {
            this.router.navigate(['../'], { relativeTo: this.route });
          }
        }

        break;
    }
  }

  /**
   * this function creates the filds for the dynamic
   * form according to the properties of an entity type
   * @param entityType
   */
  private getBaseFieldsFromEntityType(entityType: EntityType): void {
    if (entityType.properties.length) {
      entityType.properties.forEach((property: EntityTypeProperty) => {
        if (property.guiSettings) {
          const guiSettings = this.utilityService.guiSettingToObject(
            property.guiSettings,
            'entities.' + entityType.name + '.fields'
          );

          if (!guiSettings.key) {
            guiSettings.key = property.name;
          }

          this.entityTypeDynamicFormElementFields.push(guiSettings);
        }
      });
    }

    this.entityTypeDynamicFormElementFields = this.arrayUtilityService.clone(
      this.entityTypeDynamicFormElementFields
    );
  }

  /**
   * this function creates the actions depending
   * on the operations associated to a given entity type
   *
   * @param entityTypeOperations
   */
  private getActionsFromEntityType(
    entityTypeOperations: EntityTypeOperation[]
  ) {
    entityTypeOperations.forEach((operation) => {
      if (operation.guiSettings) {
        const guiSettingsObject = this.utilityService.guiSettingToObject(
          operation.guiSettings
        );

        guiSettingsObject.operation = operation;

        const baseAction = new BaseAction(guiSettingsObject);

        this.entityTypeDynamicFormElementActions.push(baseAction);
      }
    });
    this.entityTypeDynamicFormElementActions = this.arrayUtilityService.clone(
      this.entityTypeDynamicFormElementActions
    );
  }
}
