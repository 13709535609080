import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AbstractAuthService } from '../services/auth/abstract-auth.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * Guard to verify if there is a logged user. If true, user can't access login page and register page.
 */
export class AuthGuardLogin implements CanActivate {
  /**
   * constructor
   * @param authService
   * @param router
   */
  constructor(
    private authService: AbstractAuthService,
    private router: Router
  ) {}

  /**
   *  This method is responsable to check if there is a user logged on local storage and return true if not or redirect user to home page if there is a user logged
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree}
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.getLoggedUser()) {
      return true;
    } else {
      return this.router.createUrlTree(['']);
    }
  }
}
