import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PrgConfigurationItemsConfig } from '../../models/prg-configuration-items-config';

/**
 * Inject token
 */
export const PRG_CONFIGURATION_ITEMS_CONFIG =
  new InjectionToken<PrgConfigurationItemsConfig>(
    'PRG_CONFIGURATION_ITEMS_CONFIG'
  );

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * PrgCoreConfigurationService class definition
 * <br />that specifies the properties that the
 * <br />application can send to the lib for
 * <br />configuration purposes
 */
export class PrgAuthConfigurationService {
  /**
   * Constructor
   *
   * @param prgConfigurationItemsConfig
   */
  constructor(
    @Inject(PRG_CONFIGURATION_ITEMS_CONFIG)
    private prgConfigurationItemsConfig: PrgConfigurationItemsConfig
  ) {}
}
