import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Login Page
 */
@Component({
  selector: 'prg-auth-login',
  templateUrl: './prg-auth-login.page.html',
  styleUrls: ['./prg-auth-login.page.scss'],
})
export class PrgAuthLoginPage implements OnInit {
  /**
   * constructor
   * @param logger
   */
  constructor(private logger: NGXLogger) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.logger.debug('Login Page on Init');
  }
}
