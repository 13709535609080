<span *ngIf="form" class="p-float-label" [formGroup]="form">
  <p-calendar
    [inputId]="configs.key"
    [setDateTimeConfigs]="configs"
    [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
    [formControlName]="configs.key"
    [name]="configs.key + '.name' | translate"
    [placeholder]="
      configs.basePathTranslation + '.' + configs.key + '.placeholder'
        | translate
    "
    (ngModelChange)="onChanged($event)"
  >
  </p-calendar>

  <label for="{{ configs.key }}"
    >{{
      configs.basePathTranslation + '.' + configs.key + '.label' | translate
    }}
  </label>
</span>
