import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UIChart } from 'primeng/chart';
import { filter, Subscription } from 'rxjs';
import { ObjectsUtilityService } from '../../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../../core/services/utility/utility.service';
import { AbstractDashboardService } from '../../../services/dashboard/abstract-dashboard.service';
import { BaseDashboardComponent } from '../base-dashboard-component';
import { PRG_BAR_DOUGHNUT_SETTINGS } from './prg-doughnut-chart-settings';

/**
 * Doughnut Chart Component
 */
@Component({
  selector: 'prg-doughnut-chart',
  templateUrl: './prg-doughnut-chart.component.html',
  styleUrls: ['./prg-doughnut-chart.component.scss'],
})
export class PrgDoughnutChartComponent
  extends BaseDashboardComponent
  implements OnInit, OnDestroy
{
  /**
   *  Data for this chart
   * @type {any}
   */
  public basicData: any;
  /**
   * Options for this chart
   * @type {any}
   */
  public basicOptions: any;
  /**
   * Height of the chart
   * @type {string}
   */
  public height: string;
  /**
   * Width of the chart
   * @type {string}
   */
  public width: string;
  /**
   * The item id used on dashboard
   */
  @Input('itemId') itemId;

  /**
   * The custom settings for this component
   * @param itemSettings
   */
  @Input('itemSettings') set itemSettings(itemSettings: any) {
    if (itemSettings != null) {
      this._itemSettings = this.objectsUtilityService.cloneObject(
        this.utilityService.guiSettingToObject(itemSettings)
      );
    }
  }

  /**
   * aux settings
   * @private
   */
  private _itemSettings;

  /**
   * Getter for item settings
   * @returns {any}
   */
  get itemSettings(): any {
    return this._itemSettings;
  }

  /**
   * The chart element from template
   * @type {UIChart}
   */
  @ViewChild('chart') chart: UIChart;
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * Cosntructor
   * @param {AbstractDashboardService} dashboardService
   * @param {UtilityService} utilityService
   * @param {ObjectsUtilityService} objectsUtilityService
   */
  constructor(
    private dashboardService: AbstractDashboardService,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService
  ) {
    super();
    PrgDoughnutChartComponent.key = PRG_BAR_DOUGHNUT_SETTINGS.key;
    PrgDoughnutChartComponent.staticSettings =
      PRG_BAR_DOUGHNUT_SETTINGS.staticSettings;
    PrgDoughnutChartComponent.configurationSettings =
      PRG_BAR_DOUGHNUT_SETTINGS.configurationSettings;
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    if (this._itemSettings == null) {
      this._itemSettings = this.objectsUtilityService.cloneObject(
        this.utilityService.guiSettingToObject(
          PrgDoughnutChartComponent.staticSettings
        )
      );
    }

    this._itemSettings = Object.values(this._itemSettings);

    this.basicData = {
      labels: ['A', 'B', 'C'],
      datasets: [
        {
          data: this._itemSettings,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
    };

    this.basicOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: '#495057',
          },
        },
      },
    };

    this.subscription.push(
      this.dashboardService
        .getDashboardItemsResizeObservable()
        .pipe(filter((item) => item?.item?.id === this.itemId))
        .subscribe((item) => {
          this.height = (item.height - item.height * 0.05).toString() + 'px';
          this.width = (item.width - item.width * 0.02).toString() + 'px';
          if (this.chart?.chart) {
            this.chart.chart.canvas.parentNode.style.height =
              (item.height - item.height * 0.05).toString() + 'px';
            this.chart.chart.canvas.parentNode.style.width =
              (item.width - item.width * 0.02).toString() + 'px';
            this.chart.reinit();
          }
        })
    );
  }
  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}
