/**
 * Token class that defines some basic JWT
 * <br />properties and adds additional
 * <br />generic properties required by the
 * <br />front-end
 */
export class Token {
  /**
   * User roles array (may only be included
   * <br />if a configuration item is true)
   */
  public roles: string[];
  /**
   * User scopes array
   */
  public scopes: string[];
  /**
   * * Workspace id
   */
  public workspaceId: string;
  /**
   * User id
   */
  public userId: string;
  /**
   * Type of token (normally "Bearer")
   */
  public typ: string;
  /**
   * User unique name
   */
  public unique_name: string;
  /**
   * User profile image url
   */
  public imageUrl: string;

  /**
   * Constructor
   */
  constructor(init?: Partial<Token>) {
    Object.assign(this, init);
  }
}

/**
 * PasswordRequirements configuration items model
 * <br />to be used when creating or resetting
 * <br />user passwords
 */
export class PasswordRequirements {
  /**
   * Gets or sets the minimum length a password must be. Defaults to 6.
   */
  public requiredLength: number = 6;
  /**
   * Gets or sets the minimum number of unique characters which a password must contain.
   *<br />Defaults to 1.
   */
  public requiredUniqueChars: number = 1;
  /**
   * Gets or sets a flag indicating if passwords must contain a non-alphanumeric character.
   * <br />Defaults to true.
   */
  public requireNonAlphanumeric: boolean = true;
  /**
   * Gets or sets a flag indicating if passwords must contain a lower case ASCII character.
   * <br />Defaults to true.
   */
  public requireLowercase: boolean = true;
  /**
   * Gets or sets a flag indicating if passwords must contain a upper case ASCII character.
   * <br />Defaults to true.
   */
  public requireUppercase: boolean = true;
  /**
   * Gets or sets a flag indicating if passwords must contain a digit.
   * <br />Defaults to true.
   */
  public requireDigit: boolean = true;
}

/**
 * User class that defines the basic properties
 * <br />of an user.
 */
export class User {
  /**
   * Id
   */
  public id: string;
  /**
   * Name
   */
  public name: string;
  /**
   * Token
   */
  public token: Token;
  /**
   * Email
   */
  public email: string;

  /**
   * Password
   */
  public password: string;

  /**
   * Reset Password token
   */
  public tokenResetPassword: string;

  /**
   * Constructor
   * @param {Partial<User>} init
   */
  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}
