import { Component, Inject, Input } from '@angular/core';
import { PrgDashboardConfig } from '../../models/prg-dashboard-config';
import { PRG_DASHBOARD_CONFIG } from '../../services/prg-dashboard-configuration/prg-dashboard-configuration.service';

/**
 * Dashboard List Component
 */
@Component({
  selector: 'prg-dashboard-list',
  templateUrl: './prg-dashboard-list.component.html',
  styleUrls: ['./prg-dashboard-list.component.scss'],
})
export class PrgDashboardListComponent {
  /**
   * Entity type name to pass to component prg-list-specific-entity-type
   * @type {string}
   */
  @Input() entityTypeName: string = 'dashboard';
  /**
   * The route for editing a dashboard
   */
  @Input() editDashboardPageRoute: string =
    '/' +
    this.prgDashboardConfig.dashboardRoutingBasePath +
    '/' +
    this.prgDashboardConfig.dashboardEditRoutingPath;

  /**
   * Constructor
   * @param {PrgDashboardConfig} prgDashboardConfig
   */
  constructor(
    @Inject(PRG_DASHBOARD_CONFIG) private prgDashboardConfig: PrgDashboardConfig
  ) {}
}
