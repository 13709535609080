import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LookupTable, LookupTableItem } from '../../models/lookup-table.model';
import { AbstractLookupTableService } from './abstract-lookup-table.service';

/** Injectable */
@Injectable({
  providedIn: 'root',
})

/**
 * Lookup table real service
 */
export class LookupTableService extends AbstractLookupTableService {
  /** constructor */
  constructor(@Inject(TranslateService) translateService: TranslateService) {
    super(translateService);
  }

  /**
   * Get all Lookup Tables
   */
  public getAllLookupTableAsync(): Promise<LookupTable[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * this method is responsible for saving a new lookupTable
   * or update an existing one
   *
   * @param lookupTable
   * @returns LookupTable
   */
  public saveLookupTableAsync(lookupTable: LookupTable): Promise<LookupTable> {
    throw new Error('Method not implemented.');
  }

  /**
   * this method is responsible for adding or
   * updating a set of lookup tables
   *
   * @param lookupTables
   * @returns LookupTable[]
   */
  public saveMultipleLookupTableAsync(
    lookupTables: LookupTable[]
  ): Promise<LookupTable[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Get lookup table items by lookup table id
   * @param id
   */
  public getLookupTableItemsByLookupTableIdAsync(
    id: string
  ): Promise<LookupTableItem[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Get lookup table items by lookup table name
   * @param name
   */
  public getLookupTableItemsByLookupTableNameAsync(
    name: string
  ): Promise<LookupTableItem[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * this method is responsible for saving a new lookupTableItem
   * or update an existing one
   * @param lookupTableItem
   */
  public saveLookupTableItemAsync(
    lookupTableItem: LookupTableItem
  ): Promise<LookupTableItem> {
    throw new Error('Method not implemented.');
  }

  /**
   * this method is responsible for adding or
   * updating a set of lookup tables items
   *
   * @param lookupTablesItems
   * @returns LookupTableItems[]
   */
  public saveMultipleLookupTableItemsAsync(
    lookupTables: LookupTableItem[]
  ): Promise<LookupTableItem[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Get lookup table item by id
   * @param {string} lookUpTableItemId
   * @returns {Promise<LookupTableItem>}
   */
  public getLookUpTableItemByIdAsync(
    lookUpTableItemId: string
  ): Promise<LookupTableItem> {
    throw new Error('Method not implemented.');
  }

  public getMultipleLookupTableByName(
    lookupTableNames: string[]
  ): Promise<LookupTable[]> {
    throw new Error('Method not implemented.');
  }
}
