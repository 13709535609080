import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Input Text
 */
export class InputTextConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.inputText;

  /* /!**
   * When present, it specifies that the element should be disabled.
   *!/
  public disabled: boolean = false;*/ //Property already on Base Field

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<InputTextConfiguration>) {
    super();
    Object.assign(this, init);
  }
}
