<prg-header></prg-header>
<div class="grid grid-nogutter">
  <prg-sidebar class="col-fixed grid-nogutter"></prg-sidebar>

  <main class="col grid-nogutter pt-2 px-2">
    <router-outlet></router-outlet>
  </main>
</div>

<!--

<div>
<prg-header></prg-header>
<div class="flex">
  <div class="flex-none flex">

    <prg-sidebar></prg-sidebar>
  </div>

  <div class="flex-grow-1 justify-content-center flex pt-2 px-5">
    <router-outlet ></router-outlet>
  </div>
</div>
</div>
-->
