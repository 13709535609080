import { Inject, Injectable, InjectionToken } from "@angular/core";
import { PrgTermsConfig } from "../../models/prg-terms-config";

/**
 * Inject token
 */
export const PRG_TERMS_CONFIG = new InjectionToken<PrgTermsConfig>(
  "PRG_TERMS_CONFIG"
);

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * PrgCoreConfigurationService class definition
 * <br />that specifies the properties that the
 * <br />application can send to the lib for
 * <br />configuration purposes
 */
export class PrgTermsConfigurationService {
  /**
   * Constructor
   *
   * @param prgTermsConfig
   */
  constructor(
    @Inject(PRG_TERMS_CONFIG) private prgTermsConfig: PrgTermsConfig
  ) {}
}
