import { Component } from '@angular/core';

/**
 * Request password page
 */
@Component({
  selector: 'prg-auth-request-password',
  templateUrl: './prg-auth-request-password.page.html',
  styleUrls: ['./prg-auth-request-password.page.scss'],
})
export class PrgAuthRequestPasswordPage {}
