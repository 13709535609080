import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { PrgValidators } from '../../models/validators';
import { ArrayUtilityService } from './array-utility.service';
import { ObjectsUtilityService } from './objects-utility.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * UtilityService
 */
export class UtilityService {
  /**
   * Constructor
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {ObjectsUtilityService} objectsUtilityService
   */
  constructor(
    private arrayUtilityService: ArrayUtilityService,
    private objectsUtilityService: ObjectsUtilityService
  ) {}

  /**
   * the default debounce time
   * @type {number}
   * @private
   */
  private defaultDebounceTime: number = 1500;

  /**
   * this function generate a string
   * @returns string
   */
  public newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        // tslint:disable-next-line: no-bitwise
        const r = (Math.random() * 16) | 0,
          // tslint:disable-next-line: no-bitwise
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  /**
   * this function make a timeout
   *
   * @param timeInMs
   * @returns Promise<void>
   */
  public sleepMsAsync(timeInMs: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, timeInMs);
    });
  }

  /**
   * this function make a timeout
   *
   * @param timeInSec
   * @returns Promise<void>
   */
  public sleepSecAsync(timeInSec: number): Promise<void> {
    return this.sleepMsAsync(timeInSec * 1000);
  }

  /**
   * Make eval of expression
   * @param {string} expression
   * @param context
   * @returns {any}
   */
  public evalFunction(expression: string, ...context: any[]): any {
    if (context == null || context.length === 0 || context[0] == null) {
      return function () {
        return eval(expression);
      };
    }
    let mergeContext = this.shallowClone(context[0]);
    context.forEach((cont) => {
      if (cont != null) {
        mergeContext = Object.assign(mergeContext, cont);
      }
    });
    return function () {
      return eval(expression);
    }.call(mergeContext);
    // return eval(expression.replace(new RegExp('this.', 'g'), 'context.'));
  }

  /**
   * An auxiliary function to make a deeper copy of the object
   * @param obj
   * @returns {any}
   * @private
   */
  private shallowClone(obj) {
    var clone = Object.create(Object.getPrototypeOf(obj));
    var props = Object.getOwnPropertyNames(obj);
    props.forEach(function (key) {
      var desc = Object.getOwnPropertyDescriptor(obj, key);
      Object.defineProperty(clone, key, desc);
    });
    return clone;
  }

  /**
   * debounce function
   * @param func
   * @param wait
   * @param immediate
   * @returns
   */
  public debounce(
    func: any,
    wait: number = this.defaultDebounceTime,
    immediate: boolean = false
  ) {
    let timeout: any;

    return function (args: any = null) {
      const context = this;

      const later = function () {
        timeout = null;
        if (!immediate) {
          if (Array.isArray(args)) {
            func.apply(context, args);
          } else {
            func.call(context, args);
          }
        }
      };

      const callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) {
        if (Array.isArray(args)) {
          func.apply(context, args);
        } else {
          func.call(context, args);
        }
      }
    };
  }

  /**
   * this function transforms the guiSettings into an object,
   * even managing the validators if they exist
   * @param guiSettings
   * @param basePathTranslation
   * @returns
   */
  public guiSettingToObject(
    guiSettings: string,
    basePathTranslation: string = null
  ): any {
    const guiSettingsObject = JSON.parse(guiSettings);

    if (!guiSettingsObject.basePathTranslation && basePathTranslation) {
      guiSettingsObject.basePathTranslation = basePathTranslation;
    }

    const validators = guiSettingsObject['validators'];
    if (validators) {
      guiSettingsObject['validators'] = [];
      validators.forEach((validator) => {
        guiSettingsObject['validators'].push(
          this.evalFunction('this.' + validator.trim(), PrgValidators)
        );
      });
    }
    return guiSettingsObject;
  }

  /**
   * This function build a tree structure to be used on primeng from a list of paths (array of strings) that
   * are joined with a separator ex: "[Configs:UserPreferences:Defaults,Configs:UserPreferences:User...]"
   * @param {string[]} arrayOfPaths
   * @param {string} separator
   * @returns {TreeNode[]}
   */
  public arrayOfPathsToTree(
    arrayOfPaths: string[],
    separator: string
  ): TreeNode[] {
    //Remove duplicates from the array of paths
    const itemsUniquePath: string[] = this.arrayUtilityService.clone(
      Array.from(new Set(arrayOfPaths))
    );

    //Sort array of paths alphabetically
    const itemsUniquePathSorted = itemsUniquePath.sort((a, b) =>
      a.localeCompare(b)
    );

    //split the path in subArrays with separator
    const itemsSplitPath = itemsUniquePathSorted.map((value) =>
      value.split(separator)
    );

    //Find out the number of levels that will have the menu
    const menuLevels = itemsSplitPath.reduce(
      (previousValue, currentValue) =>
        previousValue > currentValue.length
          ? previousValue
          : currentValue.length,
      0 // initial value
    );

    // Tree table construction
    const tree: TreeNode[] = [];
    const levels: any[] = [tree];
    let lastPath: string[] = [];
    itemsUniquePathSorted.forEach((path) => {
      let splitPathTemp = path.split(':');
      splitPathTemp.forEach((label, index) => {
        if (lastPath[index] === label) return;
        let parentPath: string[] = [];
        splitPathTemp.map((path, subIndex) => {
          if (index >= subIndex) {
            parentPath.push(path);
          }
        });
        if (index < menuLevels - 1) {
          levels[index].push({
            label,
            expanded: true,
            key: parentPath.join(separator),
            children: (levels[index + 1] = []),
            selectable: index === splitPathTemp.length - 1,
          });
        } else {
          levels[index].push({
            label,
            key: parentPath.join(separator),
            selectable: true,
          });
        }
      });
      lastPath = splitPathTemp;
    });
    return tree;
  }

  /**
   * This function is responsible to search for a key in map by a given value
   * @param map
   * @param searchValue
   * @returns {any}
   */
  public getKeyByValueOnMap(map, searchValue): any {
    for (let [key, value] of map.entries()) {
      if (value === searchValue) return key;
    }
  }
}
