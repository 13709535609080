import { Component } from '@angular/core';

/**
 * page entity types
 */
@Component({
  selector: 'prg-entity-types',
  templateUrl: './prg-entity-types.page.html',
  styleUrls: ['./prg-entity-types.page.scss'],
})
export class PrgEntityTypesPage {}
