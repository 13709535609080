<div>
  <ng-container *ngIf="multipleSelect; else elseBlock">
    <p-multiSelect
      [options]="rolesList"
      [(ngModel)]="rolesSelected"
      [optionLabel]="optionLabel"
      defaultLabel="{{
        'components.roles-scopes.select-role.label' | translate
      }}"
      [showHeader]="showHeader"
      (ngModelChange)="sendRolesSelected()"
      selectedItemsLabel="{{
        'components.roles-scopes.select-role.roles-selected.label' | translate
      }}"
      [maxSelectedLabels]="1"
    ></p-multiSelect>

    <button
      pButton
      *ngIf="useButton"
      type="button"
      label="{{ 'buttons.select.label' | translate }}"
      (click)="sendRolesSelected()"
      [disabled]="!(rolesSelected.length > 0)"
    ></button>
  </ng-container>
  <ng-template #elseBlock>
    <p-dropdown
      [options]="rolesList"
      [(ngModel)]="roleSelected"
      [optionLabel]="optionLabel"
      placeholder="{{
        'components.roles-scopes.select-role.label' | translate
      }}"
      (ngModelChange)="sendRolesSelected()"
      [filter]="true"
      filterBy="name"
    ></p-dropdown>
  </ng-template>
</div>
