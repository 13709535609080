import { Directive, Input } from '@angular/core';
import { RadioButton } from 'primeng/radiobutton';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Radio Button
 */
export class RadioButtonConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.radioButton;

  /**
   *Name of the radiobutton group.
   */
  public name: string = null;
  /**
   *Value of the radiobutton.
   */
  public value: any = null;
  /**
   *Label of the radiobutton.
   */
  public label: string = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;
  /**
   *Used to define a string that labels the input element.
   */
  public ariaLabel: string = null;
  /**
   *Inline style of the component.
   */
  public style: object = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Style class of the label.
   */
  public labelStyleClass: string = null;

  /**
   *An array of objects to display as dynamic values.Mandatory field.
   */
  public options: any[] = null;

  /**
   *Name of the value field of an option. Default 'value'.
   */
  public optionValue: string = 'value';

  /**
   *Name of the key field of an option.Default 'key'.Also used to bring the translation of the field
   */
  public optionKey: string = 'key';

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<RadioButtonConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setRadioButtonConfigs]',
})
export class PrgSetRadioButtonConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: RadioButton) {}

  /**
   * input Radio Button configs and assign configs to element
   */
  @Input() set setRadioButtonConfigs(configs: RadioButtonConfiguration) {
    Object.assign(this.element, configs);
  }
}
