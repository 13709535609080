import { Validators } from '@angular/forms';
import {
  BaseAction,
  BaseActionKey,
} from '../../core/components/dynamic-form/models/base-action';
import { BaseField } from '../../core/components/dynamic-form/models/base-field';
import { ActionName } from '../../core/components/dynamic-form/models/constants/action-names';
import { ControlName } from '../../core/components/dynamic-form/models/constants/control-names';
import { ToolbarSlot } from '../../core/components/dynamic-form/models/constants/toolbar-slots';

/**
 * Dynamic form fields and actions configurations to Entity type
 */
export const ENTITY_TYPE_DYNAMIC_FORM = {
  fields: [
    new BaseField({
      controlName: ControlName.inputText,
      value: null,
      key: 'name',
      validators: [Validators.required],
      basePathTranslation: 'entity-type.fields',
    }),
    new BaseField({
      controlName: ControlName.inputText,
      value: null,
      key: 'guiSettings',
      validators: [Validators.required],
      basePathTranslation: 'entity-type.fields',
    }),
    new BaseField({
      controlName: ControlName.inputText,
      value: null,
      key: 'group',
      validators: [Validators.required],
      basePathTranslation: 'entity-type.fields',
    }),
    new BaseField({
      controlName: ControlName.inputText,
      visibleExpression: ' this.entity!= null && this.entity.id',
      value: '1',
      key: 'universalStateId',
      basePathTranslation: 'entity-type.fields',
    }),
  ],
  actions: [
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Save,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Cancel,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Edit,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='read'",
    }),
  ],
};
