import { Validators } from '@angular/forms';
import { BaseField } from '../../core/components/dynamic-form/models/base-field';
import { QueryFilterExpression, QueryFilterOperation } from './query.model';

/**
 * Class to be used on the map of Query Filter to link fields to operators and data type
 */
export class OperatorsAndDataType {
  /**
   * Allowed operation types for the selected field
   */
  public queryFilterOperationByType: FilterOperationByTypeToTranslate[];
  /**
   * The data type for the selected field
   */
  public dataType: string;
}

/**
 * Interface that defines the filters expressions and his labels to be able to translate them
 */
export interface FilterExpressionToTranslate {
  /**
   * Filters Expression
   */
  expression: QueryFilterExpression;
  /**
   * Define label to be use in template to translate
   */
  label: string;
}

/**
 * Interface that defines the filters operators and his labels to be use on OperatorsAndDataType class
 * and translate them
 */
export interface FilterOperationByTypeToTranslate {
  /**
   * Operators filters
   */
  operators: QueryFilterOperation;
  /**
   * Define label to be use in template to translate
   */
  label: string;
}

/**
 * Constant that defines the properties for each column of the Queries Filter table
 */
export const QueriesFilterColumns: BaseField[] = [
  new BaseField({
    key: 'startGroup',
    validators: [Validators.required],
    value: true,
    readonly: true,
    basePathTranslation: 'queries.fields.query-filter.fields.',
  }),
  new BaseField({
    key: 'propertyName',
    validators: [Validators.required],
    readonly: true,
    basePathTranslation: 'queries.fields.query-filter.fields.',
  }),
  new BaseField({
    key: 'filterOperation',
    validators: [Validators.required],
    readonly: true,
    basePathTranslation: 'queries.fields.query-filter.fields.',
  }),
  new BaseField({
    key: 'value',
    validators: [Validators.required],
    readonly: true,
    basePathTranslation: 'queries.fields.query-filter.fields.',
  }),
  new BaseField({
    key: 'value2',
    readonly: true,
    basePathTranslation: 'queries.fields.query-filter.fields.',
  }),
  new BaseField({
    key: 'query-filter-row-actions',
    readonly: true,
    basePathTranslation: 'queries.query-modal.actions.',
  }),
  new BaseField({
    key: 'filterExpression',
    validators: [Validators.required],
    value: QueryFilterExpression.And,
    readonly: true,
    basePathTranslation: 'queries.fields.query-filter.fields.',
  }),
];
