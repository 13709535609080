import { NgModule } from '@angular/core';
import { RouterModule, Routes, ROUTES } from '@angular/router';
import { PrgLayoutsEmptyComponent } from '../layouts/empty/prg-layouts-empty.component';
import { PrgLayoutsHeaderSidebarComponent } from '../layouts/pages/prg-layouts-header-sidebar';
import { PrgConfigItemsAdminComponent } from './components/config-items-admin/prg-config-items-admin.component';
import { PrgConfigItemsUserComponent } from './components/config-items-user/prg-config-items-user.component';
import { configurationItemsConfig } from './prg-configuration-items.module';

/**
 * Config Items routing module routes
 */
const standardRoutes: Routes = [];

/**
 * Config Items Routing Module
 */
@NgModule({
  imports: [RouterModule.forChild(standardRoutes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        let routes: Routes = [];
        routes.push({
          path: configurationItemsConfig.configurationItemsRoutingBasePath,
          component: PrgLayoutsHeaderSidebarComponent,
          children: [
            {
              path: configurationItemsConfig.configurationItemsRoutingAdminPath,
              component: PrgConfigItemsAdminComponent,
            },
            {
              path: configurationItemsConfig.configurationItemsRoutingUserPath,
              component: PrgConfigItemsUserComponent,
            },
          ],
        });

        return [...routes, ...standardRoutes];
      },
      multi: true,
    },
  ],
})
export class PrgConfigurationItemsRoutingModule {}
