/**
 * class PrgError
 */
export class PrgError {
  /**
   * title key
   */
  titleKey: string | null;
  /**
   * detailKey
   */
  detailKey: string | null;

  /**
   * time to disappear
   */
  life: number = 3500;
  /**
   * extra data
   */
  data: any;

  /**
   * constructor
   *
   * @param error
   */
  constructor(error?: Partial<PrgError>) {
    Object.assign(this, error);
  }
}
