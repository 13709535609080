import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { PrgCoreDynamicFormModule } from '../core/components/dynamic-form/prg-core-dynamic-form.module';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';
import { PrgConfigItemsAdminComponent } from './components/config-items-admin/prg-config-items-admin.component';
import { PrgConfigItemsUserComponent } from './components/config-items-user/prg-config-items-user.component';
import { MockConfigurationItemsService } from './mock/services/configuration-items.service.mock';
import { PrgConfigurationItemsConfig } from './models/prg-configuration-items-config';
import { PrgConfigurationItemsRoutingModule } from './prg-configuration-items-routing.module';
import { AbstractConfigurationItemsService } from './services/configuration-items/abstract-configuration-items.service';
import { ConfigurationItemsService } from './services/configuration-items/configuration-items.service';
import { PRG_CONFIGURATION_ITEMS_CONFIG } from './services/prg-configuration-items-config/prg-configuration-items-config.service';

/**
 * Module that handles authentication
 */
@NgModule({
  declarations: [PrgConfigItemsUserComponent, PrgConfigItemsAdminComponent],
  imports: [
    PrgSharedComponentsModule,
    PrgConfigurationItemsRoutingModule,
    PrgCoreDynamicFormModule,
  ],
  exports: [],
})
export class PrgConfigurationItemsModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgConfigurationItemsConfig: PrgConfigurationItemsConfig
  ): ModuleWithProviders<PrgConfigurationItemsModule> {
    configurationItemsConfig = prgConfigurationItemsConfig;
    return {
      ngModule: PrgConfigurationItemsModule,
      providers: [
        {
          provide: PRG_CONFIGURATION_ITEMS_CONFIG,
          useValue: prgConfigurationItemsConfig,
        },
        {
          provide: AbstractConfigurationItemsService,
          useClass: prgConfigurationItemsConfig.useMockServices
            ? MockConfigurationItemsService
            : ConfigurationItemsService,
        },
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the ConfigItemsCoreConfig object
 */
export let configurationItemsConfig: PrgConfigurationItemsConfig;
