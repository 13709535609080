import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { SelectConfiguration } from './select.model';

/**
 * component dropdown/select
 */
@Component({
  selector: 'prg-select-field',
  templateUrl: './select.component.html',
})
export class PrgSelectComponent {
  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the outpout
   */
  public onChanged(event: any) {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event.value,
          event: event,
        })
      );
    }
  }

  /**
   * Select config
   */
  @Input() configs: SelectConfiguration;

  /**
   * Form
   */
  @Input() form: FormGroup;
}
