import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { PrgMenuItem } from '../../../../../../layouts/models/prg-menu-item';
import { ArrayUtilityService } from '../../../../../services/utility/array-utility.service';
import { ActionOutput } from '../../../models/base-action';
import { SpeedDialConfiguration } from './speed-dial.model';

/**
 * component speed dial
 */
@Component({
  selector: 'prg-speed-dial-action',
  templateUrl: './speed-dial.component.html',
})
export class PrgSpeedDialComponent {
  /**
   * Data of speed button menu
   * @type {PrgMenuItem[]}
   */
  public menuButton: PrgMenuItem[] = [];

  /**
   * Speed Dial configs
   */
  @Input('configs') set configs(configs: SpeedDialConfiguration) {
    this._configs = configs;
    this.assignCommandsToMenuButton(configs.items);
  }

  /**
   * Getter of configs
   * @returns {SpeedDialConfiguration}
   */
  get configs(): SpeedDialConfiguration {
    return this._configs;
  }

  /**
   * Configs aux
   * @type {SpeedDialConfiguration}
   * @private
   */
  private _configs: SpeedDialConfiguration;
  /**
   * form configs
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() actionClick = new EventEmitter<ActionOutput>();

  /**
   * Constructor
   * @param {ArrayUtilityService} arrayUtilityService
   */
  constructor(private arrayUtilityService: ArrayUtilityService) {}

  /**
   * function that triggers the output
   * @param {PrgMenuItem} menuItem
   */
  public onButtonClicked(menuItem: PrgMenuItem): void {
    if (menuItem?.key) {
      this.actionClick.emit({
        action: menuItem?.key,
        group: menuItem?.group,
      });
    }
  }

  /**
   * Assign commands to menu button
   * @param {PrgMenuItem[]} menuItems
   */
  public assignCommandsToMenuButton(menuItems: PrgMenuItem[]) {
    this.menuButton = this.arrayUtilityService.clone(menuItems);
    this.menuButton.map((item) => {
      if (item.key) {
        item.command = (event) => {
          this.onButtonClicked(event.item);
        };
      }
    });
  }
}
