<span *ngIf="form" class="p-float-label" [formGroup]="form">
  <span class="p-float-label">
    <textarea
      type="text"
      [id]="configs.key"
      [formControlName]="configs.key"
      [cols]="configs.cols"
      [rows]="configs.rows"
      [attr.disabled]="configs.disabled?configs.disabled:null"
      [readonly]="configs.readonly"
      [autoResize]="configs.autoResize"
      pInputTextarea
      [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
      [placeholder]="
        configs.basePathTranslation + '.' + configs.key + '.placeholder'
          | translate
      "
      (ngModelChange)="debounceOnChanged($event)"
      [name]="configs.key + '.name' | translate">
    </textarea>
    <label for="{{configs.key}}">{{  configs.basePathTranslation + '.' + configs.key + '.label' | translate
      }}</label>
  </span>
</span>
