export enum ControlName {
  inputText = 'inputText',
  inputNumber = 'inputNumber',
  inputDateTime = 'inputDateTime',
  inputPassword = 'inputPassword',
  dropdown = 'dropdown',
  multiSelect = 'multiSelect',
  checkbox = 'checkbox',
  triStateCheckbox = 'triStateCheckbox',
  inputTextArea = 'inputTextArea',
  selectButton = 'selectButton',
  radioButton = 'radioButton',
  colorPicker = 'colorPicker',
  inputMask = 'inputMask',
  slider = 'slider',
  inputSwitch = 'inputSwitch',
  rating = 'rating',
  knob = 'knob',
  chips = 'chips',
  toggleButton = 'toggleButton',
  listBox = 'listBox',
  cascadeSelect = 'cascadeSelect',
  autoComplete = 'autoComplete',
}
