<div *ngIf="!isLoading">
<div class="my-4">
  <p-toggleButton (onChange)="onChangeQueryType()" onLabel="{{'queries.query-modal.actions.query-type.filter-query.label' |translate}}" offLabel="{{'queries.query-modal.actions.query-type.raw-query.label' |translate}}"
                  onIcon="pi pi-filter-fill" offIcon="pi pi-file" iconPos="right"
                  [(ngModel)]="checkBoxQueryRaw"></p-toggleButton>

</div>
<ng-template [ngIf]="checkBoxQueryRaw" [ngIfElse]="noRawQuery">


  <div class="mt-3">
    <prg-dynamic-form [viewMode]="queriesViewMode" [actions]="queriesRawDynamicFormActions"
                      [fields]="queriesRawDynamicFormFields"
                      [entity]="query" (actionOutput)="onActionQueryRaw($event)">
    </prg-dynamic-form>
  </div>

</ng-template>


<ng-template #noRawQuery >

  <prg-dynamic-form (fieldChangeOutput)="onChangeEntity($event.value)" #PrgDynamicFormComponent
                    (actionOutput)="onActionQueryFilter($event)" [viewMode]="queriesViewMode"
                    [fields]="queriesFilterDynamicFormFields" [actions]="queriesFilterDynamicFormActions"
                    [entity]="query">

  </prg-dynamic-form >




  <form [formGroup]="formQueryFilter" *ngIf="!isLoading">

    <div class="mt-2">
      <ng-template [ngIf]="PrgDynamicFormComponent.form.value['entityTypeId']">
        <p-table styleClass="p-datatable-gridlines" [value]="filters" dataKey="value" formArrayName="filters">
          <ng-template pTemplate="header">
            <tr >
              <ng-container *ngFor="let col of queriesFilterColumns">
              <th style="width: 10rem;text-align: center" *ngIf="col.key==='startGroup'||col.key==='filterExpression'; else otherFields " [hidden]="queryFilterArrayForm.length<=1">{{col.basePathTranslation+col.key+'.label'|translate}}</th>
              <ng-template #otherFields>
                <th style="width: 10rem;text-align: center" *ngIf="col.key!=='value2'&&col.key!=='query-filter-row-actions'">{{col.basePathTranslation+col.key+'.label'|translate}}</th>
                <th style="width: 10rem;text-align: center" *ngIf="col.key==='query-filter-row-actions'">{{col.basePathTranslation+col.key+'.label'|translate}}</th>
                <th style="width: 10rem;text-align: center" *ngIf="col.key==='value2'&&queryHasFilterWithTwoValues">{{col.basePathTranslation+col.key+'.label'|translate}}</th>
              </ng-template>
              </ng-container>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-i="rowIndex">

            <tr [formGroupName]="i">

              <td style="width: 5rem;text-align: center" [hidden]="queryFilterArrayForm.length<=1">

                <p-checkbox formControlName="startGroup" [binary]="true"></p-checkbox>
              </td>

              <td style="width: 10rem;text-align: center">


                <p-dropdown [hidden]="PrgDynamicFormComponent.form.value['entityTypeId']==null"
                            (onChange)="onChangeField(i,$event.value)" formControlName="propertyName"
                            [options]="optionsQueryFields" optionValue="name" optionLabel="label" [filter]="true"
                            [showClear]="true" placeholder="{{'queries.fields.query-filter.fields.propertyName.placeholder'|translate}}" #fieldInput>

                  <!--optionsQueryFields-->

                </p-dropdown>
              </td>
              <td style="width: 10rem;text-align: center">

                <p-dropdown [hidden]="fieldInput.value==null" formControlName="filterOperation" optionLabel="label" optionValue="operators"
                            (onChange)="onCheckNumberOfFields()"
                            [options]="mappedOperatorsAndDataTypeByField?.get(fieldInput.value)?.queryFilterOperationByType" [filter]="true"
                            [showClear]="true" placeholder="{{'queries.fields.query-filter.fields.filterOperation.placeholder'|translate}}" #operatorInput>


                </p-dropdown>

              </td>


              <td style="width: 10rem;text-align: center"><input *ngIf="operatorInput.value!=null"
                                                                 formControlName="value" id="value"
                                                                 [type]="mappedOperatorsAndDataTypeByField?.get(fieldInput.value)?.dataType==='number'?'number':mappedOperatorsAndDataTypeByField?.get(fieldInput.value)?.dataType==='date'?'date':'text'"
                                                                 placeholder="{{'queries.fields.query-filter.fields.value.placeholder'|translate}}" pInputText


              >
              </td>

              <td *ngIf="queryHasFilterWithTwoValues" style="width: 10rem;text-align: center"><input
                *ngIf="operatorType.numberOfFields(operatorInput.value)==2"
                formControlName="value2" id="value2"
                [type]="mappedOperatorsAndDataTypeByField?.get(fieldInput.value)?.dataType==='number'?'number':mappedOperatorsAndDataTypeByField?.get(fieldInput.value)?.dataType==='date'?'date':'text'"
                placeholder="{{'queries.fields.query-filter.fields.value2.placeholder'|translate}}"
                pInputText>
              </td>

              <td style="width: 10rem;text-align: center">

                <button [disabled]="this.queryFilterArrayForm.disabled" pButton pRipple type="button"  icon="pi pi-plus-circle"
                        (click)="onAddRow(i)"
                  class="p-button-rounded p-button-text p-button-success p-mr-2"
                ></button>

                <button [disabled]="this.queryFilterArrayForm.disabled" (click)="onDeleteRow(i)" pButton pRipple type="button"  icon="pi pi-times-circle" class="p-button-rounded p-button-text p-button-danger"
                ></button>
              </td>
              <td style="width: 3rem;text-align: center" [hidden]="i===(queryFilterArrayForm.length-1)">
                <p-dropdown  formControlName="filterExpression" optionLabel="label" optionValue="expression"
                            [options]="filterExpressions" [filter]="true"
                            [showClear]="true" placeholder="{{'queries.fields.query-filter.fields.filterExpression.placeholder'|translate}}">


                </p-dropdown>
              </td>


            </tr>

          </ng-template>


        </p-table>

      </ng-template>
    </div>
  </form>

</ng-template>
</div>
