import { Component, Inject, Input, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';

import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { EntityType } from '../../../entity-types/models/entity-type.model';
import { AbstractEntityTypeService } from '../../../entity-types/services/entity-types/abstract-entity-type.service';
import { PrgRolesScopesConfig } from '../../models/prg-roles-scopes-config';
import { Role } from '../../models/role.model';
import { PRG_ROLES_SCOPES_CONFIG } from '../../services/roles-scopes-configuration/prg-roles-scopes-configuration.service';
import { AbstractRolesScopesService } from '../../services/roles-scopes/abstract-roles-scopes.service';
import {
  PrgScopeColumnData,
  PrgScopesColumns,
  PrgScopesNode,
} from './models/prg-scopes-node';

/**
 * Scopes table component
 * Tri checkbox legend:
 * true-> all selected
 * false-> partial selected
 * null-> non selected
 */

@Component({
  selector: 'prg-scopes-table',
  templateUrl: './prg-scopes-table.component.html',
  styleUrls: ['./prg-scopes-table.component.scss'],
})
export class PrgScopesTableComponent implements OnInit {
  /**
   * has a role selected
   */
  public haveRole: boolean = false;
  /**
   * Role aux
   */
  private _role: Role;

  /**
   * Role that we want to configure
   */
  @Input('role') set role(value: Role) {
    if (value) {
      this.haveRole = true;
      this._role = this.objectsUtilityService.cloneObject(value);
      if (this._role.scopes) {
        this.mappingTheScopesOfARole(value.scopes);
      } else {
        this.treeData = this.createDataStructure();
      }
    }
  }

  /**
   * treeData
   */
  public treeData: TreeNode<PrgScopesNode>[];

  /**
   * table columns
   */
  public tableColumns: string[] = [];

  /**
   * haveChangesToSave
   */
  public haveChangesToSave: boolean = false;

  /**
   * Entity types
   */
  private entityTypes: EntityType[];

  /**
   * base scopes
   */
  private baseScopes: string[] = this.prgRolesScopesConfig.baseScopes;

  /**
   * constructor
   * @param objectsUtilityService
   * @param arrayUtilityService
   * @param rolesScopesService
   * @param prgRolesScopesConfig  prgRolesScopesConfig
   */
  constructor(
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private rolesScopesService: AbstractRolesScopesService,
    private abstractEntityTypeService: AbstractEntityTypeService,
    @Inject(PRG_ROLES_SCOPES_CONFIG)
    public prgRolesScopesConfig: PrgRolesScopesConfig
  ) {}

  /**
   * ngOnInit
   *
   * set value to
   * [treeData]{@link #treeData}
   * [entityTypes]{@link #entityTypes}
   * [tableColumns]{@link #tableColumns}
   *
   * call method [getAllEntityType]{@link /classes/AbstractRolesScopesService.html#getAllEntityType} from services
   */
  ngOnInit() {
    // TODO: acho que fizemos um erro de spec, ou seja, estamos a usar as entitytypes para criar a estrutura
    //    quando deveriamos usar apenas as scopes ou pelo menos devemos poder acrescentar scopes sem entity type, a falar...

    this.abstractEntityTypeService
      .getEntityTypeListWithOperationAsync()
      .then((response) => {
        this.entityTypes = response;
        // this.treeData = this.createDataStructure();
      });

    this.tableColumns = this.tableColumns.concat(this.baseScopes);
    this.tableColumns.unshift('name');
    this.tableColumns.push(this.prgRolesScopesConfig.otherScopes);
  }

  /**
   * this function receives the scopes and arranges
   * them by group into the correct structure
   * to be used in the treetable
   *
   * this function call [createEntityTypeColumns]{@link #createEntityTypeColumns} to create entity type columns
   * this function call [createGroupColumns]{@link #createGroupColumns} to create columns
   *
   * @returns TreeNode<PrgScopesNode>[]
   */
  private createDataStructure(): TreeNode<PrgScopesNode>[] {
    if (this.entityTypes == null) {
      return null;
    }

    let entityTypesOrganisedByGroup: Map<string, TreeNode<PrgScopesNode>[]> =
      new Map();

    let entityTypesOrganizedTreeNode: TreeNode<PrgScopesNode>[] = [];

    this.entityTypes.forEach((element) => {
      if (element.operations != null && element.operations.length) {
        const treeNodeEntityType: TreeNode<PrgScopesNode> = {
          data: {
            isGroup: false,
            name: element.name,
            label: element.label,
            columns: this.createEntityTypeColumns(element),
          },
        };

        if (entityTypesOrganisedByGroup.has(element.group)) {
          entityTypesOrganisedByGroup
            .get(element.group)
            .push(treeNodeEntityType);
        } else {
          entityTypesOrganisedByGroup.set(element.group, [treeNodeEntityType]);
        }
      }
    });

    entityTypesOrganisedByGroup.forEach(
      (nodes: TreeNode<PrgScopesNode>[], groupName: string) => {
        let treeNodeGroup: TreeNode<PrgScopesNode> = {};
        treeNodeGroup.data = {
          isGroup: true,
          name: groupName,
          label: groupName,
          columns: this.createGroupColumns(nodes),
        };

        treeNodeGroup.children = nodes;
        treeNodeGroup.expanded = true;
        entityTypesOrganizedTreeNode.push(treeNodeGroup);
      }
    );
    return entityTypesOrganizedTreeNode;
  }

  /**
   * this function create Entity Columns
   * @param entityType
   * @returns PrgScopesColumns
   */
  private createEntityTypeColumns(entityType: EntityType): PrgScopesColumns {
    const prgScopesColumns: PrgScopesColumns = {
      value: null,
      children: new Map<string, PrgScopeColumnData>(),
    };

    if (!entityType.operations) {
      return prgScopesColumns;
    }

    entityType.operations.forEach((item) => {
      if (this.baseScopes.includes(item.name)) {
        // create columns when is a base scope
        prgScopesColumns.children.set(item.name, {
          value: null,
          scope: entityType.name + ':' + item.name,
          children: null,
          label: 'ola1',
        });
      } else {
        // create columns when is other scopes
        let other = prgScopesColumns.children.get(
          this.prgRolesScopesConfig.otherScopes
        );
        if (!other) {
          other = {
            value: null,
            scope: null,
            children: new Map<string, PrgScopeColumnData>(),
            data: [],
            label: 'ola2',
          };
          prgScopesColumns.children.set(
            this.prgRolesScopesConfig.otherScopes,
            other
          );
        }
        other.children.set(item.name, {
          parent: other,
          value: false,
          scope: entityType.name + ':' + item.name,
          children: null,
          data: [],
          label: item.label,
        });
      }
    });
    return prgScopesColumns;
  }

  /**
   * this function create group columns
   * @param nodes
   * @returns PrgScopesColumns
   */
  private createGroupColumns(
    nodes: TreeNode<PrgScopesNode>[]
  ): PrgScopesColumns {
    const prgScopesColumns: PrgScopesColumns = {
      value: null,
      children: new Map<string, PrgScopeColumnData>(),
    };

    // loop base scopes to check if exist a child with this scope action
    this.baseScopes.forEach((col) => {
      if (nodes.find((n) => n.data.columns.children.has(col)) != null) {
        prgScopesColumns.children.set(col, {
          value: null,
          scope: null,
          children: null,
          label: 'ola4',
        });
      }
    });

    // check if exist a child with other scope action
    if (
      nodes.find((n) =>
        n.data.columns.children.has(this.prgRolesScopesConfig.otherScopes)
      ) == null
    ) {
      return prgScopesColumns;
    }

    prgScopesColumns.children.set(this.prgRolesScopesConfig.otherScopes, {
      value: null,
      scope: null,
      children: null,
      label: 'ola5',
    });

    return prgScopesColumns;
  }

  /**
   * this function returns an array with all scopes
   *
   * @returns PrgScopeColumnData[]
   */
  private getScopeObjectsFromTree(): PrgScopeColumnData[] {
    return this.treeData.reduce(
      (prev, next) =>
        this.getScopeObjectsFromTreeChildrens(prev, next.children),
      []
    );
  }

  /**
   * is an auxiliary function, which makes use of
   * recursion to return all the scopes of
   * all the children of the tree
   *
   * @param prev
   * @param items
   * @returns PrgScopeColumnData[]
   */
  private getScopeObjectsFromTreeChildrens(
    prev: PrgScopeColumnData[],
    items: any[]
  ): PrgScopeColumnData[] {
    if (items == null) return prev;
    items.forEach((x) => {
      if (x?.scope) {
        prev.push(x);
      } else if (x?.data?.columns?.children) {
        this.getScopeObjectsFromTreeChildrens(prev, x.data.columns.children);
      } else if (x?.value?.children) {
        this.getScopeObjectsFromTreeChildrens(prev, x.value.children);
      } else if (x?.children) {
        this.getScopeObjectsFromTreeChildrens(prev, x.children);
      }
    });
    return prev;
  }

  /**
   * This function is responsible for checking all scopes received as parameters
   *
   * this function call [getScopeObjectsFromTree]{@link #getScopeObjectsFromTree} to get all scopes
   *
   * @param scopes
   */
  private setSelectedScopes(scopes: string[]): void {
    this.getScopeObjectsFromTree().forEach((x) => {
      x.value = scopes.find((y) => y === x.scope) != null;
      if (x.parent) {
        x.parent.data.push(x.scope);
        x.parent.value =
          x.parent.data.length == x.parent.children.size
            ? true
            : x.parent.data.length > 0
            ? false
            : null;
      }
    });
  }

  /**
   * this function maps the scopes of the selected role in the table data
   *
   *
   * @param roleScopes
   * @returns
   */
  private mappingTheScopesOfARole(roleScopes: string[]): void {
    // clean treeData
    this.treeData = this.createDataStructure();
    if (this.treeData == null) {
      return;
    }

    if (roleScopes && roleScopes.length > 0) {
      this.setSelectedScopes(roleScopes);

      //loop to check row, columns and groups
      this.treeData.forEach((group) => {
        const groupName = group.data.name;
        group.children.forEach((child) => {
          this.checkIfAllScopesAreSelectedByRow(child.data.name, groupName);
        });
        [...group.data.columns.children.keys()].forEach((key: string) => {
          this.checkIfAllScopesAreSelectedByGroupColumn(group.data.name, key);
        });
        this.checkIfAllScopesAreSelectedByGroup(groupName);
      });
    }
  }

  /**
   * this function is responsible for checking or unchecking
   * all the other scopes of a row, making all
   * the necessary checks to validate the state
   * of the group, column and row
   *
   * @param event
   * @param groupName
   * @param rowData
   */
  public otherCheckedChange(
    event: any,
    groupName: string,
    rowData: PrgScopesNode
  ): void {
    const otherChild = rowData.columns.children.get(
      this.prgRolesScopesConfig.otherScopes
    );

    // put all children a false
    otherChild.children.forEach((row) => {
      row.value = false;
    });
    // clean array with selected other scopes
    otherChild.data = [];

    //use event value to check other scopes again
    event.value.forEach((val) => {
      const value = val.substr(val.indexOf(':') + 1);
      const node = otherChild.children.get(value);
      node.value = !node.value;
      otherChild.data.push(val);
    });

    const checkedCount = [...otherChild.children.values()].filter(
      (n) => n.value === true
    ).length;

    otherChild.value =
      checkedCount === otherChild.children.size
        ? true
        : checkedCount > 0
        ? false
        : null;

    // check row, column and group status
    this.checkIfAllScopesAreSelectedByGroupColumn(
      groupName,
      this.prgRolesScopesConfig.otherScopes
    );
    this.checkIfAllScopesAreSelectedByRow(rowData.name, groupName);
    this.checkIfAllScopesAreSelectedByGroup(groupName);

    this.compareOriginalScopesWithCurrentScopesSelection();
  }

  /**
   * toogle column in group
   *
   * @param rowData
   * @param column
   */
  public columnCheckedChange(rowData: PrgScopesNode, column: string): void {
    const groupName = rowData.name;
    const group = this.treeData.find(
      (obj: TreeNode) => obj.data.name === groupName
    );

    // if the current status is other than true, we must pass the value to true, otherwise to null
    const status = group.data.columns.children.get(column).value !== true;

    group.data.columns.children.get(column).value = status ? true : null;

    group.children.forEach((element) => {
      if (element.data.columns.children.has(column)) {
        if (column === this.prgRolesScopesConfig.otherScopes) {
          this.groupOtherSelectionChange(
            status,
            element.data.columns.children.get(column)
          );
        } else {
          element.data.columns.children.get(column).value = status;
        }
      }
    });

    this.checkIfAllScopesAreSelectedByGroup(groupName);

    group.children.forEach((element) => {
      this.checkIfAllScopesAreSelectedByRow(element.data.name, groupName);
    });

    this.compareOriginalScopesWithCurrentScopesSelection();
  }

  /**
   * this function is responsible for checking or unchecking
   * all other scopes in a group
   *
   * @param toogleStatus
   * @param otherColumnsData
   */
  private groupOtherSelectionChange(
    toogleStatus: boolean,
    otherColumnsData: PrgScopeColumnData
  ): void {
    otherColumnsData.value = toogleStatus ? true : null;
    otherColumnsData.data = [];
    otherColumnsData.children.forEach(
      (otherChild: PrgScopeColumnData, otherKey: string) => {
        otherChild.value = toogleStatus;
        if (toogleStatus) {
          otherColumnsData.data.push(otherKey);
        }
      }
    );
  }

  /**
   * this function is responsible for checking or unchecking
   * all the scopes of a row, doing all the state
   * validations for the group and columns
   *
   * call method [groupOtherSelectionChange]{@link #groupOtherSelectionChange} to toogle other scopes
   *
   * @param groupName
   * @param rowData
   */
  public rowSelectionChange(groupName: string, rowData: PrgScopesNode): void {
    // if the current status is other than true, we must pass the value to true, otherwise to null
    const status = rowData.columns.value !== true;

    rowData.columns.value = status ? true : null;
    rowData.columns.children.forEach(
      (child: PrgScopeColumnData, key: string) => {
        if (key === this.prgRolesScopesConfig.otherScopes) {
          this.groupOtherSelectionChange(status, child);
        } else {
          child.value = status;
        }
      }
    );

    //check columns
    [...rowData.columns.children.keys()].forEach((key: string) => {
      this.checkIfAllScopesAreSelectedByGroupColumn(groupName, key);
    });

    //check group
    this, this.checkIfAllScopesAreSelectedByGroup(groupName);

    this.compareOriginalScopesWithCurrentScopesSelection();
  }

  /**
   * this function is responsible for checking or
   * unchecking all scopes in a given group
   *
   * call method [groupOtherSelectionChange]{@link #groupOtherSelectionChange} to toogle other scopes
   * @param groupName
   */
  public groupSelectionChange(groupName: string) {
    const group = this.treeData.find(
      (obj: TreeNode) => obj.data.name === groupName
    );

    // if the current status is other than true, we must pass the value to true, otherwise to null
    const status = group.data.columns.value !== true;

    group.data.columns.value = status ? true : null;
    group.data.columns.children.forEach((child: PrgScopeColumnData) => {
      child.value = status ? true : null;
    });
    group.children.forEach((child: any) => {
      child.data.columns.value = status ? true : null;
    });
    group.children.forEach((child) => {
      child.data.columns.children.forEach(
        (scopeChild: PrgScopeColumnData, key: string) => {
          if (key === this.prgRolesScopesConfig.otherScopes) {
            this.groupOtherSelectionChange(status, scopeChild);
          } else {
            scopeChild.value = status;
          }
        }
      );
    });
    this.compareOriginalScopesWithCurrentScopesSelection();
  }

  /**
   * This function is responsible for making the
   * necessary changes when a scope has its state changed.
   * Using auxiliary functions, it validates if the column,
   * row and the group in which it is inserted are totally or
   * partially with a selection or without any selection;
   *
   * Auxiliar functions:
   * [checkIfAllScopesAreSelectedByGroupColumn]{@link #checkIfAllScopesAreSelectedByGroupColumn}
   * [checkIfAllScopesAreSelectedByRow]{@link #checkIfAllScopesAreSelectedByRow}
   * [checkIfAllScopesAreSelectedByGroup]{@link #checkIfAllScopesAreSelectedByGroup}
   *
   * @param groupName
   * @param rowData
   * @param column
   */
  public scopeSelectionChange(
    groupName: string,
    rowName: string,
    column: string
  ): void {
    this.compareOriginalScopesWithCurrentScopesSelection();
    // check row, columns and group
    this.checkIfAllScopesAreSelectedByGroupColumn(groupName, column);
    this.checkIfAllScopesAreSelectedByRow(rowName, groupName);
    this.checkIfAllScopesAreSelectedByGroup(groupName);
  }

  /**
   * This function validates if scopes are fully or
   * partially selected or if no scope is selected in a group
   *
   * @param rowName
   */
  private checkIfAllScopesAreSelectedByRow(
    rowName: string,
    groupName: string
  ): void {
    const group = this.treeData.find(
      (obj: TreeNode) => obj.data.name === groupName
    );
    const row = group.children.find(
      (obj: TreeNode) => obj.data.name === rowName
    );

    // get all children checked
    const childrenColChecked = [...row.data.columns.children.values()].filter(
      (c) => c.value === true
    );

    // if have other scopes check if have some scope selected
    let childrenColPartial = null;
    if (row.data.columns.children.has(this.prgRolesScopesConfig.otherScopes)) {
      childrenColPartial = row.data.columns.children.get(
        this.prgRolesScopesConfig.otherScopes
      ).value;
    }

    row.data.columns.value =
      childrenColChecked.length == row.data.columns.children.size
        ? true
        : childrenColChecked.length > 0 || childrenColPartial !== null
        ? false
        : null;
  }

  /**
   * This function validates if scopes are fully or
   *  partially selected or if no scope is selected on a given column in a group
   *
   * Auxiliar functions:
   * [checkIfAllScopesAreSelectedForAdditional]{@link #checkIfAllScopesAreSelectedForAdditional}
   * [checkIfAllScopesAreSelectedForColumn]{@link #checkIfAllScopesAreSelectedForColumn}
   *
   * @param groupName
   * @param columnName
   */
  private checkIfAllScopesAreSelectedByGroupColumn(
    groupName: string,
    columnName: string
  ): void {
    const group = this.treeData.find(
      (obj: TreeNode) => obj.data.name === groupName
    );
    if (this.prgRolesScopesConfig.baseScopes.includes(columnName)) {
      group.data.columns.children.get(columnName).value =
        this.checkIfAllScopesAreSelectedForColumn(group.children, columnName);
    } else {
      group.data.columns.children.get(columnName).value =
        this.checkIfAllScopesAreSelectedForAdditional(group.children);
    }
  }

  /**
   * This function validates if scopes are fully or
   * partially selected or if no scope is selected in a group
   *
   * @param groupName
   */
  private checkIfAllScopesAreSelectedByGroup(groupName: string): void {
    const groupData = this.treeData.find(
      (obj: TreeNode) => obj.data.name === groupName
    ).data;

    // get all group columns checked
    const childrenColChecked = [...groupData.columns.children.values()].filter(
      (c) => c.value === true
    );

    if (childrenColChecked.length == groupData.columns.children.size) {
      groupData.columns.value = true;
      return;
    }
    const childrenColPartial = [...groupData.columns.children.values()].filter(
      (c) => c.value === false || c.value === true
    );

    groupData.columns.value = childrenColPartial.length > 0 ? false : null;
  }

  /**
   *this function validates whether the state of a column of other scopes
   * @param groupChildren
   * @returns boolean or null
   */
  private checkIfAllScopesAreSelectedForAdditional(
    groupChildren: TreeNode<PrgScopesNode>[]
  ): boolean | null {
    //get list with all custom scopes selected
    const childrenColChecked = groupChildren.filter(
      (c) =>
        !c.data.columns.children.has(this.prgRolesScopesConfig.otherScopes) ||
        c.data.columns.children.get(this.prgRolesScopesConfig.otherScopes)
          .value === true
    );

    if (childrenColChecked.length == groupChildren.length) {
      return true;
    }

    const childrenColPartial = groupChildren.filter(
      (c) =>
        !c.data.columns.children.has(this.prgRolesScopesConfig.otherScopes) ||
        c.data.columns.children.get(this.prgRolesScopesConfig.otherScopes)
          .value === false
    );

    const childrenWithoutCol = groupChildren.filter(
      (c) => !c.data.columns.children.has(this.prgRolesScopesConfig.otherScopes)
    );

    return childrenColPartial.length - childrenWithoutCol.length > 0
      ? false
      : null;
  }

  /**
   * this is an auxiliary function that validates if a given column(default columns(crud))
   * of a group has its scopes totally or
   * partially selected or without any selection
   *
   * @param groupChildren
   * @param column
   * @returns boolean or null
   */
  private checkIfAllScopesAreSelectedForColumn(
    groupChildren: TreeNode<PrgScopesNode>[],
    column: string
  ): boolean | null {
    //get an array with the selected scopes from the column
    const childrenColChecked = groupChildren.filter(
      (c) =>
        !c.data.columns.children.has(column) ||
        c.data.columns.children.get(column).value === true
    );

    //get an array with the rows that have no scope in that column
    const childrenWithoutCol = groupChildren.filter(
      (c) => !c.data.columns.children.has(column)
    );

    return childrenColChecked.length == groupChildren.length
      ? true
      : childrenColChecked.length - childrenWithoutCol.length > 0
      ? false
      : null;
  }

  /**
   * this function compares the original scopes of the selected function with the currently selected scopes
   *
   * set value to [haveChangesToSave] {@link #haveChangesToSave}
   */
  private compareOriginalScopesWithCurrentScopesSelection(): void {
    let originalScopesSelected: string[] = [];
    if (this._role && this._role.scopes) {
      originalScopesSelected = this._role.scopes.slice().sort();
    }
    const currentScopesSelected = this.getSelectedScopes();
    this.haveChangesToSave = !this.arrayUtilityService.compareTwoArray(
      originalScopesSelected,
      currentScopesSelected
    );
  }

  /**
   * this function returns an array with
   * all the selected scopes
   *
   * use the [getScopeObjectsFromTree] {@link getScopeObjectsFromTree} function to get a list of all the scopes
   * @returns  string[]
   */
  private getSelectedScopes(): string[] {
    return this.getScopeObjectsFromTree().reduce((prev, x) => {
      if (x.value) {
        prev.push(x.scope);
      }
      return prev;
    }, []);
  }

  /**
   * this function saves all changes to a role
   *
   * calling the [updateRole]{@link /classes/AbstractRolesScopesService.html#updateRole} method from services
   */
  public saveChanges(): void {
    let scopesSelected: string[] = [];
    scopesSelected = this.getSelectedScopes();
    this._role.scopes = scopesSelected;
    this.rolesScopesService.updateRole(this._role);
    this.haveChangesToSave = false;
  }
}
