import { Validators } from '@angular/forms';
import { SelectConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/select/select.model';
import { ControlName } from '../../core/components/dynamic-form/models/constants/control-names';
import { ReferenceType } from '../../core/components/dynamic-form/models/constants/reference-type';

/**
 * Dynamic form fields configurations to Entity type Attribute
 */
export const ENTITY_TYPE_ATTRIBUTE_DYNAMIC_FORM = {
  fields: [
    {
      controlName: ControlName.inputText,
      readonly: false,
      key: 'name',
      validators: [Validators.required],
    },
    {
      controlName: ControlName.inputText,
      readonly: false,
      key: 'guiSettings',
      validators: [Validators.required],
    },
    new SelectConfiguration({
      referenceType: ReferenceType.LookupTable,
      referenceName: 'dataType',
      readonly: false,
      key: 'dataTypeId',
      optionLabel: 'name',
      optionValue: 'id',
      validators: [Validators.required],
    }),
  ],
};
