/**
 * Injectable
 */
import { Injectable } from '@angular/core';
import { AbstractAuthService } from '../../../auth/services/auth/abstract-auth.service';
import { UniversalState } from '../../../core/models/base-entity.model';
import { PrgError } from '../../../core/models/error.model';
import { PrgSucess } from '../../../core/models/success.model';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import {
  ConfigItemsPath,
  ConfigurationItem,
} from '../../models/configuration-item.model';
import { AbstractConfigurationItemsService } from '../../services/configuration-items/abstract-configuration-items.service';
import { MockDatabase } from '../database.mock';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * MOCK AbstractConfigurationItemsService implementation
 */
export class MockConfigurationItemsService extends AbstractConfigurationItemsService {
  /**
   * default time in seconds
   */
  private sleepTimeSec = 0.5;

  /**
   * Constructor
   * @param {NotificationsService} notificationsService
   * @param {UtilityService} utilityService
   * @param {IsLoadingDataService} isLoadingData
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {AbstractAuthService} authService
   */
  constructor(
    private notificationsService: NotificationsService,
    private utilityService: UtilityService,
    private isLoadingData: IsLoadingDataService,
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private authService: AbstractAuthService
  ) {
    super();
  }

  /**
   * Get a list of all configuration items
   * @returns {Promise<ConfigurationItem[]>}
   */
  public getAllConfigurationItemsAsync(): Promise<ConfigurationItem[]> {
    return new Promise<ConfigurationItem[]>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const configsItems: ConfigurationItem[] = this.arrayUtilityService.clone(
        MockDatabase.ConfigurationItems
      );

      this.isLoadingData.hide();
      if (configsItems) {
        resolve(configsItems);
      } else {
        reject('Config Items could not be loaded');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Config Items could not be loaded.',
            detailKey: 'Server is down',
          })
        );
      }
    });
  }

  /**
   * Update a configuration items
   * @param configItemData
   * @returns {Promise<ConfigurationItem[]>}
   */
  public updateConfigurationItemsAsync(
    configItemData: any
  ): Promise<ConfigurationItem[]> {
    return new Promise<ConfigurationItem[]>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      this.isLoadingData.hide();
      if (configItemData.path && configItemData.path != '') {
        Object.keys(configItemData).map((key) => {
          if (key != 'path') {
            let configItemToUpdate = MockDatabase.ConfigurationItems.find(
              (configItem) =>
                configItem.name === key &&
                configItem.path === configItemData.path
            );
            if (configItemToUpdate) {
              configItemToUpdate.value = configItemData[key];
            } else {
              let newConfigItem: ConfigurationItem =
                this.objectsUtilityService.cloneObject({
                  path: configItemData.path,
                  name: key,
                  value: configItemData[key],
                  id: this.utilityService.newGuid(),
                  guiSettings: null,
                  universalStateId: UniversalState.Active,
                  createdOn: new Date(),
                  operationId: null,
                  modifiedBy: this.authService.getLoggedUser()?.id
                    ? this.authService.getLoggedUser().id
                    : null,
                  modifiedOn: new Date(),
                  createdBy: this.authService.getLoggedUser()?.id
                    ? this.authService.getLoggedUser().id
                    : null,
                  transactionId: null,
                  readonly: null,
                });
              MockDatabase.ConfigurationItems.push(newConfigItem);
            }
          }
        });
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'Configuration Item Updated.',
            detailKey: 'Success.',
          })
        );
        this.setConfigurationItems(MockDatabase.ConfigurationItems);
        resolve(MockDatabase.ConfigurationItems);
      } else {
        reject('Configuration Items could not be save.');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Error.',
            detailKey: 'Config Item Path is not present',
          })
        );
      }
    });
  }

  /**
   * Get configuration items by path
   * @param {string} path
   * @returns {Promise<any>}
   */
  public getConfigurationItemsByPathAsync(path: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const configItemsBypath: ConfigurationItem[] =
        this.arrayUtilityService.clone(
          MockDatabase.ConfigurationItems.filter((configItems) => {
            return configItems.path.includes(path);
          })
        );
      const userAndDefaultsConfigsItems: any = {};
      const configItemsDefaults: ConfigurationItem[] = configItemsBypath.filter(
        (configItemsDefaults) => {
          return configItemsDefaults.path === path + ConfigItemsPath.Defaults;
        }
      );

      const configItemsUser: ConfigurationItem[] = configItemsBypath.filter(
        (configItemsUser) => {
          return (
            configItemsUser.path ===
            path + this.authService.getLoggedUser()?.email
          );
        }
      );

      Object.assign(userAndDefaultsConfigsItems, {
        user: configItemsUser,
        defaults: configItemsDefaults,
      });

      this.isLoadingData.hide();
      if (userAndDefaultsConfigsItems) {
        resolve(userAndDefaultsConfigsItems);
      } else {
        reject('Config Items could not be loaded');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Config Items could not be loaded.',
            detailKey: 'Server is down',
          })
        );
      }
    });
  }
}
