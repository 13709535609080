import { Directive, Input } from '@angular/core';
import { InputSwitch } from 'primeng/inputswitch';
import { BaseAction } from '../../../models/base-action';
import { ActionName } from '../../../models/constants/action-names';

/**
 * Class Input Switch Action
 */
export class InputSwitchActionConfiguration extends BaseAction {
  /**
   * control name
   */
  public controlName?: string = ActionName.InputSwitch;

  /**
   *Inline style of the component.
   */
  public style: string = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *Identifier of the input element.
   */
  public inputId: string = null;
  /**
   *Name of the input element.
   */
  public name: string = null;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;
  /**
   *Value in checked state.
   */
  public trueValue: any;
  /**
   *Value in unchecked state.
   */
  public falseValue: any;
  /**
   *Used to define a string that autocomplete attribute the current element.
   */
  public ariaLabel: string = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<InputSwitchActionConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setInputSwitchActionConfigs]',
})
export class PrgSetInputSwitchActionConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: InputSwitch) {}

  /**
   * Input Switch Action configs and assign configs to element
   */
  @Input() set setInputSwitchActionConfigs(
    configs: InputSwitchActionConfiguration
  ) {
    Object.assign(this.element, configs);
  }
}
