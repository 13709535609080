import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { DateTimeConfiguration } from './date-time.model';

/**
 * component date time
 */
@Component({
  selector: 'prg-date-time-field',
  templateUrl: './date-time.component.html',
})
export class PrgDateTimeComponent {
  /**
   * Data time Configs
   */
  @Input() configs: DateTimeConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();
  /**
   * function that triggers the output
   */
  public onChanged(event: any): void {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event,
        })
      );
    }
  }
}
