import { LocationStrategy } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { Subscription } from 'rxjs';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { MainLayoutService } from '../../../layouts/services/main-layout.service';
import {
  ComponentTypeEnum,
  Dashboard,
  MapComponentTypesToClass,
} from '../../models/dashboard.model';
import {
  PrgDraggable,
  PrgGridsterConfig,
  PrgGridsterItem,
  PrgResizable,
} from '../../models/prg-gridster-config';
import { AbstractDashboardService } from '../../services/dashboard/abstract-dashboard.service';

/**
 * Display dashboard component
 */
@Component({
  selector: 'prg-dashboard-display',
  templateUrl: './prg-dashboard-display.component.html',
  styleUrls: ['./prg-dashboard-display.component.scss'],
})
export class PrgDashboardDisplayComponent implements OnInit, OnDestroy {
  /**
   * Show/hide header.Default is true.
   * @type {boolean}
   */
  @Input() displayHeader: boolean = true;
  /**
   * Show/hide dashboard selection dropdown.Default is true.
   * @type {boolean}
   */
  @Input() showDropdown: boolean = true;

  /**
   * The dashboard settings object based on Gridster options
   * @type {PrgGridsterConfig}
   */
  public dashboardOptions: PrgGridsterConfig;
  /**
   * An array of widgets (dynamic items) displayed on dashboard
   * @type {Array<PrgGridsterItem>}
   */
  public dashboardItems: Array<PrgGridsterItem> = [];
  /**
   * A list of all dashboard created by logged user
   * @type {Dashboard[]}
   */
  public dashboardsByUser: Dashboard[];
  /**
   * Show/Hide dashboard on template.Default is false.
   * @type {boolean}
   */
  public displayDashboard: boolean = false;
  /**
   * The current selected dashboard
   * @type {Dashboard}
   */
  public selectedDashboard: Dashboard;
  /**
   * The name of dashboard selected
   * @type {string}
   */
  private nameDashboard: string;
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * Constructor
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {AbstractDashboardService} dashboardService
   * @param {UtilityService} utilityService
   * @param {ActivatedRoute} route
   * @param {LocationStrategy} location
   * @param {MainLayoutService} mainLayoutService
   */
  constructor(
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private dashboardService: AbstractDashboardService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private location: LocationStrategy,
    private mainLayoutService: MainLayoutService
  ) {}

  /**
   * ngOnInit
   *
   * Verify if there is any changes on size of main sidebar and update view on dashboard.
   *
   * Load all dashboard created by logged user.
   *
   * Verify if there is a parameter on route, name of dashboard and if so set this as the selected dashboard
   *
   * @returns {Promise<void>}
   */
  public async ngOnInit() {
    this.subscription.push(
      this.mainLayoutService.getSideBarStateObservable().subscribe(() => {
        this.changedOptions();
      })
    );
    this.mainLayoutService.setDisplayHeaderState(this.displayHeader);
    this.dashboardsByUser =
      await this.dashboardService.getDashboardsByUserAsync();

    this.nameDashboard = this.route?.snapshot?.params['name'];

    if (this.nameDashboard) {
      this.selectedDashboard = this.dashboardsByUser.find(
        (dashboard) => dashboard.name === this.nameDashboard
      );
      if (this.showDropdown != null) {
        await this.settingDashboard();
        this.displayDashboard = true;
      } else {
        this.location.back();
      }
    }
  }

  /**
   * Setting up the dashboard selected
   * @returns {Promise<void>}
   * @private
   */
  private async settingDashboard() {
    this.dashboardOptions = new PrgGridsterConfig({});
    if (this.selectedDashboard) {
      if (this.selectedDashboard?.settings) {
        let dashboardOptionStringToObject =
          this.utilityService.guiSettingToObject(
            this.selectedDashboard.settings
          );
        this.dashboardOptions = this.objectsUtilityService.cloneObject(
          this.objectsUtilityService.unFlattenObj(
            dashboardOptionStringToObject,
            '.'
          )
        );
      }
      this.settingCallBackFunctionDashboard();
      this.dashboardItems = [];
      if (
        this.selectedDashboard.items != null &&
        this.selectedDashboard.items.length > 0
      ) {
        this.setDashboardItems();
      }
    } else {
      this.location.back();
    }
  }

  /**
   * Setting up items for current dashboard selected
   * @private
   */
  private setDashboardItems() {
    this.selectedDashboard.items.map((item) => {
      this.dashboardItems.push(
        new PrgGridsterItem({
          x: item.x,
          y: item.y,
          cols: item.cols,
          rows: item.rows,
          dashboardId: item.dashboardId,
          item: MapComponentTypesToClass.get(
            <ComponentTypeEnum>item.componentType
          ),
          settings: item.settings,
          id: item.id,
        })
      );
    });
  }

  /**
   * Setting up call back functions and additional options for the current dashboard selected
   * @private
   */
  private settingCallBackFunctionDashboard() {
    this.dashboardOptions = {
      ...this.dashboardOptions,
      itemResizeCallback: this.onItemChangeSized.bind(this),
      draggable: new PrgDraggable({
        enabled: false,
      }),
      resizable: new PrgResizable({
        enabled: false,
      }),
      swap: false,
    };
  }

  /**
   * This function is call whenever height or width of each item changes
   * @param {PrgGridsterItem} item
   * @param {GridsterItemComponentInterface} itemComponent
   * @private
   */
  private onItemChangeSized(
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface
  ): void {
    this.dashboardService.setDashboardItemsResize(itemComponent);
  }

  /**
   * This function is responsible to refresh grid options
   * @private
   */
  private changedOptions() {
    if (
      this.dashboardOptions?.api &&
      this.dashboardOptions?.api?.optionsChanged
    ) {
      this.dashboardOptions?.api?.optionsChanged();
    }
  }

  /**
   * This function is call when a dashboard is selected on dropdown
   * @param $event
   * @returns {Promise<void>}
   */
  public async onChangeDashboard($event: any): Promise<void> {
    this.displayDashboard = false;
    this.selectedDashboard = this.dashboardsByUser.find(
      (dashboard) => $event.value === dashboard.id
    );
    await this.settingDashboard();
    this.displayDashboard = true;
  }

  /**
   * ngOnDestroy
   *
   * Unsubscribe  subscriptions and set the display header state to true
   */
  public ngOnDestroy(): void {
    this.mainLayoutService.setDisplayHeaderState(true);
    this.subscription.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.subscription = [];
  }
}
