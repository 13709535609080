import { Component } from '@angular/core';

/**
 * entity type component
 */
@Component({
  selector: 'prg-entity-type',
  templateUrl: './prg-entity-type.component.html',
  styleUrls: ['./prg-entity-type.component.scss'],
})
export class PrgEntityTypeComponent {}
