<!--<p-tabMenu [model]="tabMenuItems" [activeItem]="activeItemTabMenu">

</p-tabMenu>-->
  <prg-dynamic-form [context]="this" [formGroupType]="'tab'"
                    (fieldChangeOutput)="fieldChanges($event)" [viewMode]="dashboardOptionsViewMode"
                    [fields]="dashboardOptionsDynamicFormFields" [entity]="dashboardOptionsFlatten"
                    (actionOutput)="actionOutput($event)"
                    [actions]="dashboardOptionsDynamicFormActions" ></prg-dynamic-form>


