/**
 * BaseEntity class extended by most framework entities
 */

export abstract class BaseEntity {
  /**
   * Id
   */
  id: string;
  /**
   * Universal State lookup table item id (foreign key)
   */
  universalStateId: string = UniversalState.Active;
  /**
   * Database transaction id
   */
  transactionId: string;
  /**
   * Database operation id
   */
  operationId: string;
  /**
   * Name
   */
  name: string;
  /**
   * Created by user id
   */
  createdBy: string;
  /**
   * Modified by (last) user id
   */
  modifiedBy: string;
  /**
   * Creation date
   */
  createdOn: Date;
  /**
   * Modification date
   */
  modifiedOn: Date;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<BaseEntity>) {
    Object.assign(this, init);
  }
}

export enum UniversalState {
  Inactive = '0',
  Active = '1',
}
