<div>
  <prg-dynamic-form
    [fields]="entityTypeDynamicFormFields"
    [actions]="entityTypeDynamicFormActions"
    [entity]="entityType"
    [viewMode]="entityTypeViewMode"
    (actionOutput)="onEntityTypeActionOutputted($event)"
  ></prg-dynamic-form>

  <p-tabMenu
    [model]="menuTabItems"
    [activeItem]="activeItem"
    styleClass="p-tabmenu-nav"
  >
    <ng-template pTemplate="item" let-item let-i="index">
      <div (click)="!item.disabled && tabChange(item)">
        <i [class]="item.icon"></i>
        {{ 'entity-type.tabs.' + item.label + '.label' | translate }}
      </div>
    </ng-template>
  </p-tabMenu>

  <p-splitter
    *ngIf="entityType.id"
    [style]="{ height: '300px' }"
    [panelSizes]="[30, 70]"
    [minSizes]="[20, 40]"
  >
    <ng-template pTemplate>
      <p-orderList
        *ngIf="elements.length > 0"
        [value]="elements"
        [disabled]="disabledList"
        [selection]="elementsSelected"
        (onSelectionChange)="onSelectionChanged($event)"
        filterBy="name"
        [dragdrop]="true"
        styleClass="p-tabmenu-nav"
        (onReorder)="reorderElements($event)"
      >
        <ng-template let-element pTemplate="list">
          <div>
            <div>
              {{
                element.id != null
                  ? ('entities.' +
                      entityType.name +
                      '.fields.' +
                      element.name +
                      '.label' | translate)
                  : ('entity.new.' + activeItem.type.toLowerCase() | translate)
              }}
            </div>
          </div>
        </ng-template>
      </p-orderList></ng-template
    >
    <ng-template pTemplate="dynamicForm">
      <div>
        <prg-dynamic-form
          [fields]="elementDynamicFormFields"
          [actions]="elementDynamicFormActions"
          [entity]="elementsSelected.length ? elementsSelected[0] : null"
          [viewMode]="elementViewMode"
          (actionOutput)="onEntityActionOutputted($event)"
        ></prg-dynamic-form>
      </div>
    </ng-template>
  </p-splitter>
</div>
