import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * BaseCacheService
 */
export class BaseCacheService<T> {
  /**
   * map with a BehaviorSubject for each element
   */
  protected cacheMapSubject = new Map<string, BehaviorSubject<T>>();

  /**
   * BehaviorSubject of a list of elements
   */
  protected cacheListSubject = new BehaviorSubject<T[]>(null);

  /**
   * cache expiration time
   */
  protected cacheTimeMs: number = 15000;

  /**
   * saves the date the cache was updated
   */
  protected entityTypeGetDate: Date = null;

  /**
   *
   * @param values
   * @param key
   */
  protected setToCache(values: T[] | T, key: string = 'id'): void {
    this.setToCacheMap(values, key);
    this.setToCacheList(values);
  }

  /**
   * this function is responsible for managing the cache of each element
   * @param values
   * @param key
   */
  private setToCacheMap(values: T[] | T, key: string = 'id'): void {
    if (this.cacheMapSubject == null) {
      this.cacheMapSubject = new Map<string, BehaviorSubject<T>>();
    }
    if (!Array.isArray(values)) {
      values = [values];
    }
    if (Array.isArray(values)) {
      values.forEach((value) => {
        if (value[key]) {
          if (this.cacheMapSubject.has(value[key])) {
            this.cacheMapSubject.get(value[key]).next(value);
          } else {
            this.cacheMapSubject.set(value[key], new BehaviorSubject(value));
          }
        }
      });
    }

    this.entityTypeGetDate = new Date();
  }

  /**
   * this function is responsible for managing the cache of a list of elements
   * @param values
   */
  private setToCacheList(values: T[] | T): void {
    if (this.cacheListSubject.getValue() == null) {
      this.cacheListSubject.next([]);
    }
    if (!Array.isArray(values)) {
      values = [values];
    }
    values.forEach((value) => {
      if (value['id'] != null) {
        const element = this.cacheListSubject
          .getValue()
          .find((el) => el['id'] === value['id']);
        if (element != null) {
          Object.assign(element, value);
          return;
        }
      }

      this.cacheListSubject.getValue().push(value);
    });

    this.cacheListSubject.next(this.cacheListSubject.getValue());
  }

  /**
   * Getter for cache map element by key
   *
   * @returns The elmement as an observable
   */
  public getElementObservableByKey(key: string): Observable<T> {
    return this.cacheMapSubject.get(key);
  }

  /**
   * Getter for element value by key
   *
   * @returns Element current value
   */
  public getElementByKey(key: string): T {
    const subjectByKey = this.cacheMapSubject.get(key);

    return subjectByKey ? subjectByKey.getValue() : null;
  }

  /**
   * returns the observale of the list
   * @returns
   */
  public getElementsListObservable(): Observable<T[]> {
    return this.cacheListSubject.asObservable();
  }

  /**
   * returns the elements of the list
   * @returns
   */
  public getElementsList(): T[] {
    return this.cacheListSubject.getValue();
  }

  /**
   * return if cache is valid
   * @returns boolean
   */

  public isCacheValid(): boolean {
    if (
      !this.entityTypeGetDate ||
      new Date().getTime() - this.entityTypeGetDate.getTime() >=
        this.cacheTimeMs
    ) {
      return false;
    } else {
      return true;
    }
  }
}
