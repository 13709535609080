import { Component } from "@angular/core";
import { Role } from "../../models/role.model";
import { AbstractRolesScopesService } from "../../services/roles-scopes/abstract-roles-scopes.service";

/**
 * Roles and Scopes page component
 */
@Component({
  selector: "app-prg-roles-scopes",
  templateUrl: "./prg-roles-scopes.page.html",
  styleUrls: ["./prg-roles-scopes.page.scss"],
})
export class PrgRolesScopesPage {
  /**
   * current role selected
   */
  public roleSelected: Role;

  /**
   * roles list
   */
  public rolesList!: Role[];

  /**
   * constructor
   */
  constructor(private rolesScopesService: AbstractRolesScopesService) {}

  /**
   * receive output onAction
   *
   * @param roles
   */
  onSubmitRolesSelected(role: Role) {
    this.roleSelected = JSON.parse(JSON.stringify(role));
  }

  /**
   * Receives output when a new role is added
   *
   * @param added
   */
  addedNewRole(added: Role) {
    this.roleSelected = added;
    this.getAllRoles();
  }

  /**
   *this function get all roles
   */
  private async getAllRoles() {
    this.rolesList = await this.rolesScopesService.getAllRolesAsync();
  }
}
