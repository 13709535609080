import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { PrgAuthConfig } from '../../auth/models/prg-auth-config';
import { PRG_AUTH_CONFIG } from '../../auth/services/prg-auth-configuration/prg-auth-configuration.service';
import { PrgConfigurationItemsConfig } from '../../configuration-items/models/prg-configuration-items-config';
import { PRG_CONFIGURATION_ITEMS_CONFIG } from '../../configuration-items/services/prg-configuration-items-config/prg-configuration-items-config.service';
import { ArrayUtilityService } from '../../core/services/utility/array-utility.service';
import { UtilityService } from '../../core/services/utility/utility.service';
import { PrgDashboardConfig } from '../../dashboard/models/prg-dashboard-config';
import { PRG_DASHBOARD_CONFIG } from '../../dashboard/services/prg-dashboard-configuration/prg-dashboard-configuration.service';
import { PrgEntityTypesConfig } from '../../entity-types/models/prg-entity-types-config';
import { PRG_ENTITY_TYPES_CONFIG } from '../../entity-types/services/entity-types-configuration/prg-entity-types-configuration.service';
import { PrgLookupTableConfig } from '../../lookup-table/models/prg-lookup-table-config';
import { PRG_LOOKUP_TABLE_CONFIG } from '../../lookup-table/services/lookup-table-configuration/prg-lookup-table-configuration.service';
import { PrgQueriesConfig } from '../../queries/models/prg-queries-config';
import { PRG_QUERIES_CONFIG } from '../../queries/services/prg-queries-configuration/prg-queries-configuration.service';
import { PrgRolesScopesConfig } from '../../roles-scopes/models/prg-roles-scopes-config';
import { PRG_ROLES_SCOPES_CONFIG } from '../../roles-scopes/services/roles-scopes-configuration/prg-roles-scopes-configuration.service';
import { PrgTermsConfig } from '../../terms/models/prg-terms-config';
import { PRG_TERMS_CONFIG } from '../../terms/services/prg-terms-configuration/prg-terms-configuration.service';
import { PrgMenuItem } from '../models/prg-menu-item';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * Main Layout Service
 */
export class MainLayoutService {
  /**
   * Current state of SideBar
   */
  private sideBarSubject = new BehaviorSubject<any>(null);

  /**
   * Show/hide header subject
   * @type {BehaviorSubject<boolean>}
   * @private
   */
  private displayHeaderSubject = new BehaviorSubject<boolean>(true);

  /**
   * constructor
   * @param {PrgAuthConfig} authConfig
   * @param {PrgEntityTypesConfig} entityTypesConfig
   * @param {PrgLookupTableConfig} lookupTableConfig
   * @param {PrgQueriesConfig} queriesConfig
   * @param {PrgRolesScopesConfig} rolesScopesConfig
   * @param {PrgTermsConfig} termsConfig
   * @param {PrgDashboardConfig} dashboardConfig
   * @param {PrgConfigurationItemsConfig} configItemsConfig
   * @param {ArrayUtilityService} arrayUtility
   * @param {TranslateService} translateService
   * @param {UtilityService} utilityService
   */
  constructor(
    @Inject(PRG_AUTH_CONFIG) private authConfig: PrgAuthConfig,
    @Inject(PRG_ENTITY_TYPES_CONFIG)
    private entityTypesConfig: PrgEntityTypesConfig,
    @Inject(PRG_LOOKUP_TABLE_CONFIG)
    private lookupTableConfig: PrgLookupTableConfig,
    @Inject(PRG_QUERIES_CONFIG)
    private queriesConfig: PrgQueriesConfig,
    @Inject(PRG_ROLES_SCOPES_CONFIG)
    private rolesScopesConfig: PrgRolesScopesConfig,
    @Inject(PRG_TERMS_CONFIG)
    private termsConfig: PrgTermsConfig,
    @Inject(PRG_CONFIGURATION_ITEMS_CONFIG)
    private configItemsConfig: PrgConfigurationItemsConfig,
    @Inject(PRG_DASHBOARD_CONFIG)
    private dashboardConfig: PrgDashboardConfig,
    private arrayUtility: ArrayUtilityService,
    private translateService: TranslateService,
    private utilityService: UtilityService
  ) {}

  /**
   * Setter for sideBarSubject
   * @param value
   */
  public setSideBarState(value: any): void {
    this.sideBarSubject.next(value);
  }

  /**
   * Getter for sideBarSubject
   *
   * @returns The sideBarSubject as an observable
   */
  public getSideBarStateObservable(): Observable<any> {
    return this.sideBarSubject.asObservable();
  }

  /**
   * Getter for sideBarSubject
   *
   * @returns The sideBarSubject current value
   */
  public getSideBarStateValue(): any {
    return this.sideBarSubject.getValue();
  }

  /**
   * Setter for displayHeaderSubject
   * @param value
   */
  public setDisplayHeaderState(value: boolean): void {
    this.displayHeaderSubject.next(value);
  }

  /**
   * Getter for displayHeaderSubject
   *
   * @returns The sideBarSubject as an observable
   */
  public getDisplayHeaderStateObservable(): Observable<boolean> {
    return this.displayHeaderSubject.asObservable();
  }

  /**
   * Getter for displayHeaderSubject
   *
   * @returns The sideBarSubject current value
   */
  public getDisplayHeaderStateValue(): any {
    return this.displayHeaderSubject.getValue();
  }
  /**

  /**
   * Function responsible to complete sidebar menu with labels of selected language
   * @param {PrgMenuItem[]} mainMenu
   * @param {boolean} mainMenuExpanded
   * @returns {Promise<MenuItem[]>}
   */
  public async getSideBarMenuItemsWithLabelsAsync(
    mainMenu: PrgMenuItem[],
    mainMenuExpanded: boolean
  ): Promise<PrgMenuItem[]> {
    let sideBarMenuItems: PrgMenuItem[] = this.arrayUtility.clone(mainMenu);

    sideBarMenuItems.map(async (item) => {
      if (item.id) {
        if (mainMenuExpanded) {
          item.label = await this.translateService
            .get('components.sidebar.' + item.id + '.label')
            .toPromise();
        } else {
          if (!item.items) {
            item.tooltipOptions = {
              tooltipLabel: await this.translateService
                .get('components.sidebar.' + item.id + '.label')
                .toPromise(),
            };
          }
        }
      }
      if (item.items) {
        if (!mainMenuExpanded) {
          item.items.unshift({
            separator: true,
          });
          item.items.unshift({
            label: await this.translateService
              .get('components.sidebar.' + item.id + '.label')
              .toPromise(),
            style: {
              'pointer-events': 'none',
              'font-weight': 'bold',
            },
          });
        }
        item.items.map(async (itemChildren) => {
          if (itemChildren.id) {
            itemChildren.label = await this.translateService
              .get(
                'components.sidebar.' +
                  item.id +
                  '.' +
                  itemChildren.id +
                  '.label'
              )
              .toPromise();
          }
        });
      }
    });
    return Promise.all(sideBarMenuItems);
  }

  /**
   * Function responsible to complete header menus (user and language) with labels of selected language
   * @param {PrgMenuItem[]} menuItem
   * @returns {Promise<PrgMenuItem[]>}
   */
  public async getHeaderMenuItemsWithLabelsAsync(
    menuItem: PrgMenuItem[]
  ): Promise<PrgMenuItem[]> {
    let menuItemWithLabels: MenuItem[] = this.arrayUtility.clone(menuItem);
    if (menuItem[0].id) {
      menuItemWithLabels[0].label = await this.translateService
        .get('components.header.' + menuItem[0].id + '.label')
        .toPromise();
    }
    menuItem[0].items.map(async (item, index) => {
      if (item.id) {
        menuItemWithLabels[0].items[index].label = await this.translateService
          .get('components.header.' + menuItem[0].id + '.' + item.id + '.label')
          .toPromise();
      }
    });
    return Promise.all(menuItemWithLabels);
  }

  /**
   * Function responsible to handle auxiliary string properties of menu type PrgMenuItem
   * @param {PrgMenuItem[]} menuItems
   * @param context
   * @returns {PrgMenuItem[]}
   */
  public mapMenuItemsHelpers(
    menuItems: PrgMenuItem[],
    context: any
  ): PrgMenuItem[] {
    let menuItemsWithHelpersMapped = this.arrayUtility.clone(menuItems);
    menuItemsWithHelpersMapped.map((items, mainIndex) => {
      if (items.commandString) {
        items.command = this.utilityService.evalFunction(
          items.commandString,
          context
        );
      }
      if (items.visibleString) {
        items.visible = this.utilityService.evalFunction(
          items.visibleString,
          context
        );
      }
      if (items.routerLinkString) {
        items.routerLink = this.utilityService.evalFunction(
          items.routerLinkString,
          this
        );
      }
      if (menuItemsWithHelpersMapped[mainIndex].items) {
        menuItemsWithHelpersMapped[mainIndex].items.forEach((subItems) => {
          if (subItems.commandString) {
            subItems.command = this.utilityService.evalFunction(
              subItems.commandString,
              context
            );
          }
          if (subItems.visibleString) {
            subItems.visible = this.utilityService.evalFunction(
              subItems.visibleString,
              context
            );
          }
          if (subItems.routerLinkString) {
            subItems.routerLink = this.utilityService.evalFunction(
              subItems.routerLinkString,
              this
            );
          }
        });
      }
    });
    return menuItemsWithHelpersMapped;
  }
}
