<div class="flex justify-content-center">
<div class="mainContainer shadow-3  border-1 border-300 surface-card border-round-lg w-28rem">
  <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitResetPassword()" novalidate>
    <div class="text-600 text-2xl w-full border-bottom-1 border-top-1 border-300 p-2 my-4 text-center">{{'components.reset-password.title'|translate|uppercase}}
    </div>
    <div class="px-5 mb-3">
      <h6 class="text-700">{{'components.reset-password.new-password.label'|translate}}</h6>
      <span class="p-input-icon-left w-full "><i class="pi pi-lock" (click)="onShowPassword()"
                                                 style="cursor: pointer"></i>
              <input placeholder="{{'components.reset-password.new-password.placeholder'|translate}}" formControlName="password" autofocus id="password"
                     autocomplete="new-password"
                     [type]="showPassword?'text':'password'" class="w-full"
                     pInputText></span>
      <div class="flex flex-column mt-2"
           *ngIf="resetPasswordForm.controls.password.touched&&resetPasswordForm.controls.password.dirty">
        <small class="p-error w-max" *ngIf="resetPasswordForm.controls.password.hasError('required')">Password is
          required.</small>
        <small class="p-error w-max" *ngIf="resetPasswordForm.controls.password.hasError('hasCapitalCase')">Password
          must have
          one upper case.</small>
        <small class="p-error" *ngIf="resetPasswordForm.controls.password.hasError('hasSmallCase')">Password must have
          one lower
          case.</small>
        <small class="p-error" *ngIf="resetPasswordForm.controls.password.hasError('hasNumber')">Password must have one
          number.</small>
        <small class="p-error" *ngIf="resetPasswordForm.controls.password.hasError('minlength')">Password must
          have {{this.passwordRequirements.requiredLength}} chars in length.</small>
        <small class="p-error" *ngIf="resetPasswordForm.controls.password.hasError('hasSpecialCharacters')">Password
          must have
          one special char.</small>
        <small class="p-error" *ngIf="resetPasswordForm.controls.password.hasError('numberOfUniqueChars')">Password must
          have {{this.passwordRequirements.requiredUniqueChars}} unique chars.</small>
      </div>
    </div>
    <div class="px-5 mb-5">
      <h6 class="text-700">{{'components.reset-password.new-password-confirmation.label'|translate}}</h6>
      <span class="p-input-icon-left w-full "><i class="pi pi-lock" (click)="onShowPassword()"
                                                 style="cursor: pointer"></i>
              <input placeholder="{{'components.reset-password.new-password-confirmation.placeholder'|translate}}" formControlName="confirmPassword" id="confirmPassword"
                     autocomplete="new-password"
                     [type]="showPassword?'text':'password'" class="w-full"
                     pInputText></span>

      <div class="flex flex-column mt-2"
           *ngIf="resetPasswordForm.controls.confirmPassword.touched&&resetPasswordForm.controls.confirmPassword.dirty">

        <small class="p-error w-max" *ngIf="resetPasswordForm.controls.confirmPassword.hasError('required')">Confirm
          Password is
          required.</small>
        <small class="p-error w-max" *ngIf="resetPasswordForm.hasError('passDontMatch')">Password and
          confirm password do not match</small>
      </div>
    </div>
    <div class="px-5 mb-3 text-center">
      <button type="submit" [disabled]="!resetPasswordForm.valid||(isLoadingData.visibility|async)" pButton pRipple
              label="{{'components.reset-password.button.label'|translate}}"
              icon="pi pi-unlock"
              class="w-full"></button>
    </div>
    <div class="px-5 mb-3  text-center" *ngIf="showLoginLink">
      <a [style.pointer-events]="(isLoadingData.visibility|async)?'none':'auto'"  routerLink="{{loginPageRoute}}"
         class="underlineEffect text-sm blue-500 font-medium cursor-pointer"
         style="text-decoration: none">{{'components.request-password.login-link.label'|translate}}</a>
    </div>

  </form>
</div>
</div>

