import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { OwnerContext } from "../../models/owner-context.model";

/**
 * Injectable
 */
@Injectable()
/**
 * Generic context service, provides a way to store information in any context
 */
export class ContextGenericService {
  /**
   * Map that holds all the variables and actions.
   * <br />-> key - owner name
   * <br />-> value object that holds a map for variables and a map for actions
   */
  protected context = new Map<string, OwnerContext>();
  /**
   * Array that holds all subscriptions, used to clean up on destroy
   */
  protected subscriptions: Subscription[] = [];

  /**
   * Clean up all
   */
  ngOnDestroy() {
    while (this.subscriptions.length > 0) {
      this.unsubscribe(this.subscriptions[0]);
    }
  }

  /**
   * Get owner context using name, if the context does not exist, it is created
   * @param ownerName Name of the owner
   * @returns The owner full context
   */
  protected getOwnerContext(ownerName: string): any {
    let ownerContext = this.context[ownerName];
    if (!ownerContext) {
      // owner context does not exist, create it
      ownerContext = {
        variables: new Map<string, BehaviorSubject<any>>(),
        actions: new Map<string, EventEmitter<any>>(),
      };
      this.context[ownerName] = ownerContext;
    }
    return ownerContext;
  }

  /**
   * Get a variable subject using owner and name, if the variable does not exist, it is created
   * @param ownerName Name of the owner
   * @param variableName Name of the variable
   * @returns Subject of the variable
   */
  protected getVariable(
    ownerName: string,
    variableName: string
  ): BehaviorSubject<any> {
    // Check if owner context exist
    let ownerContext = this.getOwnerContext(ownerName);

    // check if variable exist for this owner
    let variable = ownerContext.variables[variableName];

    if (!variable) {
      // variable does not exist, create it
      variable = new BehaviorSubject<any>(null);
      ownerContext.variables[variableName] = variable;
    }

    return variable;
  }

  /**
   * Subscribes to a variable subject using owner and name, if the variable does not exist, it is created
   * @param ownerName Name of the owner
   * @param variableName Name of the variable
   * @param callback callback function of the subscriber
   * @returns Susbcription
   */
  public subscribeVariable(
    ownerName: string,
    variableName: string,
    callback: Function
  ): Subscription {
    const subject = this.getVariable(ownerName, variableName);
    const subscription = subject.asObservable().subscribe((data) => {
      callback(this.cloneObject(data));
    });

    this.subscriptions.push(subscription);
    return subscription;
  }

  /**
   *  // TODO: This method should be changed to a generic utils class
   */
  protected cloneObject(obj: any) {
    return obj ? Object.assign({}, obj) : null; // TODO: check best way to clone a full deep copy
  }

  /**
   * Set data to a variable subject using owner and name, if the variable does not exist, it is created
   * @param ownerName Name of the owner
   * @param variableName Name of the variable
   * @param data Data to be set
   */
  public setVariableData(
    ownerName: string,
    variableName: string,
    data: any
  ): void {
    const subject = this.getVariable(ownerName, variableName);
    const newValue = this.cloneObject(data);
    subject.next(newValue);
  }

  /**
   * Get an action event using owner and name, if the action does not exist, it is created
   * @param ownerName Name of the owner
   * @param actionName Name of the action
   * @returns Event of the action
   */
  protected getAction(
    ownerName: string,
    actionName: string
  ): EventEmitter<any> {
    // Check if owner context exist
    let ownerContext = this.getOwnerContext(ownerName);

    // check if variable exist for this owner
    let action = ownerContext.actions[actionName];

    if (!action) {
      // variable does not exist, create it
      action = new EventEmitter<any>(true);
      ownerContext.actions[actionName] = action;
    }

    return action;
  }

  /**
   * Subscribes to an action using owner and name, if the variable does not exist, it is created
   * @param ownerName Name of the owner
   * @param actionName Name of the action
   * @param callback callback function of the subscriber
   * @returns Susbcription
   */
  public subscribeAction(
    ownerName: string,
    actionName: string,
    callback: Function
  ): Subscription {
    const subject = this.getAction(ownerName, actionName);
    const subscription = subject.subscribe((data) => {
      callback(this.cloneObject(data));
    });
    this.subscriptions.push(subscription);
    return subscription;
  }

  /**
   * Send action using owner and name, if the action does not exist, it is created
   * @param ownerName Name of the owner
   * @param actionName Name of the action
   * @param data Data to be set
   */
  public sendAction(ownerName: string, actionName: string, data: any): void {
    const action = this.getAction(ownerName, actionName);
    action.emit(this.cloneObject(data));
  }

  /**
   * Unsubscribes a subscription
   * @param subscription Subscription to unsubscribe
   */
  public unsubscribe(subscription: Subscription | Subscription[]) {
    if (!subscription) return;

    if (Array.isArray(subscription)) {
      // TODO:
    } else {
      const index = this.subscriptions.findIndex((s) => s === subscription);
      subscription.unsubscribe();
      if (index >= 0) {
        this.subscriptions.splice(index, 1);
      }
    }
  }
}
