import { Injectable } from '@angular/core';
import { ConfigurationItem } from '../../models/configuration-item.model';
import { AbstractConfigurationItemsService } from './abstract-configuration-items.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * REAL ConfigurationItemsService implementation
 */
export class ConfigurationItemsService extends AbstractConfigurationItemsService {
  /**
   * Get a list of all configuration items
   * @returns {Promise<ConfigurationItem[]>}
   */
  public getAllConfigurationItemsAsync(): Promise<ConfigurationItem[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Function responsible to update Config Items
   * @param configItemData
   * @returns {Promise<ConfigurationItem[]>}
   */
  public updateConfigurationItemsAsync(
    configItemData: any
  ): Promise<ConfigurationItem[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Get configuration items by path
   * @param {string} path
   * @returns {Promise<any>}
   */
  public getConfigurationItemsByPathAsync(path: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
