import { BaseEntity } from '../../core/models/base-entity.model';
/**
 * Role class that defines the basic properties
 * <br />of an Role.
 */
export class Role extends BaseEntity {
  /**
   * scopes that this role has
   */
  scopes: string[];

  /**
   * scope label
   */

  label: string;
}
