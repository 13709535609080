import { Role } from '../models/role.model';

/**
 * Database interface that defines a mock
 * <br />database to be used while developing
 * <br />and/or testing the front-end.
 */
export interface RolesScopesBD {
  /**
   * Mock Roles
   */
  Roles: Role[];
}
/**
 * MockDatabase object to be used while developing
 * <br /> and/or testing the front-end
 */
export const RolesScopesBDMOCk: RolesScopesBD = {
  Roles: [
    {
      id: '1',
      name: 'Admin',
      universalStateId: '',
      createdBy: '',
      modifiedBy: '',
      createdOn: null,
      modifiedOn: null,
      operationId: null,
      transactionId: null,
      label: null,
      scopes: [
        'Customer:view',
        'Customer:create',
        'Customer:delete',
        'Product:view',
        'Product:create',
        'User:create',
        'User:update',
        'User:view',
        'Orders:create',
        'Orders:viewUserOrder',
        'Orders:updateUserOrder',
      ],
    },
    {
      id: '2',
      name: 'Manager',
      universalStateId: '',
      createdBy: '',
      modifiedBy: '',
      createdOn: null,
      modifiedOn: null,
      operationId: '',
      transactionId: '',
      label: null,
      scopes: ['Customer:view', 'Customer:create'],
    },
    {
      id: '3',
      name: 'shop-manager',
      universalStateId: '',
      createdBy: '',
      modifiedBy: '',
      createdOn: null,
      modifiedOn: null,
      operationId: '',
      transactionId: '',
      scopes: null,
      label: null,
    },
    {
      id: '4',
      name: 'Marketing',
      universalStateId: '',
      createdBy: '',
      modifiedBy: '',
      createdOn: null,
      modifiedOn: null,
      operationId: '',
      transactionId: '',
      scopes: null,
      label: null,
    },
  ],
};
