/**
 * Class Terms
 */
export class Terms {
  /**
   * The header of each section of terms
   */
  public header: string;
  /**
   * The content of each section of terms
   */
  public content: string;
  /**
   * The order of each section of terms
   */
  public order: string;
}
