import { NgModule } from '@angular/core';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { PrgLayoutsEmptyComponent } from '../layouts/empty/prg-layouts-empty.component';
import { PrgLayoutsHeaderSidebarComponent } from '../layouts/pages/prg-layouts-header-sidebar';
import { PrgDashboardDisplayComponent } from './components/dashboard-display/prg-dashboard-display.component';
import { PrgDashboardListComponent } from './components/dashboard-list/prg-dashboard-list.component';
import { PrgDashboardComponent } from './components/dashboard/prg-dashboard.component';
import { dashboardConfig } from './prg-dashboard.module';

const standardRoutes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(standardRoutes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        let routes: Routes = [];
        routes.push({
          path: dashboardConfig.dashboardRoutingBasePath,
          component: PrgLayoutsHeaderSidebarComponent,
          children: [
            {
              path: dashboardConfig.dashboardCreateRoutingPath,
              component: PrgDashboardComponent,
            },
            {
              path: dashboardConfig.dashboardEditRoutingPath + '/:id',
              component: PrgDashboardComponent,
            },
            {
              path: dashboardConfig.dashboardDisplayRoutingPath + '/:name',
              component: PrgDashboardDisplayComponent,
            },
            {
              path: dashboardConfig.dashboardDisplayRoutingPath,
              component: PrgDashboardDisplayComponent,
            },
            {
              path: dashboardConfig.dashboardListRoutingPath,
              component: PrgDashboardListComponent,
            },
          ],
        });

        return [...routes, ...standardRoutes];
      },
      multi: true,
    },
  ],
})
export class PrgDashboardRoutingModule {}
