import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { UtilityService } from '../../../../../services/utility/utility.service';
import { FieldChangeOutput } from '../../../models/base-action';
import { InputTextAreaConfiguration } from './input-text-area.model';

/**
 * component input text area
 */
@Component({
  selector: 'prg-input-text-area-field',
  templateUrl: './input-text-area.component.html',
})
export class PrgInputTextAreaComponent {
  /**
   * input text area configs
   */
  @Input() configs: InputTextAreaConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * Constructor
   * @param {UtilityService} utilityService
   */
  constructor(private utilityService: UtilityService) {}

  /**
   * function that triggers the output
   */
  public onChanged(event: any): void {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event,
        })
      );
    }
  }

  /**
   * Debounced method onChanged
   * @type {(args?: any) => void}
   */
  public debounceOnChanged = this.utilityService.debounce((event) => {
    this.onChanged(event);
  }, 500);
}
