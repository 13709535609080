import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../auth/models/user.model';
import { AbstractAuthService } from '../../../auth/services/auth/abstract-auth.service';
import {
  BaseAction,
  BaseActionKey,
  DynamicFormActionOutput,
} from '../../../core/components/dynamic-form/models/base-action';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { CONFIG_ITEMS_DYNAMIC_FORM } from '../../dynamic-form-structure/config-items-dynamic-form';
import { ConfigurationItem } from '../../models/configuration-item.model';
import { AbstractConfigurationItemsService } from '../../services/configuration-items/abstract-configuration-items.service';

/**
 * Config items user component
 */
@Component({
  selector: 'prg-config-items-user',
  templateUrl: './prg-config-items-user.component.html',
  styleUrls: ['./prg-config-items-user.component.scss'],
})
export class PrgConfigItemsUserComponent implements OnInit {
  /**
   * The view mode type of the form. Default is "read"
   * @type {ViewMode}
   */
  public configItemsViewMode: ViewMode = ViewMode.Read;

  /**
   * The complete path for the config item user-preferences defaults
   * @type {string}
   */
  @Input() configItemUserPreferencesDefaultsPath =
    'Config-Items:User-Preferences:';

  /**
   * A boolean property of the class to show/hide form. Default is false(hide)
   * @type {boolean}
   */
  public initForm: boolean = false;

  /**
   * An object with the data to build form , keys and values
   * @type {any}
   */
  public entityConfigItems: any = {};

  /**
   * The title of the form of configuration item
   * @type {string}
   */
  public titleForm: string;

  /**
   * The dynamic form fields to build dynamic form config items in template
   */
  public configItemsDynamicFormFields: any[] = this.arrayUtilityService.clone(
    CONFIG_ITEMS_DYNAMIC_FORM.fields
  );

  /**
   * The dynamic form actions to build dynamic form config items in template
   */
  public configItemsDynamicFormActions: BaseAction[] =
    this.arrayUtilityService.clone(CONFIG_ITEMS_DYNAMIC_FORM.actions);
  /**
   * A copy of entityConfigItems object
   * @type {any}
   * @private
   */
  private entityConfigItemCopy: any = {};

  /**
   * User and defaults config items
   * @type {any}
   */
  public userAndDefaultsConfigsItems: any = {};

  /**
   * Current logged User
   * @type {User}
   */
  public loggedUser: User;

  /**
   * The path of user preferences for the current logged user
   * @type {string}
   */
  public configItemUserPath: string;

  /**
   * The configuration items defaults of user preferences
   * @type {ConfigurationItem[]}
   * @private
   */
  private configItemsUserPreferencesDefaults: ConfigurationItem[] = [];
  /**
   * The configuration items of user preferences of current user
   * @type {ConfigurationItem[]}
   * @private
   */
  private configItemsUserPreferences: ConfigurationItem[] = [];

  /**
   * Constructor
   * @param {AbstractConfigurationItemsService} configurationItemsService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {UtilityService} utilityService
   * @param {TranslateService} translateService
   * @param {ObjectsUtilityService} objectUtility
   * @param {AbstractAuthService} authService
   */
  constructor(
    private configurationItemsService: AbstractConfigurationItemsService,
    private arrayUtilityService: ArrayUtilityService,
    private utilityService: UtilityService,
    private translateService: TranslateService,
    private objectUtility: ObjectsUtilityService,
    private authService: AbstractAuthService
  ) {}

  /**
   * ngOnInit
   *
   * Get  configuration items User Preferences and settings to initiate the template
   * @returns {Promise<void>}
   */
  public async ngOnInit() {
    this.userAndDefaultsConfigsItems =
      await this.configurationItemsService.getConfigurationItemsByPathAsync(
        this.configItemUserPreferencesDefaultsPath
      );
    this.configItemsUserPreferencesDefaults = this.objectUtility.cloneObject(
      this.userAndDefaultsConfigsItems.defaults
    );

    await this.getConfigItemsUserPreferencesByLoggedUser();

    this.configItemsDynamicFormFields = this.arrayUtilityService.clone(
      this.getAndParseGuiSettingsItems()
    );
    this.assignConfigItemDataToForm();

    this.setTitleForm();
    //Initiate Form
    this.initForm = true;
  }

  /**
   * This function is responsible to get Config Items regarding user logged
   * @returns {Promise<void>}
   */
  public async getConfigItemsUserPreferencesByLoggedUser() {
    this.loggedUser = this.objectUtility.cloneObject(
      this.authService.getLoggedUser()
    );
    this.configItemUserPath =
      this.configItemUserPreferencesDefaultsPath + this.loggedUser?.email;

    this.configItemsUserPreferences = this.objectUtility.cloneObject(
      this.userAndDefaultsConfigsItems.user
    );
  }

  /**
   * This function is responsible to get the gui settings of config item and parse it
   * @returns {any}
   * @private
   */
  private getAndParseGuiSettingsItems(): any {
    //Gui Settings
    let guiSettings;

    if (
      this.configItemsUserPreferencesDefaults != null &&
      this.configItemsUserPreferencesDefaults.length > 0
    ) {
      guiSettings = this.configItemsUserPreferencesDefaults.map(
        (configItem) => {
          return this.utilityService.guiSettingToObject(configItem.guiSettings);
        }
      );
    } else {
      guiSettings = [];
    }
    return guiSettings;
  }

  /**
   * This function will assign config items data to form
   * @private
   */
  private assignConfigItemDataToForm(): void {
    // Assign config item data to form - entities
    const mapConfigItemsIntoEntity: any[] = [];
    this.configItemsUserPreferences.map((configItems) => {
      mapConfigItemsIntoEntity[configItems.name] = configItems.value;
    });

    if (
      this.configItemsUserPreferencesDefaults != null &&
      this.configItemsUserPreferencesDefaults.length > 0
    ) {
      this.configItemsUserPreferencesDefaults.map(async (configItems) => {
        if (!mapConfigItemsIntoEntity[configItems.name]) {
          mapConfigItemsIntoEntity[configItems.name] = null;
        }
        let fieldGuiSettings = this.configItemsDynamicFormFields.find(
          (value) => value.key === configItems.name
        );

        if (fieldGuiSettings.basePathTranslation.includes('lookup-tables')) {
          fieldGuiSettings.placeholder =
            this.translateService.instant(
              fieldGuiSettings.basePathTranslation +
                '.' +
                fieldGuiSettings.key +
                '.items.' +
                configItems.value +
                '.name'
            ) + ' (default)';
        } else {
          fieldGuiSettings.placeholder = configItems.value + ' (default)';
        }
      });
    }
    mapConfigItemsIntoEntity['path'] = this.configItemUserPath;
    this.entityConfigItems = Object.assign({}, mapConfigItemsIntoEntity);
    this.entityConfigItemCopy = this.objectUtility.cloneObject(
      this.entityConfigItems
    );
  }

  /**
   * This function will set the title of the form
   * @private
   */
  private setTitleForm(): void {
    // Title of Form
    if (this.entityConfigItems.path) {
      this.titleForm = this.entityConfigItems.path
        .substring(0, this.entityConfigItems.path.lastIndexOf(':'))
        .replaceAll(':', '.')
        .toLowerCase();
    }
  }

  /**
   * This function is responsible to handle actions from the dynamic config items form
   * @param {DynamicFormActionOutput} event
   * @returns {Promise<void>}
   */
  public async onActionConfigItems(
    event: DynamicFormActionOutput
  ): Promise<void> {
    switch (event.action) {
      case BaseActionKey.Save:
        await this.configItemsSave(event);
        break;

      case BaseActionKey.Cancel:
        this.configItemsViewMode = ViewMode.Read;
        this.entityConfigItems = this.objectUtility.cloneObject(
          this.entityConfigItemCopy
        );

        break;

      case BaseActionKey.Edit:
        this.configItemsViewMode = ViewMode.Edit;
        break;
      default:
        break;
    }
  }

  /**
   * This function is responsible to save a new config item or update an existing one
   * @param {DynamicFormActionOutput} event
   * @returns {Promise<void>}
   * @private
   */
  private async configItemsSave(event: DynamicFormActionOutput): Promise<void> {
    this.configItemsViewMode = ViewMode.Read;

    try {
      this.initForm = false;
      await this.configurationItemsService.updateConfigurationItemsAsync(
        event.formEntity
      );
      this.configItemsUserPreferences = this.arrayUtilityService.clone(
        this.configurationItemsService
          .getConfigurationItemsValue()
          .filter((configItem) => configItem.path === this.configItemUserPath)
      );

      this.assignConfigItemDataToForm();

      this.initForm = true;
    } catch (e) {}
  }
}
