<div *ngIf="height&&width" class="card" [style]="{'height':height,'width':width}">
<p-table   [value]="products"  [scrollable]="true" [scrollHeight]="height" >
  <ng-template pTemplate="header">
    <tr style="text-align: center">
      <th style="text-align: center" >Code</th>
      <th style="text-align: center">Name</th>
      <th style="text-align: center">Category</th>
      <th style="text-align: center">Quantity</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr style="text-align: center">
      <td style="text-align: center">{{product.code}}</td>
      <td style="text-align: center">{{product.name}}</td>
      <td style="text-align: center">{{product.category}}</td>
      <td style="text-align: center">{{product.quantity}}</td>
    </tr>
  </ng-template>
</p-table>
</div>
