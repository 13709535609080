<p>{{
    configs.translationBasePath + '.' + configs.key + '.label' | translate
    }}</p>
<p-selectButton
  (onOptionClick)="onButtonClicked($event)"
  [setSelectButtonActionConfigs]="configs"
  [disabled]="!form.valid && configs.disableWhenFormInvalid!"
  >
</p-selectButton>

