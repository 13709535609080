import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActionOutput, BaseAction } from '../models/base-action';
import { ActionTypes } from '../models/constants/action-types';

/**
 * Dynamic form action component
 */
@Component({
  selector: 'prg-dynamic-form-action',
  templateUrl: './prg-dynamic-form-action.component.html',
  styleUrls: ['./prg-dynamic-form-action.component.scss'],
})
export class PrgDynamicFormActionComponent {
  /**
   * actions types
   */
  public actionTypes = ActionTypes;

  /**
   * receives a action
   */
  @Input() action!: BaseAction;

  /**
   * receive the formGroup
   */
  @Input() form!: FormGroup;

  /**
   * action Click output
   */
  @Output() actionClick = new EventEmitter<ActionOutput>();

  /**
   * output to dynamic component
   */
  outputs = {
    actionClick: (action: ActionOutput) => {
      this.actionClick.emit(action);
    },
  };
}
