
<div>
  <p-treeTable [value]="treeData" >
    <ng-template pTemplate="header">
      <tr>

        <th style="width: 10rem;text-align: center" *ngFor="let col of queriesTableColumns" [ttSortableColumn]="col.key" pTooltip="{{col.basePathTranslation+'.'+col.key+'.tooltip'|translate }}" tooltipPosition="top">

            {{ col.basePathTranslation+"."+col.key+".label"|translate }}

          <p-treeTableSortIcon [field]="col.key"></p-treeTableSortIcon>

        </th>
        <th style="width: 3rem;text-align: center">
          <p-button pTooltip="{{'queries.queries-list.actions.add.label'|translate}}" tooltipPosition="bottom" (click)=" openQueryDialog(null)" type="button" icon="pi pi-plus-circle"></p-button>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr
        [ttRow]="rowNode"
        (click)="rowNode.level !== 0 && openQueryDialog(rowData)"
        [ngClass]="{ clickableRow: rowNode.level !== 0 }"
      >
        <td *ngFor="let col of queriesTableColumns; let i = index ;let last = last " [colSpan]="last?2:1" [ngStyle]="{'text-align':(rowNode.level!==0)? 'center':''}" >
          <p-treeTableToggler
            *ngIf="i == 0"
            [rowNode]="rowNode"

          ></p-treeTableToggler>

          <ng-template [ngIf]="col.key=='isRaw' && rowNode.level!==0">
            {{rowData[col.key] ? 'Yes': 'No'}}
          </ng-template>
          <ng-template  [ngIf]="col.key!='isRaw' && rowNode.level!==0" >
            {{rowData[col.key]}}
          </ng-template>

          <ng-template  [ngIf]="i==0&&rowNode.level==0">
            {{'queries.queries-list.queries-group.'+rowData[col.key]+'.label'|translate }}

          </ng-template>



        </td>
      </tr>
    </ng-template>
  </p-treeTable>

</div>
