import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, lastValueFrom, map, Subscription } from 'rxjs';
import { ObjectsUtilityService } from '../../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../../core/services/utility/utility.service';
import { MainLayoutService } from '../../../../layouts/services/main-layout.service';
import { AbstractDashboardService } from '../../../services/dashboard/abstract-dashboard.service';
import { BaseDashboardComponent } from '../base-dashboard-component';
import { PRG_TABLE_SETTINGS } from './prg-table-settings';

/**
 * Table Component
 */
@Component({
  selector: 'prg-table',
  templateUrl: './prg-table.component.html',
  styleUrls: ['./prg-table.component.scss'],
})
export class PrgTableComponent
  extends BaseDashboardComponent
  implements OnInit, OnDestroy
{
  /**
   *  Data for this table
   * @type {any}
   */
  public products: Product[];
  /**
   * Height of the chart
   * @type {string}
   */
  public height: string;
  /**
   * Width of the chart
   * @type {string}
   */
  public width: string;
  /**
   * The item id used on dashboard
   */
  @Input('itemId') itemId;

  /**
   * The custom settings for this component
   * @param itemSettings
   */
  @Input('itemSettings') set itemSettings(itemSettings: any) {
    if (itemSettings != null) {
      this._itemSettings = this.objectsUtilityService.cloneObject(
        this.utilityService.guiSettingToObject(itemSettings)
      );
    }
  }

  /**
   * aux settings
   * @private
   */
  private _itemSettings;

  /**
   * Getter for item settings
   * @returns {any}
   */
  get itemSettings(): any {
    return this._itemSettings;
  }

  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * Constructor
   * @param {HttpClient} http
   * @param {MainLayoutService} mainLayoutService
   * @param {AbstractDashboardService} dashboardService
   * @param {UtilityService} utilityService
   * @param {ObjectsUtilityService} objectsUtilityService
   */
  constructor(
    private http: HttpClient,
    private mainLayoutService: MainLayoutService,
    private dashboardService: AbstractDashboardService,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService
  ) {
    super();
    PrgTableComponent.key = PRG_TABLE_SETTINGS.key;
    PrgTableComponent.staticSettings = PRG_TABLE_SETTINGS.staticSettings;
    PrgTableComponent.configurationSettings =
      PRG_TABLE_SETTINGS.configurationSettings;
  }

  /**
   * ngOnInit
   * @returns {Promise<void>}
   */
  async ngOnInit() {
    if (this._itemSettings == null) {
      this._itemSettings = this.objectsUtilityService.cloneObject(
        this.utilityService.guiSettingToObject(PrgTableComponent.staticSettings)
      );
    }

    this._itemSettings = Object.values(this._itemSettings);
    this.subscription.push(
      this.dashboardService
        .getDashboardItemsResizeObservable()
        .pipe(filter((item) => item?.item?.id === this.itemId))
        .subscribe((item) => {
          this.height = (item.height - item.height * 0.05).toString() + 'px';
          this.width = (item.width - item.width * 0.02).toString() + 'px';
        })
    );
    this.products = await lastValueFrom(
      this.http
        .get<any>('assets/products.json')
        .pipe(map((res) => <Product[]>res.data))
    );
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}

/**
 * Product Interface used to test table component
 */
export interface Product {
  /**
   * id
   */
  id?: string;
  /**
   * code
   */
  code?: string;
  /**
   * name
   */
  name?: string;
  /**
   * description
   */
  description?: string;
  /**
   * price
   */
  price?: number;
  /**
   * quantity
   */
  quantity?: number;
  /**
   * inventoryStatus
   */
  inventoryStatus?: string;
  /**
   * category
   */
  category?: string;
  /**
   * image
   */
  image?: string;
  /**
   * rating
   */
  rating?: number;
}
