import { Directive, Input } from '@angular/core';
import { InputMask } from 'primeng/inputmask';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Input Mask
 */
export class InputMaskConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.inputMask;

  /**
   *HTML5 input type
   */
  public type: string = 'text';
  /**
   *Mask pattern.
   */
  public mask: string = null;
  /**
   *Placeholder character in mask, default is underscore.
   */
  public slotChar: string = '_';
  /**
   *Clears the incomplete value on blur.
   */
  public autoClear: boolean = true;
  /**
   *Defines if ngModel sets the raw unmasked value to bound value or the formatted mask value.
   */
  public unmask: boolean = false;
  /**
   *Inline style of the input field.
   */
  public style: string = null;
  /**
   *Style class of the input field.
   */
  public styleClass: string = null;
  /**
   *Advisory information to display on input.
   */
  public placeholder: string = null;
  /**
   *Size of the input field.
   */
  public size: number = null;
  /**
   *Maximum number of character allows in the input field.
   */
  public maxlength: number = null;
  /**
   *Specifies tab order of the element.
   */
  public tabindex: number = null;
  /**
   *Name of the input field.
   */
  public name: string = null;
  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;
  /**
   *When present, it specifies that an input field must be filled out before submitting the form.
   */
  public required: boolean = false;
  /**
   *Regex pattern for alpha characters
   */
  public characterPattern: string = '[a-Za-z]';
  /**
   *When present, the input gets a focus automatically on load.
   */
  public autoFocus: boolean = false;
  /**
   *When enabled, a clear icon is displayed to clear the value.
   */
  public showClear: boolean = false;
  /**
   *Used to define a string that autocomplete attribute the current element.
   */
  public autocomplete: string = null;
  /**
   *Used to define a string that labels the input element.
   */
  public ariaLabel: string = null;
  /**
   *Used to indicate that user input is required on an element before a form can be submitted.
   */
  public ariaRequired: boolean = false;
  /**
   *Title text of the input text.
   */
  public title: string = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<InputMaskConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setInputMaskConfigs]',
})
export class PrgSetInputMaskConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: InputMask) {}

  /**
   * input Input Mask configs and assign configs to element
   */
  @Input() set setInputMaskConfigs(configs: InputMaskConfiguration) {
    Object.assign(this.element, configs);
  }
}
