<div *ngIf="form" [formGroup]="form">
  <p>{{(configs.basePathTranslation + '.' + configs.key + '.label' | translate)}}</p>
  <p-autoComplete
    [setAutoCompleteConfigs]="configs"
    [formControlName]="configs.key"
    [emptyMessage]="(configs.basePathTranslation + '.' + configs.key + '.emptyMessage' | translate)"
    [inputId]="configs.key"
    [placeholder]="
        configs.basePathTranslation + '.' + configs.key + '.filterPlaceholder'
          | translate
      "
    (completeMethod)="search($event)"
    [suggestions]="configs.suggestions"

  ></p-autoComplete>
</div>
