import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * injectable
 */
@Injectable({
  providedIn: 'root',
})
export class IsLoadingDataService {
  /**
   * visibility BehaviorSubject
   */
  public visibility = new BehaviorSubject(false);

  /**
   * this function change visibility to true
   */
  show(): void {
    this.visibility.next(true);
  }
  /**
   * this function change visibility to false
   */
  hide(): void {
    this.visibility.next(false);
  }
}
