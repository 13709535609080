import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

/**
 * Specific Entity type page
 */
@Component({
  selector: 'prg-specific-entity-type',
  templateUrl: './specific-entity-type.page.html',
  styleUrls: ['./specific-entity-type.page.scss'],
})

/**
 * PrgSpecificEntityTypePage
 */
export class PrgSpecificEntityTypePage implements OnInit {
  /**
   * entityTypeName
   */
  public entityTypeName: string;

  /**
   * constructor
   * @param route
   */
  constructor(private route: ActivatedRoute) {}

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.entityTypeName = params.get('entityTypeName');
    });
  }
}
