import { BaseEntity } from '../../core/models/base-entity.model';

/**
 * LookupTableItem class that defines a look up table
 * <br />item to be used in dropowns by the front-end.
 * <br />Its form must have an name and description
 * <br />input fields that will be saved in i18n
 * <br />using an unique identifier created through
 * <br />its normalized "name" property
 */
export class LookupTableItem extends BaseEntity {
  /**
   * label translated
   */
  public label: string;
  /**
   * Description
   */
  public description: string;

  /**
   * Workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Lookup table id (foreign key)
   */
  public lookupTableId: string; // Foreign key
}

/**
 * LookupTable class that defines a look up table
 * <br />to be used in dropowns by the front-end.
 * <br />Its form must have an name and description
 * <br />input fields that will be saved in i18n
 * <br />using an unique identifier created through
 * <br />its normalized "name" property
 */
export class LookupTable extends BaseEntity {
  /**
   * TranslatedName
   */
  public label: string;
  /**
   * Description
   */
  public description: string;

  /**
   * Entity type workspace id (foreign key)
   */
  public workspaceId: string; // Foreign key
  /**
   * LookupTableItems array
   */
  public items: LookupTableItem[];
}
