import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Listbox } from 'primeng/listbox';
import { AbstractEntityTypeService } from '../../../../../../entity-types/services/entity-types/abstract-entity-type.service';
import { AbstractLookupTableService } from '../../../../../../lookup-table/services/lookup-table/abstract-lookup-table.service';
import { ObjectsUtilityService } from '../../../../../services/utility/objects-utility.service';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';
import { ReferenceType } from '../../../models/constants/reference-type';

/**
 * Class List box
 */
export class ListBoxConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName: string = ControlName.listBox;

  /**
   * Reference type
   */
  public referenceType: ReferenceType = null;

  /**
   * Reference name -> shoud be a lookup table name
   */
  public referenceName: string = null;

  /**
   *Defines a string that labels the filter input.
   */
  public ariaFilterLabel: string = null;
  /**
   *When specified, allows selecting items with checkboxes.
   */
  public checkbox: boolean = false;
  /**
   *A property to uniquely identify a value in options.
   */
  public dataKey: string = null;

  /**
   *When specified, displays a filter input at header.
   */
  public filter: boolean = false;
  /**
   *Defines how the items are filtered, valid values are "contains" (default) "startsWith", "endsWith", "equals", "notEquals", "in", "lt", "lte", "gt" and "gte".
   */
  public filterMatchMode: string = 'contains';
  /**
   *When specified, filter displays with this value.
   */
  public filterValue: string = null;
  /**
   *Locale to use in filtering. The default locale is the host environment's current locale.
   */
  public filterLocale: string = undefined;
  /**
   *When filtering is enabled, filterBy decides which field or fields (comma separated) to search against.
   */
  public filterBy: string = null;
  /**
   *Defines placeholder of the filter input.
   */
  public filterPlaceHolder: string = null;

  /**
   *Inline style of the list element.
   */
  public listStyle: string = null;
  /**
   *Style class of the list element.
   */
  public listStyleClass: string = null;
  /**
   *Defines how multiple items can be selected, when true metaKey needs to be pressed to select or unselect an item and when set to false selection of each item can be toggled individually. On touch enabled devices, metaKeySelection is turned off automatically.
   */
  public metaKeySelection: boolean = true;
  /**
   *When specified, allows selecting multiple values.
   */
  public multiple: boolean = false;
  /**
   *Text to display when there is no data. Defaults to global value in i18n translation configuration.
   */
  public emptyMessage: string = 'No records found';
  /**
   *Text to display when filtering does not return any results. Defaults to global value in i18n translation configuration.
   */
  public emptyFilterMessage: string = 'No results found';
  /**
   *An array of selectitems to display as the available options.
   */
  public options: any[] = null;
  /**
   *Name of the label field of an option.
   */
  public optionLabel: string = 'label';
  /**
   *Name of the value field of an option.
   */
  public optionValue: string = 'value';
  /**
   *Name of the disabled field of an option.
   */
  public optionDisabled: string = 'disabled';
  /**
   *Name of the label field of an option group.
   */
  public optionGroupLabel: string = 'label';
  /**
   *Name of the options field of an option group.
   */
  public optionGroupChildren: string = 'items';
  /**
   *Whether to display options as grouped when nested options are provided.
   */
  public group: boolean = false;
  /**
   *Whether header checkbox is shown in multiple mode.
   */
  public showToggleAll: boolean = true;
  /**
   *Inline style of the container.
   */
  public style: string = null;
  /**
   *Style class of the container.
   */
  public styleClass: string = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<ListBoxConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setListBoxConfigs]',
})
export class PrgSetListBoxConfigsDirective {
  /**
   * Constructor
   * @param {Listbox} element
   * @param {AbstractLookupTableService} lookupTableService
   * @param {AbstractEntityTypeService} entityTypeService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ChangeDetectorRef} cdc
   */
  constructor(
    private element: Listbox,
    private lookupTableService: AbstractLookupTableService,
    private entityTypeService: AbstractEntityTypeService,
    private objectsUtilityService: ObjectsUtilityService,
    private cdc: ChangeDetectorRef
  ) {}
  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * get options from lookup table or entity type list
   * and assign configs to element
   */
  @Input() set setListBoxConfigs(configs: ListBoxConfiguration) {
    switch (configs.referenceType) {
      case ReferenceType.LookupTable:
        if (configs.referenceName !== null && configs.referenceName.length) {
          this.lookupTableService
            .getLookupTableItemsByLookupTableNameAsync(configs.referenceName)
            .then((options) => {
              configs.options = options;
              Object.assign(this.element, configs);
              /**
               * We have to initiate change detection
               * through ChangeDetectorRef because for some reason
               * this object assignment is not reflected in the template
               */
              this.cdc.detectChanges();

              /**
               * we need to set the value of the control
               * again because for some reason it stays null
               * and doesn't map the value selected by default,
               * we've already had to analyse this
               * and for now we can't understand why this behaviour
               */
              if (configs.value) {
                this.form.get(configs.key).setValue(configs.value);
              }
            });
        } else {
          this.lookupTableService.getAllLookupTableAsync().then((options) => {
            configs.options = options;
            Object.assign(this.element, configs);

            /**
             * we need to set the value of the control
             * again because for some reason it stays null
             * and doesn't map the value selected by default,
             * we've already had to analyse this
             * and for now we can't understand why this behaviour
             */
            if (configs.value) {
              this.form.get(configs.key).setValue(configs.value);
            }
          });
        }
        break;
      case ReferenceType.EntityType:
        this.entityTypeService.getEntityTypeListAsync().then((value) => {
          configs.options = value;
          Object.assign(this.element, configs);
          /**
           * We have to initiate change detection
           * through ChangeDetectorRef because for some reason
           * this object assignment is not reflected in the template
           */
          this.cdc.detectChanges();
          if (configs.value) {
            this.form.get(configs.key).setValue(configs.value);
          }
        });
        break;
      default:
        Object.assign(this.element, configs);
        break;
    }
  }
}
