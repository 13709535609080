import { Component } from '@angular/core';

/**
 * Page Queries
 */
@Component({
  selector: 'prg-queries',
  templateUrl: './prg-queries.page.html',
  styleUrls: ['./prg-queries.page.scss'],
})
export class PrgQueriesPage {}
