import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { ReferenceType } from '../../../core/components/dynamic-form/models/constants/reference-type';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { ExecutedActionBehaviour } from '../../../core/models/executed-action-behaviourts';
import {
  FilterGroup,
  PrimeNgTypeToOurTypes,
} from '../../../core/models/filters';
import { TableConfigs } from '../../../core/models/table-configs';
import { PrimengUtilityService } from '../../../core/services/utility/primeng-utility.service';
import { EntityType } from '../../models/entity-type.model';
import { PrgEntityTypesConfig } from '../../models/prg-entity-types-config';
import { PRG_ENTITY_TYPES_CONFIG } from '../../services/entity-types-configuration/prg-entity-types-configuration.service';
import { AbstractEntityTypeService } from '../../services/entity-types/abstract-entity-type.service';
import { PrgEntityTypeElementComponent } from '../entity-type-element/prg-entity-type-element.component';

/**
 * list specific entity type elemens
 */
@Component({
  selector: 'prg-list-specific-entity-type',
  templateUrl: './prg-list-specific-entity-type.component.html',
  styleUrls: ['./prg-list-specific-entity-type.component.scss'],
  providers: [DialogService, ConfirmationService],
})
/**
 * PrgListSpecificEntityTypeComponent
 */
export class PrgListSpecificEntityTypeComponent implements OnInit, OnDestroy {
  /**
   * table view child
   */
  @ViewChild(Table) dataTableComponent: Table;

  /**
   * this input receives the entity type to be listed
   */
  @Input() entityTypeName: string;

  @Input() onClickGoTo: string = null;

  /**
   * entity type data
   */
  public entityType: EntityType;

  /**
   * entity type elemens
   */
  public entityTypeAllElements: any[] = [];

  /**
   * entity groups
   */
  public entityGroups: any[];

  /**
   * lazy loading are loading data
   */
  public loading: boolean;

  /**
   * total elements that can be listed
   */
  public totalRecords: number;

  /**
   * auxiliar primeNgTypeToOurTypes enum
   */
  public primeNgTypeToOurTypes = PrimeNgTypeToOurTypes;

  /**
   * auxiliar ReferenceType enum
   */
  public ReferenceType = ReferenceType;

  /**
   * dialog ref
   */
  public elementRef: DynamicDialogRef;

  /**
   * selected columns objects data
   */
  public selectedColumnsObject: any[] = [];

  /**
   * table configs
   */
  public tableConfigs: TableConfigs;

  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   */
  public subscription: Subscription[] = [];

  /**
   * glabal filter is active
   */
  public globalFilterIsActive: boolean = false;

  /**
   * global filtar diabled
   */
  public globalFilterDisabled: boolean = false;

  /**
   * global filter value
   */
  public globalFilterValue: string;

  /**
   * filter group
   */
  private filterGroup: FilterGroup;

  /**
   * this variable serves to get the translation
   * label to put in the column select (directly in html doesn't work)
   */
  public columnsSelectedLabel: string;

  /**
   * constructor
   *
   * @param entityTypeService
   * @param lookupTableService
   * @param dialogService
   * @param prgEntityTypesConfig
   * @param primengUtilityService
   * @param translateService
   * @param router
   */
  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private dialogService: DialogService,
    @Inject(PRG_ENTITY_TYPES_CONFIG)
    private prgEntityTypesConfig: PrgEntityTypesConfig,
    private primengUtilityService: PrimengUtilityService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  /**
   * ngOnInit
   */
  async ngOnInit() {
    this.tableConfigs = this.prgEntityTypesConfig.specificEntityTypeListConfigs;

    this.entityType = await this.entityTypeService.getAllEntityTypeDataByName(
      this.entityTypeName
    );

    this.columnsSelectedLabel = await this.translateService
      .get('primeng.columns-selected')
      .toPromise();

    this.groupPropertyAndAttributesToColumnsSelect();

    this.getColumnsData();

    this.loading = true;
  }

  /**
   * ngOnDestroy
   *
   * Unsubscribe all subscriptions
   */
  public ngOnDestroy(): void {
    this.subscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscription = [];
  }

  /**
   * this function is executed every time
   * there is a change in the table's settings (pagination filters, etc)
   * @param event
   */
  public async lazyLoadElements(event: LazyLoadEvent) {
    if (event.globalFilter) {
      this.globalFilterIsActive = true;
    } else {
      this.globalFilterIsActive = false;
    }
    if (this.checkIfHaveColumnFilter(event.filters)) {
      this.globalFilterValue = '';
      this.globalFilterDisabled = true;
    } else {
      this.globalFilterDisabled = false;
    }

    this.createFilterGroup(event);
    this.getTableData();
  }

  /**
   * this function make an reset to table
   */
  public clearAllFilters() {
    this.dataTableComponent.reset();
  }

  /**
   * open popup to edit or create an element
   * @param element
   */
  public editElement(event: any, onPopup: boolean, element: any): void {
    if (onPopup) {
      event.stopPropagation();
      this.elementOnPopUp(element, ViewMode.Read);
    } else {
      if (this.onClickGoTo) {
        this.router.navigate([this.onClickGoTo, element.id]);
      } else {
        this.router.navigate([this.router.url, element.id]);
      }
    }
  }

  /**
   * this function is responsible for opening the popup or
   * redirect to the page to be created a new element
   */
  public addNewElement(onPopup: boolean): void {
    if (onPopup) {
      this.elementOnPopUp({}, ViewMode.Edit);
    } else {
      this.router.navigate([this.router.url, 'new']);
    }
  }

  /**
   * this function is used when we have a custom filter,
   * and the value is a lookuptable, when we click on apply
   * we don't have the value set in the filters,
   * and I do this setter here, it just allows to have a search(not add option a and b)
   * @param value
   * @param field
   * @param matchMode
   */
  public lookupTableFilterChange(
    value: any,
    field: string,
    matchMode: string
  ): void {
    let operator: string = 'and';
    const filterAux = this.dataTableComponent.filters[field];

    if (filterAux) {
      operator = filterAux[0].operator;
    }

    this.dataTableComponent.filters[field] = [
      { value: value, matchMode: matchMode, operator: operator },
    ];
  }

  /**
   * get columns object by columns name in table configs
   */
  private getColumnsData(): void {
    this.tableConfigs.defaultColumns.forEach((col) => {
      let field = this.entityType.properties.find(
        (property) => property.name === col
      );
      if (!field) {
        field = this.entityType.attributes.find(
          (attribute) => attribute.name === col
        );
      }

      if (field) {
        this.selectedColumnsObject.push(field);
      }
    });
  }

  /**
   * create multiselect groups
   */
  private groupPropertyAndAttributesToColumnsSelect(): void {
    this.entityGroups = [
      {
        label: 'Properties',
        value: 'properties',
        items: this.entityType.properties,
      },
      // {
      //   label: 'Attributes',
      //   value: 'attributes',
      //   items: this.entityType.attributes,
      // },
    ];
  }

  /**
   * this function makes the request to get the data to be showed in the table
   */
  private async getTableData(): Promise<void> {
    const paginationResponse =
      await this.entityTypeService.getEntityTypeElements(
        this.entityTypeName,
        this.filterGroup
      );
    this.entityTypeAllElements = paginationResponse.items;
    this.totalRecords = paginationResponse.totalItems;

    this.loading = false;
  }

  /**
   * this is an auxiliary function to check
   * if any of the table's filters are active
   * @param filters
   * @returns
   */
  private checkIfHaveColumnFilter(filters: any): boolean {
    let filtersKeys = Object.keys(filters);
    let findValue: boolean = false;
    if (filtersKeys.length) {
      let index = filtersKeys.indexOf('global');
      if (index !== -1) {
        filtersKeys.splice(index, 1);
      }

      filtersKeys.forEach((filterKey) => {
        if (filters[filterKey][0].value) {
          findValue = true;
        }
      });

      return findValue;
    } else {
      return false;
    }
  }

  /**
   * this function calls the service that receives
   * the event (LazyLoadEvent) and creates a filterGroup
   * @param event
   * @returns
   */
  private createFilterGroup(event: LazyLoadEvent): void {
    this.filterGroup = this.primengUtilityService.getFilterGroupByLazyLoadEvent(
      event,
      this.tableConfigs.globalFilterFields
    );
  }

  /**
   * this function is responsible for creating
   *  the popup to edit or add an element
   * @param element
   * @param viewMode
   */
  private elementOnPopUp(element: any, viewMode: ViewMode): void {
    this.elementRef = this.dialogService.open(PrgEntityTypeElementComponent, {
      data: {
        element: element,
        entityType: this.entityType,
        viewMode: viewMode,
      },
      width: '90vw',
      showHeader: true,
      contentStyle: { 'max-height': '70%', overflow: 'auto' },
      dismissableMask: true,
    });

    this.subscription.push(
      this.elementRef.onClose.subscribe((data: ExecutedActionBehaviour) => {
        if (data) {
          if (data.reloadData) {
            this.getTableData();
          } else if (data.resetTable) {
            this.dataTableComponent.reset();
          }
        }
        this.elementRef.destroy();
      })
    );
  }
}
