import { Validators } from '@angular/forms';
import { SelectConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/select/select.model';
import { ControlName } from '../../core/components/dynamic-form/models/constants/control-names';
import { HttpVerb } from '../models/entity-type.model';

/**
 * Dynamic form fields configurations to Entity type operation
 */
export const ENTITY_TYPE_OPERATION_DYNAMIC_FORM = {
  fields: [
    {
      controlName: ControlName.inputText,
      value: null,
      readonly: false,
      key: 'name',
      validators: [Validators.required],
    },
    {
      controlName: ControlName.inputText,
      value: null,
      readonly: false,
      key: 'guiSettings',
      validators: [Validators.required],
    },
    new SelectConfiguration({
      controlName: ControlName.dropdown,
      value: null,
      readonly: false,
      key: 'httpVerb',
      options: [
        { value: HttpVerb.Get, label: 'get' },
        { value: HttpVerb.Post, label: 'post' },
        { value: HttpVerb.Delete, label: 'delete' },
        { value: HttpVerb.Put, label: 'put' },
        { value: HttpVerb.Patch, label: 'patch' },
      ],
      filter: true,
      validators: [Validators.required],
    }),
  ],
};
