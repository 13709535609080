
<div class="dynamic-form">
  <p-tabMenu *ngIf="formGroupType==='tab'&&tabMenuItemsDynamicForm.length>0" [model]="tabMenuItemsDynamicForm" [activeItem]="activeTabMenuItemsDynamicForm">

  </p-tabMenu>


  <form [formGroup]="form">
    <div class="form-row" *ngIf="actions!=null&&actions.length>0">
      <p-toolbar >
        <div class="p-toolbar-group-left">
          <ng-container *ngFor="let action of leftActions">
            <prg-dynamic-form-action
              *ngIf="evalExpression(action.visibleExpression,true)"
              [action]="action"
              [form]="form"
              (actionClick)="onActionClicked($event)"
            >
            </prg-dynamic-form-action>
          </ng-container>
        </div>
        <div>
          <ng-container *ngFor="let action of middleActions">
            <prg-dynamic-form-action
              *ngIf="evalExpression(action.visibleExpression, true)"
              [action]="action"
              [form]="form"
              (actionClick)="onActionClicked($event)"
            >
            </prg-dynamic-form-action>
          </ng-container>
        </div>
        <div class="p-toolbar-group-right">
          <ng-container *ngFor="let action of rightActions">
            <prg-dynamic-form-action
              *ngIf="evalExpression(action.visibleExpression, true)"
              [action]="action"
              [form]="form"
              (actionClick)="onActionClicked($event)"
            >
            </prg-dynamic-form-action>
          </ng-container>
        </div>
      </p-toolbar>
    </div>
    <ng-container *ngIf="viewMode !== 'add' && form != null">
      <div *ngFor="let field of fields" class="form-row dynamic-form-fields">

        <prg-dynamic-form-field
          *ngIf="evalExpression(field.visibleExpression, true,field.groupFieldsId)"
          [field]="field"
          [form]="form"
          (onChange)="onFieldChanged($event)"
        ></prg-dynamic-form-field>
      </div>
    </ng-container>
  </form>
</div>
