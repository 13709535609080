<div class="field-color-picker" *ngIf="form" [formGroup]="form">

  <p-colorPicker
    (onChange)="onChanged($event)"
    [setColorPickerConfigs]="configs"
    [formControlName]="configs.key"
    [inputId]="configs.key">
  </p-colorPicker>
  <label [ngClass]="['pl-1']" for="{{configs.key}}">{{configs.basePathTranslation + '.' + configs.key + '.label' | translate}}</label>

</div>
