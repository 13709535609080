import { Validators } from '@angular/forms';

/**
 * PRG validators
 */
export class PrgValidators {
  /**
   * Validators
   */
  public static Validators = Validators;
}
