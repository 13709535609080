import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { InputNumberConfiguration } from './input-number.model';

/**
 * component input number
 */
@Component({
  selector: 'prg-input-number-field',
  templateUrl: './input-number.component.html',
})
export class PrgInputNumberComponent {
  /**
   * input number configs
   */
  @Input() configs: InputNumberConfiguration;

  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the output
   */
  public onChanged(event: any) {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event,
        })
      );
    }
  }
}
