import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { InputSwitchConfiguration } from './input-switch.model';

/**
 * component Input Switch
 */
@Component({
  selector: 'prg-input-switch-field',
  templateUrl: './input-switch.component.html',
})
export class PrgInputSwitchComponent {
  /**
   * Input Switch Configs
   */
  @Input() configs: InputSwitchConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the output
   */
  public onChanged(event: any): void {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          event: event,
          value: event.checked,
        })
      );
    }
  }
}
