/**
 * ConfigurationItem class that defines generic configurations
 */
import { BaseEntity } from '../../core/models/base-entity.model';

/**
 * The model of Configuration Items
 */
export class ConfigurationItem extends BaseEntity {
  /**
   * Path that leads to the item (excluding itself)
   * <br />e.g. "Identity:SignIn"
   * <br />the node name is on the name property from BaseEntity
   */
  path: string;
  /**
   * Item value
   */
  value: any;
  /**
   * Item value type
   */
  //TODO :datatype, referenceType and referenceName should be inside of guiSettings?
  /*  dataType: string;
  /!**
   * existing enum (looukup, entitytype)
   *!/

  referenceType: ReferenceType;
  /!**
   * If type is lookup table, item
   * <br /> lookup table name
   *!/
  referenceName: string;*/
  /**
   * gui settings
   * @type {string}
   */
  guiSettings: string;

  /**
   * Item is system
   */
  readonly: boolean;
  //isSystem

  /**
   * Constructor
   * @param {Partial<ConfigurationItem>} init
   */
  constructor(init?: Partial<ConfigurationItem>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Enum of most common sub-paths of config items
 */
export enum ConfigItemsPath {
  Defaults = 'Defaults',
  UserPreferences = 'User-Preferences',
}
