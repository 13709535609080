import { InputNumberConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/input-number/input-number.model';

/**
 * Constant that define the key , configuration settings and settings for doughnut chart component
 * @type {{staticSettings: string, configurationSettings: {fields: InputNumberConfiguration[]}, key: string}}
 */
export const PRG_BAR_DOUGHNUT_SETTINGS = {
  key: 'Doughnut-settings',
  configurationSettings: {
    fields: [
      new InputNumberConfiguration({
        key: 'donutChartProperty1',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'donutChartProperty2',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'donutChartProperty3',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
    ],
  },
  staticSettings: JSON.stringify({
    donutChartProperty1: 30,
    donutChartProperty2: 58,
    donutChartProperty3: 29,
  }),
};
