import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActionOutput } from '../../../models/base-action';
import { SelectButtonActionConfiguration } from './select-button-action.model';

/**
 * component select button
 */
@Component({
  selector: 'prg-select-button-action',
  templateUrl: './select-button-action.component.html',
})
export class PrgSelectButtonActionComponent {
  /**
   * Select Button Configs
   */
  @Input() configs: SelectButtonActionConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() actionClick = new EventEmitter<ActionOutput>();

  /**
   * function that triggers the output
   */
  public onButtonClicked(event: any): void {
    this.actionClick.emit(
      new ActionOutput({
        action: this.configs.key,
        value: event?.option?.value,
        group: event?.option?.name,
      })
    );
  }
}
