import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import {
  Filter,
  FilterExpressions,
  FilterGroup,
  FilterOperations,
  MapPrimeNgExpressionsToFilterExpressions,
  MapPrimeNgFilterOperationsToFilterOperations,
  Order,
  PrimeNgSortToOrderTypeMap,
} from '../../models/filters';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * PrimengUtilityService
 */
export class PrimengUtilityService {
  /**
   * this function is to calculate the index of the page we need to request
   * @param event
   * @returns
   */
  private calcPageIndex(event: LazyLoadEvent): number {
    let pageIndex = 0;
    if (event.first != 0) {
      pageIndex = event.first / event.rows;
    }
    return pageIndex;
  }

  /**
   * this function processes the filters coming from
   * the event thrown by primeng and returns
   *  a working filter array to our api
   * @param filters
   * @param globalFilterFields
   * @param globalFilterExpression
   * @returns Filter[]
   */
  private filtersProcessing(
    filters: any,
    globalFilterFields: string[],
    globalFilterExpression: FilterExpressions
  ): Filter[] {
    let filtersArray: Filter[] = [];

    const filtersKeys = Object.keys(filters);
    if (filtersKeys.includes('global')) {
      globalFilterFields.forEach((globalFilterField) => {
        filtersArray.push(
          this.generateFilter(
            filters['global'].value,
            globalFilterField,
            globalFilterExpression,
            MapPrimeNgFilterOperationsToFilterOperations.get(
              filters['global'].matchMode
            )
          )
        );
      });
    } else {
      filtersKeys.forEach((key: string) => {
        Object.keys(filters[key]).forEach((keySubFilter: string) => {
          let eventFilter = filters[key][keySubFilter];

          if (eventFilter.value) {
            filtersArray.push(
              this.generateFilter(
                eventFilter.value,
                key,
                MapPrimeNgExpressionsToFilterExpressions.get(
                  eventFilter.operator
                ),
                MapPrimeNgFilterOperationsToFilterOperations.get(
                  eventFilter.matchMode
                )
              )
            );
          }
        });
      });
    }
    return filtersArray;
  }

  /**
   * this function creates a filter with the received parameters
   * @param filterValue
   * @param propertyName
   * @param filterExpression
   * @param filterOperation
   * @returns
   */
  private generateFilter(
    filterValue: any,
    propertyName: string,
    filterExpression: FilterExpressions,
    filterOperation: FilterOperations
  ): Filter {
    let filter: Filter = new Filter();
    filter.value = filterValue;
    filter.propertyName = propertyName;
    filter.filterExpression = filterExpression;
    filter.filterOperation = filterOperation;

    return filter;
  }

  /**
   * this function processes the sort from the primeng
   * event and returns a functional order array to our api
   *
   * @param multiSortMeta
   * @param sortField
   * @param sortOrder
   * @returns Order[]
   */
  private sortProcessing(
    multiSortMeta: any[],
    sortField: string,
    sortOrder: number
  ): Order[] {
    let filterOrders: Order[] = [];

    if (multiSortMeta) {
      multiSortMeta.forEach((sortElement) => {
        filterOrders.push(
          new Order({
            propertyName: sortElement.field,
            orderType: PrimeNgSortToOrderTypeMap.get(sortElement.order),
          })
        );
      });
    } else {
      filterOrders.push(
        new Order({
          propertyName: sortField,
          orderType: PrimeNgSortToOrderTypeMap.get(sortOrder),
        })
      );
    }
    return filterOrders;
  }

  /**
   * this function receives the event from primeng and returns
   * the filtergroup with all the necessary information to be used by our api
   * @param event
   * @param globalFilterFields
   * @param globalFilterExpression
   * @returns FilterGroup
   */
  public getFilterGroupByLazyLoadEvent(
    event: LazyLoadEvent,
    globalFilterFields: string[] = [],
    globalFilterExpression: FilterExpressions = FilterExpressions.Or
  ): FilterGroup {
    let filterGroup: FilterGroup = new FilterGroup();
    filterGroup.pageIndex = this.calcPageIndex(event);
    filterGroup.pageSize = event.rows;

    if (event.sortField || event.multiSortMeta) {
      filterGroup.orderCollection = this.sortProcessing(
        event.multiSortMeta,
        event.sortField,
        event.sortOrder
      );
    }

    if (event.filters != null && Object.keys(event.filters).length) {
      filterGroup.filterCollections = this.filtersProcessing(
        event.filters,
        globalFilterFields,
        globalFilterExpression
      );
    }

    return filterGroup;
  }
}
