<div>
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <prg-select-role
        (onSubmitRolesSelected)="onSubmitRolesSelected($event)"
        [multipleSelect]="false"
        [defaultSelected]="roleSelected"
        [rolesList]="rolesList"
      ></prg-select-role>
    </div>
    <div class="p-toolbar-group-right">
      <prg-create-role
        (addedNewRole)="addedNewRole($event)"
        placeholder="Insert Role name"
        buttonLabel="{{'buttons.add.label' | translate}}"
      ></prg-create-role>
    </div>
  </p-toolbar>
  <prg-scopes-table [role]="roleSelected"></prg-scopes-table>
</div>
