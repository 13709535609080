import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dashboard } from '../../models/dashboard.model';
import { AbstractDashboardService } from './abstract-dashboard.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * REAL AbstractDashboardService implementation
 */
export class DashboardService extends AbstractDashboardService {
  /**
   * Constructor
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  /**
   * Function responsible to store a new Dashboard
   * @param {Dashboard} newDashboard
   * @returns {Promise<Dashboard>}
   */
  public createDashboardAsync(newDashboard: Dashboard): Promise<Dashboard> {
    throw new Error('Method not implemented.');
  }
  /**
   * Get dashboard by Id
   * @param {string} dashboardId
   * @returns {Promise<Dashboard>}
   */
  public getDashboardByIdAsync(dashboardId: string): Promise<Dashboard> {
    throw new Error('Method not implemented.');
  }

  /**
   * Function responsible to update a dashboard
   * @param {string} dashboardId
   * @param {Dashboard} dashboard
   * @returns {Promise<Dashboard>}
   */
  public updateDashboardAsync(
    dashboardId: string,
    dashboard: Dashboard
  ): Promise<Dashboard> {
    throw new Error('Method not implemented.');
  }
  /**
   * Get dashboards by User Logged
   * @returns {Promise<Dashboard[]>}
   */
  public getDashboardsByUserAsync(): Promise<Dashboard[]> {
    throw new Error('Method not implemented.');
  }
}
