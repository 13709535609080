import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { KnobConfiguration } from './knob.model';

/**
 * Component Knob
 */
@Component({
  selector: 'prg-knob-field',
  templateUrl: './knob.component.html',
})
export class PrgKnobComponent {
  /**
   * Property of the class which bind to the value of the Knob in template
   * @type {number}
   */
  public value: number;
  /**
   * Knob Configs
   */
  @Input() configs: KnobConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;
  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the outpout
   */
  public onChanged(event: any) {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event,
        })
      );
    }
  }
}
