import {
  BaseAction,
  BaseActionKey,
} from '../../../core/components/dynamic-form/models/base-action';
import { ActionName } from '../../../core/components/dynamic-form/models/constants/action-names';
import { ToolbarSlot } from '../../../core/components/dynamic-form/models/constants/toolbar-slots';

/**
 * A constant that define the dynamic form actions for the components used on dashboard
 * @type {BaseAction[]}
 */
export const DASHBOARD_ITEMS_DYNAMIC_FORM_ACTIONS: BaseAction[] = [
  new BaseAction({
    controlName: ActionName.Button,
    key: BaseActionKey.Save,
    disableWhenFormInvalid: true,
    toolbarSlot: ToolbarSlot.Right,
    visibleExpression: "this.viewMode==='edit'",
  }),
  new BaseAction({
    controlName: ActionName.Button,
    key: BaseActionKey.Cancel,
    disableWhenFormInvalid: false,
    toolbarSlot: ToolbarSlot.Right,
    visibleExpression: "this.viewMode==='edit'",
  }),
  new BaseAction({
    controlName: ActionName.Button,
    key: BaseActionKey.Edit,
    disableWhenFormInvalid: false,
    toolbarSlot: ToolbarSlot.Right,
    visibleExpression: "this.viewMode==='read'",
  }),
];
