import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { SliderConfiguration } from './slider.model';

/**
 * Component Slider
 */
@Component({
  selector: 'prg-slider-field',
  templateUrl: './slider.component.html',
})
export class PrgSliderComponent {
  /**
   * Property of the class which bind to the value of the slider in template
   * @type {number}
   */
  public value: number;
  /**
   * Slider Configs
   */
  @Input() configs: SliderConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;
  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();
  /**
   * function that triggers the outpout
   */
  public onChanged(event: any) {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event.value,
          event: event,
        })
      );
    }
  }
}
