import { Directive, Input } from '@angular/core';
import { Rating } from 'primeng/rating';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Rating
 */
export class RatingConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.rating;

  /**
   *Number of stars.
   */
  public stars: number = 5;
  /**
   *When specified a cancel icon is displayed to allow removing the value.
   */
  public cancel: boolean = true;
  /**
   *Style class of the on icon.
   */
  public iconOnClass: string = 'pi pi-star-fill';
  /**
   *Style class of the off icon.
   */
  public iconOffClass: string = 'pi pi-star';
  /**
   *Style class of the cancel icon.
   */
  public iconCancelClass: string = 'pi pi-ban';
  /**
   *Inline style of the on icon.
   */
  public iconOnStyle: object = null;
  /**
   *Inline style of the off icon.
   */
  public iconOffStyle: object = null;
  /**
   *Inline style of the cancel icon.
   */
  public iconCancelStyle: object = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<RatingConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setRatingConfigs]',
})
export class PrgSetRatingConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: Rating) {}

  /**
   * input Rating configs and assign configs to element
   */
  @Input() set setRatingConfigs(configs: RatingConfiguration) {
    Object.assign(this.element, configs);
  }
}
