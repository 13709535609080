import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BaseAction,
  BaseActionKey,
  DynamicFormActionOutput,
} from '../../../core/components/dynamic-form/models/base-action';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { PrgGridsterItem } from '../../models/prg-gridster-config';
import { DASHBOARD_ITEMS_DYNAMIC_FORM_ACTIONS } from '../dashboard-items/dashboard-items-dynamic-form-actions';

/**
 * Dashboard Items Options Component
 */
@Component({
  selector: 'prg-dashboard-items-options',
  templateUrl: './prg-dashboard-items-options.component.html',
  styleUrls: ['./prg-dashboard-items-options.component.scss'],
})
export class PrgDashboardItemsOptionsComponent implements OnInit {
  /**
   * The dynamic form fields to build dashboard items form in template
   */
  public dashboardItemsOptionsDynamicFormFields: any[] = [];
  /**
   * The dynamic form actions to build dashboard items form in template
   */
  public dashboardItemsOptionsDynamicFormActions: BaseAction[] =
    this.arrayUtilityService.clone(DASHBOARD_ITEMS_DYNAMIC_FORM_ACTIONS);
  /**
   * The view mode for dynamic form
   * @type {ViewMode}
   */
  public dashboardItemsOptionsViewMode = ViewMode.Read;
  /**
   * The dashboard item settings to be used on the form (entity)
   */
  public dashboardItemSettings;
  /**
   * The dashboard widget (dynamic component) to be edited
   * @type {PrgGridsterItem}
   * @private
   */
  private dashboardItem: PrgGridsterItem;
  /**
   * The index of dashboard item on the array of items
   * @type {number}
   * @private
   */
  private index: number;

  /**
   * Constructor
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {DynamicDialogRef} dynamicDialogRef
   * @param {DynamicDialogConfig} dynamicDialogConfig
   * @param {UtilityService} utilityService
   */
  constructor(
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private utilityService: UtilityService
  ) {}

  /**
   * ngOnInit
   *
   * Get the data from parent component
   */
  public ngOnInit() {
    this.dashboardItem = this.objectsUtilityService.cloneObject(
      this.dynamicDialogConfig.data?.item
    );
    if (this.dashboardItem.settings) {
      this.dashboardItemSettings = this.objectsUtilityService.cloneObject(
        this.utilityService.guiSettingToObject(this.dashboardItem.settings)
      );
    } else {
      this.dashboardItemSettings = this.objectsUtilityService.cloneObject(
        this.utilityService.guiSettingToObject(
          this.dashboardItem.item.staticSettings
        )
      );
    }
    this.index = this.dynamicDialogConfig.data?.index;
    this.dashboardItemsOptionsDynamicFormFields =
      this.arrayUtilityService.clone(
        this.dashboardItem.item.configurationSettings.fields
      );
  }

  /**
   * This function is responsible to handle actions from the dynamic form
   * @param {DynamicFormActionOutput} event
   */
  public actionOutput(event: DynamicFormActionOutput): void {
    switch (event.action) {
      case BaseActionKey.Save:
        this.dashboardItem.settings = JSON.stringify(event.formEntity);
        this.dynamicDialogRef.close({
          item: this.dashboardItem,
          index: this.index,
        });
        this.dashboardItemsOptionsViewMode = ViewMode.Read;
        break;
      case BaseActionKey.Cancel:
        this.dashboardItemsOptionsViewMode = ViewMode.Read;
        break;
      case BaseActionKey.Edit:
        this.dashboardItemsOptionsViewMode = ViewMode.Edit;
        break;
      default:
        break;
    }
  }
}
