import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { EntityType } from '../../models/entity-type.model';
import { AbstractEntityTypeService } from '../../services/entity-types/abstract-entity-type.service';

@Component({
  selector: 'prg-entity-type-element-page',
  templateUrl: './prg-entity-type-element.page.html',
  styleUrls: ['./prg-entity-type-element.page.scss'],
})
/**
 * PrgEntityTypeElementPage
 */
export class PrgEntityTypeElementPage implements OnInit {
  /**
   * entityTypeName
   */
  private entityTypeName: string;

  /**
   * entityTypeElementID
   */
  private entityTypeElementID: string;

  /**
   * entityType
   */
  public entityType: EntityType;
  /**
   * element
   */
  public element: any;

  /**
   * viewMode
   */
  public viewMode: ViewMode = ViewMode.Read;

  /**
   * constructor
   * @param route
   * @param router
   * @param entityTypeService
   * @param objectsUtilityService
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entityTypeService: AbstractEntityTypeService,
    private objectsUtilityService: ObjectsUtilityService
  ) {}

  /**
   * ngOnInit
   */
  async ngOnInit() {
    // get params from url
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.entityTypeName = params.get('entityTypeName');
      this.entityTypeElementID = params.get('id');
    });

    this.getEntityTypeData(this.entityTypeName);

    if (this.entityTypeElementID == 'new') {
      this.element = {};
      this.viewMode = ViewMode.Edit;
    } else {
      const elementAux = await this.getEntityTypeElementData(
        this.entityTypeName,
        this.entityTypeElementID
      );

      // check if element exist
      if (elementAux) {
        this.element = this.objectsUtilityService.cloneObject(elementAux);
      } else {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    }
  }

  /**
   * this function fetches the data from the entityType based on the entity type name
   * @param entityTypeName
   */
  private async getEntityTypeData(entityTypeName: string) {
    this.entityType = await this.entityTypeService.getAllEntityTypeDataByName(
      entityTypeName
    );
  }

  /**
   * this function fetches the data from the entityType
   * element based on the entity type name and element id
   * @param entitiesTypeName
   * @param elementId
   * @returns
   */
  private async getEntityTypeElementData(
    entitiesTypeName: string,
    elementId: string
  ): Promise<any> {
    return await this.entityTypeService.getEntityTypeElementById(
      entitiesTypeName,
      elementId
    );
  }
}
