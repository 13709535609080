import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PrgLookupTableConfig } from '../../models/prg-lookup-table-config';

/**
 * Inject token
 */
export const PRG_LOOKUP_TABLE_CONFIG = new InjectionToken<PrgLookupTableConfig>(
  'PRG_LOOKUP_TABLE_CONFIG'
);

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class PrgLookupTableConfigurationService {
  /**
   * constructor
   * @param prgLookupTableConfig
   */
  constructor(
    @Inject(PRG_LOOKUP_TABLE_CONFIG)
    private prgLookupTableConfig: PrgLookupTableConfig
  ) {}
}
