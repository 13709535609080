import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '../../models/role.model';
import { AbstractRolesScopesService } from './abstract-roles-scopes.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 *RolesScopesService implementation
 */
export class RolesScopesService extends AbstractRolesScopesService {
  /**
   * constructor
   */
  constructor(@Inject(TranslateService) translateService: TranslateService) {
    super(translateService);
  }

  /**
   * Get all Roles Async
   *
   * @returns Roles array promisse
   */
  public getAllRolesAsync(): Promise<Role[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * create role Async
   *
   * @param roleName
   * @returns Boolean promise, true when successful
   */
  public createRoleAsync(newRole: Role): Promise<Role> {
    throw new Error('Method not implemented.');
  }

  /**
   * update role
   *
   * @param role role with new data
   * @returns booler promise, true when successful
   */
  public updateRole(role: Role): Promise<Role> {
    throw new Error('Method not implemented.');
  }
}
