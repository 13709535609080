<p-button

  [setButtonConfigs]="configs"
  [label]="
    configs.translationBasePath + '.' + configs.key + '.label' | translate
  "
  [disabled]="!form.valid && configs.disableWhenFormInvalid!"
  (onClick)="onButtonClicked()"
></p-button>

