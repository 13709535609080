<div class="flex justify-content-center">
  <div
    class="mainContainer shadow-3 border-1 border-300 surface-card border-round-lg w-28rem"
  >
    <form
      [formGroup]="loginForm"
      (ngSubmit)="onSubmitLogin()"
      novalidate
      autocomplete="off"
    >
      <div
        class="text-center"
        [class]="{ 'my-3': imagePathLogo, 'my-1': !imagePathLogo }"
      >
        <p-image [src]="imagePathLogo" class="w-8" alt=""></p-image>
      </div>
      <div
        class="text-600 text-2xl w-full border-bottom-1 border-top-1 border-300 p-2 mb-4 text-center"
      >
        {{ 'components.login.title' | translate | uppercase }}
      </div>
      <div class="px-5 mb-4">
        <h6 class="text-700">
          {{
            ('entities.user.fields.email.label' | translate) +
              ' ou ' +
              ('entities.user.fields.token.unique_name.label'
                | translate
                | lowercase)
          }}
        </h6>
        <span class="p-input-icon-left w-full"
          ><i class="bi bi-envelope"></i>
          <input
            placeholder="{{
              ('entities.user.fields.email.placeholder' | translate) +
                ' ou ' +
                ('entities.user.fields.token.unique_name.label'
                  | translate
                  | lowercase) +
                '...'
            }}"
            formControlName="email"
            id="email"
            autocomplete="off"
            autofocus
            type="email"
            class="w-full"
            pInputText
            [defaultValue]="authService.getStoredUserName()"
        /></span>
        <div
          class="flex flex-column mt-2"
          *ngIf="
            loginForm.controls.email.touched && loginForm.controls.email.dirty
          "
        >
          <small
            class="p-error w-max"
            *ngIf="loginForm.controls.email.hasError('required')"
            >Email or username is required.</small
          >
        </div>
      </div>
      <div
        class="px-5"
        [class]="{
          'mb-3': showStoreUserName && showResetPasswordLink,
          'mb-4': !showStoreUserName || !showResetPasswordLink
        }"
      >
        <h6 class="text-700">
          {{ 'entities.user.fields.password.label' | translate }}
        </h6>
        <span class="p-input-icon-left w-full"
          ><i
            class="pi pi-lock"
            (click)="onShowPassword()"
            style="cursor: pointer"
          ></i>
          <input
            placeholder="{{
              ('entities.user.fields.password.placeholder' | translate) + '...'
            }}"
            formControlName="password"
            id="password"
            autocomplete="off"
            [type]="showPassword ? 'text' : 'password'"
            class="w-full"
            pInputText
        /></span>
        <div
          class="flex flex-column mt-2"
          *ngIf="
            loginForm.controls.password.touched &&
            loginForm.controls.password.dirty
          "
        >
          <small
            class="p-error w-max"
            *ngIf="loginForm.controls.password.hasError('required')"
            >Password is required.</small
          >
        </div>
      </div>

      <div class="flex justify-content-between flex-wrap px-5 mb-4">
        <div
          class="field-checkbox flex align-items-center justify-content-center"
          *ngIf="showStoreUserName"
        >
          <p-checkbox
            [(ngModel)]="checkedStoreUserName"
            formControlName="storeUserName"
            [binary]="true"
            inputId="storeUserName"
          ></p-checkbox>
          <label for="storeUserName" class="text-600 text-sm">{{
            'components.login.remember-me.label' | translate
          }}</label>
        </div>
        <div
          class="field flex align-items-center justify-content-center"
          *ngIf="showResetPasswordLink"
        >
          <a
            [style.pointer-events]="
              (isLoadingData.visibility | async) ? 'none' : 'auto'
            "
            routerLink="{{
              !(isLoadingData.visibility | async) ? resetPasswordPageRoute : ''
            }}"
            class="underlineEffect text-sm blue-500 font-medium ml-1 cursor-pointer"
            style="text-decoration: none"
            >{{ 'components.login.forget-password-link.label' | translate }}</a
          >
        </div>
      </div>
      <div class="px-5 mb-3 text-center">
        <button
          type="submit"
          [disabled]="!loginForm.valid || (isLoadingData.visibility | async)"
          pButton
          pRipple
          label="{{ 'components.login.button.label' | translate }}"
          icon="pi pi-unlock"
          class="w-full"
        ></button>
      </div>
      <div class="px-5 mb-3 text-center" *ngIf="showSignUpRoute">
        <label class="text-600 text-sm">{{
          'components.login.question-account.label' | translate
        }}</label>
        <a
          [style.pointer-events]="
            (isLoadingData.visibility | async) ? 'none' : 'auto'
          "
          routerLink="{{ signUpPageRoute }}"
          class="underlineEffect text-sm blue-500 font-medium ml-1 text-right cursor-pointer"
          style="text-decoration: none"
          >{{ 'components.login.signup-link.label' | translate }}</a
        >
      </div>
    </form>
  </div>
</div>
