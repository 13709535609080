import { Directive, Input } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { ScrollerOptions } from 'primeng/scroller';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Auto Complete
 */
export class AutoCompleteConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.autoComplete;

  /**
   * An array of data to perform a search for autocomplete purpose
   */
  public dataToSearch?: any[] = null;

  /**
   *An array of suggestions to display.
   */
  public suggestions: any[];

  /**
   *Field of a suggested object to resolve and display.
   */
  public field: any = null;
  /**
   *Maximum height of the suggestions panel.
   */
  public scrollHeight: string = '200px';
  /**
   *Displays a button next to the input field when enabled.
   */
  public dropdown: boolean = false;
  /**
   *Specifies if multiple values can be selected.
   */
  public multiple: boolean = false;
  /**
   *Icon class of the dropdown icon.
   */
  public dropdownIcon: string = 'pi pi-chevron-down;';
  /**
   *Minimum number of characters to initiate a search.
   */
  public minLength: number = 1;
  /**
   *Delay between keystrokes to wait before sending a query.
   */
  public delay: number = 300;
  /**
   *Whether to run a query when input receives focus.
   */
  public completeOnFocus: boolean = false;
  /**
   *Inline style of the component.
   */
  public style: string = null;
  /**
   *Inline style of the input field.
   */
  public inputStyle: string = null;
  /**
   *Inline style of the overlay panel element.
   */
  public panelStyle: string = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Inline style of the input field.
   */
  public inputStyleClass: string = null;
  /**
   *Style class of the overlay panel element.
   */
  public panelStyleClass: string = null;
  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;
  /**
   *Name of the input element.
   */
  public name: string = null;
  /**
   *Name of the label field of an option group.
   */
  public optionGroupLabel: string = 'label';
  /**
   *Whether to display options as grouped when nested options are provided.
   */
  public group: boolean = false;
  /**
   *Name of the options field of an option group.
   */
  public optionGroupChildren: string = 'items';
  /**
   *Hint text for the input field.
   */
  public placeholder: string = null;
  /**
   *Maximum number of character allows in the input field.
   */
  public maxlength: number = null;
  /**
   *Size of the input field.
   */
  public size: number = null;
  /**
   *Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   */
  public appendTo: any = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *A property to uniquely identify a value in options.
   */
  public dataKey: string = null;
  /**
   *When enabled, highlights the first item in the list by default.
   */
  public autoHighlight: boolean = false;
  /**
   *Type of the input, defaults to "text".
   */
  public type: string = 'text';
  /**
   *Whether to show the empty message or not.
   */
  public showEmptyMessage: boolean = false;
  /**
   *Text to display when there is no data. Defaults to global value in i18n translation configuration.
   */
  public emptyMessage: string = 'No results found';
  /**
   *Defines how the suggestions should be manipulated. More information is available at "Change Detection" section above.
   */
  public immutable: boolean = true;
  /**
   *When present, it specifies that an input field must be filled out before submitting the form.
   */
  public required: boolean = false;
  /**
   *When present, it specifies that the component should automatically get focus on load.
   */
  public autofocus: boolean = false;
  /**
   *When present, autocomplete clears the manual input if it does not match of the suggestions to force only accepting values from the suggestions.
   */
  public forceSelection: boolean = false;
  /**
   *Specifies the behavior dropdown button. Default "blank" mode sends an empty string and "current" mode sends the input value.
   */
  public dropdownMode: string = 'blank';
  /**
   *Base zIndex value to use in layering.
   */
  public baseZIndex: number = 0;
  /**
   *Whether to automatically manage layering.
   */
  public autoZIndex: boolean = true;
  /**
   *Transition options of the show animation.
   */
  public showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   *Transition options of the hide animation.
   */
  public hideTransitionOptions: string = '.1s linear';
  /**
   *Defines a string that labels the input for accessibility.
   */
  public ariaLabel: string = null;
  /**
   *Specifies one or more IDs in the DOM that labels the input field.
   */
  public ariaLabelledBy: string = null;
  /**
   *Defines a string that labels the dropdown button for accessibility.
   */
  public dropdownAriaLabel: string = null;
  /**
   *Ensures uniqueness of selected items on multiple mode.
   */
  public unique: boolean = true;
  /**
   *Used to define a string that autocomplete attribute the current element.
   */
  public autocomplete: string = null;
  /**
   *When enabled, a clear icon is displayed to clear the value.
   */
  public showClear: boolean = false;
  /**
   *Whether the data should be loaded on demand during scroll.
   */
  public virtualScroll: boolean = false;
  /**
   *Height of an item in the list for VirtualScrolling.
   */
  public virtualScrollItemSize: number = null;
  /**
   *Whether to use the scroller feature. The properties of scroller component can be used like an object in it.
   */
  public virtualScrollOptions: ScrollerOptions = null;
  /**
   *Defines if data is loaded and interacted with in lazy manner.
   */
  public lazy: boolean = false;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<AutoCompleteConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setAutoCompleteConfigs]',
})
export class PrgSetAutoCompleteConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: AutoComplete) {}

  /**
   * input Auto Complete configs and assign configs to element
   */
  @Input() set setAutoCompleteConfigs(configs: AutoCompleteConfiguration) {
    Object.assign(this.element, configs);
  }
}
