import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { PrgAuthConfig } from '../../models/prg-auth-config';
import { PasswordRequirements } from '../../models/user.model';
import { AbstractAuthService } from '../../services/auth/abstract-auth.service';
import { PRG_AUTH_CONFIG } from '../../services/prg-auth-configuration/prg-auth-configuration.service';
import { PrgAuthCustomPasswordRequirements } from '../../validators/custom-password-requirements/prg-auth-custom-password-requirements';

/**
 * The form reset password component
 */
@Component({
  selector: 'prg-form-reset-password',
  templateUrl: './prg-form-reset-password.component.html',
  styleUrls: ['./prg-form-reset-password.component.scss'],
  providers: [PasswordRequirements],
})
export class PrgFormResetPasswordComponent implements OnInit, OnDestroy {
  /**
   * The route for login page
   */
  @Input() loginPageRoute: string =
    '/' +
    this.authRoutes.authRoutingBasePath +
    '/' +
    this.authRoutes.authRoutingLoginPath;
  /**
   * Shows/hide the login link.  Default true.
   */
  @Input() showLoginLink: boolean = true;
  /**
   * Shows/hide password on form. Default false.
   */
  public showPassword: boolean = false;
  /**
   * Property of the class with type Form Group which bind to the register form
   */
  public resetPasswordForm: FormGroup;
  /**
   * The reset password token taken from route parameters
   */
  private token: string;
  /**
   * The subscription of the observable of query parameters
   */
  private queryParamsSubscription: Subscription;
  /**
   * Constructor
   * @param route
   * @param router
   * @param logger
   * @param authService
   * @param primengConfig
   * @param authRoutes
   * @param passwordRequirements passwordRequirements
   * @param isLoadingData isLoadingData
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger,
    private authService: AbstractAuthService,
    private primengConfig: PrimeNGConfig,
    @Inject(PRG_AUTH_CONFIG) private authRoutes: PrgAuthConfig,
    public passwordRequirements: PasswordRequirements,
    public isLoadingData: IsLoadingDataService
  ) {}
  /**
   * ngOnInit
   */
  public ngOnInit() {
    this.logger.debug('RESET PASSWORD FORM ON INIT');
    this.token = this.route.snapshot.queryParams['token'];
    if (this.token == null || this.token.length === 0) {
      this.router.navigate([this.loginPageRoute]);
    }
    /*This observable is used when is necessary to reload the component within the same component*/
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (queryParams: Params) => {
        this.token = queryParams['token'];
      }
    );

    this.initFormResetPassword();
  }
  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.logger.debug('RESET PASSWORD FORM ON DESTROY');
    this.queryParamsSubscription.unsubscribe();
  }
  /**
   * Initiate the reset password form and its form controls with validators
   */
  private initFormResetPassword(): void {
    this.resetPasswordForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(this.passwordRequirements.requiredLength),
          PrgAuthCustomPasswordRequirements.patternValidator(
            /\d/,
            {
              hasNumber: true,
            },
            this.passwordRequirements.requireDigit
          ),
          PrgAuthCustomPasswordRequirements.patternValidator(
            /[a-z]/,
            {
              hasSmallCase: true,
            },
            this.passwordRequirements.requireLowercase
          ),
          PrgAuthCustomPasswordRequirements.patternValidator(
            /[A-Z]/,
            {
              hasCapitalCase: true,
            },
            this.passwordRequirements.requireUppercase
          ),
          PrgAuthCustomPasswordRequirements.patternValidator(
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true,
            },
            this.passwordRequirements.requireNonAlphanumeric
          ),
          PrgAuthCustomPasswordRequirements.uniqueCharsValidator(
            this.passwordRequirements.requiredUniqueChars,
            {
              numberOfUniqueChars:
                this.passwordRequirements.requiredUniqueChars,
            }
          ),
        ]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      {
        validators: PrgAuthCustomPasswordRequirements.passwordMatchValidator,
        updateOn: 'change',
      }
    );
  }
  /**
   * Function to display/hide password on click
   */
  public onShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
  /**
   * Form submission
   */
  public async onSubmitResetPassword(): Promise<void> {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    const userNewPassword = this.resetPasswordForm.controls.password.value;

    this.resetPasswordForm.disable();
    try {
      if (
        await this.authService.resetPasswordAsync(userNewPassword, this.token)
      ) {
        this.resetPasswordForm.reset();
        this.router.navigate([this.loginPageRoute]);
      }
    } catch (error) {
      this.logger.debug(error);
    }
    this.resetPasswordForm.enable();
  }
}
