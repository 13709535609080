import { NgModule } from '@angular/core';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { PrgLayoutsEmptyComponent } from '../layouts/empty/prg-layouts-empty.component';
import { PrgLayoutsHeaderSidebarComponent } from '../layouts/pages/prg-layouts-header-sidebar';
import { PrgEntityTypeElementPage } from './pages/entity-type-element/prg-entity-type-element.page';
import { PrgEntityTypesPage } from './pages/entity-types/prg-entity-types.page';
import { PrgSpecificEntityTypePage } from './pages/specific-entity-type/specific-entity-type.page';
import { entityTypesConfig } from './prg-entity-types.module';

/**
 * Authentication routing module routes
 */
const standardRoutes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(standardRoutes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        let routes: Routes = [];
        routes.push({
          path: entityTypesConfig.entityTypeBasePath,
          component: PrgLayoutsHeaderSidebarComponent,
          children: [
            {
              path: '',
              component: PrgEntityTypesPage,
            },
            {
              path: ':entityTypeName',
              component: PrgSpecificEntityTypePage,
            },
            {
              path: ':entityTypeName/:id',
              component: PrgEntityTypeElementPage,
            },
          ],
        });

        return [...routes, ...standardRoutes];
      },
      multi: true,
    },
  ],
})
export class PrgEntityTypesRoutingModule {}
