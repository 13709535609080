import { Directive, Input } from '@angular/core';
import { Knob } from 'primeng/knob';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Knob
 */
export class KnobConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.knob;

  /**
   *Size of the component in pixels.
   */
  public size: number = 100;
  /**
   *Step factor to increment/decrement the value.
   */
  public step: number = 1;
  /**
   *Mininum boundary value.
   */
  public min: number = 0;
  /**
   *Maximum boundary value.
   */
  public max: number = 100;
  /**
   *Background of the value.
   */
  public valueColor: string;
  /**
   *Background color of the range.
   */
  public rangeColor: number;
  /**
   *Color of the value text.
   */
  public textColor: number;
  /**
   *Width of the knob stroke.
   */
  public strokeWidth: number = 14;
  /**
   *Whether the show the value inside the knob.
   */
  public showValue: boolean = true;
  /**
   *Template string of the value.
   */
  public valueTemplate: string = '{value}';
  /**
   *Inline style of the component.
   */
  public style: object = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<KnobConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setKnobConfigs]',
})
export class PrgSetKnobConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: Knob) {}

  /**
   * input Knob configs and assign configs to element
   */
  @Input() set setKnobConfigs(configs: KnobConfiguration) {
    Object.assign(this.element, configs);
  }
}
