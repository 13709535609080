import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';
import { PrgFormLoginComponent } from './components/form-login/prg-form-login.component';
import { PrgFormRegisterComponent } from './components/form-register/prg-form-register.component';
import { PrgFormRequestPasswordComponent } from './components/form-request-password/prg-form-request-password.component';
import { PrgFormResetPasswordComponent } from './components/form-reset-password/prg-form-reset-password.component';
import { MockAuthService } from './mock/services/auth.service.mock';
import { PrgAuthConfig } from './models/prg-auth-config';
import { PrgAuthLoginPage } from './pages/login/prg-auth-login.page';
import { PrgAuthRegisterPage } from './pages/register/prg-auth-register.page';
import { PrgAuthRequestPasswordPage } from './pages/request-password/prg-auth-request-password.page';
import { PrgAuthResetPasswordPage } from './pages/reset-password/prg-auth-reset-password.page';
import { PrgAuthRoutingModule } from './prg-auth-routing.module';
import { AbstractAuthService } from './services/auth/abstract-auth.service';
import { AuthService } from './services/auth/auth.service';
import { PRG_AUTH_CONFIG } from './services/prg-auth-configuration/prg-auth-configuration.service';

// CR: falta o multilingue em todo o módulo
/**
 * Module that handles authentication
 */
@NgModule({
  declarations: [
    PrgAuthRegisterPage,
    PrgFormRegisterComponent,
    PrgAuthLoginPage,
    PrgFormLoginComponent,
    PrgFormRequestPasswordComponent,
    PrgAuthRequestPasswordPage,
    PrgFormResetPasswordComponent,
    PrgAuthResetPasswordPage,
  ],
  imports: [PrgSharedComponentsModule, PrgAuthRoutingModule],
  exports: [
    PrgFormRegisterComponent,
    PrgFormLoginComponent,
    PrgFormRequestPasswordComponent,
    PrgFormResetPasswordComponent,
  ],
})
export class PrgAuthModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgAuthConfig: PrgAuthConfig
  ): ModuleWithProviders<PrgAuthModule> {
    authConfig = prgAuthConfig;
    return {
      ngModule: PrgAuthModule,
      providers: [
        {
          provide: PRG_AUTH_CONFIG,
          useValue: prgAuthConfig,
        },
        {
          provide: AbstractAuthService,
          useClass: prgAuthConfig.useMockServices
            ? MockAuthService
            : AuthService,
        },
        {
          provide: 'AUTHSTORAGE',
          useValue: authConfig.authStorage,
        }, // CR: ok o tipo de storage deve ser uma configuração no environment e o nome que lhe damos deve ser mais generico (ex: "AUTHSTORAGE")
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the AuthCoreConfig object
 */
export let authConfig: PrgAuthConfig;
