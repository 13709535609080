import { Directive, Input } from '@angular/core';
import { ColorPicker } from 'primeng/colorpicker';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Color Picker
 */
export class ColorPickerConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.colorPicker;
  /**
   *Inline style of the component.
   */
  public style: string = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Whether to display as an overlay or not.
   */
  public inline: boolean = false;
  /**
   *Format to use in value binding, supported formats are "hex", "rgb" and "hsb".
   */
  public format: string = 'hex';
  /**
   *Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   */
  public appendTo: any = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *Identifier of the focus input to match a label defined for the dropdown.
   */
  public inputId: string = null;
  /**
   *Base zIndex value to use in layering.
   */
  public baseZIndex: number = 0;
  /**
   *Whether to automatically manage layering.
   */
  public autoZIndex: boolean = true;
  /**
   *Transition options of the show animation.
   */
  public showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   *Transition options of the hide animation.
   */
  public hideTransitionOptions: string = '.1s linear';

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<ColorPickerConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setColorPickerConfigs]',
})
export class PrgSetColorPickerConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: ColorPicker) {}

  /**
   * input Color Picker configs and assign configs to element
   */
  @Input() set setColorPickerConfigs(configs: ColorPickerConfiguration) {
    Object.assign(this.element, configs);
  }
}
