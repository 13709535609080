import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrgTermsConfig } from '../../models/prg-terms-config';
import { Terms } from '../../models/terms.model';
import { PRG_TERMS_CONFIG } from '../prg-terms-configuration/prg-terms-configuration.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * TermsService class is responsible to perform tasks and manage data related to the terms component
 */
export class TermsService {
  /**
   * constructor
   * @param {TranslateService} translateService
   * @param {PrgTermsConfig} termsConfig
   */
  constructor(
    private translateService: TranslateService,
    @Inject(PRG_TERMS_CONFIG)
    private termsConfig: PrgTermsConfig
  ) {}

  /**
   * Method to get terms and sections base on a key and sort them according to predefined configuration
   * @param {string} baseTermsTranslationKey
   * @returns {Terms[]}
   */
  public getTermsSections(baseTermsTranslationKey: string): Terms[] {
    if (
      baseTermsTranslationKey == null ||
      baseTermsTranslationKey.length === 0
    ) {
      baseTermsTranslationKey = this.termsConfig.baseTermsTranslationKey;
    }

    const sectionsTerms = this.translateService.instant(
      baseTermsTranslationKey
    );
    const sectionsTermsArray = [];

    if (sectionsTerms == null || sectionsTerms.sections == null) {
      return sectionsTermsArray;
    }

    Object.keys(sectionsTerms.sections).forEach(function (key) {
      sectionsTermsArray.push(sectionsTerms.sections[key]);
    });

    sectionsTermsArray.sort((a, b) => {
      return b.order < a.order ? 1 : -1;
    });

    return sectionsTermsArray;
  }
}
