<div class="field-tri-state-checkbox" *ngIf="form" [formGroup]="form">

<p-triStateCheckbox (onChange)="onChanged($event)"
                    [setTriStateCheckboxConfigs]="configs"
                    [formControlName]="configs.key"
                    [inputId]="configs.key"
                    [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
                    [name]="configs.key + '.name' | translate">
</p-triStateCheckbox>
  <label for="{{configs.key}}">{{configs.basePathTranslation + '.' + configs.key + '.label' | translate}}</label>
</div>
