import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PrgDashboardConfig } from '../../models/prg-dashboard-config';

/**
 * Inject token
 */
export const PRG_DASHBOARD_CONFIG = new InjectionToken<PrgDashboardConfig>(
  'PRG_DASHBOARD_CONFIG'
);

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * PrgCoreConfigurationService class definition
 * <br />that specifies the properties that the
 * <br />application can send to the lib for
 * <br />configuration purposes
 */
export class PrgDashboardConfigurationService {
  /**
   * Constructor
   * @param {PrgDashboardConfig} prgDashboardConfig
   */
  constructor(
    @Inject(PRG_DASHBOARD_CONFIG) private prgDashboardConfig: PrgDashboardConfig
  ) {}
}
