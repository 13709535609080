<div>
  <p-button
    [label]="'buttons.add.label' | translate"
    (click)="showEntityTypeData(null)"
  ></p-button>

  <p-treeTable [value]="treeData">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of tableEntityTypeColumns">
          {{ 'entity-type.fields.' + col.key + '.label' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr
        [ttRow]="rowNode"
        (click)="rowNode.level !== 0 && showEntityTypeData(rowData)"
        [ngClass]="{ clickableRow: rowNode.level !== 0 }"
      >
        <td *ngFor="let col of tableEntityTypeColumns; let i = index">
          <p-treeTableToggler
            *ngIf="i == 0"
            [rowNode]="rowNode"
          ></p-treeTableToggler>
          <ng-container *ngIf="col.key === 'name'; else elseBlock">
            {{
              (rowNode.level == 0 ? 'entity-type.groups.' : 'entities.') +
                rowData[col.key].toLowerCase() +
                '.label' | translate
            }}
          </ng-container>
          <ng-template #elseBlock> {{ rowData[col.key] }} </ng-template>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
