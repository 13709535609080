import { Directive, Input } from '@angular/core';
import { Slider } from 'primeng/slider';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Slider
 */
export class SliderConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.slider;
  /**
   *When enabled, displays an animation on click of the slider bar.
   */
  public animate: boolean = false;
  /**
   *Mininum boundary value.
   */
  public min: number = 0;
  /**
   *Maximum boundary value.
   */
  public max: number = 100;
  /**
   *Orientation of the slider, valid values are horizontal and vertical.
   */
  public orientation: string = 'horizontal';
  /**
   *Step factor to increment/decrement the value.
   */
  public step: number = 1;
  /**
   *When specified, allows two boundary values to be picked.
   */
  public range: boolean = false;
  /**
   *Inline style of the component.
   */
  public style: string = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = 0;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<SliderConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setSliderConfigs]',
})
export class PrgSetSliderConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: Slider) {}

  /**
   * input slider configs and assign configs to element
   */
  @Input() set setSliderConfigs(configs: SliderConfiguration) {
    Object.assign(this.element, configs);
  }
}
