<span  class="p-float-label"  *ngIf="form" [formGroup]="form">
<span class="p-float-label">
  <p-inputMask
    [setInputMaskConfigs]="configs"
    [formControlName]="configs.key"
    [inputId]="configs.key"
    [placeholder]="
        configs.basePathTranslation + '.' + configs.key + '.placeholder'
          | translate"
    [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"

  >
  </p-inputMask>
    <label for="{{configs.key}}">{{  configs.basePathTranslation + '.' + configs.key + '.label' | translate
      }}</label>
  </span>

</span>
