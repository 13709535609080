import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PrgQueriesConfig } from '../../models/prg-queries-config';

/**
 * Inject token
 */
export const PRG_QUERIES_CONFIG = new InjectionToken<PrgQueriesConfig>(
  'PRG_QUERIES_CONFIG'
);

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * PrgCoreConfigurationService class definition
 * <br />that specifies the properties that the
 * <br />application can send to the lib for
 * <br />configuration purposes
 */
export class PrgQueriesConfigurationService {
  /**
   * Constructor
   *
   * @param prgQueriesConfig
   */
  constructor(
    @Inject(PRG_QUERIES_CONFIG) private prgQueriesConfig: PrgQueriesConfig
  ) {}
}
