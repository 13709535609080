import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { CascadeSelectConfiguration } from './cascade-select..model';

/**
 * component Cascade Select
 */
@Component({
  selector: 'prg-cascade-select-field',
  templateUrl: './cascade-select.component.html',
})
export class PrgCascadeSelectComponent {
  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the outpout
   */
  public onChanged(event: any) {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event.value,
          event: event,
        })
      );
    }
  }

  /**
   * CascadeSelect config
   */
  @Input() configs: CascadeSelectConfiguration;

  /**
   * Form
   */
  @Input() form: FormGroup;
}
