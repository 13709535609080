/**
 * Constant that defines the fields and actions for the dynamic form query filter
 */
import { CheckboxConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/checkbox/checkbox.model';
import { InputNumberConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/input-number/input-number.model';
import { InputTextConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/input-text/input-text.model';
import { SelectConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/select/select.model';
import {
  BaseAction,
  BaseActionKey,
} from '../../../../core/components/dynamic-form/models/base-action';
import { ActionName } from '../../../../core/components/dynamic-form/models/constants/action-names';
import { ToolbarSlot } from '../../../../core/components/dynamic-form/models/constants/toolbar-slots';
import {
  PrgCompactType,
  PrgGridType,
} from '../../../models/prg-gridster-config';

/**
 * This constant defines the fields and actions to be used on dynamic form for dashboard options
 * @type {{fields: (SelectConfiguration | InputNumberConfiguration | CheckboxConfiguration | InputTextConfiguration)[], actions: BaseAction[]}}
 */
export const DASHBOARD_OPTIONS_DYNAMIC_FORM: any = {
  fields: [
    //Group General
    new SelectConfiguration({
      key: 'gridType',
      options: Object.values(PrgGridType).map((gridType) => {
        return { label: gridType, value: gridType };
      }),
      showClear: true,
      disabledOutputEvent: false,
      groupFieldsId: 'general',
    }),
    new SelectConfiguration({
      key: 'compactType',
      options: Object.values(PrgCompactType).map((compactType) => {
        return { label: compactType, value: compactType };
      }),
      showClear: true,
      disabledOutputEvent: false,
      groupFieldsId: 'general',
    }),
    new InputNumberConfiguration({
      key: 'fixedColWidth',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'general',
    }),
    new InputNumberConfiguration({
      key: 'fixedRowHeight',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'general',
    }),
    new InputNumberConfiguration({
      key: 'mobileBreakpoint',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'general',
    }),
    new InputNumberConfiguration({
      key: 'rowHeightRatio',
      disabledOutputEvent: false,
      showButtons: true,
      mode: 'decimal',
      minFractionDigits: 1,
      maxFractionDigits: 2,
      step: 0.1,
      groupFieldsId: 'general',
    }),
    new CheckboxConfiguration({
      key: 'setGridSize',
      disabledOutputEvent: false,
      groupFieldsId: 'general',
    }),
    new CheckboxConfiguration({
      key: 'keepFixedHeightInMobile',
      disabledOutputEvent: false,
      groupFieldsId: 'general',
    }),
    new CheckboxConfiguration({
      key: 'keepFixedWidthInMobile',
      disabledOutputEvent: false,
      groupFieldsId: 'general',
    }),
    new CheckboxConfiguration({
      key: 'useBodyForBreakpoint',
      disabledOutputEvent: false,
      groupFieldsId: 'general',
    }),
    //Group Resizable
    new InputNumberConfiguration({
      key: 'resizable.delayStart',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.enabled',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.handles.s',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.handles.e',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.handles.n',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.handles.w',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.handles.se',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.handles.ne',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.handles.sw',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    new CheckboxConfiguration({
      key: 'resizable.handles.nw',
      disabledOutputEvent: false,
      groupFieldsId: 'resizable',
    }),
    //Group Push
    new CheckboxConfiguration({
      key: 'pushItems',
      disabledOutputEvent: false,
      groupFieldsId: 'push',
    }),
    new CheckboxConfiguration({
      key: 'disablePushOnDrag',
      disabledOutputEvent: false,
      groupFieldsId: 'push',
    }),
    new CheckboxConfiguration({
      key: 'disablePushOnResize',
      disabledOutputEvent: false,
      groupFieldsId: 'push',
    }),
    new CheckboxConfiguration({
      key: 'pushResizeItems',
      disabledOutputEvent: false,
      groupFieldsId: 'push',
    }),
    new CheckboxConfiguration({
      key: 'pushDirections.north',
      disabledOutputEvent: false,
      groupFieldsId: 'push',
    }),
    new CheckboxConfiguration({
      key: 'pushDirections.east',
      disabledOutputEvent: false,
      groupFieldsId: 'push',
    }),
    new CheckboxConfiguration({
      key: 'pushDirections.south',
      disabledOutputEvent: false,
      groupFieldsId: 'push',
    }),
    new CheckboxConfiguration({
      key: 'pushDirections.west',
      disabledOutputEvent: false,
      groupFieldsId: 'push',
    }),
    //Group Swap
    new CheckboxConfiguration({
      key: 'swap',
      disabledOutputEvent: false,
      groupFieldsId: 'swap',
    }),
    new CheckboxConfiguration({
      key: 'swapWhileDragging',
      disabledOutputEvent: false,
      groupFieldsId: 'swap',
    }),
    //Group Draggable
    new InputNumberConfiguration({
      key: 'draggable.delayStart',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'draggable',
    }),
    new CheckboxConfiguration({
      key: 'draggable.enabled',
      disabledOutputEvent: false,
      groupFieldsId: 'draggable',
    }),
    new CheckboxConfiguration({
      key: 'draggable.ignoreContent',
      disabledOutputEvent: false,
      groupFieldsId: 'draggable',
    }),
    new CheckboxConfiguration({
      key: 'draggable.dropOverItems',
      disabledOutputEvent: false,
      groupFieldsId: 'draggable',
    }),
    new InputTextConfiguration({
      key: 'draggable.dragHandleClass',
      disabledOutputEvent: true,
      groupFieldsId: 'draggable',
    }),

    new InputTextConfiguration({
      key: 'draggable.ignoreContentClass',
      disabledOutputEvent: true,
      groupFieldsId: 'draggable',
    }),
    //Group EmptyCell
    new CheckboxConfiguration({
      key: 'enableEmptyCellClick',
      disabledOutputEvent: false,
      groupFieldsId: 'emptyCell',
    }),
    new CheckboxConfiguration({
      key: 'enableEmptyCellContextMenu',
      disabledOutputEvent: false,
      groupFieldsId: 'emptyCell',
    }),
    new CheckboxConfiguration({
      key: 'enableEmptyCellDrop',
      disabledOutputEvent: false,
      groupFieldsId: 'emptyCell',
    }),
    new CheckboxConfiguration({
      key: 'enableEmptyCellDrag',
      disabledOutputEvent: false,
      groupFieldsId: 'emptyCell',
    }),
    new CheckboxConfiguration({
      key: 'enableOccupiedCellDrop',
      disabledOutputEvent: false,
      groupFieldsId: 'emptyCell',
    }),
    new InputNumberConfiguration({
      key: 'emptyCellDragMaxCols',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'emptyCell',
    }),
    new InputNumberConfiguration({
      key: 'emptyCellDragMaxRows',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'emptyCell',
    }),
    //Group Margins
    new InputNumberConfiguration({
      key: 'margin',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'margins',
    }),
    new CheckboxConfiguration({
      key: 'outerMargin',
      disabledOutputEvent: false,
      groupFieldsId: 'emptyCell',
    }),
    new InputNumberConfiguration({
      key: 'outerMarginLeft',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'margins',
    }),
    new InputNumberConfiguration({
      key: 'outerMarginTop',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'margins',
    }),
    new InputNumberConfiguration({
      key: 'outerMarginRight',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'margins',
    }),
    new InputNumberConfiguration({
      key: 'outerMarginBottom',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'margins',
    }),
    //Grid Sizes
    new InputNumberConfiguration({
      key: 'minCols',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'maxRows',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'maxCols',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'minRows',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'defaultItemCols',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'defaultItemRows',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'minItemCols',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'maxItemCols',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'minItemRows',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'maxItemRows',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'minItemArea',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'maxItemArea',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
    new InputNumberConfiguration({
      key: 'addEmptyRowsCount',
      disabledOutputEvent: false,
      showButtons: true,
      groupFieldsId: 'gridSizes',
    }),
  ],

  actions: [
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Save,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Cancel,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Edit,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='read'",
    }),
  ],
};
