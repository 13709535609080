import { NgModule } from '@angular/core';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';
import { PrgHeaderComponent } from './components/header/prg-header.component';
import { PrgSidebarComponent } from './components/sidebar/prg-sidebar.component';
import { PrgLayoutsEmptyComponent } from './empty/prg-layouts-empty.component';
import { PrgLayoutsHeaderSidebarComponent } from './pages/prg-layouts-header-sidebar';

/**
 * Module that handles all page layouts
 */
@NgModule({
  declarations: [
    PrgLayoutsEmptyComponent,
    PrgHeaderComponent,
    PrgSidebarComponent,
    PrgLayoutsHeaderSidebarComponent,
  ],
  imports: [PrgSharedComponentsModule],
  exports: [
    PrgLayoutsEmptyComponent,
    PrgHeaderComponent,
    PrgSidebarComponent,
    PrgLayoutsHeaderSidebarComponent,
  ],
})
export class PrgLayoutsModule {}
