import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { IsLoadingDataService } from '../../core/services/is-loading-data/isloadingdata.service';

/**
 * Empty Layout component, contains a router-outlet
 * <br />that displays child components
 */
@Component({
  selector: 'prg-layouts-empty',
  templateUrl: './prg-layouts-empty.component.html',
  styleUrls: ['./prg-layouts-empty.component.scss'],
})
export class PrgLayoutsEmptyComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  /**
   * spinnerVisible value
   */
  public spinnerVisible: boolean = false;
  /**
   * subscriptions
   */
  private subscriptions: Subscription[] = [];
  /**
   * Constructor
   * @param logger
   * @param isLoadingData isLoadingData
   * @param cdr
   */
  constructor(
    private logger: NGXLogger,
    public isLoadingData: IsLoadingDataService,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.logger.debug('EMPTY LAYOUT ON INIT');
  }

  /**
   * ngOnInit
   */
  ionViewWillEnter(): void {
    this.logger.debug('EMPTY LAYOUT WILL ENTER');
  }

  /**
   * ngOnInit
   */
  ionViewWillLeave(): void {
    this.logger.debug('EMPTY LAYOUT WILL LEAVE');
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.logger.debug('EMPTY LAYOUT ON DESTROY');
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  /**
   * ngAfterContentChecked
   */
  ngAfterContentChecked(): void {
    this.subscriptions.push(
      this.isLoadingData.visibility.subscribe((visible: boolean) => {
        this.spinnerVisible = visible;
        this.cdr.detectChanges();
      })
    );
  }
}
