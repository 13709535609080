import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { PrgMenuItem } from '../../../../../../layouts/models/prg-menu-item';
import { ArrayUtilityService } from '../../../../../services/utility/array-utility.service';
import { ActionOutput } from '../../../models/base-action';
import { SplitButtonConfiguration } from './split-button.model';

/**
 * component split button
 */
@Component({
  selector: 'prg-button-action',
  templateUrl: './split-button.component.html',
})
export class PrgSplitButtonComponent {
  /**
   * Data of split button menu
   * @type {PrgMenuItem[]}
   */
  public menuButton: PrgMenuItem[] = [];

  /**
   * Split Button configs
   */
  @Input('configs') set configs(configs: SplitButtonConfiguration) {
    this._configs = configs;
    this.assignCommandsToMenuButton(configs.items);
  }

  /**
   * Getter of configs
   * @returns {SplitButtonConfiguration}
   */
  get configs(): SplitButtonConfiguration {
    return this._configs;
  }

  /**
   * Configs aux
   * @type {SplitButtonConfiguration}
   * @private
   */
  private _configs: SplitButtonConfiguration;

  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() actionClick = new EventEmitter<ActionOutput>();

  /**
   * Constructor
   * @param {ArrayUtilityService} arrayUtilityService
   */
  constructor(private arrayUtilityService: ArrayUtilityService) {}

  /**
   * function that triggers the output
   * @param {PrgMenuItem} menuItem
   */
  public onButtonClicked(menuItem: PrgMenuItem = null): void {
    if (menuItem?.key) {
      this.actionClick.emit({
        action: menuItem?.key,
        group: menuItem?.group,
      });
    } else {
      this.actionClick.emit(new ActionOutput({ action: this.configs.key }));
    }
  }

  /**
   * Assign commands to menu button
   * @param {PrgMenuItem[]} menuItems
   */
  public assignCommandsToMenuButton(menuItems: PrgMenuItem[]) {
    this.menuButton = this.arrayUtilityService.clone(menuItems);
    this.menuButton.map((item) => {
      let path: string;
      path = item?.label;
      if (item.key) {
        item.command = (event) => {
          this.onButtonClicked(event.item);
        };
        item.group = path;
      }
      if (item.items != null && item.items.length > 0) {
        let children: PrgMenuItem[] = item.items;
        children.forEach((itemChildren: PrgMenuItem) => {
          return this.iterateOverChildren(itemChildren, path);
        });
      }
    });
  }

  /**
   * Iterate over all children of menu button
   * @param {PrgMenuItem} itemChildren
   * @param {string} path
   * @returns {PrgMenuItem}
   */
  public iterateOverChildren(itemChildren: PrgMenuItem, path: string) {
    let currentChildren: PrgMenuItem = itemChildren;
    path = path + ':' + itemChildren.label;
    if (currentChildren.key) {
      currentChildren.command = (event) => {
        this.onButtonClicked(event.item);
      };
      itemChildren.group = path;
    }
    if (currentChildren.items != null && currentChildren.items.length > 0) {
      currentChildren.items.forEach((child) => {
        if (child.key) {
          child.group = path + ':' + child.label;
          child.command = (event) => {
            this.onButtonClicked(event.item);
          };
        }
        if (child.items != null && child.items.length > 0) {
          this.iterateOverChildren(child, path);
        }
      });
    }

    return currentChildren;
  }
}
