import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../../../auth/models/user.model';
import { AbstractAuthService } from '../../../auth/services/auth/abstract-auth.service';

import { PrgError } from '../../../core/models/error.model';
import { PrgSucess } from '../../../core/models/success.model';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { MockDatabase as MockDatabaseEntityType } from '../../../entity-types/mock/database.mock';
import { Dashboard } from '../../models/dashboard.model';
import { AbstractDashboardService } from '../../services/dashboard/abstract-dashboard.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * MOCK AbstractDashboardService implementation
 */
export class MockDashboardService extends AbstractDashboardService {
  /**
   * default time in seconds
   */
  private sleepTimeSec = 0.5;

  /**
   * Constructor
   * @param {NotificationsService} notificationsService
   * @param {UtilityService} utilityService
   * @param {IsLoadingDataService} isLoadingData
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {AbstractAuthService} authService
   * @param {HttpClient} httpClient
   */
  constructor(
    private notificationsService: NotificationsService,
    private utilityService: UtilityService,
    private isLoadingData: IsLoadingDataService,
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private authService: AbstractAuthService,
    private httpClient: HttpClient
  ) {
    super(httpClient);
  }

  /**
   * Function responsible to store a new Dashboard
   * @param {Dashboard} newDashboard
   * @returns {Promise<Dashboard>}
   */
  public createDashboardAsync(newDashboard: Dashboard): Promise<Dashboard> {
    return new Promise<Dashboard>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      let checkNameDashboard = MockDatabaseEntityType.dashboard.find(
        (dashboard) => dashboard.name === newDashboard.name
      );
      this.isLoadingData.hide();

      if (!checkNameDashboard) {
        let dashboard: Dashboard =
          this.objectsUtilityService.cloneObject(newDashboard);
        dashboard['createdBy'] = this.authService.getLoggedUser()?.id;

        MockDatabaseEntityType.dashboard.push(dashboard);
        const dashboardsUpdated = this.arrayUtilityService.clone(
          MockDatabaseEntityType.dashboard
        );
        await this.setDashboardListUpdated(dashboardsUpdated);
        resolve(dashboard);
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'New Dashboard Created.',
            detailKey: 'Success.',
          })
        );
      } else {
        reject('Dashboard not save');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Dashboard not save.',
            detailKey: 'Dashboard name already exists in database',
          })
        );
      }
    });
  }

  /**
   * Get Dashboard by Id
   * @param {string} dashboardId
   * @returns {Promise<Dashboard>}
   */
  public getDashboardByIdAsync(dashboardId: string): Promise<Dashboard> {
    return new Promise<Dashboard>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const dashboard: Dashboard = this.objectsUtilityService.cloneObject(
        MockDatabaseEntityType.dashboard.find((dashboard) => {
          return dashboard.id === dashboardId;
        })
      );
      this.isLoadingData.hide();
      if (dashboard) {
        resolve(dashboard);
      } else {
        reject('dashboard could not be loaded');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'dashboard could not be loaded.',
            detailKey: 'Server is down',
          })
        );
      }
    });
  }

  /**
   * Function responsible to update a dashboard
   * @param {string} dashboardId
   * @param {Dashboard} dashboard
   * @returns {Promise<Dashboard>}
   */
  public updateDashboardAsync(
    dashboardId: string,
    dashboard: Dashboard
  ): Promise<Dashboard> {
    return new Promise<Dashboard>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);

      const dashboardIndexToUpdate: number =
        MockDatabaseEntityType.dashboard.findIndex((dashboard) => {
          return dashboard.id == dashboardId;
        });
      if (dashboardIndexToUpdate > -1) {
        MockDatabaseEntityType.dashboard[dashboardIndexToUpdate] =
          this.objectsUtilityService.cloneObject(dashboard);

        const dashboardsUpdated = this.arrayUtilityService.clone(
          MockDatabaseEntityType.dashboard
        );
        await this.setDashboardListUpdated(dashboardsUpdated);
        this.isLoadingData.hide();
        resolve(dashboard);
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'Dashboard updated.',
            detailKey: 'Success.',
          })
        );
      } else {
        reject('dashboard could not be updated');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'dashboard could not be updated.',
            detailKey: 'Server is down',
          })
        );
      }
    });
  }
  /**
   * Get dashboards by User Logged
   * @returns {Promise<Dashboard[]>}
   */
  public getDashboardsByUserAsync(): Promise<Dashboard[]> {
    return new Promise<Dashboard[]>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      let userLogged: User = this.authService.getLoggedUser();
      const dashboards: Dashboard[] = this.objectsUtilityService.cloneObject(
        MockDatabaseEntityType.dashboard.filter((dashboard) => {
          return dashboard.createdBy === userLogged.id;
        })
      );
      this.isLoadingData.hide();
      if (dashboards) {
        resolve(dashboards);
      } else {
        reject('dashboards could not be loaded');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'dashboards could not be loaded.',
            detailKey: 'Server is down',
          })
        );
      }
    });
  }
}
