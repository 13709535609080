import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Table } from 'primeng/table';
import { BaseField } from '../../../core/components/dynamic-form/models/base-field';
import { FormGroupService } from '../../../core/services/form-group/form-group.service';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { LookupTable } from '../../models/lookup-table.model';
import { PrgLookupTableConfig } from '../../models/prg-lookup-table-config';
import { PRG_LOOKUP_TABLE_CONFIG } from '../../services/lookup-table-configuration/prg-lookup-table-configuration.service';
import { AbstractLookupTableService } from '../../services/lookup-table/abstract-lookup-table.service';

/**
 * LookupTables Component
 */
@Component({
  selector: 'prg-lookup-table-table',
  templateUrl: './prg-lookup-table-table.component.html',
  styleUrls: ['./prg-lookup-table-table.component.scss'],
})
export class PrgLookupTableTableComponent implements OnInit {
  /**
   * View Child table
   */
  @ViewChild(Table) private dataTable: Table;

  /**
   *  new Lookup Table Id Prefix
   */
  public newLookupTableIdPrefix = 'newLookupTable';

  /**
   * table columns
   */
  public lookupTableColumns: BaseField[] =
    this.prgLookupTableConfig.lookupTableColumns;

  /**
   * Lookup table form group
   */
  public lookupTableForm: FormGroup;

  /**
   * cloned Lookup Table
   * this map is used to temporarily store the elements in edit mode
   */
  private clonedLookupTable: Map<string, LookupTable> = new Map<
    string,
    LookupTable
  >();

  /**
   * auxiliary variable to help define the id of the new lookuptables
   */
  private newLookupTableIdCount: number = 0;

  /**
   * constructor
   *
   * @param lookupTableService
   * @param prgLookupTableConfig prgLookupTableConfig
   * @param isLoadingDataService isLoadingDataService
   * @param formBuilder
   * @param formGroupService
   * @param objectsUtilityService
   */
  constructor(
    private lookupTableService: AbstractLookupTableService,
    @Inject(PRG_LOOKUP_TABLE_CONFIG)
    public prgLookupTableConfig: PrgLookupTableConfig,
    public isLoadingDataService: IsLoadingDataService,
    private formBuilder: FormBuilder,
    private formGroupService: FormGroupService,
    private objectsUtilityService: ObjectsUtilityService
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.lookupTableForm = this.formBuilder.group({
      lookupTableFormArray: this.formBuilder.array([]),
    });
    this.populateData();
  }

  /**
   * Return lookup table form array
   */
  get lookupTableFormArray(): FormArray {
    return this.lookupTableForm.get('lookupTableFormArray') as FormArray;
  }

  /**
   * this function is responsible for adding a new row
   * to the table for the user to configure the new lookuptable
   *
   */
  public newLookupTable(): void {
    const newLookupTable: LookupTable = {
      id: `${this.newLookupTableIdPrefix}${this.newLookupTableIdCount}`,
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: null,
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      description: null,
      workspaceId: null,
      items: null,
    };

    this.newLookupTableIdCount++;

    this.lookupTableFormArray.insert(
      0,
      this.formGroupService.toFormGroupOneObject(
        newLookupTable,
        this.lookupTableColumns
      )
    );

    //TODO: SEE this
    this.lookupTableFormArray.value.unshift(newLookupTable);

    this.lookupTableForm.markAllAsTouched();
    this.dataTable.editingRowKeys[newLookupTable.id] = true;
  }

  /**
   * return lookup table  control for given lookup table
   */
  public getLookupTableControlById(lookupTableId: string): AbstractControl {
    return this.lookupTableFormArray.controls.find(
      (control) => control.value.id === lookupTableId
    );
  }

  /**
   * this function is responsible for creating a
   * clone of a lookupTable that is selected for editing
   *
   * @param lookupTable
   */
  public onRowEditInit(lookupTable: LookupTable): void {
    this.clonedLookupTable.set(lookupTable.id, { ...lookupTable });
  }

  /**
   * this function is responsible for saving a lookup table,
   * either an update or adding a new lookup table to the database
   *
   * @param lookupTable
   */
  public onRowEditSave(lookupTable: LookupTable): void {
    const lookupTableToSave =
      this.objectsUtilityService.cloneObject(lookupTable);
    if (lookupTableToSave.id.includes(this.newLookupTableIdPrefix)) {
      lookupTableToSave.id = null;
    }

    this.lookupTableService
      .saveLookupTableAsync(lookupTableToSave)
      .then((response) => {
        this.getLookupTableControlById(lookupTable.id).setValue(response);
        delete this.dataTable.editingRowKeys[lookupTable.id];
        this.clonedLookupTable.delete(lookupTable.id);
      });
  }

  /**
   * this function is responsible for restoring the data
   * that was there before starting the edit if the
   * lookup table is already in the database,
   * otherwise it just deletes the row that was temporarily created
   *
   * @param lookupTable
   */
  public onRowEditCancel(lookupTable: LookupTable) {
    if (lookupTable.id.includes(this.newLookupTableIdPrefix)) {
      this.lookupTableFormArray.removeAt(
        this.getLookupTableControlIndexById(lookupTable.id)
      );
    } else {
      const lookupTableOrignal = this.clonedLookupTable.get(lookupTable.id);
      this.getLookupTableControlById(lookupTable.id).setValue(
        lookupTableOrignal
      );
    }
  }

  /**
   * this function is responsible for checking
   * if the lookup table already has the
   * items loaded, if not it calls the function to load them
   * @param lookupTable
   */
  public expandLookupTable(lookupTable: LookupTable): void {
    if (lookupTable.items === null) {
      this.loadLookupTableItemsByLookupTableId(lookupTable);
    }
  }

  /**
   * this function is responsible for saving
   * all active edits (new lines or updating existing ones)
   */
  public async saveAllChanges(): Promise<void> {
    let lookupTablesToSave: LookupTable[] = [];
    Object.getOwnPropertyNames(this.dataTable.editingRowKeys).forEach(
      (element) => {
        if (element.includes(this.newLookupTableIdPrefix)) {
          const lookupTableAux = this.objectsUtilityService.cloneObject(
            this.getLookupTableControlById(element).value
          );
          lookupTableAux.id = null;
          lookupTablesToSave.push(lookupTableAux);
        } else {
          lookupTablesToSave.push(
            this.objectsUtilityService.cloneObject(
              this.getLookupTableControlById(element).value
            )
          );
        }
      }
    );
    this.lookupTableService
      .saveMultipleLookupTableAsync(lookupTablesToSave)
      .then((response) => {
        response.forEach((slt) => {
          const lookupTableById = this.getLookupTableControlById(slt.id);
          if (lookupTableById) {
            lookupTableById.setValue(slt);
          } else {
            this.lookupTableFormArray.push(
              this.formGroupService.toFormGroupOneObject(
                slt,
                this.lookupTableColumns
              )
            );
          }
        });

        this.clonedLookupTable.clear();
        this.deleteAllTempLookupTable();
      });
  }

  /**
   * this function is responsible for discarding
   * all active edits, and resetting the data that
   * was set before starting the edit
   *
   */
  public discardAllChanges(): void {
    Object.getOwnPropertyNames(this.dataTable.editingRowKeys).forEach(
      (element) => {
        if (element.includes(this.newLookupTableIdPrefix)) {
          this.lookupTableFormArray.removeAt(
            this.getLookupTableControlIndexById(element)
          );
        } else {
          this.getLookupTableControlById(element).setValue(
            this.clonedLookupTable.get(element)
          );
        }
      }
    );
    this.clonedLookupTable.clear();
    this.dataTable.editingRowKeys = {};
  }

  /**
   *this function is responsible for sorting a given column
   * @param event
   */
  public customSort(event: any) {
    event.data.sort((data1, data2) => {
      let value1 = data1.value[event.field];
      let value2 = data2.value[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  /**
   * this function recive the item saved in child component
   * @param event
   */
  public itemsSaved(event: string) {
    const lookupTable = this.getLookupTableControlById(event).value;
    this.loadLookupTableItemsByLookupTableId(lookupTable);
  }

  /**
   * return index of control for given lookup table
   */
  private getLookupTableControlIndexById(lookupTableId: string): number {
    return this.lookupTableFormArray.controls.findIndex(
      (control) => control.value.id === lookupTableId
    );
  }

  /**
   * Populate data into Form
   */
  private populateData() {
    this.lookupTableService.getAllLookupTableAsync().then((response) => {
      const aux = this.formGroupService.toFormGroupMultipleObjects(
        response,
        this.lookupTableColumns
      );

      aux.forEach((a) => {
        this.lookupTableForm.markAllAsTouched();
        this.lookupTableFormArray.push(a);
      });
    });
  }

  /**
   * this function is responsible for loading the
   * lookup table items for a given lookup table
   * @param lookupTable
   */
  private loadLookupTableItemsByLookupTableId(lookupTable: LookupTable): void {
    this.lookupTableService
      .getLookupTableItemsByLookupTableIdAsync(lookupTable.id)
      .then((response) => {
        lookupTable.items = response;
        this.getLookupTableControlById(lookupTable.id).setValue(lookupTable);
      });
  }

  /**
   * this function is an auxiliary function that is used in the [saveAllChanges]{@link #saveAllChanges}
   *  function to delete all temporary rows that will be added later
   */
  private deleteAllTempLookupTable(): void {
    const tempLookupTable = this.lookupTableFormArray.controls.filter(
      (control) => control.value.id.includes(this.newLookupTableIdPrefix)
    );
    if (tempLookupTable.length > 0) {
      tempLookupTable.forEach((tlt) => {
        this.lookupTableFormArray.removeAt(
          this.getLookupTableControlIndexById(tlt.value.id)
        );
      });
    }
  }
}
