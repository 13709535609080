import { InputNumberConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/input-number/input-number.model';

/**
 * Constant that define the key , configuration settings and settings for line chart component
 * @type {{staticSettings: string, configurationSettings: {fields: InputNumberConfiguration[]}, key: string}}
 */
export const PRG_BAR_LINE_SETTINGS = {
  key: 'Line-Chart',
  configurationSettings: {
    fields: [
      new InputNumberConfiguration({
        key: 'lineChartProperty1',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'lineChartProperty2',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'lineChartProperty3',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'lineChartProperty4',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'lineChartProperty5',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'lineChartProperty6',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'lineChartProperty7',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
    ],
  },
  staticSettings: JSON.stringify({
    lineChartProperty1: 35,
    lineChartProperty2: 58,
    lineChartProperty3: 85,
    lineChartProperty4: 33,
    lineChartProperty5: 99,
    lineChartProperty6: 120,
    lineChartProperty7: 150,
  }),
};
