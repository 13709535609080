import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'checklist/:id',
    loadChildren: () =>
      import('./checklist/checklist.module').then((m) => m.ChecklistPageModule),
  },
  {
    path: 'list/:id',
    loadChildren: () => import('./checklist-list/checklist-list.module').then( m => m.ChecklistListPageModule)
  },
  {
    path: 'workorder/:id',
    loadChildren: () => import('./workorder/workorder.module').then( m => m.WorkorderPageModule)
  },
  {
    path: 'workorder-list/:filter',
    loadChildren: () => import('./workorder-list/workorder-list.module').then( m => m.WorkorderListPageModule)
  },
  {
    path: 'terms-of-use-app',
    loadChildren: () =>
      import('./terms/terms.module').then((m) => m.TermsModule),
  },
  
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
