import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { PrimeNGConfig } from 'primeng/api';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { PrgAuthConfig } from '../../models/prg-auth-config';
import { AbstractAuthService } from '../../services/auth/abstract-auth.service';
import { PRG_AUTH_CONFIG } from '../../services/prg-auth-configuration/prg-auth-configuration.service';

/**
 * Form Register Component
 */
@Component({
  selector: 'prg-form-register',
  templateUrl: './prg-form-register.component.html',
  styleUrls: ['./prg-form-register.component.scss'],
})
export class PrgFormRegisterComponent implements OnInit, OnDestroy {
  /**
   * Shows/hide the checkbox terms of use. Default true.
   */
  @Input() showTermsOfUse: boolean = true;

  /**
   * Shows/hide the login link.  Default true.
   */
  @Input() showLoginLink: boolean = true;

  /**
   * The route for login page
   */
  @Input() loginPageRoute: string =
    '/' +
    this.authRoutes.authRoutingBasePath +
    '/' +
    this.authRoutes.authRoutingLoginPath;

  /**
   * The route for terms of use page
   */
  @Input() termsOfUsePageRoute: string =
    '/' + this.authRoutes.termsOfUseRoutingPath;

  /**
   * Property of the class with type string which bind to image logo form.
   */
  @Input() imagePathLogo: string = '';

  /**
   * Property of the class with type Form Group binding to the register form
   */
  public registerForm: FormGroup;

  /**
   * Constructor
   * @param logger
   * @param authService
   * @param primengConfig
   * @param authRoutes
   * @param isLoadingData isLoadingData
   */
  constructor(
    private logger: NGXLogger,
    private authService: AbstractAuthService,
    private primengConfig: PrimeNGConfig,
    @Inject(PRG_AUTH_CONFIG) private authRoutes: PrgAuthConfig,
    public isLoadingData: IsLoadingDataService
  ) {}

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.logger.debug('REGISTER FORM ON INIT');
    this.primengConfig.ripple = true;
    this.initFormRegister();
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.logger.debug('REGISTER FORM ON DESTROY');
  }

  /**
   * Initiate the register form and its form controls with validators
   */
  private initFormRegister(): void {
    this.registerForm = new FormGroup(
      {
        email: new FormControl(null, [Validators.required, Validators.email]),
        termsOfUse: new FormControl(null, [Validators.requiredTrue]),
      },
      { updateOn: 'change' }
    );
    if (!this.showTermsOfUse) {
      this.registerForm.controls.termsOfUse.removeValidators(
        Validators.requiredTrue
      );
    }
  }

  /**
   * Form submission
   */
  public async onSubmitRegister(): Promise<void> {
    if (this.registerForm.invalid) {
      return;
    }
    const userEmail = this.registerForm.controls.email.value;
    this.registerForm.disable();
    try {
      if (await this.authService.signupAsync(userEmail)) {
        this.registerForm.reset();
        // TODO: Falta reencaminhar o user após sucesso no registo .Rota??
      }
    } catch (error) {
      this.logger.debug(error);
    }
    this.registerForm.enable();
  }
}
