import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PrgError } from '../../../../core/models/error.model';
import { FilterGroup } from '../../../../core/models/filters';
import { PaginationResponse } from '../../../../core/models/pagination-response';
import { PrgSucess } from '../../../../core/models/success.model';
import { IsLoadingDataService } from '../../../../core/services/is-loading-data/isloadingdata.service';
import { NotificationsService } from '../../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../../core/services/utility/utility.service';
import {
  EntityType,
  EntityTypeAttribute,
  EntityTypeOperation,
  EntityTypeProperty,
} from '../../../models/entity-type.model';
import { AbstractEntityTypeService } from '../../../services/entity-types/abstract-entity-type.service';
import { MockDatabase } from '../../database.mock';

/**
 * injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * class MockEntityTypesService
 */
export class MockEntityTypesService extends AbstractEntityTypeService {
  /**
   * default time in seconds
   */
  private sleepTimeSec = 0.2;

  /**
   * constructor
   * @param utilityService
   * @param objectsUtilityService
   * @param notificationsService
   * @param arrayUtilityService
   * @param isLoadingData
   * @param translateService
   */
  constructor(
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    @Inject(NotificationsService) notificationsService: NotificationsService,
    private arrayUtilityService: ArrayUtilityService,
    private isLoadingData: IsLoadingDataService,
    @Inject(TranslateService) translateService: TranslateService
  ) {
    super(notificationsService, translateService);
  }

  /**
   * Get all Entity Types
   *
   * @Returns Entity types[]
   */
  public getEntityTypeListAsync(): Promise<EntityType[]> {
    return new Promise<EntityType[]>(async (resolve, reject) => {
      if (this.isCacheValid()) {
        const values = this.getElementsList();
        resolve(values);
      } else {
        this.isLoadingData.show();
        await this.utilityService.sleepSecAsync(this.sleepTimeSec);
        const entityTypeList = this.arrayUtilityService.clone(
          MockDatabase.EntityTypes
        );
        this.isLoadingData.hide();
        if (entityTypeList !== null && entityTypeList.length > 0) {
          const entityTypeTranslation = <EntityType[]>(
            await this.getTranslationsAsync(entityTypeList)
          );
          this.setToCache(entityTypeTranslation, 'name');
          resolve(entityTypeTranslation);
        } else {
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'errorGetEntityTypeList',
            })
          );
          reject('Error');
        }
      }
    });
  }

  /**
   * Get entity type list with operation
   *
   *  @Returns Entity types[]
   */
  public getEntityTypeListWithOperationAsync(): Promise<EntityType[]> {
    return new Promise<EntityType[]>(async (resolve, reject) => {
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const entityTypeList = this.arrayUtilityService.clone(
        MockDatabase.EntityTypes
      );
      this.isLoadingData.hide();
      if (entityTypeList !== null && entityTypeList.length > 0) {
        entityTypeList.forEach((entityType) => {
          entityType.operations = this.arrayUtilityService
            .clone(MockDatabase.EntityTypesOperations)
            .filter(
              (operation: EntityTypeOperation) =>
                operation.entityTypeId === entityType.id
            );
        });
        const entityTypeTranslation = <EntityType[]>(
          await this.getTranslationsAsync(entityTypeList)
        );
        this.setToCache(entityTypeTranslation, 'name');
        resolve(entityTypeTranslation);
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorGetEntityTypeList',
          })
        );
        reject('Error');
      }
    });
  }

  /**
   * save entity type
   * @param entityType
   * @return Entity type
   */
  public saveEntityTypeAsync(entityType: EntityType): Promise<EntityType> {
    return new Promise<EntityType>(async (resolve, reject) => {
      if (entityType.id) {
        const entityTypeToUpdate = MockDatabase.EntityTypes.find(
          (et) => et.id === entityType.id
        );

        if (entityTypeToUpdate) {
          entityTypeToUpdate.guiSettings = entityType.guiSettings;
          entityTypeToUpdate.group = entityType.group;
          entityTypeToUpdate.universalStateId = entityType.universalStateId;

          //set catch
          this.setToCache(
            this.objectsUtilityService.cloneObject(entityTypeToUpdate),
            'name'
          );

          resolve(this.objectsUtilityService.cloneObject(entityTypeToUpdate));
          this.notificationsService.successNotification(
            new PrgSucess({
              titleKey: 'success',
              detailKey: 'entityTypeUpdatedSucess',
            })
          );
        } else {
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'entityTypeUpdatedError',
            })
          );
          reject(null);
        }
      } else {
        const newEntityType: EntityType = {
          id: this.utilityService.newGuid(),
          universalStateId: entityType.universalStateId,
          transactionId: null,
          operationId: null,
          label: null,
          name: entityType.name,
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          stateId: '1',
          workspaceId: '1',
          guiSettings: entityType.guiSettings,
          group: entityType.group,
          properties: null,
          attributes: null,
          operations: null,
        };

        const entityTypeList = MockDatabase.EntityTypes;
        entityTypeList.unshift(newEntityType);
        this.setToCache(
          this.objectsUtilityService.cloneObject(newEntityType),
          'name'
        );

        resolve(this.objectsUtilityService.cloneObject(newEntityType));
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'success',
            detailKey: 'entityTypeSuccessAdded',
          })
        );
      }
    });
  }

  /**
   * get all operations of an entity
   * @param entityTypeId
   * @return EntityTypeOperation[]
   */
  public getAllOperationsByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeOperation[]> {
    return new Promise<EntityTypeOperation[]>((resolve, reject) => {
      const entityTypeOperationList = this.arrayUtilityService
        .clone(MockDatabase.EntityTypesOperations)
        .filter((operation) => operation.entityTypeId === entityTypeId);
      if (entityTypeOperationList) {
        resolve(entityTypeOperationList);
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorGetEntityTypeOperationsList',
          })
        );
        reject('Error');
      }
    });
  }

  /**
   * add or update entity type operation
   * @param entityTypeOperation
   * @return EntityTypeOperation
   */
  public saveEntityTypeOperationAsync(
    entityTypeOperation: EntityTypeOperation
  ): Promise<EntityTypeOperation> {
    return new Promise<EntityTypeOperation>(async (resolve, reject) => {
      if (entityTypeOperation.id) {
        const entityTypeOperationToUpdate =
          MockDatabase.EntityTypesOperations.find(
            (eto) => eto.id === entityTypeOperation.id
          );

        if (entityTypeOperationToUpdate) {
          entityTypeOperationToUpdate.httpVerb = entityTypeOperation.httpVerb;
          entityTypeOperationToUpdate.universalStateId =
            entityTypeOperation.universalStateId;
          entityTypeOperationToUpdate.guiSettings =
            entityTypeOperation.guiSettings;
          entityTypeOperationToUpdate.imports = entityTypeOperation.imports;
          entityTypeOperationToUpdate.override = entityTypeOperation.override;
          entityTypeOperationToUpdate.universalStateId =
            entityTypeOperation.universalStateId;

          resolve(
            this.objectsUtilityService.cloneObject(entityTypeOperationToUpdate)
          );

          /*  this.setEntityTypeOperationByEntityTypeId(
            this.objectsUtilityService.cloneObject(entityTypeOperationToUpdate)
          );*/
          this.notificationsService.successNotification(
            new PrgSucess({
              titleKey: 'success',
              detailKey: 'entityTypeOperationUpdatedSucess',
            })
          );
        } else {
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'entityTypeOperationUpdatedError',
            })
          );
          reject(null);
        }
      } else {
        const newEntityTypeOperation: EntityTypeOperation =
          new EntityTypeOperation({
            id: this.utilityService.newGuid(),
            universalStateId: entityTypeOperation.universalStateId,
            transactionId: null,
            operationId: null,
            name: entityTypeOperation.name,
            createdBy: null,
            modifiedBy: null,
            createdOn: null,
            modifiedOn: null,
            workspaceId: '1',
            entityTypeId: entityTypeOperation.entityTypeId,
            guiSettings: entityTypeOperation.guiSettings,
            override: entityTypeOperation.override,
            imports: entityTypeOperation.imports,
            sourceCode: 'source code',
            httpVerb: entityTypeOperation.httpVerb,
          });

        const entityTypeOperationList = MockDatabase.EntityTypesOperations;
        entityTypeOperationList.unshift(newEntityTypeOperation);
        resolve(this.objectsUtilityService.cloneObject(newEntityTypeOperation));
        /*this.setEntityTypeOperationByEntityTypeId(
          this.objectsUtilityService.cloneObject(newEntityTypeOperation)
        );*/

        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'success',
            detailKey: 'entityTypeOperationSuccessAdded',
          })
        );
      }
    });
  }

  /**
   * get all attributes of an entity
   * @param entityTypeId
   * @return EntityTypeAttribute[]
   */
  public getAllAttributesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeAttribute[]> {
    return new Promise<EntityTypeAttribute[]>((resolve, reject) => {
      const entityTypeAttributeList = this.arrayUtilityService
        .clone(MockDatabase.EntityTypeAttribute)
        .filter((operation) => operation.entityTypeId === entityTypeId);
      if (entityTypeAttributeList) {
        resolve(entityTypeAttributeList);
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorGetEntityTypeAttributeListList',
          })
        );
        reject('Error');
      }
    });
  }

  /**
   * add or update entity type attribute
   * @param entityTypeAttribute
   * @return EntityTypeAttribute
   */
  public saveEntityTypeAttributeAsync(
    entityTypeAttribute: EntityTypeAttribute
  ): Promise<EntityTypeAttribute> {
    return new Promise<EntityTypeAttribute>(async (resolve, reject) => {
      if (entityTypeAttribute.id) {
        const entityTypeAttributeToUpdate =
          MockDatabase.EntityTypeAttribute.find(
            (eta) => eta.id === entityTypeAttribute.id
          );

        if (entityTypeAttributeToUpdate) {
          Object.assign(entityTypeAttributeToUpdate, entityTypeAttribute);

          resolve(
            this.objectsUtilityService.cloneObject(entityTypeAttributeToUpdate)
          );

          //TODO: UPDATE CACHE
          this.notificationsService.successNotification(
            new PrgSucess({
              titleKey: 'success',
              detailKey: 'entityTypeAttributeUpdatedSucess',
            })
          );
        } else {
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'entityTypeAttributeUpdatedError',
            })
          );
          reject(null);
        }
      } else {
        const newEntityTypeAttribute: EntityTypeAttribute =
          new EntityTypeAttribute({
            id: this.utilityService.newGuid(),
            universalStateId: entityTypeAttribute.universalStateId,
            transactionId: null,
            operationId: null,
            name: entityTypeAttribute.name,
            createdBy: null,
            label: null,
            modifiedBy: null,
            createdOn: null,
            modifiedOn: null,
            workspaceId: '1',
            entityTypeId: entityTypeAttribute.entityTypeId,
            guiSettings: entityTypeAttribute.guiSettings,
            dataTypeId: entityTypeAttribute.dataTypeId,
            order: entityTypeAttribute.order,
            reference: entityTypeAttribute.reference,
            isArray: entityTypeAttribute.isArray,
          });

        const entityTypeAttributeList = MockDatabase.EntityTypeAttribute;
        entityTypeAttributeList.unshift(newEntityTypeAttribute);
        resolve(this.objectsUtilityService.cloneObject(newEntityTypeAttribute));

        //TODO: Update cache
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'success',
            detailKey: 'entityTypeAttributeSuccessAdded',
          })
        );
      }
    });
  }

  /**
   * get all properties of an entity
   * @param entityTypeId
   * @return EntityTypeProperty[]
   */
  public getAllPropertiesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeProperty[]> {
    return new Promise<EntityTypeProperty[]>((resolve, reject) => {
      const entityTypePropertynList = this.arrayUtilityService
        .clone(MockDatabase.EntityTypeProperty)
        .filter((operation) => operation.entityTypeId === entityTypeId);
      if (entityTypePropertynList) {
        resolve(entityTypePropertynList);
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorGetEntityTypePropertynListList',
          })
        );
        reject('Error');
      }
    });
  }

  /**
   * add or update entity type property
   * @param EntityTypeProperty
   * @return EntityTypeProperty
   */
  public saveEntityTypePropertyAsync(
    entityTypeProperty: EntityTypeProperty
  ): Promise<EntityTypeProperty> {
    return new Promise<EntityTypeProperty>(async (resolve, reject) => {
      if (entityTypeProperty.id) {
        const entityTypePropertyToUpdate = MockDatabase.EntityTypeProperty.find(
          (etp) => etp.id === entityTypeProperty.id
        );

        if (entityTypePropertyToUpdate) {
          Object.assign(entityTypePropertyToUpdate, entityTypeProperty);

          resolve(
            this.objectsUtilityService.cloneObject(entityTypePropertyToUpdate)
          );

          //TODO: UPDATE CACHE
          this.notificationsService.successNotification(
            new PrgSucess({
              titleKey: 'success',
              detailKey: 'entityTypePropertyUpdatedSucess',
            })
          );
        } else {
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'entityTypePropertyUpdatedError',
            })
          );
          reject(null);
        }
      } else {
        const newEntityTypeProperty: EntityTypeProperty =
          new EntityTypeProperty({
            id: this.utilityService.newGuid(),
            universalStateId: entityTypeProperty.universalStateId,
            transactionId: null,
            operationId: null,
            name: entityTypeProperty.name,
            createdBy: null,
            modifiedBy: null,
            label: null,
            createdOn: null,
            modifiedOn: null,
            workspaceId: '1',
            entityTypeId: entityTypeProperty.entityTypeId,
            guiSettings: entityTypeProperty.guiSettings,
            dataTypeId: entityTypeProperty.dataTypeId,
            order: entityTypeProperty.order,
            reference: entityTypeProperty.reference,
            isArray: entityTypeProperty.isArray,
          });

        const entityTypePropertyList = MockDatabase.EntityTypeProperty;
        entityTypePropertyList.unshift(newEntityTypeProperty);
        resolve(this.objectsUtilityService.cloneObject(newEntityTypeProperty));
        //TODO: Update cache
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'success',
            detailKey: 'entityTypePropertySuccessAdded',
          })
        );
      }
    });
  }

  /**
   * update entity type properties using patch logic
   * @param entityTypesPropertiesToPatch
   * @return EntityTypeProperty[]
   */
  public updateEntityTypePropertiesAsync(
    entityTypesPropertiesToPatch: Map<string, any>
  ): Promise<EntityTypeProperty[]> {
    return new Promise<EntityTypeProperty[]>(async (resolve, reject) => {
      const updated = [];
      entityTypesPropertiesToPatch.forEach(
        (fieldsToPatch: any, key: string) => {
          const entityTypePropertyToUpdate =
            MockDatabase.EntityTypeProperty.find((etp) => etp.id === key);

          updated.push(
            this.objectsUtilityService.cloneObject(
              Object.assign(entityTypePropertyToUpdate, fieldsToPatch)
            )
          );
        }
      );

      resolve(updated);
    });
  }

  /**
   * update entity type attributes using patch logic
   * @param entityTypesAttributesToPatch
   * @return EntityTypeAttribute[]
   */
  public updateEntityTypeAttributesAsync(
    entityTypesAttributesToPatch: Map<string, any>
  ): Promise<EntityTypeAttribute[]> {
    return new Promise<EntityTypeProperty[]>(async (resolve, reject) => {
      entityTypesAttributesToPatch.forEach(
        (fieldsToPatch: any, key: string) => {
          const entityTypeAttributesToUpdate =
            MockDatabase.EntityTypeAttribute.find((eta) => eta.id === key);

          Object.assign(entityTypeAttributesToUpdate, fieldsToPatch);
        }
      );

      resolve(this.arrayUtilityService.clone(MockDatabase.EntityTypeProperty));
    });
  }

  /**
   * this service returns all the data (properties, attributes and operations)
   * of a given entity type (by entity name)
   * @param entityTypeName
   */
  public getAllEntityTypeDataByName(
    entityTypeName: string
  ): Promise<EntityType> {
    return new Promise<EntityType>(async (resolve, reject) => {
      let entityType: EntityType = this.arrayUtilityService
        .clone(MockDatabase.EntityTypes)
        .find((entity: EntityType) => entity.name === entityTypeName);

      if (entityType) {
        entityType.properties = this.arrayUtilityService
          .clone(MockDatabase.EntityTypeProperty)
          .filter(
            (property: EntityTypeProperty) =>
              property.entityTypeId === entityType.id
          );
        entityType.attributes = this.arrayUtilityService
          .clone(MockDatabase.EntityTypeAttribute)
          .filter(
            (attribute: EntityTypeAttribute) =>
              attribute.entityTypeId === entityType.id
          );
        entityType.operations = this.arrayUtilityService
          .clone(MockDatabase.EntityTypesOperations)
          .filter(
            (operation: EntityTypeOperation) =>
              operation.entityTypeId === entityType.id
          );

        entityType = <EntityType>await this.getTranslationsAsync(entityType);
        resolve(entityType);
      }
    });
  }

  /**
   * this service returns an array of elements
   * of the entity sent as a parameter,
   *  based on the filter it receives as a parameter.
   * @param entityTypeName
   * @param FilterGroup
   */
  public getEntityTypeElements(
    entityTypeName: string,
    filterGroup: FilterGroup
  ): Promise<PaginationResponse> {
    return new Promise<PaginationResponse>(async (resolve, reject) => {
      let entityElementsAux = [];

      entityElementsAux = this.arrayUtilityService.clone(
        MockDatabase[entityTypeName]
      );
      if ('orderCollection' in filterGroup) {
        entityElementsAux = this.arrayUtilityService.sortByProperty(
          entityElementsAux,
          filterGroup.orderCollection[0].propertyName,
          filterGroup.orderCollection[0].orderType === 0 ? true : false
        );
      }

      if ('filterCollections' in filterGroup) {
        filterGroup.filterCollections.forEach((filter) => {
          entityElementsAux = entityElementsAux.filter((element) =>
            element[filter.propertyName].includes(filter.value)
          );
        });
      }
      const qtdElement = entityElementsAux.length;
      entityElementsAux = entityElementsAux.slice(
        filterGroup.pageIndex * filterGroup.pageSize,
        (filterGroup.pageIndex + 1) * filterGroup.pageSize
      );
      resolve(
        new PaginationResponse({
          items: entityElementsAux,
          totalItems: qtdElement,
          currentPage: filterGroup.pageIndex,
        })
      );
    });
  }

  /**
   * Get all properties and attributes by entity type id
   * @param {string} entityTypeId
   * @returns {Promise<EntityTypeProperty[]>}
   */
  public getAllPropertiesAndAttributesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeProperty[]> {
    return new Promise<EntityTypeProperty[]>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      this.isLoadingData.hide();

      let entityType = MockDatabase.EntityTypes.find(
        (entityType) => entityType.id === entityTypeId
      );

      const entityTypeProperties = [...MockDatabase.EntityTypeProperty].filter(
        (entityTypeProperty) => entityTypeProperty.entityTypeId === entityTypeId
      );

      const entityTypeAttributes = [...MockDatabase.EntityTypeAttribute].filter(
        (entityTypeAttributes) =>
          entityTypeAttributes.entityTypeId === entityTypeId
      );

      entityType.properties = [...entityTypeProperties];
      entityType.attributes = [...entityTypeAttributes];
      const entityTypeToTranslate: EntityType = <EntityType>(
        await this.getTranslationsAsync(entityType)
      );

      const entityTypePropertyAndAttributesList = [
        ...entityTypeToTranslate.properties,
        ...entityTypeToTranslate.attributes,
      ];

      this.isLoadingData.hide();
      if (entityTypePropertyAndAttributesList.length > 0) {
        resolve(entityTypePropertyAndAttributesList);
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorGetEntityTypePropertynListList',
          })
        );
        reject('Error');
      }
    });
  }

  /**
   * This service return a element from a entity type list by id
   * @param entityTypeName
   * @param elementId
   */
  public getEntityTypeElementById(
    entityTypeName: string,
    elementId: string
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const element = this.objectsUtilityService.cloneObject(
        MockDatabase[entityTypeName].find((element) => element.id === elementId)
      );

      resolve(element);
    });
  }

  /**
   * this service excute an operation
   * @param entityName
   * @param operation
   * @param entity
   * @returns
   */
  public executeAction(
    entityName: string,
    operation: EntityTypeOperation,
    entity: any
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      switch (operation.name) {
        case 'save':
          resolve(this.saveOperationProduct(entity));
          break;
        case 'delete':
          resolve(this.deleteOperationProduct(entity));
          break;
        case 'update':
          resolve(this.updateOperationProduct(entity));
          break;
        default:
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'operationNotFound',
            })
          );
          break;
      }
    });
  }

  /**
   * operations save product
   * @param element
   * @returns
   */
  private saveOperationProduct(element: any): any {
    if (element.id) {
      const productToUpdate = MockDatabase.product.find(
        (product) => product.id === element.id
      );
      Object.assign(productToUpdate, element);
    } else {
      element['id'] = this.utilityService.newGuid();
      MockDatabase.product.unshift(element);
      return this.objectsUtilityService.cloneObject(element);
    }
  }

  /**
   * operation delte product
   * @param element
   * @returns
   */
  private deleteOperationProduct(element: any): any {
    const elementToDeleteIndex = MockDatabase.product.findIndex(
      (product) => product.id === element.id
    );
    MockDatabase.product.splice(elementToDeleteIndex, 1);
    return element;
  }

  /**
   *  operation update product
   * @param element
   * @returns
   */
  private updateOperationProduct(element: any) {
    const elementToUpdate = MockDatabase.product.find(
      (product) => product.id === element.id
    );

    if (elementToUpdate) {
      Object.assign(elementToUpdate, element);

      return elementToUpdate;
    }
  }
}
