import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BaseAction,
  BaseActionKey,
  DynamicFormActionOutput,
} from '../../../core/components/dynamic-form/models/base-action';
import { BaseField } from '../../../core/components/dynamic-form/models/base-field';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { PrgError } from '../../../core/models/error.model';
import { FormGroupService } from '../../../core/services/form-group/form-group.service';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import {
  EntityType,
  EntityTypeProperty,
} from '../../../entity-types/models/entity-type.model';
import { AbstractEntityTypeService } from '../../../entity-types/services/entity-types/abstract-entity-type.service';
import { AbstractLookupTableService } from '../../../lookup-table/services/lookup-table/abstract-lookup-table.service';
import {
  FilterExpressionToTranslate,
  OperatorsAndDataType,
  QueriesFilterColumns,
} from '../../models/query-table-filter.model';
import {
  Query,
  QueryFilter,
  QueryFilterExpression,
  QueryFilterOperationByType,
} from '../../models/query.model';
import { QUERIES_FILTER_DYNAMIC_FORM } from '../../queries-dynamic-form-structure/queries-filter-dynamic-form';
import { QUERIES_RAW_DYNAMIC_FORM } from '../../queries-dynamic-form-structure/queries-raw-dynamic-form';
import { AbstractQueriesService } from '../../services/queries/abstract-queries.service';

/**
 * Query Modal Component
 */
@Component({
  selector: 'prg-select-query',
  templateUrl: './prg-query-modal.component.html',
  styleUrls: ['./prg-query-modal.component.scss'],
})
export class PrgQueryModalComponent implements OnInit {
  /**
   * Map of Operators and datatype associated to field
   * @type {Map<string, OperatorsAndDataType>}
   */
  public mappedOperatorsAndDataTypeByField = new Map<
    string,
    OperatorsAndDataType
  >();

  /**
   * A copy of mappedOperatorsAndDataTypeByField
   * @type {Map<string, OperatorsAndDataType>}
   */
  public mappedOperatorsAndDataTypeByFieldCopy = new Map<
    string,
    OperatorsAndDataType
  >();
  /**
   * Display column "Value2" when a query has a filter with two inputs values.
   *
   * Default false, hidden column.
   */
  public queryHasFilterWithTwoValues: boolean = false;

  /**
   * The filter expressions ex:"AND" and its translations
   * @type {FilterExpressionToTranslate[]}
   */
  public filterExpressions: FilterExpressionToTranslate[] = [];

  /**
   * Create an object type QueryFilterOperationByType to
   * access his method numberOfFields on template.
   */
  public operatorType = new QueryFilterOperationByType();

  /**
   * A form array that belongs to the formQueryFilter
   */
  public queryFilterArrayForm = new FormArray([]);
  /**
   * Class property with type Query which bind to the entity of dynamic form in template
   *
   * The query data comes from parent component.
   */
  public query: Query;

  /**
   * Property with type Form Group which bind to the query filter form (only filter table)
   */
  public formQueryFilter: FormGroup;

  /**
   * Property  with type boolean which bind to the checkbox in template to change query type.
   *
   * Default is true (raw query)
   */
  public checkBoxQueryRaw: boolean = true;
  /**
   * The properties and attributes available for selection by entity type
   */
  public optionsQueryFields: EntityTypeProperty[] = [];

  /**
   * A copy of OptionsQueryFields
   */
  public optionsQueryFieldsCopy: EntityTypeProperty[] = [];
  /**
   * The dynamic form fields to build dynamic form filter query in template
   */
  public queriesFilterDynamicFormFields: any[] = this.arrayUtilityService.clone(
    QUERIES_FILTER_DYNAMIC_FORM.fields
  );
  /**
   * The dynamic form actions to build dynamic form filter query in template
   */
  public queriesFilterDynamicFormActions: BaseAction[] =
    this.arrayUtilityService.clone(QUERIES_FILTER_DYNAMIC_FORM.actions);
  /**
   * The dynamic form fields to build dynamic form raw query in template
   */
  public queriesRawDynamicFormFields: BaseField[] =
    this.arrayUtilityService.clone(QUERIES_RAW_DYNAMIC_FORM.fields);
  /**
   * The dynamic form actions to build dynamic form raw query in template
   */
  public queriesRawDynamicFormActions: BaseAction[] =
    this.arrayUtilityService.clone(QUERIES_RAW_DYNAMIC_FORM.actions);

  /**
   * Query table columns to build the formQueryFilter
   */
  public queriesFilterColumns: BaseField[] = QueriesFilterColumns;

  /**
   * An array of all entity type
   * @type {EntityType[]}
   */
  public entityTypeList: EntityType[];

  /**
   * This property define if component is loading data from an existing query.
   *
   * Default is true.
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The view mode type that comes from parent component. Default is "read"
   */
  public queriesViewMode: ViewMode = ViewMode.Read;

  /**
   * Property that defines if you are editing a query or creating a new one.
   *
   * Default is false (new query)
   */
  private editMode: boolean = false;

  /**
   * The current entity type selected
   */
  private currentEntityTypeIdSelected: string;
  /**
   * A copy of the query that comes from the parent component.
   *
   * This property is used to reset the query to its original values
   */
  private originalQuery: Query;

  /**
   * Constructor
   * @param {DynamicDialogRef} querySelectedDialogRef querySelectedDialogRef
   * @param {DynamicDialogConfig} querySelectedDialogConfig querySelectedDialogConfig
   * @param {AbstractQueriesService} queriesService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {FormGroupService} formGroupService
   * @param {NotificationsService} notificationsService
   * @param {PrimeNGConfig} primengConfig
   * @param {AbstractEntityTypeService} entityTypeService
   * @param {AbstractLookupTableService} lookupTableService
   */
  constructor(
    public querySelectedDialogRef: DynamicDialogRef,
    public querySelectedDialogConfig: DynamicDialogConfig,
    private queriesService: AbstractQueriesService,
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private formGroupService: FormGroupService,
    private notificationsService: NotificationsService,
    private primengConfig: PrimeNGConfig,
    private entityTypeService: AbstractEntityTypeService,
    private lookupTableService: AbstractLookupTableService
  ) {}

  /**
   * ngOnInit
   *
   * Set up all configurations require to initiate properly the component
   */
  public async ngOnInit() {
    let dataQueryFromTable: Query = this.querySelectedDialogConfig.data.query;
    this.queriesViewMode = this.querySelectedDialogConfig.data.viewMode;
    this.checkBoxQueryRaw = dataQueryFromTable.isRaw;
    if (dataQueryFromTable.id) {
      this.query = this.objectsUtilityService.cloneObject(
        await this.queriesService.getQueryByIdAsync(dataQueryFromTable.id)
      );
      this.editMode = true;
      this.currentEntityTypeIdSelected = this.query.entityTypeId;
    } else {
      this.query = this.objectsUtilityService.cloneObject(dataQueryFromTable);
      this.editMode = false;
    }

    this.originalQuery =
      this.objectsUtilityService.cloneObject(dataQueryFromTable);

    this.primengConfig.ripple = true;
    this.initFormQueryFilter();

    if (this.queriesViewMode === ViewMode.Read) {
      this.queryFilterArrayForm.disable();
    }

    this.onCheckNumberOfFields();
    await this.getInitialData();
    this.filterExpressions =
      await this.queriesService.getFilterExpressionTranslationsAsync(
        Object.keys(QueryFilterExpression)
      );
    this.isLoading = false;
  }

  /**
   * Get initial data (async)
   *
   * Function responsible for getting all data from api/mock required to initialize form query filter
   * @private
   */
  private async getInitialData(): Promise<void> {
    this.entityTypeList = this.arrayUtilityService.clone(
      await this.entityTypeService.getEntityTypeListAsync()
    );

    if (this.currentEntityTypeIdSelected && !this.checkBoxQueryRaw) {
      await this.getFieldsByEntityTypeSelect();
      await this.mapOperatorsAndDataTypeByField();
    }
  }

  /**
   * Get fields by entity type selected(async)
   *
   * Function responsible for getting all fields (properties and attributes) available
   * from api/mock by entity type selected
   * @private
   */
  private async getFieldsByEntityTypeSelect(): Promise<void> {
    /*Check if entity selected has properties and attributes*/
    try {
      if (!this.checkBoxQueryRaw && this.currentEntityTypeIdSelected) {
        let entityTypeNameSelected: string = this.entityTypeList.find(
          (entity) => entity.id === this.currentEntityTypeIdSelected
        ).name;

        let entityAllData: EntityType = this.objectsUtilityService.cloneObject(
          await this.entityTypeService.getAllEntityTypeDataByName(
            entityTypeNameSelected
          )
        );
        this.optionsQueryFields = this.arrayUtilityService.clone([
          ...entityAllData.properties,
          ...entityAllData.attributes,
        ]);
        if (
          this.optionsQueryFieldsCopy == null ||
          this.optionsQueryFieldsCopy.length < 1
        ) {
          this.optionsQueryFieldsCopy = this.arrayUtilityService.clone(
            this.optionsQueryFields
          );
        }
      }
    } catch (e) {
      this.optionsQueryFields = [];
    }
  }

  /**
   * Query raw action output (async)
   *
   * This function is responsible to handle actions from the dynamic raw form
   * @param {DynamicFormActionOutput} event the output from the dynamic raw form
   */
  public async onActionQueryRaw(event: DynamicFormActionOutput): Promise<void> {
    switch (event.action) {
      case BaseActionKey.Save:
        await this.queryRawSave(event);
        break;
      case BaseActionKey.Cancel:
        if (event.formEntity.id) {
          this.query = this.objectsUtilityService.cloneObject(
            this.originalQuery
          );
        } else {
          this.querySelectedDialogRef.close();
        }
        this.queriesViewMode = ViewMode.Read;
        break;

      case BaseActionKey.Edit:
        this.queriesViewMode = ViewMode.Edit;
        break;
      default:
        break;
    }
  }

  /**
   * Save raw query (async)
   *
   * This function is responsible to save a new raw query or update an existing one
   * @param {DynamicFormActionOutput} event the output from the dynamic raw form
   * @private
   */
  private async queryRawSave(event: DynamicFormActionOutput): Promise<void> {
    this.queriesViewMode = ViewMode.Read;
    // @ts-ignore
    const { group, ...query } = event.formEntity;

    query.isRaw = true;
    let queryResolve: Query;
    if (query.id == null || query.id.length === 0) {
      queryResolve = await this.queriesService.createQueryAsync(query);
    } else {
      delete query.filters;
      delete query.entityTypeId;
      queryResolve = await this.queriesService.updateQueryAsync(
        query.id,
        query
      );
    }
    this.querySelectedDialogRef.close(queryResolve);
  }

  /**
   * Initialize Form Array of Filter Query
   * @param {boolean} editMode
   * @private
   */
  private initFormArray(editMode: boolean = false): void {
    if (this.query['filters'] && !editMode) {
      const aux = this.formGroupService.toFormGroupMultipleObjects(
        this.query.filters,
        this.queriesFilterColumns
      );

      this.query.filters.forEach((value, index) => {
        this.queryFilterArrayForm.push(aux[index]);
      });
    } else {
      const newQueryFilter: QueryFilter =
        PrgQueryModalComponent.newQueryFilterObject();
      (<FormArray>this.queryFilterArrayForm).controls = [];
      const aux2 = this.formGroupService.toFormGroupOneObject(
        newQueryFilter,
        this.queriesFilterColumns
      );
      this.queryFilterArrayForm.push(aux2);
    }
  }

  /**
   * Initialize Query Filter Form
   * @private
   */
  private initFormQueryFilter(): void {
    this.initFormArray();

    this.formQueryFilter = new FormGroup({
      filters: this.queryFilterArrayForm,
    });
  }

  /**
   * A getter for the form array controls
   * @returns {any}
   */
  public get filters(): any {
    return (<FormArray>this.formQueryFilter.get('filters')).controls;
  }

  /**
   * Delete a row of the filter query table  (async)
   * @param {number} i The table row
   *
   */
  public onDeleteRow(i: number): void {
    if (this.queryFilterArrayForm.length > 1) {
      (<FormArray>this.formQueryFilter.get('filters')).removeAt(i);
    } else {
      (<FormArray>this.formQueryFilter.get('filters')).reset();
    }
  }

  /**
   * Changing field (async)
   *
   * Function is call whenever is a change of field value
   * @param {number} i The table row
   * @param {string}value the name of the field
   */
  public async onChangeField(i: number, value: string): Promise<void> {
    (<FormArray>this.formQueryFilter.get('filters')).controls.forEach(
      (arrayRow, index) => {
        if (index === i) {
          (<FormGroup>arrayRow).controls.filterOperation.reset();
        }
      }
    );
  }

  /**
   * Add a new element to query filter array (async)
   * @param {number} i The table row
   */
  public async onAddRow(i: number): Promise<void> {
    const newQueryFilter: QueryFilter =
      PrgQueryModalComponent.newQueryFilterObject();
    this.queryFilterArrayForm.insert(
      i + 1,
      this.formGroupService.toFormGroupOneObject(
        newQueryFilter,
        this.queriesFilterColumns
      )
    );
  }

  /**
   * Function is call whenever entity type value changes
   * @param {string} entityID
   *
   */
  public async onChangeEntity(entityID: string): Promise<void> {
    if (entityID == null) {
      return;
    } else {
      this.currentEntityTypeIdSelected = entityID;
      await this.getFieldsByEntityTypeSelect();
      await this.mapOperatorsAndDataTypeByField();

      this.initFormArray(this.editMode);
    }
  }

  /**
   * Changing Query type
   *
   * Function is call whenever toggle button changes
   */
  public async onChangeQueryType(): Promise<void> {
    if (!this.checkBoxQueryRaw && !this.query.id) {
      await this.getFieldsByEntityTypeSelect();
      await this.mapOperatorsAndDataTypeByField();
      this.initFormArray();
    }
  }

  /**
   * Auxiliary function to create a new instance of class QueryFilter
   * with all its properties
   * @returns {QueryFilter} new Object QueryFilter
   * @private
   */
  private static newQueryFilterObject(): QueryFilter {
    return {
      id: null,
      universalStateId: null,
      transactionId: null,
      operationId: null,
      name: null,
      workspaceId: null,
      filterExpression: QueryFilterExpression.And,
      filterOperation: null,
      queryId: null,
      propertyName: null,
      startGroup: true,
      value: null,
      value2: null,
      createdBy: null,
      createdOn: null,
      modifiedBy: null,
      modifiedOn: null,
    };
  }

  /**
   * Function is call whenever operator input changes in each row
   *
   * Function is responsible to find out if any of filter rows has an
   * operator that requires two inputs values an if so makes field value2 visible and apply required validator
   */
  public onCheckNumberOfFields(): void {
    if (
      this.queryFilterArrayForm != null &&
      this.queryFilterArrayForm.length > 0
    ) {
      const allOperations = this.queryFilterArrayForm.controls.map((a) => {
        return a.value.filterOperation;
      });
      let checkMultipleFields = false;

      for (let operation of allOperations) {
        let index = allOperations.indexOf(operation);
        let operatorType = new QueryFilterOperationByType();
        if (operatorType.numberOfFields(operation) === 2) {
          checkMultipleFields = true;
          this.queryFilterArrayForm.controls[index]
            .get('value2')
            .setValidators(Validators.required);
          break;
        }
      }

      this.queryHasFilterWithTwoValues = checkMultipleFields;
    }
  }

  /**
   * Query filter action output (async)
   *
   * This function is responsible to handle actions from the dynamic filter form
   * @param {DynamicFormActionOutput} event the output from the dynamic filter form
   */
  public async onActionQueryFilter(event: DynamicFormActionOutput) {
    switch (event.action) {
      case BaseActionKey.Save:
        if (this.queryFilterArrayForm.status === 'INVALID') {
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'Error Submitting Query ',
              detailKey: 'Filling missing fields',
            })
          );
          return;
        }
        await this.queryFilterSave(event);
        break;

      case BaseActionKey.Cancel:
        await this.queryFilterCancel(event);
        break;

      case BaseActionKey.Edit:
        this.queriesViewMode = ViewMode.Edit;
        this.queryFilterArrayForm.enable();
        break;
      default:
        break;
    }
  }

  /**
   * Save filter query (async)
   *
   * This function is responsible to save a new filter query or update an existing one
   * @param {DynamicFormActionOutput} event the output from the dynamic filter form
   * @private
   */
  private async queryFilterSave(event: DynamicFormActionOutput) {
    this.queriesViewMode = ViewMode.Read;

    const newQuery: Query = new Query({
      isRaw: false,
      id: this.query.id ? this.query.id : null,
      createdBy: this.query.createdBy ? this.query.createdBy : null,
      name: event.formEntity.name,
      description: event.formEntity.description,
      entityTypeId: event.formEntity.entityTypeId,
      filters: this.formQueryFilter.value.filters,
    });

    let queryResolve: Query;
    if (newQuery.id == null || newQuery.id.length === 0) {
      queryResolve = await this.queriesService.createQueryAsync(newQuery);
    } else {
      queryResolve = await this.queriesService.updateQueryAsync(
        newQuery.id,
        newQuery
      );
    }

    this.querySelectedDialogRef.close(queryResolve);
  }

  /**
   * Cancel filter query (async)
   *
   * This function is responsible to handle operations whenever cancel button is pressed
   *
   * If editing an existing query it will reset all values of the form
   *
   * If is a new one will close dialog
   * @param {DynamicFormActionOutput} event the output from the dynamic filter form
   * @private
   */
  private async queryFilterCancel(event: DynamicFormActionOutput) {
    if (event.formEntity.id) {
      this.formQueryFilter.reset();
      this.isLoading = true;
      this.currentEntityTypeIdSelected = this.query.entityTypeId;
      this.optionsQueryFields = this.arrayUtilityService.clone(
        this.optionsQueryFieldsCopy
      );
      this.mappedOperatorsAndDataTypeByField.clear();
      this.mappedOperatorsAndDataTypeByField =
        this.objectsUtilityService.cloneObject(
          this.mappedOperatorsAndDataTypeByFieldCopy
        );

      (<FormArray>this.queryFilterArrayForm).controls = [];
      this.initFormArray();
      this.query = this.objectsUtilityService.cloneObject(this.originalQuery);
      this.isLoading = false;
    } else {
      this.querySelectedDialogRef.close();
    }
    this.queriesViewMode = ViewMode.Read;
    this.queryFilterArrayForm.disable();
  }

  /**
   * This Function is responsible to map datatype for the fields values and operators
   *
   * associated to the chosen property or attribute for each filter row
   * @returns {Promise<void>}
   */
  public async mapOperatorsAndDataTypeByField() {
    if (this.optionsQueryFields != null && this.optionsQueryFields.length > 0) {
      this.mappedOperatorsAndDataTypeByField.clear();
      let initMappedCopy: boolean = false;
      if (
        this.mappedOperatorsAndDataTypeByFieldCopy == null ||
        this.mappedOperatorsAndDataTypeByFieldCopy.size < 1
      ) {
        initMappedCopy = true;
      }
      for (const fields of this.optionsQueryFields) {
        let dataTypeAux = this.lookupTableService
          .getLookUpTableItemByIdAsync(fields.dataTypeId)
          .then((value) => {
            return value.name;
          });

        let auxOperation = QueryFilterOperationByType[await dataTypeAux];

        let queryFilterOperationAux =
          await this.queriesService.getFilterOperationsTranslationsAsync(
            auxOperation
          );

        Promise.all([dataTypeAux, queryFilterOperationAux]).then((values) => {
          this.mappedOperatorsAndDataTypeByField.set(fields.name, {
            queryFilterOperationByType: values[1],
            dataType: values[0],
          });
          if (initMappedCopy) {
            this.mappedOperatorsAndDataTypeByFieldCopy.set(fields.name, {
              queryFilterOperationByType: values[1],
              dataType: values[0],
            });
          }
        });
      }
    }
  }
}
