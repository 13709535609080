import { LookupTable, LookupTableItem } from '../models/lookup-table.model';

/**
 * Database interface that defines a mock
 * <br />database to be used while developing
 * <br />and/or testing the front-end.
 */
export interface Database {
  /**
   * Mock Lookup Table
   */
  LookupTable: LookupTable[];

  /**
   * Mock Lookup table items
   */
  LookupTableItems: LookupTableItem[];
}

/**
 * MockDatabase object to be used while developing
 * <br /> and/or testing the front-end
 */
export const MockDatabase: Database = {
  LookupTable: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'GameStatus',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'Estados de jogo',
      items: null,
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Sports',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'Despostos',
      items: null,
    },
    {
      id: 'dataType',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'dataType',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'dataType',
      items: null,
    },
    {
      id: 'language',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'language',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'language',
      items: null,
    },

    {
      id: 'stockStatus',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'stockStatus',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'stockStatus',
      items: null,
    },
    {
      id: 'theme',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'theme',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'theme',
      items: null,
    },
  ],

  LookupTableItems: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'standby',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'Standby',
      lookupTableId: '1',
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'running',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'Running',
      lookupTableId: '1',
    },
    {
      id: '3',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'ended',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'Ended',
      lookupTableId: '1',
    },
    {
      id: '4',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'waterPolo',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'Water polo',
      lookupTableId: '2',
    },
    {
      id: '5',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'football',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'Football',
      lookupTableId: '2',
    },
    {
      id: '6',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'ended',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'Ended',
      lookupTableId: '2',
    },
    {
      id: 'dataType.string',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'string',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'string',
      lookupTableId: 'dataType',
    },
    {
      id: 'dataType.number',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'number',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'number',
      lookupTableId: 'dataType',
    },
    {
      id: 'dataType.date',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'date',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'date',
      lookupTableId: 'dataType',
    },
    {
      id: 'dataType.default',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'default',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'default',
      lookupTableId: 'dataType',
    },
    {
      id: 'dataType.lookupTable',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'lookupTable',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'lookup table',
      lookupTableId: 'dataType',
    },
    {
      id: 'dataType.entityType',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'entityType',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'entity type',
      lookupTableId: 'dataType',
    },

    {
      id: 'stockStatus.unavailable',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'unavailable',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'unavailable',
      lookupTableId: 'stockStatus',
    },
    {
      id: 'stockStatus.available',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'available',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'entity type',
      lookupTableId: 'stockStatus',
    },
    {
      id: 'language.portuguese',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'portuguese',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'portuguese',
      lookupTableId: 'language',
    },
    {
      id: 'language.english',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'english',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'english',
      lookupTableId: 'language',
    },
    {
      id: 'theme.lara-light-blue',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'lara-light-blue',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'lara-light-blue',
      lookupTableId: 'theme',
    },
    {
      id: 'theme.bootstrap4-dark-purple',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'bootstrap4-dark-purple',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'bootstrap4-dark-purple',
      lookupTableId: 'theme',
    },
    {
      id: 'theme.bootstrap4-light-purple',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'bootstrap4-light-purple',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'bootstrap4-light-purple',
      lookupTableId: 'theme',
    },
    {
      id: 'theme.bootstrap4-light-blue',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'bootstrap4-light-blue',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'bootstrap4-light-blue',
      lookupTableId: 'theme',
    },
    {
      id: 'theme.rhea',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'rhea',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      description: 'rhea',
      lookupTableId: 'theme',
    },
  ],
};
