import { Directive, Input } from '@angular/core';
import { SelectButton } from 'primeng/selectbutton';
import { BaseAction } from '../../../models/base-action';
import { ActionName } from '../../../models/constants/action-names';

/**
 * Class Select Button Action
 */
export class SelectButtonActionConfiguration extends BaseAction {
  /**
   * control name
   */
  public controlName?: string = ActionName.SelectButton;

  /**
   *An array of selectitems to display as the available options.
   */
  public options: any[] = null;
  /**
   *Name of the label field of an option.
   */
  public optionLabel: string = 'label';
  /**
   *Name of the value field of an option.
   */
  public optionValue: string = 'value';
  /**
   *Name of the disabled field of an option.
   */
  public optionDisabled: string = 'disabled';
  /**
   *When specified, allows selecting multiple values.
   */
  public multiple: boolean = false;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = 0;
  /**
   *Inline style of the component.
   */
  public style: string = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;
  /**
   *A property to uniquely identify a value in options.
   */
  public dataKey: string = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<SelectButtonActionConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setSelectButtonActionConfigs]',
})
export class PrgSetSelectButtonActionConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: SelectButton) {}

  /**
   * Set Select Button configs and assign configs to element
   */
  @Input() set setSelectButtonActionConfigs(
    configs: SelectButtonActionConfiguration
  ) {
    Object.assign(this.element, configs);
  }
}
