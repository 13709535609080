import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { PrgError } from '../../models/error.model';
import { PrgSucess } from '../../models/success.model';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  /**
   * constructor
   *
   * @param messageService
   */
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {}

  /**
   * this function fire an error toast
   * @param error
   */
  public errorNotification(error: PrgError) {
    this.messageService.add({
      key: 'message',
      severity: 'error',
      summary: error.titleKey,
      detail: error.detailKey,
      life: error.life,
    });
  }

  /**
   * this function fire an success toast
   * @param success
   */
  public successNotification(success: PrgSucess) {
    this.messageService.add({
      key: 'message',
      severity: 'success',
      summary: success.titleKey,
      detail: success.detailKey,
      life: success.life,
    });
  }

  public async prgConfirmationService(
    translationPath: string,
    entityNameTranslationParameter: string = null,
    throwOnCancel: boolean = false
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const confirmationServiceTranslations = await this.translateService
        .get(
          [
            translationPath + '.message',
            translationPath + '.header',
            translationPath + '.icon',
            translationPath + '.reject-label',
            translationPath + '.accept-label',
          ],
          entityNameTranslationParameter != null
            ? {
                entityName: entityNameTranslationParameter,
              }
            : null
        )
        .toPromise();

      this.confirmationService.confirm({
        key: 'confirmationDialog',
        message: confirmationServiceTranslations[translationPath + '.message'],
        header: confirmationServiceTranslations[translationPath + '.header'],
        icon: confirmationServiceTranslations[translationPath + '.icon'],
        rejectLabel:
          confirmationServiceTranslations[translationPath + '.reject-label'],
        acceptLabel:
          confirmationServiceTranslations[translationPath + '.accept-label'],

        accept: () => {
          resolve(true);
        },

        reject: () => {
          if (throwOnCancel) {
            reject();
          } else {
            resolve(false);
          }
        },
      });
    });
  }
}
