<ng-container *ngIf="configs.options;else singleCheckbox">
  <div *ngIf="form" [formGroup]="form">
    <div *ngFor="let option of configs.options; let i=index" class="field-checkbox">
      <p-checkbox
                  [formControlName]="configs.key"
                  (onChange)="onChanged($event)"
                  [setCheckboxConfigs]="configs"
                  [inputId]="option[configs.optionKey]"
                  [value]="option[configs.optionValue]"
                  [binary]="false"
                  [name]="option[configs.optionKey]"
                  [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
                  [(ngModel)]="valueChecked"
                  [disabled]="configs.disabled||(!configs.allowMultipleCheckBoxSelection&&valueChecked?.length>0&&valueChecked!=option[configs.optionValue])"
      >
      </p-checkbox>
      <label
        for="{{option[configs.optionKey]}}">{{configs.basePathTranslation + '.' + configs.key + '.' + option[configs.optionKey] + '.label' | translate}}</label>
    </div>
  </div>
</ng-container>
<ng-template #singleCheckbox>
  <div class="field-checkbox" *ngIf="form" [formGroup]="form">
    <p-checkbox
      (onChange)="onChanged($event)"
      [setCheckboxConfigs]="configs"
      [formControlName]="configs.key"
      [inputId]="configs.key"
      [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
      [name]="configs.key + '.name' | translate"></p-checkbox>
    <label for="{{configs.key}}">{{configs.basePathTranslation + '.' + configs.key + '.label' | translate}}</label>
  </div>
</ng-template>
