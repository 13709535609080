import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActionOutput } from '../../../models/base-action';
import { InputSwitchActionConfiguration } from './input-switch-action.model';

/**
 * Component Input Switch Action
 */
@Component({
  selector: 'prg-input-switch-action',
  templateUrl: './input-switch-action.component.html',
})
export class PrgInputSwitchActionComponent {
  /**
   * Input Switch Action Configs
   */
  @Input() configs: InputSwitchActionConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() actionClick = new EventEmitter<ActionOutput>();

  /**
   * function that triggers the outpout
   */
  public onButtonClicked(event: any): void {
    this.actionClick.emit(
      new ActionOutput({ action: this.configs.key, value: event.checked })
    );
  }
}
