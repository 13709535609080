import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { ScrollerOptions } from 'primeng/scroller';
import { EntityType } from '../../../../../../entity-types/models/entity-type.model';
import { AbstractEntityTypeService } from '../../../../../../entity-types/services/entity-types/abstract-entity-type.service';
import { AbstractLookupTableService } from '../../../../../../lookup-table/services/lookup-table/abstract-lookup-table.service';
import { ArrayUtilityService } from '../../../../../services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../../../services/utility/objects-utility.service';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';
import { ReferenceType } from '../../../models/constants/reference-type';

/**
 * Class Select
 */
export class SelectConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName: string = ControlName.dropdown;

  /**
   * Reference type
   */
  public referenceType: ReferenceType = null;

  /**
   * Reference name -> shoud be a lookup table name
   */
  public referenceName: string = null;

  /**
   *An array of objects to display as the available options.
   */
  public options: any[] = null;

  /**
   *Name of the label field of an option.
   */
  public optionLabel: string = 'label';

  /**
   *Name of the value field of an option.
   */
  public optionValue: string = 'value';

  /**
   *Name of the disabled field of an option.
   */
  public optionDisabled: string = 'disabled';

  /**
   *Name of the label field of an option group.
   */
  public optionGroupLabel: string = 'label';

  /**
   *Name of the options field of an option group.
   */
  public optionGroupChildren: string = 'items';

  /**
   *Name of the input element.
   */
  public name: string = null;

  /**
   *Height of the viewport in pixels, a scrollbar is defined if height of list exceeds this value.
   */
  public scrollHeight: string = '200px';

  /**
   *Inline style of the element.
   */
  public style: string = null;

  /**
   *Inline style of the overlay panel element.
   */
  public panelStyle: string = null;

  /**
   *Style class of the element.
   */
  public styleClass: string = null;

  /**
   *Style class of the overlay panel element.
   */
  public panelStyleClass: string = null;

  /**
   *When specified, displays an input field to filter the items on keyup.
   */
  public filter: boolean = false;

  /**
   *When specified, filter displays with this value.
   */
  public filterValue: string = null;

  /**
   *When filtering is enabled, filterBy decides which field or fields (comma separated) to search against.
   */
  public filterBy: string = null;

  /**
   *Defines how the items are filtered, valid values are contains (default) startsWith, endsWith, equals, notEquals, in, lt, lte, gt and gte.
   */
  public filterMatchMode: string = 'contains';

  /**
   *Placeholder text to show when filter input is empty.
   */
  public filterPlaceholder: string = null;

  /**
   *Locale to use in filtering. The default locale is the host environment's current locale.
   */
  public filterLocale: string = 'undefined';

  /**
   *When present, it specifies that an input field must be filled out before submitting the form.
   */
  public required: boolean = false;
  /*
  /!**
   *When present, it specifies that the component should be disabled.
   *!/
  public disabled: boolean = false;*/ //Property already on Base Field

  /*  /!**
   *When present, it specifies that the component cannot be edited.
   *!/
  public readonly: boolean = false;*/ //Property already on Base Field

  /**
   *Text to display when there is no data. Defaults to global value in i18n translation configuration.
   */
  public emptyMessage: string = 'No records found.';

  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;

  /**
   *When present, custom value instead of predefined options can be entered using the editable input field.
   */
  public editable: boolean = false;

  /**
   *Maximum number of character allows in the editable input field.
   */
  public maxlength: number = null;

  /**
   *Target element to attach the overlay, valid values are body or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]=mydiv for a div element having #mydiv as variable name).
   */
  public appendTo: any = null;

  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;

  /**
   *Default text to display when no option is selected.
   */
  public placeholder: string = null;

  /**
   *Identifier of the accessible input element.
   */
  public inputId: string = null;

  /**
   *A property to uniquely identify a value in options.
   */
  public dataKey: string = null;

  /**
   *When present, it specifies that the component should automatically get focus on load.
   */
  public autofocus: boolean = false;

  /**
   *Applies focus to the filter element when the overlay is shown.
   */
  public autofocusFilter: boolean = false;

  /**
   *Clears the filter value when hiding the dropdown.
   */
  public resetFilterOnHide: boolean = false;

  /**
   *Icon class of the dropdown icon.
   */
  public dropdownIcon: string = 'pi pi-chevron-down';

  /**
   *Text to display when filtering does not return any results.
   */
  public emptyFilterMessage: string = 'No results found';

  /**
   *Whether to display the first item as the label if no placeholder is defined and value is null.
   */
  public autoDisplayFirst: boolean = true;

  /**
   *Whether to display options as grouped when nested options are provided.
   */
  public group: boolean = false;

  /**
   *When enabled, a clear icon is displayed to clear the value.
   */
  public showClear: boolean = false;

  /**
   *Base zIndex value to use in layering.
   */
  public baseZIndex: number = 0;

  /**
   *Whether to automatically manage layering.
   */
  public autoZIndex: boolean = true;

  /**
   *Transition options of the show animation.
   */
  public showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';

  /**
   *Transition options of the hide animation.
   */
  public hideTransitionOptions: string = '.1s linear';

  /**
   *Defines a string that labels the filter input.
   */
  public ariaFilterLabel: string = null;

  /**
   *Used to define a string that autocomplete attribute the current element.
   */
  public ariaLabel: string = null;

  /**
   *Advisory information to display in a tooltip on hover.
   */
  public tooltip: any = null;

  /**
   *Style class of the tooltip.
   */
  public tooltipStyleClass: string = null;

  /**
   *Position of the tooltip, valid values are right, left, top and bottom.
   */
  public tooltipPosition: string = 'top';

  /**
   *Type of CSS position.
   */
  public tooltipPositionStyle: string = 'absolute';

  /**
   *Whether the data should be loaded on demand during scroll.
   */
  public virtualScroll: boolean = false;

  /**
   *Height of an item in the list for VirtualScrolling.
   */
  public virtualScrollItemSize: number = null;

  /**
   *Whether to use the scroller feature. The properties of scroller component can be used like an object in it.
   */
  public virtualScrollOptions: ScrollerOptions = null;

  /**
   *Defines if data is loaded and interacted with in lazy manner.
   */
  public lazy: boolean = false;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<SelectConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setSelectConfigs]',
})
export class PrgSetSelectConfigsDirective {
  /**
   * constructor
   * @param {Dropdown} element
   * @param {AbstractLookupTableService} lookupTableService
   * @param {AbstractEntityTypeService} entityTypeService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {ObjectsUtilityService} objectsUtilityService
   */
  constructor(
    private element: Dropdown,
    private lookupTableService: AbstractLookupTableService,
    private entityTypeService: AbstractEntityTypeService,
    private cd: ChangeDetectorRef,
    private arrayUtilityService: ArrayUtilityService,
    private objectsUtilityService: ObjectsUtilityService
  ) {}
  /**
   * form
   */
  @Input() form: FormGroup;
  /**
   * get options from lookup table or entity type list
   * and assign configs to element
   */
  @Input() set setSelectConfigs(configs: SelectConfiguration | any) {
    switch (configs.referenceType) {
      case ReferenceType.LookupTable:
        if (configs.referenceName !== null && configs.referenceName.length) {
          this.lookupTableService
            .getLookupTableItemsByLookupTableNameAsync(configs.referenceName)
            .then((options) => {
              configs.options = this.objectsUtilityService.cloneObject(options);
              Object.assign(this.element, configs);

              /**
               * we need to set the value of the control
               * again because for some reason it stays null
               * and doesn't map the value selected by default,
               * we've already had to analyse this
               * and for now we can't understand why this behaviour
               */
              if (!this.form) {
                this.form = new FormGroup({
                  [configs.key]: new FormControl(),
                });
              }
              if (configs.value != null) {
                this.form.get(configs.key).setValue(configs.value);

                this.cd.detectChanges();
              }
            });
          this.cd.detectChanges();
        } else {
          this.lookupTableService.getAllLookupTableAsync().then((options) => {
            configs.options = this.objectsUtilityService.cloneObject(options);
            Object.assign(this.element, configs);

            /**
             * we need to set the value of the control
             * again because for some reason it stays null
             * and doesn't map the value selected by default,
             * we've already had to analyse this
             * and for now we can't understand why this behaviour
             */
            if (configs.value) {
              this.form.get(configs.key).setValue(configs.value);
            }
          });
        }
        break;

      case ReferenceType.EntityType:
        this.entityTypeService.getEntityTypeListAsync().then((value) => {
          configs.options = value;
          Object.assign(this.element, configs);
          if (configs.value) {
            this.form.get(configs.key).setValue(configs.value);
          }
        });
        this.cd.detectChanges();
        break;
      case ReferenceType.EntityTypeAllData:
        this.entityTypeService.getEntityTypeListAsync().then((value) => {
          let entities: EntityType[] = this.arrayUtilityService.clone(value);

          entities.map(async (entityType, index) => {
            let entityTypeAllData: EntityType =
              this.objectsUtilityService.cloneObject(
                await this.entityTypeService.getAllEntityTypeDataByName(
                  entityType.name
                )
              );

            entities[index].properties = this.objectsUtilityService.cloneObject(
              entityTypeAllData.properties
            );
            entities[index].attributes = this.objectsUtilityService.cloneObject(
              entityTypeAllData.attributes
            );
            entities[index].operations = this.objectsUtilityService.cloneObject(
              entityTypeAllData.operations
            );
            /* entities[index]['data'] = [];
            entities[index]['data'].push({
              subData: this.arrayUtilityService.clone(
                entityTypeAllData.operations
              ),
              label: 'operations',
            });

            entities[index]['data'].push({
              subData: this.arrayUtilityService.clone(
                entityTypeAllData.properties
              ),
              label: 'properties',
            });
            entities[index]['data'].push({
              subData: this.arrayUtilityService.clone(
                entityTypeAllData.attributes
              ),
              label: 'attributes',
            });*/
          });
          configs.options = entities;
          Object.assign(this.element, configs);

          if (configs.value) {
            this.form.get(configs.key).setValue(configs.value);
          }
        });
        break;
      default:
        Object.assign(this.element, configs);
        break;
    }
  }
}
