import { ConfigurationItem } from './configuration-item.model';

/**
 * Database interface that defines a mock
 * <br />database to be used while developing
 * <br />and/or testing the front-end.
 */
export interface Database {
  /**
   * Mock configuration items
   */
  ConfigurationItems: ConfigurationItem[];

  /* /!**
   * Mock Entity types
   *!/
  EntityTypes: EntityType[];

  /!**
   * Mock Entity Type Operations
   *!/
  EntityTypesOperations: EntityTypeOperation[];

  /!**
   * Mock Entity Type Properties
   *!/
  EntityTypesProperties: EntityTypeProperty[];

  /!**
   * Mock Entity Type Attributes
   *!/
  EntityTypesAttributes: EntityTypeAttribute[];*/
}

// GCR: por cada módulo criar uma pasta mock onde tem uma bd desse módulo apenas, ou seja,
// cada módulo ter a sua mock database para podermos, no futuro,
// separar os modulos em packages sem alterar código
// GCR: todos os ficheiros mock deve estar na pasta mock com a mesma estrutura dos reais
/**
 * MockDatabase object to be used while developing
 * <br /> and/or testing the front-end
 */
export const MockDatabase: Database = {
  /*  EntityTypes: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Customer',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      guiSettings: '',
      group: 'System',
      properties: null /!*definidas inicialmente e nunca mais alteradas*!/,
      attributes: null /!*são atributos que podem adicionados/editados*!/,
      operations: [
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          label:null,
          name: 'view',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '1',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'create',
          createdBy: null,
          label:null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '1',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },

        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'update',
          createdBy: null,
          modifiedBy: null,
          label:null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '1',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'delete',
          createdBy: null,
          label:null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '1',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
      ],
    },
    {
      id: '2',
      label: null,
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'User',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      guiSettings: '',
      group: 'System',
      properties: null,
      attributes: null,
      operations: [
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'view',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '2',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'create',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '2',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'update',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '2',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
      ],
    },
    {
      id: '3',
      label: null,
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Product',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      guiSettings: '',
      group: 'Store',
      properties: null,
      attributes: null,
      operations: [
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'view',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '3',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'create',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '3',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
      ],
    },
    {
      id: '4',
      label: null,
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Orders',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      guiSettings: '',
      group: 'Store',
      properties: null,
      attributes: null,
      operations: [
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'create',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '3',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'viewUserOrder',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '3',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'updateUserOrder',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          entityTypeId: '3',
          guiSettings: 'string',
          override: false,
          imports: null,
          sourceCode: 'string',
          httpVerb: HttpVerb.Get,
          order: 0,
        },
      ],
    },
  ],

  EntityTypesOperations: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'getCustomer',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
  ],
  EntityTypesProperties: [
    {
      id: '1',
      label: null,
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'customer name',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'customer name',
      dataTypeId: 'dataType.string',
      order: 1,
      reference: 'customer name',
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      label: null,
      operationId: null,
      name: 'customer Id',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'customer Id',
      dataTypeId: 'dataType.number',
      order: 1,
      reference: 'customer Id',
    },
    {
      id: '3',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'date of birth',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'date of birth',
      dataTypeId: 'dataType.date',
      order: 1,
      reference: 'date of birth',
    },

    {
      id: '4',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'user name',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'user name',
      dataTypeId: 'dataType.string',
      order: 1,
      reference: 'user name',
    },
    {
      id: '5',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'user Id',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'user Id',
      dataTypeId: 'dataType.number',
      order: 1,
      reference: 'user Id',
    },
    {
      id: '6',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'RegisterDate',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'RegisterDate',
      dataTypeId: 'dataType.date',
      order: 1,
      reference: 'RegisterDate',
    },
  ],
  EntityTypesAttributes: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      label: null,
      operationId: null,
      name: 'customer address',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'customer address',
      dataTypeId: 'dataType.string',
      order: 1,
      reference: 'customer address',
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'VAT',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'VAT',
      dataTypeId: 'dataType.number',
      order: 1,
      reference: 'VAT',
    },
    {
      id: '3',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'city',
      createdBy: null,
      label: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'city',
      dataTypeId: 'dataType.string',
      order: 1,
      reference: 'city',
    },
    {
      id: '4',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'user address',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'user address',
      dataTypeId: 'dataType.string',
      order: 1,
      reference: 'user address',
    },
    {
      id: '5',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'user date of birth',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'user date of birth',
      dataTypeId: 'dataType.date',
      order: 1,
      reference: 'user date of birth',
    },
    {
      id: '6',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'email',
      createdBy: null,
      label: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'email',
      dataTypeId: 'dataType.string',
      order: 1,
      reference: 'email',
    },
  ],*/

  /* TODO: MAP IDENTITY CONFIG TO CONFIGURATION ITEMS
    "Identity": {
      "DefaultEnabledUser": true, // user will be enabled on register
      "AllowSelfRegister": true,
      "LockoutEnabled": true,
      "CreateWorkspaceOnUserRegister": true,
      "SignIn": {
        "RequireEnabledUser": false,
        "RequireConfirmedAdmin": false,
        "RequireConfirmedEmail": true,
        "RequireConfirmedPhoneNumber": false
      },
      "Lockout": {
        "LockoutTimeSpan": "1.00:00:00" //lock out for 1 day
      },
      "Password": {
        "RequireDigit": true,
        "RequiredLength": 7,
        "RequireLowercase": true,
        "RequiredUniqueChars": 1,
        "RequireUppercase": true,
        "RequireNonAlphanumeric": true
      },
      "Callbacks": {
        "BaseUrl": "http://localhost:8100",
        "LoginUrl": "/auth/login",
        "ConfirmedAccountUrl": "/auth/confirm",
        "ResetPasswordUrl": "/auth/reset",
        "UnsubscribeUrl": "/auth/unsubscribe"
      },
      "Token": {
        "Audience": "Prg.Framework.Api.Audience",
        "Issuer": "Prg.Framework.Api.Issuer",
        "Seconds": 86400, // 24 hours
        "RefreshDuration": 24 // 24 hours
      }
    }
  */
  ConfigurationItems: [],
};
