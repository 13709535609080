import { Directive, Input } from '@angular/core';
import { SpeedDial } from 'primeng/speeddial';
import { PrgMenuItem } from '../../../../../../layouts/models/prg-menu-item';
import { BaseAction } from '../../../models/base-action';
import { ActionName } from '../../../models/constants/action-names';

/**
 * class SpeedDialConfiguration
 */
export class SpeedDialConfiguration extends BaseAction {
  /**
   * Control name
   */
  public controlName?: string = ActionName.SpeedDial;

  /**
   * items
   */
  public items: PrgMenuItem[];

  /**
   *Style class of the button element.
   */
  public buttonClassName: string = null;

  /**
   *Inline style of the button element.
   */
  public buttonStyle: object = null;

  /**
   *Template of button element.
   */
  public buttonTemplate: any = null;

  /**
   *Style class of the element.
   */
  public className: string = null;

  /**
   *Specifies the opening direction of actions. Valid values are 'up', 'down', 'left', 'right', 'up-left', 'up-right', 'down-left' and 'down-right'
   */
  public direction: string = 'up';

  /**
   *Whether the component is disabled.
   */
  public disabled: boolean = false;

  /**
   *Hide icon of the button element.
   */
  public hideIcon: string = null;

  /**
   *Whether the actions close when clicked outside.
   */
  public hideOnClickOutside: boolean = true;

  /**
   *Unique identifier of the element.
   */
  public id: string = null;

  /**
   *Whether to show a mask element behind the speeddial
   */
  public mask: boolean = false;

  /**
   *Style class of the mask element.
   */
  public maskClassName: string = null;

  /**
   *Inline style of the mask element.
   */
  public maskStyle: object = null;

  /**
   *MenuModel instance to define the action items.
   */
  public model: object = null;

  /**
   *Radius for *circle types.
   */
  public radius: number = 0;

  /**
   *Defined to rotate showIcon when hideIcon is not present.
   */
  public rotateAnimation: boolean = true;

  /**
   *Show icon of the button element.
   */
  public showIcon: string = 'pi pi-plus';

  /**
   *Inline style of the element.
   */
  public style: object = null;

  /**
   *Transition delay step for each action item.
   */
  public transitionDelay: number = 30;

  /**
   *Specifies the visibility of the overlay.
   */
  public visible: boolean = false;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<SpeedDialConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setSpeedDialConfigs]',
})
export class PrgSpeedDialConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: SpeedDial) {}

  /**
   * set button configs, assign configs received to element
   */
  @Input() set setSpeedDialConfigs(configs: SpeedDialConfiguration) {
    Object.assign(this.element, configs);
  }
}
