import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaginationResponse } from '../../../core/models/pagination-response';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import {
  EntityType,
  EntityTypeAttribute,
  EntityTypeOperation,
  EntityTypeProperty,
} from '../../models/entity-type.model';

import { AbstractEntityTypeService } from './abstract-entity-type.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class EntityTypeService extends AbstractEntityTypeService {
  /**
   * constructor
   * @param notificationsService notificationsService
   * @param translateService translateService
   */
  constructor(
    @Inject(NotificationsService) notificationsService: NotificationsService,
    @Inject(TranslateService) translateService: TranslateService
  ) {
    super(notificationsService, translateService);
  }

  /**
   * Get all Entity Types
   *
   * @Returns Entity types[]
   */
  public getEntityTypeListAsync(): Promise<EntityType[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Get entity type list with operation
   *
   *  * @Returns Entity types[]
   */
  public getEntityTypeListWithOperationAsync(): Promise<EntityType[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * save entity type
   * @param entityType
   * @return Entity type
   */
  public saveEntityTypeAsync(entityType: EntityType): Promise<EntityType> {
    throw new Error('Method not implemented.');
  }

  /**
   * get all operations of an entity
   * @param entityTypeId
   * @return EntityTypeOperation[]
   */
  public getAllOperationsByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeOperation[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * add or update entity type operation
   * @param entityTypeOperation
   * @return EntityTypeOperation
   */
  public saveEntityTypeOperationAsync(
    entityTypeOperation: EntityTypeOperation
  ): Promise<EntityTypeOperation> {
    throw new Error('Method not implemented.');
  }

  /**
   * get all attributes of an entity
   * @param entityTypeId
   * @return EntityTypeAttribute[]
   */
  public getAllAttributesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeAttribute[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * add or update entity type attribute
   * @param entityTypeAttribute
   * @return EntityTypeAttribute
   */
  public saveEntityTypeAttributeAsync(
    entityTypeAttribute: EntityTypeAttribute
  ): Promise<EntityTypeAttribute> {
    throw new Error('Method not implemented.');
  }

  /**
   * get all properties of an entity
   * @param entityTypeId
   * @return EntityTypeProperty[]
   */
  public getAllPropertiesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeProperty[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * add or update entity type property
   * @param EntityTypeProperty
   * @return EntityTypeProperty
   */
  public saveEntityTypePropertyAsync(
    entityTypeProperty: EntityTypeProperty
  ): Promise<EntityTypeProperty> {
    throw new Error('Method not implemented.');
  }

  /**
   * update entity type properties using patch logic
   * @param entityTypesPropertiesToPatch
   * @return EntityTypeProperty[]
   */
  public updateEntityTypePropertiesAsync(
    entityTypesPropertiesToPatch: Map<string, any>
  ): Promise<EntityTypeProperty[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * update entity type attributes using patch logic
   * @param entityTypesAttributesToPatch
   * @return EntityTypeAttribute[]
   */
  public updateEntityTypeAttributesAsync(
    entityTypesAttributesToPatch: Map<string, any>
  ): Promise<EntityTypeAttribute[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * this service returns all the data (properties, attributes and operations)
   * of a given entity type (by entity name)
   * @param entityTypeName
   */
  public getAllEntityTypeDataByName(
    entityTypeName: string
  ): Promise<EntityType> {
    throw new Error('Method not implemented.');
  }

  /**
   * this service returns an array of elements
   * of the entity sent as a parameter,
   *  based on the filter it receives as a parameter.
   * @param entityTypeName
   * @param FilterGroup
   */
  public getEntityTypeElements(
    entityTypeName: string,
    FilterGroup: any
  ): Promise<PaginationResponse> {
    throw new Error('Method not implemented.');
  }
  /*
   * Get all properties and attributes by entity type id
   * @param {string} entityTypeId
   * @returns {Promise<EntityTypeProperty[]>}
   */
  public getAllPropertiesAndAttributesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeProperty[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * This service return a element from a entity type list by id
   * @param entityTypeName
   * @param elementId
   */
  public getEntityTypeElementById(
    entityTypeName: string,
    elementId: string
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  /**
   * this service excute in database the operation received as parameter
   * @param entityName
   * @param operation
   * @param entity
   */
  public executeAction(
    entityName: string,
    operation: EntityTypeOperation,
    entity: any
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
