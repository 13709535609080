import { Directive, Input } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Input Text
 */
export class DateTimeConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.inputDateTime;
  /**
   *Set the date to highlight on first opening if the field is blank.
   */
  public defaultDate: Date = null;

  /**
   *Defines the quantity of the selection, valid values are single, multiple and range.
   */
  public selectionMode: string = 'single';

  /**
   *Inline style of the component.
   */
  public style: string = null;

  /**
   *Style class of the component.
   */
  public styleClass: string = null;

  /**
   *Inline style of the input field.
   */
  public inputStyle: string = null;

  /**
   *Style class of the input field.
   */
  public inputStyleClass: string = null;

  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;

  /**
   *Name of the input element.
   */
  // public name: string = null;
  /*
  /!**
   *When specified, disables the component.
   *!/
  public disabled: boolean = false;*/ //Property already on Base Field

  /**
   *Format of the date which can also be defined at locale settings.
   */
  public dateFormat: string = 'mm/dd/yy';

  /**
   *When enabled, displays the calendar as inline. Default is false for popup mode.
   */
  public inline: boolean = false;

  /**
   *Whether to display dates in other months (non-selectable) at the start or end of the current month. To make these days selectable use the selectOtherMonths option.
   */
  public showOtherMonths: boolean = true;

  /**
   *Whether days in other months shown before or after the current month are selectable. This only applies if the showOtherMonths option is set to true.
   */
  public selectOtherMonths: boolean = false;

  /**
   *When enabled, displays a button with icon next to input.
   */
  public showIcon: boolean = false;

  /**
   *When disabled, datepicker will not be visible with input focus.
   */
  public showOnFocus: boolean = true;

  /**
   *When enabled, calendar will show week numbers.
   */
  public showWeek: boolean = false;

  /**
   *Icon of the calendar button.
   */
  public icon: string = 'pi pi-calendar';

  /**
   *Target element to attach the overlay, valid values are body or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]=mydiv for a div element having #mydiv as variable name).
   */
  public appendTo: any = null;

  /**
   *When specified, prevents entering the date manually with keyboard.
   */
  public readonlyInput: boolean = null;

  /**
   *The cutoff year for determining the century for a date.
   */
  public shortYearCutoff: string = '10';

  /**
   *The minimum selectable date.
   */
  public minDate: Date = null;

  /**
   *The maximum selectable date.
   */
  public maxDate: Date = null;

  /**
   *Array with dates that should be disabled (not selectable).
   */
  public disabledDates: Array<Date> = null;

  /**
   *Array with weekday numbers that should be disabled (not selectable).
   */
  public disabledDays: Array<number> = null;

  /**
   *Whether the month should be rendered as a dropdown instead of text.
   */
  public monthNavigator: boolean = false;

  /**
   *Whether the year should be rendered as a dropdown instead of text.
   */
  public yearNavigator: boolean = false;

  /**
   *The range of years displayed in the year drop-down in (nnnn:nnnn) format such as (2000:2020).
   */
  public yearRange: string = null;

  /**
   *Whether to display timepicker.
   */
  public showTime: boolean = false;

  /**
   *Specifies 12 or 24 hour format.
   */
  public hourFormat: string = '24';

  /**
   *Whether to display timepicker only.
   */
  public timeOnly: boolean = false;

  /**
   *Separator of time selector.
   */
  public timeSeparator: string = ':';

  /**
   *Type of the value to write back to ngModel, default is date and alternative is string.
   */
  public dataType: string = 'date';

  /**
   *When present, it specifies that an input field must be filled out before submitting the form.
   */
  public required: boolean = false;

  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;

  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;

  /**
   *Defines a string that labels the icon button for accessibility.
   */
  public iconAriaLabel: string = null;

  /**
   *Whether to show the seconds in time picker.
   */
  public showSeconds: boolean = false;

  /**
   *Hours to change per step.
   */
  public stepHour: number = 1;

  /**
   *Minutes to change per step.
   */
  public stepMinute: number = 1;

  /**
   *Seconds to change per step.
   */
  public stepSecond: number = 1;

  /**
   *Maximum number of selectable dates in multiple mode.
   */
  public maxDateCount: number = null;

  /**
   *Whether to display today and clear buttons at the footer
   */
  public showButtonBar: boolean = false;

  /**
   *Style class of the today button.
   */
  public todayButtonStyleClass: string = 'p-secondary-button';

  /**
   *Style class of the clear button.
   */
  public clearButtonStyleClass: string = 'p-secondary-button';

  /**
   *Base zIndex value to use in layering.
   */
  public baseZIndex: number = 0;

  /**
   *Whether to automatically manage layering.
   */
  public autoZIndex: boolean = true;

  /**
   *Style class of the datetimepicker container element.
   */
  public panelStyleClass: string = null;

  /**
   *Inline style of the datetimepicker container element.
   */
  public panelStyle: object = null;

  /**
   *Keep invalid value when input blur.
   */
  public keepInvalid: boolean = false;

  /**
   *Whether to hide the overlay on date selection.
   */
  public hideOnDateTimeSelect: boolean = true;

  /**
   *Number of months to display.
   */
  public numberOfMonths: number = 1;

  //TODO: see this
  /**
   *Type of view to display, valid values are date for datepicker and month for month picker.
   */
  public view: 'date' | 'month' | 'year' = 'date';

  /**
   *Separator for multiple selection mode.
   */
  public multipleSeparator: string = ',';

  /**
   *Separator for joining start and end dates on range selection mode.
   */
  public rangeSeparator: string = '-';

  /**
   *When enabled, calendar overlay is displayed as optimized for touch devices.
   */
  public touchUI: boolean = false;

  /**
   *When enabled, can only focus on elements inside the calendar.
   */
  public focusTrap: boolean = true;

  /**
   *Transition options of the show animation.
   */
  public showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';

  /**
   *Transition options of the hide animation.
   */
  public hideTransitionOptions: string = '.1s linear';

  /**
   *Defines the first of the week for various date calculations.
   */
  public firstDayOfWeek: number = 0;

  /**
   *When enabled, a clear icon is displayed to clear the value.
   */
  public showClear: boolean = false;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<DateTimeConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setDateTimeConfigs]',
})
export class PrgSetDateTimeConfigsDirective {
  /**
   * constructor
   * @param cal
   */
  constructor(private cal: Calendar) {}

  /**
   * input data time configs and assign configs to element
   */
  @Input() set setDateTimeConfigs(configs: DateTimeConfiguration | any) {
    Object.assign(this.cal, configs);
  }
}
