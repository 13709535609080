import { BaseEntity } from '../../core/models/base-entity.model';
import {
  PrgBarChartComponent,
  PrgDoughnutChartComponent,
  PrgLineChartComponent,
  PrgTableComponent,
} from '../components/dashboard-items';

/**
 * Enum for dynamic components to be used on dashboards
 */
export enum ComponentTypeEnum {
  BarChart = 'Bar-Chart',
  LineChart = 'Line-Chart',
  DoughnutChart = 'Doughnut-Chart',
  Table = 'Table',
}

/**
 * Map name of componentType to classes
 */
export const MapComponentTypesToClass: Map<string, any> = new Map<
  ComponentTypeEnum,
  any
>([
  [ComponentTypeEnum.BarChart, PrgBarChartComponent],
  [ComponentTypeEnum.LineChart, PrgLineChartComponent],
  [ComponentTypeEnum.DoughnutChart, PrgDoughnutChartComponent],
  [ComponentTypeEnum.Table, PrgTableComponent],
]);

/**
 * Dashboard Class
 */
export class Dashboard extends BaseEntity {
  /**
   * Complete path for configuration items tree
   * @type {string}
   */
  path: string;
  /**
   * Read only
   * @type {boolean}
   */
  isPublic: boolean = true;
  /**
   * An array of entity types
   * @type {string[]}
   */
  entityTypeIds: string[]; // db JSON string
  /**
   * Settings of the dashboard
   * @type {string}
   */
  settings: string;
  /**
   * The items of dashboard
   * @type {DashboardItem[]}
   */
  items: DashboardItem[];

  /**
   * Cosntructor
   * @param {Partial<Dashboard>} init
   */
  constructor(init?: Partial<Dashboard>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Dashboard Items CLass
 */
export class DashboardItem extends BaseEntity {
  /**
   *  number of columns in of the item on dashboard
   * @type {number}
   */
  cols: number;
  /**
   * number of rows in of the item on dashboard
   * @type {number}
   */
  rows: number;
  /**
   * x position in dashboard
   * @type {number}
   */
  x: number;
  /**
   *  y position in dashboard
   * @type {number}
   */
  y: number;
  /**
   * Component type
   * @type {string}
   */
  componentType: string;
  /**
   * Dashboard Id
   * @type {string}
   */
  dashboardId: string;
  /**
   * Override GuiSettings settings of componentType
   * @type {string}
   */
  settings: string;

  /**
   * Constructor
   * @param {Partial<DashboardItem>} init
   */
  constructor(init?: Partial<DashboardItem>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Component Type Class
 */
export class ComponentType extends BaseEntity {
  /**
   * Gui Settings
   * @type {string}
   */
  generalSettings: any;
  /**
   * Image of Component type
   * @type {string}
   */
  imageUrl: string;
  /**
   * Type of component
   * @type {ComponentTypeEnum}
   */
  type: ComponentTypeEnum;

  /**
   * Label for translation purpose
   * @type {string}
   */
  label: string;

  /**
   * Constructor
   * @param {Partial<DashboardItem>} init
   */
  constructor(init?: Partial<ComponentType>) {
    super();
    Object.assign(this, init);
  }
}
