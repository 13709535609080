import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';
import { AbstractAuthService } from '../../../auth/services/auth/abstract-auth.service';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { PrgMenuItem } from '../../models/prg-menu-item';
import { MainLayoutService } from '../../services/main-layout.service';
import {
  LANGUAGE_MENU_HEADER,
  USER_MENU_HEADER,
} from '../menus-structure/menus-header-structure';

//TODO: It's missing a way to save user and language preferences (Session / Locale Storage)
/**
 * Header Component
 */
@Component({
  selector: 'prg-header',
  templateUrl: './prg-header.component.html',
  styleUrls: ['./prg-header.component.scss'],
})
export class PrgHeaderComponent implements OnInit, OnDestroy {
  /**
   * Property of the class with type string which bind to menu logo.
   */
  @Input() imagePathLogo: string = '/assets/images/pragma_h.png';

  /**
   * Property of the class with type string which bind to user default image
   */
  @Input() imagePathUserDefault: string = '/assets/images/Profile_image.jpg';
  /**
   * Property of the class with type string which bind to user image
   */
  public imagePathUser: string;

  /**
   * Current logged user
   * @type {User}
   */
  public user: User;
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * A class property that defines if header is display
   * @type {boolean}
   */
  public displayHeader: boolean = true;

  /**
   * Menu Items data of user menu
   * @type {PrgMenuItem[]}
   */
  @Input() public menuItemsUser: PrgMenuItem[] =
    this.arrayUtilityService.clone(USER_MENU_HEADER);

  /**
   * Menu Items data of language menu
   * @type {PrgMenuItem[]}
   */
  @Input() public menuItemsLanguage: PrgMenuItem[] =
    this.arrayUtilityService.clone(LANGUAGE_MENU_HEADER);

  /**
   * Constructor
   * @param {AbstractAuthService} authService
   * @param {ObjectsUtilityService} objectUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {TranslateService} translateService
   * @param {MainLayoutService} mainLayoutService
   */
  constructor(
    private authService: AbstractAuthService,
    private objectUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private translateService: TranslateService,
    private mainLayoutService: MainLayoutService
  ) {}

  /**
   * ngOnInit
   *
   * @returns {Promise<void>}
   */
  public async ngOnInit() {
    this.subscription.push(
      this.authService.getLoggedUserObservable().subscribe((user) => {
        this.user = this.objectUtilityService.cloneObject(user);

        if (this.user && this.user.token !== null) {
          this.imagePathUser = this.user.token.imageUrl;
        } else {
          this.imagePathUser = null;
        }
        this.initMenuUser();
      })
    );

    this.subscription.push(
      this.mainLayoutService
        .getDisplayHeaderStateObservable()
        .subscribe((display) => {
          this.displayHeader = display;
        })
    );

    await this.initMenuLanguage();
  }

  /**
   * Function responsible to reload menu data when user change language
   * @param {string} lang
   * @returns {Promise<void>}
   */
  public async changeLanguage(lang: string) {
    this.translateService.use(lang);
    await this.initMenuLanguage();
    await this.initMenuUser();
  }

  /**
   * ngOnDestroy
   *
   * Unsubscribe the user logged observable
   */
  public ngOnDestroy(): void {
    this.subscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscription = [];
  }

  /**
   * This method is responsible for calling the functions that will build the language menu
   * @returns {Promise<void>}
   */
  public async initMenuLanguage() {
    this.menuItemsLanguage = this.arrayUtilityService.clone(
      this.mainLayoutService.mapMenuItemsHelpers(this.menuItemsLanguage, this)
    );
    this.menuItemsLanguage = this.arrayUtilityService.clone(
      await this.mainLayoutService.getHeaderMenuItemsWithLabelsAsync(
        this.menuItemsLanguage
      )
    );
  }

  /**
   * This method is responsible for calling the functions that will build the user menu
   * @returns {Promise<void>}
   */
  public async initMenuUser() {
    this.menuItemsUser = this.arrayUtilityService.clone(
      this.mainLayoutService.mapMenuItemsHelpers(this.menuItemsUser, this)
    );
    this.menuItemsUser = this.arrayUtilityService.clone(
      await this.mainLayoutService.getHeaderMenuItemsWithLabelsAsync(
        this.menuItemsUser
      )
    );
  }

  /**
   * This method is responsible for calling the function that will log out user
   * @returns {Promise<void>}
   */
  private onClickLogout = async () => {
    if (await this.authService.logoutAsync()) {
      //TODO Redirect after logout success. Route?
    }
  };
}
