import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrgError } from '../../../../core/models/error.model';
import { PrgSucess } from '../../../../core/models/success.model';
import { IsLoadingDataService } from '../../../../core/services/is-loading-data/isloadingdata.service';
import { NotificationsService } from '../../../../core/services/notifications/notifications.service';
import { UtilityService } from '../../../../core/services/utility/utility.service';
import { Role } from '../../../models/role.model';
import { AbstractRolesScopesService } from '../../../services/roles-scopes/abstract-roles-scopes.service';
import { RolesScopesBDMOCk } from '../../rolesScopesDB.mock';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * MOCK MockRolesScopesService implementation
 */
export class MockRolesScopesService extends AbstractRolesScopesService {
  /**
   * default time in seconds
   */
  private sleepTimeSec = 4;

  /**
   * constructor
   */
  constructor(
    private notificationsService: NotificationsService,
    private utilityService: UtilityService,
    private isLoadingDataService: IsLoadingDataService,
    @Inject(TranslateService) translateService: TranslateService
  ) {
    super(translateService);
  }

  /**
   * Get all Roles Async
   *
   * @returns Roles array promisse
   */
  public getAllRolesAsync(): Promise<Role[]> {
    return new Promise<Role[]>(async (resolve, reject) => {
      this.isLoadingDataService.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      this.isLoadingDataService.hide();

      const roles = [...RolesScopesBDMOCk.Roles];

      if (roles.length > 0) {
        const rolesTranslation = <Role[]>await this.getTranslationsAsync(roles);
        resolve(rolesTranslation);
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorGetScopesList',
          })
        );
      }
    });
  }

  /**
   * create role Async
   *
   * @param roleName
   * @returns Boolean promise, true when successful
   */
  public async createRoleAsync(role: Role): Promise<Role> {
    this.isLoadingDataService.show();
    await this.utilityService.sleepSecAsync(this.sleepTimeSec);
    this.isLoadingDataService.hide();
    const newRole: Role = {
      id: this.utilityService.newGuid(),
      universalStateId: '0',
      transactionId: null,
      operationId: null,
      name: role.name,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      scopes: role.scopes,
      label: null,
    };

    return new Promise<Role>((resolve) => {
      if (RolesScopesBDMOCk.Roles.push(newRole)) {
        resolve(newRole);
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'success',
            detailKey: 'roleSuccessAdded',
          })
        );
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorOnAddRole',
          })
        );
      }
    });
  }

  /**
   * update role
   *
   * @param role role with new data
   * @returns booler promise, true when successful
   */
  public updateRole(role: Role): Promise<Role> {
    return new Promise<Role>(async (resolve, reject) => {
      this.isLoadingDataService.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      this.isLoadingDataService.hide();
      const roles = [...RolesScopesBDMOCk.Roles];
      const roleToUpdate = roles.find((r) => r.id === role.id);
      if (roleToUpdate) {
        roleToUpdate.scopes = role.scopes;
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'success',
            detailKey: 'roleSuccessUpdate',
          })
        );
        resolve(roleToUpdate);
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorUpdateRole',
          })
        );
      }
    });
  }
}
