import { TranslateService } from '@ngx-translate/core';
import { Role } from '../../models/role.model';

/**
 * AbstractRolesScopesService class that defines an
 * <br />abstract roles and scopes service to
 * <br />be extended by the mock and real
 * <br />roles and scopes services
 */
export abstract class AbstractRolesScopesService {
  /**
   * constructor
   * @param translationService
   */

  constructor(private translationService: TranslateService) {}
  /**
   * Get all Roles
   */
  public abstract getAllRolesAsync(): Promise<Role[]>;

  /**
   * Create Role Async
   *
   * @param {string} roleName  The new Role name
   *
   * @Returns boolean
   */
  public abstract createRoleAsync(role: Role): Promise<Role>;

  /**
   * Update Scope
   *
   * @param role
   *
   * @Returns boolean
   */
  public abstract updateRole(role: Role): Promise<Role>;

  /* this service get translations for all entity types,properties and attributes
   * @param {EntityType[] | EntityType} entities
   * @returns {Promise<EntityType[] | EntityType>}
   * @protected
   */
  protected async getTranslationsAsync(
    roles: Role[] | Role
  ): Promise<Role[] | Role> {
    return new Promise<Role[] | Role>((resolve, reject) => {
      if (Array.isArray(roles)) {
        //Translate Entities
        let rolesList = <Role[]>roles;
        rolesList.map(async (role: Role) => {
          role.label = await this.translationService
            .get('roles.' + role.name.toLowerCase() + '.label')
            .toPromise();
        });
        resolve(roles);
      }
    });
  }
}
