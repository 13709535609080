import { PrgAutoCompleteComponent } from '../../dynamic-form-fields/fields/auto-complete/auto-complete.component';
import { PrgCascadeSelectComponent } from '../../dynamic-form-fields/fields/cascade-select/cascade-select..component';
import { PrgCheckboxComponent } from '../../dynamic-form-fields/fields/checkbox/checkbox.component';
import { PrgChipsComponent } from '../../dynamic-form-fields/fields/chips/chips.component';
import { PrgColorPickerComponent } from '../../dynamic-form-fields/fields/color-picker/color-picker.component';
import { PrgDateTimeComponent } from '../../dynamic-form-fields/fields/input-date-time/date-time.component';
import { PrgInputMaskComponent } from '../../dynamic-form-fields/fields/input-mask/input-mask.component';
import { PrgInputNumberComponent } from '../../dynamic-form-fields/fields/input-number/input-number.component';
import { PrgInputSwitchComponent } from '../../dynamic-form-fields/fields/input-switch/input-switch.component';
import { PrgInputTextAreaComponent } from '../../dynamic-form-fields/fields/input-text-area/input-text-area.component';
import { PrgInputTextComponent } from '../../dynamic-form-fields/fields/input-text/input-text.component';
import { PrgKnobComponent } from '../../dynamic-form-fields/fields/knob/knob.component';
import { PrgListBoxComponent } from '../../dynamic-form-fields/fields/list-box/list-box.component';
import { PrgMultiSelectComponent } from '../../dynamic-form-fields/fields/multiselect/multi-select.component';
import { PrgPasswordComponent } from '../../dynamic-form-fields/fields/password/password.component';
import { PrgRadioButtonComponent } from '../../dynamic-form-fields/fields/radio-button/radio-button.component';
import { PrgRatingComponent } from '../../dynamic-form-fields/fields/rating/rating.component';
import { PrgSelectButtonComponent } from '../../dynamic-form-fields/fields/select-button/select-button.component';
import { PrgSelectComponent } from '../../dynamic-form-fields/fields/select/select.component';
import { PrgSliderComponent } from '../../dynamic-form-fields/fields/slider/slider.component';
import { PrgToggleButtonComponent } from '../../dynamic-form-fields/fields/toggle-button/toggle-button.component';
import { PrgTriStateCheckboxComponent } from '../../dynamic-form-fields/fields/tri-state-checkbox/tri-state-checkbox.component';

/**
 * list of types of fields
 */
export const ControlTypes = {
  inputText: PrgInputTextComponent,
  inputNumber: PrgInputNumberComponent,
  inputDateTime: PrgDateTimeComponent,
  inputPassword: PrgPasswordComponent,
  dropdown: PrgSelectComponent,
  multiSelect: PrgMultiSelectComponent,
  checkbox: PrgCheckboxComponent,
  inputTextArea: PrgInputTextAreaComponent,
  triStateCheckbox: PrgTriStateCheckboxComponent,
  selectButton: PrgSelectButtonComponent,
  radioButton: PrgRadioButtonComponent,
  colorPicker: PrgColorPickerComponent,
  inputMask: PrgInputMaskComponent,
  slider: PrgSliderComponent,
  inputSwitch: PrgInputSwitchComponent,
  rating: PrgRatingComponent,
  knob: PrgKnobComponent,
  chips: PrgChipsComponent,
  toggleButton: PrgToggleButtonComponent,
  listBox: PrgListBoxComponent,
  cascadeSelect: PrgCascadeSelectComponent,
  autoComplete: PrgAutoCompleteComponent,
};
