import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { BaseField } from '../../../core/components/dynamic-form/models/base-field';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { EntityType } from '../../models/entity-type.model';
import { PrgEntityTypesConfig } from '../../models/prg-entity-types-config';
import { PRG_ENTITY_TYPES_CONFIG } from '../../services/entity-types-configuration/prg-entity-types-configuration.service';
import { AbstractEntityTypeService } from '../../services/entity-types/abstract-entity-type.service';
import { PrgEntityTypeComponent } from '../entity-type/prg-entity-type.component';

/**
 * component List entity types
 */
@Component({
  selector: 'prg-list-entity-types',
  templateUrl: './prg-list-entity-types.component.html',
  styleUrls: ['./prg-list-entity-types.component.scss'],
  providers: [DialogService],
})

/**
 * class PrgListEntityTypesComponent
 */
export class PrgListEntityTypesComponent implements OnInit, OnDestroy {
  /**
   * tree data
   */
  public treeData: TreeNode[];
  /**
   * table columns
   */
  public tableEntityTypeColumns: BaseField[] =
    this.prgEntityTypesConfig.entityTypeTableColumns;

  /**
   * subscriptions
   */
  private subscriptions: Subscription[] = [];
  /**
   * property name to group
   */
  private readonly GROUP_BY_PROPERTY_NAME: string = 'group';

  /**
   * constructor
   * @param entityTypeService
   * @param arrayUtilityService
   * @param prgEntityTypesConfig
   * @param dialogService dialogService
   * @param objectsUtilityService
   */
  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private arrayUtilityService: ArrayUtilityService,
    @Inject(PRG_ENTITY_TYPES_CONFIG)
    private prgEntityTypesConfig: PrgEntityTypesConfig,
    public dialogService: DialogService,
    private objectsUtilityService: ObjectsUtilityService
  ) {}

  /**
   * dialog ref
   */
  public ref: DynamicDialogRef;

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.entityTypeService.getEntityTypeListAsync().then(() => {
      this.subscriptions.push(
        this.entityTypeService
          .getElementObservableByKey('product')
          .subscribe((value) => {})
      );
    });

    this.subscriptions.push(
      this.entityTypeService
        .getElementsListObservable()
        .subscribe((entityTypeList: EntityType[]) => {
          this.treeData = this.arrayUtilityService.groupByField(
            entityTypeList,
            this.GROUP_BY_PROPERTY_NAME
          );
        })
    );
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];
  }

  /**
   * this function is responsible for generating and sending the necessary data to the dialog
   * @param entityType
   */
  public showEntityTypeData(entityType: EntityType) {
    let entityTypeAux: EntityType;
    if (entityType) {
      entityTypeAux = this.objectsUtilityService.cloneObject(entityType);
    } else {
      entityTypeAux = new EntityType();
    }

    this.ref = this.dialogService.open(PrgEntityTypeComponent, {
      data: {
        entityType: entityTypeAux,
        viewMode: entityTypeAux.id ? ViewMode.Read : ViewMode.Edit,
      },
      width: '90vw',
      showHeader: true,
      contentStyle: { 'max-height': '70%', overflow: 'auto' },
      baseZIndex: 10000,
      //dismissableMask: true,
    });
  }
}
