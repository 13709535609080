<div class="grid-nogutter max-w-max shadow-5" [ngClass]="headerDisplay?'sidebar':'sidebar-no-header'" >
  <aside class="shadow-5 " >
    <div  class="flex flex-column "
          [@OnExpandSideBar]="mainMenuExpanded?'open':'closed'" [ngClass]="headerDisplay?'sidebar':'sidebar-no-header'">
      <div class="pt-2" *ngIf="!mainMenuExpanded">
        <p-tieredMenu  [styleClass]="'collapseSideBar'"   [autoDisplay]="true" [model]="mainMenuIcons" ></p-tieredMenu>
      </div>
      <section  [@OnExpandSideBarText]="mainMenuExpanded?'show-content':'hide-content'">
        <div class="pt-2" *ngIf="mainMenuExpanded">
          <p-tieredMenu   [autoDisplay]="true" [model]="mainMenu"></p-tieredMenu>
        </div>
      </section>
      <div class="flex flex-column pb-2 flex-grow-1 justify-content-end" [ngClass]="mainMenuExpanded?'align-items-end':'align-items-center'">

          <button (click)=" onShowHideSideBarDetails()" pButton pRipple type="button" icon="{{mainMenuExpanded?'pi pi-angle-double-left':'pi pi-angle-double-right'}} text-2xl" class="p-button-rounded p-button-text p-button-plain"></button></div>
      </div>



  </aside>
</div>


<!--<div class="grid-nogutter max-w-max sidebar shadow-5" >
  <aside class="shadow-5" >
    <div  class="flex flex-column h-screen py-2"
          [@OnExpandSideBar]="mainMenuExpanded?'open':'closed'">
      <div class="pt-3" *ngIf="!mainMenuExpanded">
        <p-tieredMenu  [styleClass]="'collapseSideBar'"   [autoDisplay]="true" [model]="mainMenuIcons" ></p-tieredMenu>
      </div>
      <section  [@OnExpandSideBarText]="mainMenuExpanded?'show-content':'hide-content'">
        <div class="flex flex-column align-self-start pt-3" *ngIf="mainMenuExpanded">
          <p-tieredMenu   [autoDisplay]="true" [model]="mainMenu"></p-tieredMenu>
        </div>
      </section>
      <div class="flex flex-column h-screen justify-content-end">
        <div class="flex " [ngClass]="mainMenuExpanded?'justify-content-end':'align-self-center'" style="padding-bottom: 70px">
          <button (click)=" onShowHideSideBarDetails()" pButton pRipple type="button" icon="{{mainMenuExpanded?'pi pi-angle-double-left':'pi pi-angle-double-right'}} text-2xl" class="p-button-rounded p-button-text p-button-plain"></button></div>
      </div>
    </div>


  </aside>
</div>-->





