import { PrgMenuItem } from '../../models/prg-menu-item';

/**
 * Constant that defines the menu items for main menu
 */
export const SIDEBAR_MENU: PrgMenuItem[] = [
  {
    id: 'home',
    icon: 'pi pi-home',
    routerLink: [''],
    routerLinkActiveOptions: { exact: true },
  },
  {
    id: 'auth',
    icon: 'pi pi-user',

    items: [
      {
        icon: 'pi pi-sign-in',
        id: 'login',
        routerLinkString:
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingLoginPath]",
        routerLinkActiveOptions: { exact: true },
        visibleString: '!this.user',
      },
      {
        icon: 'pi pi-book',
        id: 'signup',
        routerLinkString:
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingSignupPath]",
        routerLinkActiveOptions: { exact: true },
        visibleString: '!this.user',
      },
      {
        icon: 'pi pi-key',
        id: 'request-pass',
        routerLinkString:
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingRequestPasswordPath]",
        routerLinkActiveOptions: { exact: true },
      },
      {
        icon: 'pi pi-key',
        id: 'reset-pass',
        routerLinkString:
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingResetPasswordPath]",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
  {
    id: 'entity-types',
    icon: 'pi pi-database',
    routerLinkString: "['/' + this.entityTypesConfig.entityTypeBasePath]",
    routerLinkActiveOptions: { exact: true },
  },
  {
    id: 'lookup-table',
    icon: 'pi pi-map',
    routerLinkString: "['/' + this.lookupTableConfig.lookupTableBasePath]",
    routerLinkActiveOptions: { exact: true },
  },
  {
    id: 'queries',
    icon: 'pi pi-filter',
    routerLinkString: "['/' + this.queriesConfig.queriesRoutingPath]",
    routerLinkActiveOptions: { exact: true },
  },
  {
    id: 'roles-scopes',
    icon: 'pi pi-user-edit',
    routerLinkString: "['/' + this.rolesScopesConfig.rolesScopesBasePath]",
    routerLinkActiveOptions: { exact: true },
  },
  {
    id: 'terms',
    icon: 'pi pi-book',
    routerLinkString: "['/' + this.termsConfig.termsOfUseRoutingPath]",
    routerLinkActiveOptions: { exact: true },
  },
  {
    id: 'config-items',
    icon: 'pi pi-cog',

    items: [
      {
        icon: 'pi pi-user-edit',
        id: 'admin',
        routerLinkString:
          "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingAdminPath]",
        routerLinkActiveOptions: { exact: true },
      },
      {
        icon: 'pi pi-user',
        id: 'user',
        routerLinkString:
          "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingUserPath]",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
  {
    id: 'dashboard',
    icon: 'pi pi-chart-bar',

    items: [
      {
        icon: 'pi pi-plus-circle',
        id: 'create',
        routerLinkString:
          "['/' + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardCreateRoutingPath]",
        routerLinkActiveOptions: { exact: true },
      },
      {
        icon: 'pi pi-list',
        id: 'list',
        routerLinkString:
          "['/' + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardListRoutingPath]",
        routerLinkActiveOptions: { exact: true },
      },
      {
        icon: 'pi pi-eye',
        id: 'display',
        routerLinkString:
          "['/' + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardDisplayRoutingPath]",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
];
