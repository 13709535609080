import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Role } from '../../models/role.model';
import { AbstractRolesScopesService } from '../../services/roles-scopes/abstract-roles-scopes.service';

/**
 * select role component
 */
@Component({
  selector: 'prg-select-role',
  templateUrl: './prg-select-role.component.html',
  styleUrls: ['./prg-select-role.component.scss'],
})
export class PrgSelectRoleComponent implements OnInit {
  /**
   * roles list
   */
  @Input() rolesList!: Role[];

  /**
   * allow multi select or not
   */
  @Input() multipleSelect: boolean = false;

  /**
   * field that should be used as an option
   */
  @Input() optionLabel: string = 'label';

  /**
   * show search bar or not
   */
  @Input() showHeader: boolean = true;

  /**
   * allow button to confirm selection or not
   */
  @Input() useButton: boolean = false;

  /**
   * default value selected to single selections
   */
  @Input('defaultSelected') set defaultSelected(value: Role) {
    if (value) {
      this.roleSelected = value;
    }
  }

  /**
   * Output with the selected roles
   */
  @Output() onSubmitRolesSelected = new EventEmitter<any>();

  /**
   * Item selectable, if multiple is null else is 1
   */
  public maxItemsSelectable: number = 1;

  /**
   * roles selected when is multiple
   */
  public rolesSelected!: Role[];

  /**
   * Role selected when is single
   */
  public roleSelected!: Role;

  /**
   * Constructor
   * @param rolesScopesService
   */
  constructor(private rolesScopesService: AbstractRolesScopesService) {}

  /**
   * ngOnInit
   *
   * set value to [rolesList]{@link #rolesList}
   *
   * call method [getAllRolesAsync]{@link /classes/AbstractRolesScopesService.html#getAllRolesAsync} from services
   */
  ngOnInit() {
    if (!this.rolesList) {
      this.getAllRoles();
    }
  }

  /**
   *this function get all roles
   */
  private async getAllRoles() {
    this.rolesList = await this.rolesScopesService.getAllRolesAsync();
  }
  w;

  /**
   * This function fire [onSubmitRolesSelected]{@link #onSubmitRolesSelected} output
   */
  public sendRolesSelected() {
    if (this.multipleSelect && this.rolesSelected.length > 0) {
      this.onSubmitRolesSelected.emit(this.rolesSelected);
    } else if (!this.multipleSelect && this.roleSelected) {
      this.onSubmitRolesSelected.emit(this.roleSelected);
    }
  }
}
