import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * This class define methods to validate passwords
 */
export class PrgAuthCustomPasswordRequirements {
  /**
   * Method to validate passwords through Regular Expression (RegExp)
   * @param {RegExp} regex
   * @param {ValidationErrors} error
   * @param {boolean} passwordRequirements
   * @returns {ValidatorFn}
   */
  public static patternValidator(
    regex: RegExp,
    error: ValidationErrors,
    passwordRequirements: boolean
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value || !passwordRequirements) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  /**
   * Method to validate passwords with a number of unique chars
   * @param {number} numberUniqueChars
   * @param {ValidationErrors} error
   * @returns {ValidatorFn}
   */
  public static uniqueCharsValidator(
    numberUniqueChars: number,
    error: ValidationErrors
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const splitControlValueIntoArray = Array.from(control.value);
      const uniqueCharsArray = [...new Set(splitControlValueIntoArray)];
      if (uniqueCharsArray.length >= numberUniqueChars) {
        return null;
      } else {
        return error;
      }
    };
  }

  /**
   * Method to validate if password and confirm password match
   * @param {AbstractControl} group
   * @returns {ValidationErrors | null}
   */
  public static passwordMatchValidator: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { passDontMatch: true };
  };
}
