import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import {
  BaseAction,
  BaseActionKey,
  DynamicFormActionOutput,
} from '../../../core/components/dynamic-form/models/base-action';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { CONFIG_ITEMS_DYNAMIC_FORM } from '../../dynamic-form-structure/config-items-dynamic-form';
import {
  ConfigItemsPath,
  ConfigurationItem,
} from '../../models/configuration-item.model';
import { AbstractConfigurationItemsService } from '../../services/configuration-items/abstract-configuration-items.service';

/**
 * Config items admin component
 */
@Component({
  selector: 'prg-config-items-admin',
  templateUrl: './prg-config-items-admin.component.html',
  styleUrls: ['./prg-config-items-admin.component.scss'],
})
export class PrgConfigItemsAdminComponent implements OnInit {
  /**
   * The structure of the tree including paths and data
   * @type {TreeNode[]}
   */
  public configItemsTree: TreeNode[];

  /**
   * The current selected node of the tree
   * @type {TreeNode}
   */
  public selectedNode: TreeNode;

  /**
   * The view mode type of the form. Default is "read"
   * @type {ViewMode}
   */
  public configItemsViewMode: ViewMode = ViewMode.Read;

  /**
   * A boolean property of the class to show/hide form. Default is false(hide)
   * @type {boolean}
   */
  public initForm: boolean = false;

  /**
   * An object with the data to build form , keys and values
   * @type {any}
   */
  public entityConfigItems: any = {};

  /**
   * The title of the form of configuration item
   * @type {string}
   */
  public titleForm: string;

  /**
   * The dynamic form fields to build dynamic form config items in template
   */
  public configItemsDynamicFormFields: any[] = this.arrayUtilityService.clone(
    CONFIG_ITEMS_DYNAMIC_FORM.fields
  );

  /**
   * The dynamic form actions to build dynamic form config items in template
   */
  public configItemsDynamicFormActions: BaseAction[] =
    this.arrayUtilityService.clone(CONFIG_ITEMS_DYNAMIC_FORM.actions);
  /**
   * A copy of entityConfigItems object
   * @type {any}
   * @private
   */
  private entityConfigItemCopy: any = {};

  /**
   * The list of configuration items
   * @type {ConfigurationItem[]}
   */
  private configItemsList: ConfigurationItem[] = [];

  /**
   * The configuration items defaults selected on tree
   * @type {ConfigurationItem[]}
   */
  private configItemsSelectedDefaults: ConfigurationItem[] = [];

  /**
   * The configuration items selected on tree
   * @type {ConfigurationItem[]}
   */
  private configItemsSelected: ConfigurationItem[] = [];

  /**
   * Constructor
   * @param {AbstractConfigurationItemsService} configurationItemsService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {UtilityService} utilityService
   * @param {TranslateService} translateService
   * @param {ObjectsUtilityService} objectUtility
   */
  constructor(
    private configurationItemsService: AbstractConfigurationItemsService,
    private arrayUtilityService: ArrayUtilityService,
    private utilityService: UtilityService,
    private translateService: TranslateService,
    private objectUtility: ObjectsUtilityService
  ) {}

  /**
   * ngOnInit
   *
   * Get all configuration item
   * @returns {Promise<void>}
   */
  public async ngOnInit(): Promise<void> {
    //fetch all configuration items
    this.configItemsList =
      await this.configurationItemsService.getAllConfigurationItemsAsync();
    this.setTreeData();
  }

  /**
   * This function call rearrange all config item paths and build tree menu
   * @private
   */
  private setTreeData(): void {
    //get only the path of all configuration items
    const configItemsPath: string[] = this.configItemsList.map(
      (configItem) => configItem.path
    );

    //Build tree
    this.configItemsTree = this.arrayUtilityService.clone(
      this.utilityService.arrayOfPathsToTree(configItemsPath, ':')
    );
  }

  /**
   * This function is responsible toe execute actions whenever a node is selected on tree
   * @param {TreeNode} node
   */
  public onSelectItem(node: TreeNode): void {
    // Return View mode back to read
    this.configItemsViewMode = ViewMode.Read;
    // Set config items selected
    this.setConfigItemsSelected(node);
    //Dynamic Form Fields
    this.configItemsDynamicFormFields = this.arrayUtilityService.clone(
      this.getAndParseGuiSettingsItems()
    );
    // Assign config item data to form - entities
    this.assignConfigItemDataToForm();
    // Title of Form
    this.setTitleForm();

    //Initiate Form
    this.initForm = true;
  }

  /**
   * This function is responsible to get the complete path of item selected and their default path if
   * they exist. Then it will get the data of config items selected
   * @param {TreeNode} node
   * @private
   */
  private setConfigItemsSelected(node: TreeNode): void {
    const pathConfigItem =
      PrgConfigItemsAdminComponent.getCompletePathOfItem(node);

    // Path of default config-items
    const pathConfigItemDefaults =
      pathConfigItem.substring(0, pathConfigItem.lastIndexOf(':')) +
      ':' +
      ConfigItemsPath.Defaults;

    // Config-items of the selectable node
    this.configItemsSelected = this.configItemsList.filter(
      (configItem) => configItem.path === pathConfigItem
    );

    // Config-items Defaults of the selectable node
    this.configItemsSelectedDefaults = this.configItemsList.filter(
      (configItem) => configItem.path === pathConfigItemDefaults
    );
  }

  /**
   * This function is responsible to get the complete path of the item in tree
   * @param {TreeNode} node
   * @returns {string}
   * @private
   */
  private static getCompletePathOfItem(node: TreeNode): string {
    // Find the complete path of the item
    const completePathItem: any[] = [];
    let flag = true;
    let parent = node?.parent;
    while (flag == true) {
      if (parent) {
        completePathItem.unshift(parent.label);
        parent = parent?.parent;
      } else {
        flag = false;
      }
    }
    completePathItem.push(node?.label);
    return completePathItem.join(':');
  }

  /**
   * This function it will set the title of the form
   * @private
   */
  private setTitleForm(): void {
    // Title of Form
    if (this.entityConfigItems.path.includes(ConfigItemsPath.UserPreferences)) {
      this.titleForm = this.entityConfigItems.path
        .substring(0, this.entityConfigItems.path.lastIndexOf(':'))
        .replaceAll(':', '.')
        .toLowerCase();
    } else {
      this.titleForm = this.entityConfigItems?.path
        .replaceAll(':', '.')
        .toLowerCase();
    }
  }

  /**
   * This function will assign config items data to form
   * @private
   */
  private assignConfigItemDataToForm(): void {
    // Assign config item data to form - entities
    const mapConfigItemsIntoEntity: any[] = [];
    this.configItemsSelected.map((configItems) => {
      mapConfigItemsIntoEntity[configItems.name] = configItems.value;
      mapConfigItemsIntoEntity['path'] = configItems.path;
    });

    if (
      this.configItemsSelectedDefaults != null &&
      this.configItemsSelectedDefaults.length > 0
    ) {
      this.configItemsSelectedDefaults.map(async (configItems) => {
        if (!mapConfigItemsIntoEntity[configItems.name]) {
          mapConfigItemsIntoEntity[configItems.name] = null;
        }
        let fieldGuiSettings = this.configItemsDynamicFormFields.find(
          (value) => value.key === configItems.name
        );

        if (fieldGuiSettings.basePathTranslation.includes('lookup-tables')) {
          fieldGuiSettings.placeholder =
            this.translateService.instant(
              fieldGuiSettings.basePathTranslation +
                '.' +
                fieldGuiSettings.key +
                '.items.' +
                configItems.value +
                '.name'
            ) + ' (default)';
        } else {
          fieldGuiSettings.placeholder = configItems.value + ' (default)';
        }
      });
    }
    this.entityConfigItems = Object.assign({}, mapConfigItemsIntoEntity);
    this.entityConfigItemCopy = this.objectUtility.cloneObject(
      this.entityConfigItems
    );
  }

  /**
   * This function is responsible to get the gui settings of the item selected and parse it
   * @returns {any}
   * @private
   */
  private getAndParseGuiSettingsItems(): any {
    //Gui Settings
    let guiSettings;

    if (
      this.configItemsSelectedDefaults == null ||
      this.configItemsSelectedDefaults.length == 0
    ) {
      guiSettings = this.configItemsSelected.map((configItem) => {
        return this.utilityService.guiSettingToObject(configItem.guiSettings);
      });
    } else {
      guiSettings = this.configItemsSelectedDefaults.map((configItem) => {
        return this.utilityService.guiSettingToObject(configItem.guiSettings);
      });
      if (this.selectedNode.key.includes(ConfigItemsPath.Defaults)) {
        guiSettings.forEach((gui) => {
          gui.validators = Validators.required;
        });
      }
    }
    return guiSettings;
  }

  /**
   * This function is responsible to handle actions from the dynamic config items form
   * @param {DynamicFormActionOutput} event
   * @returns {Promise<void>}
   */
  public async onActionConfigItems(
    event: DynamicFormActionOutput
  ): Promise<void> {
    switch (event.action) {
      case BaseActionKey.Save:
        await this.configItemsSave(event);
        break;

      case BaseActionKey.Cancel:
        this.configItemsViewMode = ViewMode.Read;
        this.entityConfigItems = this.objectUtility.cloneObject(
          this.entityConfigItemCopy
        );

        break;

      case BaseActionKey.Edit:
        this.configItemsViewMode = ViewMode.Edit;
        break;
      default:
        break;
    }
  }

  /**
   * This function is responsible to save a new config item or update an existing one
   * @param {DynamicFormActionOutput} event
   * @returns {Promise<void>}
   * @private
   */
  private async configItemsSave(event: DynamicFormActionOutput): Promise<void> {
    this.configItemsViewMode = ViewMode.Read;

    try {
      this.initForm = false;
      await this.configurationItemsService.updateConfigurationItemsAsync(
        event.formEntity
      );
      this.configItemsList = this.arrayUtilityService.clone(
        this.configurationItemsService.getConfigurationItemsValue()
      );

      this.setTreeData();
      if (this.selectedNode) {
        this.onSelectItem(this.selectedNode);
      }
      this.initForm = true;
    } catch (e) {}
  }
}
