import { Directive, Input } from '@angular/core';
import { InputNumber } from 'primeng/inputnumber';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Input Fiels
 */
export class InputNumberConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName: string = ControlName.inputNumber;
  /**
   *Whether to format the value.
   */
  public format: boolean = true;

  /**
   *Displays spinner buttons.
   */
  public showButtons: boolean = false;

  /**
   *Layout of the buttons, valid values are stacked (default), horizontal and vertical.
   */
  public buttonLayout: string = 'stacked';

  /**
   *Style class of the increment button.
   */
  public incrementButtonClass: string = null;

  /**
   *Style class of the decrement button.
   */
  public decrementButtonClass: string = null;

  /**
   *Style class of the increment button.
   */
  public incrementButtonIcon: string = 'pi pi-chevron-up';

  /**
   *Style class of the decrement button.
   */
  public decrementButtonIcon: string = 'pi pi-chevron-down';

  /**
   *Locale to be used in formatting.
   */
  public locale: string = undefined;

  /**
   *The locale matching algorithm to use. Possible values are lookup and best fit; the default is best fit. See Locale Negotation for details.
   */
  public localeMatcher: string = 'best fit';

  /**
   *Defines the behavior of the component, valid values are decimal and currency.
   */
  public mode: string = 'decimal';

  /**
   *Text to display before the value.
   */
  public prefix: string = null;

  /**
   *Text to display after the value.
   */
  public suffix: string = null;

  /**
   *The currency to use in currency formatting. Possible values are the ISO 4217 currency codes, such as USD for the US dollar, EUR for the euro, or CNY for the Chinese RMB. There is no default value; if the style is currency, the currency property must be provided.
   */
  public currency: string = undefined;

  /**
   *How to display the currency in currency formatting. Possible values are symbol to use a localized currency symbol such as €, ücode to use the ISO currency code, name to use a localized currency name such as dollar; the default is symbol.
   */
  public currencyDisplay: string = 'symbol';

  /**
   *Whether to use grouping separators, such as thousands separators or thousand/lakh/crore separators.
   */
  public useGrouping: boolean = true;

  /**
   *The minimum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number and percent formatting is 0; the default for currency formatting is the number of minor unit digits provided by the ISO 4217 currency code list (2 if the list doesn't provide that information).
   */
  public minFractionDigits: number = null;

  /**
   *The maximum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number formatting is the larger of minimumFractionDigits and 3; the default for currency formatting is the larger of minimumFractionDigits and the number of minor unit digits provided by the ISO 4217 currency code list (2 if the list doesn't provide that information).
   */
  public maxFractionDigits: number = null;

  /**
   *Mininum boundary value.
   */
  public min: number = null;

  /**
   *Maximum boundary value.
   */
  public max: number = null;

  /**
   *Step factor to increment/decrement the value.
   */
  public step: number = 1;

  /**
   *Determines whether the input field is empty.
   */
  public allowEmpty: boolean = true;

  /**
   *Inline style of the component.
   */
  public style: string = null;

  /**
   *Style class of the component.
   */
  public styleClass: string = null;

  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;

  /**
   *Inline style of the input field.
   */
  public inputStyle: string = null;

  /**
   *Style class of the input field.
   */
  public inputStyleClass: string = null;

  /**
   *Advisory information to display on input.
   */
  /*  public placeholder: string = null;*/ // this property is translated on multilingue

  /**
   *Size of the input field.
   */
  public size: number = null;

  /**
   *Maximum number of character allows in the input field.
   */
  public maxlength: number = null;

  /**
   *Specifies tab order of the element.
   */
  public tabindex: string = null;
  /*
  /!**
   *When present, it specifies that the element should be disabled.
   *!/
  public disabled: boolean = false;*/ //Property already on Base Field

  /**
   *When present, it specifies that an input field is read-only.
   */
  // public readonly: boolean = false; // CR: already in base field

  /**
   *Title text of the input text.
   */
  public title: string = null;

  /**
   *Used to define a string that labels the input element.
   */
  public ariaLabel: string = null;

  /**
   *Used to indicate that user input is required on an element before a form can be submitted.
   */
  public ariaRequired: boolean = false;

  /**
   *Name of the input field.
   */
  // public name: string = null; // this property is translated on multilingue

  /**
   *Used to define a string that autocomplete attribute the current element.
   */
  public autocomplete: string = null;

  /**
   *When enabled, a clear icon is displayed to clear the value.
   */
  public showClear: boolean = false;
  /**
   * Constructor
   * this constructor receives the fields sent by the app,
   * and creates an object with the fields sent and the values per default
   * @param init
   */
  constructor(init?: Partial<InputNumberConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * directive
 */
@Directive({
  selector: '[setInputNumberConfigs]',
})
export class PrgSetInputNumberConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: InputNumber) {}

  /**
   * input number configs and assign configs to element
   */
  @Input() set setInputNumberConfigs(configs: InputNumberConfiguration) {
    Object.assign(this.element, configs);
  }
}
