import { BaseAction, BaseActionKey } from '../models/base-action';
import { ActionName } from '../models/constants/action-names';
import { ToolbarSlot } from '../models/constants/toolbar-slots';

/**
 * Default actions
 * This array contains the add, edit, save and cancel actions,
 * as well as the visibility expression for the logic of these actions
 */
export const ACTIONS_BASE_STRUCTURE = {
  actions: [
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Add,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='add' || this.viewMode ==='read'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Edit,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='read'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Save,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Cancel,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='edit'",
    }),
  ],
};
