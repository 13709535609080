<div>
  <input
    type="text"
    placeholder="{{
      'components.roles-scopes.create-role.placeholder' | translate
    }}"
    [(ngModel)]="newRoleName"
    pInputText
  />
  <p>
    {{ 'components.roles-scopes.create-role.based-roles.label' | translate }}
  </p>
  <prg-select-role
    (onSubmitRolesSelected)="onSubmitRolesSelected($event)"
    [multipleSelect]="true"
  ></prg-select-role>

  <div>
    <button
      pButton
      type="button"
      label="{{ 'buttons.cancel.label' | translate }}"
      (click)="cancel()"
    ></button>
    <button
      pButton
      type="button"
      label="{{ 'buttons.save.label' | translate }}"
      (click)="createRole()"
      [disabled]="!newRoleName"
    ></button>
  </div>
</div>
