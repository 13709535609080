import { FilterExpressions, FilterOperations } from './filters';
import { Pagination } from './pagination';

/**
 * TableConfigs
 */
export class TableConfigs {
  /**
   * defines the type of pagination we want and all the pagination settings
   */
  public pagination: Pagination = new Pagination();

  /**
   * default columns to show
   */
  public defaultColumns: string[] = [];

  /**
   * columns in which the global filter is searched
   */
  public globalFilterFields: string[] = [];

  /**
   * global filter oprarion out value
   */
  public globalFilterOperation: FilterOperations = FilterOperations.Contains;

  /**
   * global filter expression
   */
  public globalFilterExpression: FilterExpressions = FilterExpressions.Or;

  /**
   * filter delay
   */
  public filterDelay: number = 1000;

  /**
   * multi sorte meta
   */
  public multiSortMeta: any[] = [];

  /**
   * Constructor
   * this constructor receives the fields sent by the app,
   * and creates an object with the fields sent and the values per default
   * @param init
   */
  constructor(init?: Partial<TableConfigs>) {
    Object.assign(this, init);
  }
}
