import { Token, User } from '../models/user.model';

/**
 * Database interface that defines a mock
 * <br />database to be used while developing
 * <br />and/or testing the front-end.
 */
export interface UsersBD {
  /**
   * Mock users
   */
  Users: User[];
}

/**
 * MockDatabase object to be used while developing
 * <br /> and/or testing the front-end
 */
export const UsersBDMOCk: UsersBD = {
  Users: [
    {
      id: '1',
      name: 'Ivo Carvalho',
      token: new Token({
        imageUrl: '/assets/images/Image_profile_Example.jpg',
      }),
      email: 'ivo@gmail.com',
      password: 'Abcd1_',
      tokenResetPassword: 'reset',
    },
    {
      id: '2',
      name: 'Pedro Couto',
      token: null,
      email: 'pedrocouto@gmail.com',
      password: 'Abcd1_',
      tokenResetPassword: null,
    },
    {
      id: '3',
      name: 'Pedro Lima',
      token: null,
      email: 'pedrolima@gmail.com',
      password: 'Abcd1_',
      tokenResetPassword: null,
    },
    {
      id: '4',
      name: 'Luis Alves',
      token: null,
      email: 'luis@gmail.com',
      password: 'Abcd1_',
      tokenResetPassword: null,
    },
  ],

  /* TODO: MAP IDENTITY CONFIG TO CONFIGURATION ITEMS
    "Identity": {
      "DefaultEnabledUser": true, // user will be enabled on register
      "AllowSelfRegister": true,
      "LockoutEnabled": true,
      "CreateWorkspaceOnUserRegister": true,
      "SignIn": {
        "RequireEnabledUser": false,
        "RequireConfirmedAdmin": false,
        "RequireConfirmedEmail": true,
        "RequireConfirmedPhoneNumber": false
      },
      "Lockout": {
        "LockoutTimeSpan": "1.00:00:00" //lock out for 1 day
      },
      "Password": {
        "RequireDigit": true,
        "RequiredLength": 7,
        "RequireLowercase": true,
        "RequiredUniqueChars": 1,
        "RequireUppercase": true,
        "RequireNonAlphanumeric": true
      },
      "Callbacks": {
        "BaseUrl": "http://localhost:8100",
        "LoginUrl": "/auth/login",
        "ConfirmedAccountUrl": "/auth/confirm",
        "ResetPasswordUrl": "/auth/reset",
        "UnsubscribeUrl": "/auth/unsubscribe"
      },
      "Token": {
        "Audience": "Prg.Framework.Api.Audience",
        "Issuer": "Prg.Framework.Api.Issuer",
        "Seconds": 86400, // 24 hours
        "RefreshDuration": 24 // 24 hours
      }
    }
  */
};
