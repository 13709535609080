import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { TriStateCheckboxConfiguration } from './tri-state-checkbox.model';

/**
 * component tri state checkbox
 */
@Component({
  selector: 'prg-tri-state-checkbox-field',
  templateUrl: './tri-state-checkbox.component.html',
})
export class PrgTriStateCheckboxComponent {
  /**
   * Tri State Checkbox Configs
   */
  @Input() configs: TriStateCheckboxConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the output
   */
  public onChanged(event: any): void {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          event: event,
          value: event.value,
        })
      );
    }
  }
}
