<div>
  <p-treeTable
    [value]="treeData"
    [columns]="tableColumns"
    selectionMode="checkbox"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ 'components.roles-scopes.columns.' + col + '.label' | translate }}
        </th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-rowNode
      let-rowData="rowData"
      let-columns="columns"
    >
      <ng-container *ngIf="haveRole">
        <tr
          [ttContextMenuRow]="rowNode"
          [ngClass]="{ node: rowNode.level === 0 }"
        >
          <td
            *ngFor="
              let col of tableColumns.slice(0, tableColumns.length - 1);
              let i = index
            "
          >
            <p-treeTableToggler
              *ngIf="i == 0"
              [rowNode]="rowNode"
            ></p-treeTableToggler>
            <p-triStateCheckbox
              *ngIf="i == 0 && rowData.isGroup; else elseBlockFirstCol"
              label="{{
                'entity-type.groups.' + rowData.name.toLowerCase() + '.label'
                  | translate
              }}"
              [ngModel]="rowData.columns.value"
              (click)="groupSelectionChange(rowData.name)"
              checkboxFalseIcon="pi pi-minus"
            ></p-triStateCheckbox>

            <ng-template #elseBlockFirstCol>
              <p-triStateCheckbox
                *ngIf="i == 0"
                [label]="rowData.label"
                [ngModel]="rowData.columns.value"
                (click)="rowSelectionChange(rowNode.parent.data.name, rowData)"
                checkboxFalseIcon="pi pi-minus"
              ></p-triStateCheckbox>
            </ng-template>
            <p-triStateCheckbox
              *ngIf="
                i > 0 &&
                  rowData.columns.children.has(col) &&
                  rowNode.node.children;
                else elseBlock
              "
              [ngModel]="rowData.columns.children.get(col).value"
              (click)="columnCheckedChange(rowData, col)"
              checkboxFalseIcon="pi pi-minus"
            ></p-triStateCheckbox>
            <ng-template #elseBlock>
              <p-checkbox
                *ngIf="i > 0 && rowNode.node.data.columns.children.get(col)"
                [name]="rowData.name"
                [value]="rowData.name"
                [(ngModel)]="rowNode.node.data.columns.children.get(col).value"
                (onChange)="
                  scopeSelectionChange(
                    rowNode.parent.data.name,
                    rowData.name,
                    col
                  )
                "
                [binary]="true"
              ></p-checkbox>
            </ng-template>
          </td>
          <td>
            <p-multiSelect
              *ngIf="
                rowData.columns.children.has(
                  prgRolesScopesConfig.otherScopes
                ) && !rowData.isGroup
              "
              [options]="
                rowData.columns.children.get(prgRolesScopesConfig.otherScopes)
                  .children | keyvalue
              "
              optionValue="value.scope"
              [ngModel]="
                rowData.columns.children.get(prgRolesScopesConfig.otherScopes)
                  .data
              "
              (onChange)="
                otherCheckedChange($event, rowNode.parent.data.name, rowData)
              "
              [resetFilterOnHide]="true"
              [emptyFilterMessage]="'messages.no-results' | translate"
              [filter]="false"
              defaultLabel="{{
                'components.roles-scopes.other-operations-column.other-operations.label'
                  | translate
              }}"
            >
              <ng-template let-value pTemplate="selectedItems">
                <ng-template
                  [ngIf]="
                    !rowData.columns.children.get(
                      prgRolesScopesConfig.otherScopes
                    ).data ||
                    rowData.columns.children.get(
                      prgRolesScopesConfig.otherScopes
                    ).data.length === 0
                  "
                  [ngIfElse]="withSelected"
                >
                  {{
                    'components.roles-scopes.other-operations-column.other-operations.label'
                      | translate
                  }}
                </ng-template>
                <ng-template #withSelected>
                  <div
                    *ngIf="
                      rowData.columns.children.get(
                        prgRolesScopesConfig.otherScopes
                      ).data.length === 1;
                      else elseBlockMultipleSelected
                    "
                  >
                    {{
                      rowData.columns.children.get(
                        prgRolesScopesConfig.otherScopes
                      ).data.length
                    }}
                    {{
                      'components.roles-scopes.other-operations-column.operation-selected.label'
                        | translate
                    }}
                  </div>
                  <ng-template #elseBlockMultipleSelected>
                    {{
                      rowData.columns.children.get(
                        prgRolesScopesConfig.otherScopes
                      ).data.length
                    }}
                    {{
                      'components.roles-scopes.other-operations-column.operations-selected.label'
                        | translate
                    }}
                  </ng-template>
                </ng-template>
              </ng-template>
              <ng-template let-other pTemplate="item">
                {{ other.value.label | translate }}
              </ng-template>
            </p-multiSelect>

            <p-triStateCheckbox
              *ngIf="
                rowData.columns.children.has(
                  prgRolesScopesConfig.otherScopes
                ) && rowData.isGroup
              "
              [ngModel]="
                rowData.columns.children.get(prgRolesScopesConfig.otherScopes)
                  .value
              "
              (click)="
                columnCheckedChange(rowData, prgRolesScopesConfig.otherScopes)
              "
              checkboxFalseIcon="pi pi-minus"
            ></p-triStateCheckbox>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-treeTable>
  <!-- *ngIf="haveRole" -->
  <button
    pButton
    type="button"
    label="{{ 'buttons.save.label' | translate }}"
    (click)="saveChanges()"
    [disabled]="!haveChangesToSave"
  ></button>
</div>
