import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { LocationStrategy } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  GridsterComponent,
  GridsterItemComponentInterface,
} from 'angular-gridster2';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { MainLayoutService } from '../../../layouts/services/main-layout.service';
import {
  ComponentType,
  ComponentTypeEnum,
  Dashboard,
  DashboardItem,
  MapComponentTypesToClass,
} from '../../models/dashboard.model';
import { PrgDashboardConfig } from '../../models/prg-dashboard-config';
import {
  PrgGridsterConfig,
  PrgGridsterItem,
} from '../../models/prg-gridster-config';
import { AbstractDashboardService } from '../../services/dashboard/abstract-dashboard.service';
import { PRG_DASHBOARD_CONFIG } from '../../services/prg-dashboard-configuration/prg-dashboard-configuration.service';
import { PrgDashboardItemsOptionsComponent } from '../dashboard-items-options/prg-dashboard-items-options.component';
import { PrgDashboardOptionsComponent } from '../dashboard-options/prg-dashboard-options.component';
import { DASHBOARD_DYNAMIC_FORM } from './dashboard-dynamic-form-structure/dashboard-dynamic-form';

/**
 * Dashboard Component
 */
@Component({
  selector: 'prg-dashboard',
  templateUrl: './prg-dashboard.component.html',
  styleUrls: ['./prg-dashboard.component.scss'],
  providers: [DialogService],
  animations: [
    trigger('OnExpandSideBarDashboard', [
      state(
        'open',
        style({
          width: '100%',
        })
      ),
      state(
        'closed',
        style({
          width: '70px',
        })
      ),
      transition('open => closed', [
        group([
          query(':self', [animate('0s')]),
          query('@OnExpandSideBarTextDashboard', [animateChild()]),
        ]),
      ]),
      transition('closed => open', [
        group([
          query(':self', [animate('0.2s')]),
          query('@OnExpandSideBarTextDashboard', [animateChild()]),
        ]),
      ]),
    ]),
    trigger('OnExpandSideBarTextDashboard', [
      // ...
      state(
        'show-content',
        style({
          opacity: '1',
        })
      ),
      state(
        'hide-content',
        style({
          opacity: '0',
          overflow: 'hidden',
        })
      ),
      transition('show-content => hide-content', [animate('0s')]),
      transition('hide-content => show-content', [animate('0.2s 0.3s')]),
    ]),
  ],

  /* changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,*/
})
export class PrgDashboardComponent implements OnInit, OnDestroy {
  /**
   * The dynamic form fields to build dashboard form in template
   */
  public dashboardDynamicFormFields: any[] = this.arrayUtilityService.clone(
    DASHBOARD_DYNAMIC_FORM.fields
  );
  /**
   * The view mode for dynamic form
   * @type {ViewMode}
   */
  public dashboardViewMode: ViewMode = ViewMode.Edit;

  /**
   * The current dashboard
   * @type {Dashboard}
   */
  public dashboard: Dashboard;

  /**
   * The current state of sidebar menu. Expanded is true and collapsed is false.
   *
   * Default is false.
   * @type {boolean}
   */
  public menuDashboardExpanded = true;

  /**
   * The id of dashboard get it from url parameter
   * @type {string}
   */
  public idDashboard: string;

  /**
   * An array of component type list to be shown on sidebar
   * @type {any[]}
   */
  public componentsType: any[] = [];

  /**
   * The current component type selected
   * @type {any}
   */
  public componentTypeSelected: any;

  /**
   * The base route for editing a dashboard
   * @type {string}
   */
  @Input() editDashboardPageRoute: string =
    '/' +
    this.prgDashboardConfig.dashboardRoutingBasePath +
    '/' +
    this.prgDashboardConfig.dashboardEditRoutingPath;
  /**
   * The dashboard settings object based on Gridster options
   * @type {PrgGridsterConfig}
   */
  public dashboardOptions: PrgGridsterConfig;

  /**
   * An array of widgets (dynamic items) displayed on dashboard
   * @type {Array<PrgGridsterItem>}
   */
  public dashboardItems: Array<PrgGridsterItem> = [];

  /**
   * A copy dashboard items
   * @type {Array<PrgGridsterItem>}
   * @private
   */
  private dashboardItemsCopy: Array<PrgGridsterItem> = [];
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * A copy of dashboard
   * @type {Dashboard}
   * @private
   */
  private dashboardCopy: Dashboard;

  /**
   * Property of type DynamicDialogRef to control the dialog of dashboard options
   */
  private dynamicDialogRef!: DynamicDialogRef;

  /**
   * Property of type DynamicDialogRef to control the dialog of items options
   */
  private dynamicDialogRefItems!: DynamicDialogRef;

  /**
   * A copy of dashboard options
   * @type {PrgGridsterConfig}
   * @private
   */
  private dashboardOptionsCopy: PrgGridsterConfig;

  /**
   * Constructor
   * @param {DialogService} dialogService dialogService
   * @param {MainLayoutService} mainLayoutService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {AbstractDashboardService} dashboardService
   * @param {UtilityService} utilityService
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param {PrgDashboardConfig} prgDashboardConfig
   * @param {LocationStrategy} location
   * @param {NotificationsService} notificationsService
   * @param {TranslateService} translateService
   */
  constructor(
    public dialogService: DialogService,
    private mainLayoutService: MainLayoutService,
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private dashboardService: AbstractDashboardService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PRG_DASHBOARD_CONFIG)
    private prgDashboardConfig: PrgDashboardConfig,
    private location: LocationStrategy,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  /**
   * ngOnInit
   *
   * Verify if there is any changes on size of main sidebar and update view on dashboard.
   *
   * Verify if there is any changes on Dashboard Options form and reflect them on the view of dashboard.
   *
   * Verify route and if there is a parameter on route, if of dashboard and if so set this as the selected dashboard
   * @returns {Promise<void>}
   */
  public async ngOnInit(): Promise<void> {
    this.dashboardService.setDashboardOptionsChanges(null);
    this.subscription.push(
      this.mainLayoutService.getSideBarStateObservable().subscribe(() => {
        this.changedOptions();
      })
    );
    this.subscription.push(
      this.dashboardService
        .getDashboardOptionsChangesObservable()
        .subscribe((dashboard) => {
          if (dashboard) {
            if (
              dashboard.gridsterConfig != null &&
              dashboard.gridsterItems == null
            ) {
              this.dashboardOptions = this.objectsUtilityService.cloneObject(
                dashboard.gridsterConfig
              );
              this.dashboardItems = this.arrayUtilityService.clone(
                this.dashboardItems
              );
            } else {
              this.dashboardItems = this.arrayUtilityService.clone(
                dashboard.gridsterItems
              );
            }
            this.changedOptions();
          }
        })
    );
    this.dashboardOptions = new PrgGridsterConfig({});
    this.populateComponentsType();
    if (this.router.url.includes(this.editDashboardPageRoute)) {
      await this.getDashboard();
    } else {
      await this.setNewDashboardSettings();
    }
    this.changedOptions();
  }

  /**
   * This function will populate components type list on sidebar
   * @private
   */
  private populateComponentsType(): void {
    MapComponentTypesToClass.forEach((value, key) => {
      this.componentsType.push({
        name: key,
        class: value,
      });
    });
  }

  /**
   * Setting up a new dashboard
   * @returns {Promise<void>}
   * @private
   */
  private async setNewDashboardSettings(): Promise<void> {
    this.dashboard = new Dashboard({});

    this.dashboardCopy = this.objectsUtilityService.cloneObject(this.dashboard);

    this.settingCallBackFunctionDashboard();
    this.dashboardItems = [];
    this.dashboardItemsCopy = this.arrayUtilityService.clone(
      this.dashboardItems
    );
  }

  /**
   * This function will get and set the dashboard selected on list
   * @returns {Promise<void>}
   * @private
   */
  private async getDashboard(): Promise<void> {
    this.idDashboard = this.route?.snapshot?.params['id'];
    try {
      this.dashboard = await this.dashboardService.getDashboardByIdAsync(
        this.idDashboard
      );
    } catch (e) {
      this.location.back();
    }

    if (this.dashboard) {
      this.dashboardCopy = this.objectsUtilityService.cloneObject(
        this.dashboard
      );
      if (this.dashboard?.settings) {
        let dashboardOptionStringToObject =
          this.utilityService.guiSettingToObject(this.dashboard.settings);
        this.dashboardOptions = this.objectsUtilityService.cloneObject(
          this.objectsUtilityService.unFlattenObj(
            dashboardOptionStringToObject,
            '.'
          )
        );
      }
      this.settingCallBackFunctionDashboard();
      this.dashboardItems = [];
      if (this.dashboard.items != null && this.dashboard.items.length > 0) {
        this.setDashboardItems();
      }
    } else {
      this.location.back();
    }
  }

  /**
   * Setting up items for dashboard
   * @private
   */
  private setDashboardItems(): void {
    this.dashboard.items.map((item) => {
      this.dashboardItems.push(
        new PrgGridsterItem({
          x: item.x,
          y: item.y,
          cols: item.cols,
          rows: item.rows,
          dashboardId: item.dashboardId,
          item: MapComponentTypesToClass.get(
            <ComponentTypeEnum>item.componentType
          ),
          settings: item.settings,
          id: item.id ? item.id : this.utilityService.newGuid(),
        })
      );
    });

    this.dashboardItemsCopy = this.arrayUtilityService.clone(
      this.dashboardItems
    );
  }

  /**
   * Setting up call back for dashboard
   * @private
   */
  private settingCallBackFunctionDashboard(): void {
    this.dashboardOptions = {
      ...this.dashboardOptions,
      itemResizeCallback: this.onItemChangeSized.bind(this),
      emptyCellClickCallback: this.emptyCellClick.bind(this),
      emptyCellDropCallback: this.emptyCellClick.bind(this),
    };

    this.dashboardOptionsCopy = this.objectsUtilityService.cloneObject(
      this.dashboardOptions
    );
  }

  /**
   * This function is responsible to delete an item of dashboard
   * @param {MouseEvent} event
   * @param {PrgGridsterItem} item
   */
  public onDeleteItem(event: MouseEvent, item: PrgGridsterItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.dashboardItems.splice(this.dashboardItems.indexOf(item), 1);
  }

  /**
   * This function is responsible to refresh grid options
   * @private
   */
  private changedOptions(): void {
    //Update Grid on Changes
    if (
      this.dashboardOptions?.api &&
      this.dashboardOptions?.api?.optionsChanged
    ) {
      this.dashboardOptions?.api?.optionsChanged();
    }
  }

  /**
   * This function will add an item on the first possible position of the dashboard
   * @param {ComponentType} componentType
   */
  public onAddItemFirstPossiblePosition(
    componentType: ComponentType = null
  ): void {
    if (componentType) {
      this.componentTypeSelected = componentType;
    }
    if (this.componentTypeSelected != null) {
      this.dashboardItems.push({
        x: 0,
        y: 0,
        cols: 1,
        rows: 1,
        settings: null,
        item: this.componentTypeSelected.class,
        id: this.utilityService.newGuid(),
      });
    }
  }

  /**
   * This function is responsible to clear all items of dashboard
   * @returns {Promise<void>}
   */
  public async clearAllItems(): Promise<void> {
    if (
      await this.notificationsService.prgConfirmationService(
        'messages.clear-confirmation',
        await this.translateService.get('dashboard ?').toPromise()
      )
    ) {
      this.dashboardItems = [];
    } else {
      return;
    }
  }
  /**
   * ngOnDestroy
   *
   * Unsubscribe subscriptions
   */
  public ngOnDestroy(): void {
    this.subscription.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.subscription = [];
  }

  /**
   * This function will add an item on an empty cell that was clicked
   * @param {MouseEvent} event
   * @param {PrgGridsterItem} item
   * @private
   */
  private emptyCellClick(
    event: MouseEvent = null,
    item: PrgGridsterItem
  ): void {
    if (this.componentTypeSelected != null) {
      this.dashboardItems.push({
        ...item,
        settings: null,
        item: this.componentTypeSelected.class,
        id: this.utilityService.newGuid(),
      });
    }
  }

  /**
   * This function is responsible to open dashboard options modal and handle actions when it closes
   */
  public openOptionsDashboard(): void {
    this.dynamicDialogRef = this.dialogService.open(
      PrgDashboardOptionsComponent,
      {
        header: 'DashBoardOptions',
        showHeader: true,
        width: '50%',
        height: '60vh',
        baseZIndex: 10000,
        data: {
          dashboardOptions: this.dashboardOptions,
          dashboardItems: this.dashboardItems,
        },
        draggable: true,
        resizable: true,
        maximizable: true,
      }
    );
    this.subscription.push(
      this.dynamicDialogRef.onClose.subscribe((dashboard) => {
        if (dashboard) {
          this.dashboardOptions =
            this.objectsUtilityService.cloneObject(dashboard);
          this.dashboardOptionsCopy = this.objectsUtilityService.cloneObject(
            this.dashboardOptions
          );
        } else {
          this.dashboardOptions = this.objectsUtilityService.cloneObject(
            this.dashboardOptionsCopy
          );
          this.dashboardItems = this.arrayUtilityService.clone(
            this.dashboardItems
          );
        }
        this.changedOptions();
      })
    );
  }

  /**
   *  This function will save the dashboard
   * @param entity
   * @returns {Promise<void>}
   */
  public async saveDashboard(entity: any): Promise<void> {
    let isNewDashboard = false;
    this.dashboard = this.objectsUtilityService.cloneObject(entity);
    if (entity.id == null) {
      this.dashboard.id = this.utilityService.newGuid();
      isNewDashboard = true;
    }
    this.prepareDashboardItemsToSave();

    try {
      if (isNewDashboard) {
        await this.dashboardService.createDashboardAsync(this.dashboard);
      } else {
        await this.dashboardService.updateDashboardAsync(
          this.dashboard.id,
          this.dashboard
        );
      }
      this.dashboardCopy = this.objectsUtilityService.cloneObject(
        this.dashboard
      );
      this.dashboardOptionsCopy = this.objectsUtilityService.cloneObject(
        this.dashboardOptions
      );
      this.dashboardItemsCopy = this.arrayUtilityService.clone(
        this.dashboardItems
      );
    } catch (e) {}
    /*
    this.changedOptions();*/
  }

  /**
   * This function is auxiliary function of saveDashboard. Prepares dashboard items to be saved.
   * @private
   */
  private prepareDashboardItemsToSave(): void {
    let itemsToSave: DashboardItem[] = [];
    this.dashboardItems.map((dashboardItem) => {
      let auxComponentType: string = this.utilityService.getKeyByValueOnMap(
        MapComponentTypesToClass,
        dashboardItem.item
      );

      itemsToSave.push(
        new DashboardItem({
          x: dashboardItem.x,
          y: dashboardItem.y,
          id: dashboardItem.id,
          cols: dashboardItem.cols,
          rows: dashboardItem.rows,
          settings: dashboardItem.settings,
          dashboardId: this.dashboard.id,
          componentType: auxComponentType,
        })
      );
    });

    this.dashboard = {
      ...this.dashboard,
      items: itemsToSave,
      settings: JSON.stringify(
        this.objectsUtilityService.flattenObj(this.dashboardOptions, '.')
      ),
    };
  }
  /**
   * This function is call whenever height or width of each item changes
   * @param {PrgGridsterItem} item
   * @param {GridsterItemComponentInterface} itemComponent
   * @private
   */
  private onItemChangeSized(
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface
  ): void {
    this.dashboardService.setDashboardItemsResize(itemComponent);
  }

  /**
   * This function is call when a drag occurs on dashboard.Set componentTypeSelected.
   * @param componentType
   */
  public onDragItem(componentType: any): void {
    this.componentTypeSelected = componentType;
  }

  /**
   * This function is responsible to handle actions after an item was drop in an empty cell on the dashboard
   * @param event
   * @param {GridsterComponent} gridsterComponent
   */
  public onDropItemEmptyCell(
    event: any,
    gridsterComponent: GridsterComponent
  ): void {
    event.preventDefault();
    event.stopPropagation();
    gridsterComponent.emptyCell.emptyCellDragDrop(event);
  }

  /**
   * This function is responsible to handle actions after an item was drop in an occupied cell on the dashboard
   * @param event
   * @param {PrgGridsterItem} item
   * @param {GridsterComponent} gridsterComponent
   * @constructor
   */
  OnDropItem(
    event: any,
    item: PrgGridsterItem,
    gridsterComponent: GridsterComponent
  ): void {
    if (this.componentTypeSelected != null) {
      let newPos = this.dashboardOptions.api.getFirstPossiblePosition(item);
      let index = this.dashboardItems.indexOf(item);
      this.dashboardItems[index] = { ...this.dashboardItems[index], ...newPos };
      this.dashboardItems.push({
        ...item,
        item: this.componentTypeSelected.class,
        settings: null,
        id: this.utilityService.newGuid(),
      });
    }
  }

  /**
   * This function is call whenever sidebar expands or collapses
   */
  public onShowHideSideBarDetails(): void {
    this.menuDashboardExpanded = !this.menuDashboardExpanded;
    this.changedOptions();
  }

  /**
   * This function is responsible to open dashboard items options modal and handle actions when it closes
   * @param {PrgGridsterItem} item
   */
  public onOpenItemOptions(item: PrgGridsterItem): void {
    this.dashboardItemsCopy = this.arrayUtilityService.clone(
      this.dashboardItems
    );
    this.dynamicDialogRefItems = this.dialogService.open(
      PrgDashboardItemsOptionsComponent,
      {
        header: 'DashBoardItemOptions',
        showHeader: true,
        width: '50%',
        height: '60vh',
        baseZIndex: 10000,
        data: {
          item: item,
          index: this.dashboardItems.indexOf(item),
        },
        draggable: true,
        resizable: true,
        maximizable: true,
      }
    );
    this.subscription.push(
      this.dynamicDialogRefItems.onClose.subscribe((item) => {
        if (item) {
          this.dashboardItems[item.index] =
            this.objectsUtilityService.cloneObject(item.item);
        }
        this.dashboardItemsCopy = this.arrayUtilityService.clone(
          this.dashboardItems
        );
      })
    );
  }
}
