<div >
  <p-dropdown *ngIf="showDropdown" placeholder="Select a Dashboard" (onChange)="onChangeDashboard($event)" optionValue="id" optionLabel="name" [options]="dashboardsByUser" ></p-dropdown>

    <div class="col grid-nogutter "
         [ngClass]="displayHeader&&showDropdown?'grid-layout-with-header-dropdown':displayHeader&&!showDropdown?'grid-layout-with-header':!displayHeader&&showDropdown?'grid-layout-with-dropdown':'grid-layout'">
      <gridster  *ngIf="displayDashboard"     [options]="dashboardOptions" >

        <gridster-item    [item]="item" *ngFor="let item of dashboardItems" >


            <ndc-dynamic  [ndcDynamicInputs]="{itemId:item.id,itemSettings:item.settings}"  [ndcDynamicComponent]="item.item">

            </ndc-dynamic>

        </gridster-item>
      </gridster>

</div>

</div>
