import { Component } from '@angular/core';

/**
 * Page lookup Table
 */
@Component({
  selector: 'app-prg-lookup-table',
  templateUrl: './prg-lookup-table.page.html',
  styleUrls: ['./prg-lookup-table.page.scss'],
})
export class PrgLookupTablePage {}
