<div class="field-radio-button" *ngIf="form" [formGroup]="form">
<!--//TODO Colocar title na tradução dos radio-button-->
  <label>{{configs.basePathTranslation + '.' + configs.key + '.title' | translate}}</label>
  <ng-container *ngIf="configs.options;else singleRadio"  >
  <div *ngFor="let option of configs.options " [ngClass]="['py-1']">
    <p-radioButton
      [formControlName]="configs.key"
      [setRadioButtonConfigs]="configs"
      (onClick)="onChanged($event)"
      [inputId]="option[configs.optionKey]"
      [value]="option[configs.optionValue]">
    </p-radioButton>
    <label
      for="{{option[configs.optionKey]}}">{{configs.basePathTranslation + '.' + configs.key + '.' + option[configs.optionKey] + '.label' | translate}}</label>
  </div>
  </ng-container>
  <ng-template #singleRadio>
    <p-radioButton
      [formControlName]="configs.key"
      [setRadioButtonConfigs]="configs"
      (onClick)="onChanged($event)"
      [inputId]="configs.key"
      [label]="configs.basePathTranslation + '.' + configs.key  + '.label' | translate">
    </p-radioButton>

  </ng-template>
</div>
