<div *ngIf="form && configs != null" [formGroup]="form">
  <p>{{configs.basePathTranslation + '.' + configs.key + '.name' | translate}}</p>
  <p-dropdown
    [form]="form"
    [options]="configs.options"
    [setSelectConfigs]="configs"
    [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
    [formControlName]="configs.key"
    [placeholder]="
      configs.basePathTranslation + '.' + configs.key + '.placeholder'
        | translate
    "
    [name]="configs.key + '.name' | translate"
    (onChange)="onChanged($event)"
  ></p-dropdown>
</div>
