import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractAuthService } from '../../../auth/services/auth/abstract-auth.service';
import { PrgError } from '../../../core/models/error.model';
import { PrgSucess } from '../../../core/models/success.model';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { Query } from '../../models/query.model';
import { AbstractQueriesService } from '../../services/queries/abstract-queries.service';
import { QueriesBDMOCK } from '../database.mock';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * MOCK AbstractQueriesService implementation
 */
export class MockQueriesService extends AbstractQueriesService {
  /**
   * default time in seconds
   */
  private sleepTimeSec = 0.5;

  /**
   * constructor
   * @param {NotificationsService} notificationsService
   * @param {UtilityService} utilityService
   * @param {IsLoadingDataService} isLoadingData
   * @param {ObjectsUtilityService} objectUtility
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {AbstractAuthService} authService
   * @param {TranslateService} translateService
   */
  constructor(
    private notificationsService: NotificationsService,
    private utilityService: UtilityService,
    private isLoadingData: IsLoadingDataService,
    private objectUtility: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private authService: AbstractAuthService,
    @Inject(TranslateService) translateService: TranslateService
  ) {
    super(translateService);
  }

  /**
   * Function responsible to store a new query
   * @param {Query} newQuery
   * @returns {Promise<Query>}
   */
  public createQueryAsync(newQuery: Query): Promise<Query> {
    return new Promise<Query>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      let checkNameQuery = QueriesBDMOCK.Queries.find(
        (query) => newQuery.name === query.name
      );
      this.isLoadingData.hide();

      if (!checkNameQuery) {
        let query: Query = this.objectUtility.cloneObject(newQuery);
        query['createdBy'] = this.authService?.getLoggedUser()?.id;
        query['id'] = this.utilityService.newGuid();

        QueriesBDMOCK.Queries.push(query);
        const queriesUpdated = this.arrayUtilityService.clone(
          QueriesBDMOCK.Queries
        );
        await this.setQueriesChanged(queriesUpdated);
        resolve(query);
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'New Query Created.',
            detailKey: 'Success.',
          })
        );
      } else {
        reject('Queries could not be loaded');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Query not save.',
            detailKey: 'Query name already exists in database',
          })
        );
      }
    });
  }

  /**
   * Function responsible to update a query
   * @param {string} queryId
   * @param {Query} queryUpdated
   * @returns {Promise<Query>}
   */
  public updateQueryAsync(
    queryId: string,
    queryUpdated: Query
  ): Promise<Query> {
    return new Promise<Query>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);

      const queryIndexToUpdate: number = QueriesBDMOCK.Queries.findIndex(
        (query) => {
          return query.id == queryId;
        }
      );
      if (queryIndexToUpdate > -1) {
        QueriesBDMOCK.Queries[queryIndexToUpdate] = queryUpdated;
      }

      const queriesUpdated = this.arrayUtilityService.clone(
        QueriesBDMOCK.Queries
      );
      await this.setQueriesChanged(queriesUpdated);
      this.isLoadingData.hide();
      resolve(queryUpdated);
      this.notificationsService.successNotification(
        new PrgSucess({
          titleKey: 'Query updated.',
          detailKey: 'Success.',
        })
      );
    });
  }

  /**
   * Get all Queries
   * @returns {Promise<Query[]>}
   */
  public getAllQueriesAsync(): Promise<Query[]> {
    return new Promise<Query[]>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const queries: Query[] = this.objectUtility.cloneObject(
        QueriesBDMOCK.Queries
      );
      this.isLoadingData.hide();
      if (queries) {
        resolve(queries);
      } else {
        reject('Queries could not be loaded');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Queries could not be loaded.',
            detailKey: 'Server is down',
          })
        );
      }
    });
  }

  /**
   * Get query by Id
   * @param {string} queryId
   * @returns {Promise<Query>}
   */
  public getQueryByIdAsync(queryId: string): Promise<Query> {
    return new Promise<Query>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const query: Query = this.objectUtility.cloneObject(
        QueriesBDMOCK.Queries.find((query) => {
          return query.id === queryId;
        })
      );
      this.isLoadingData.hide();
      if (query) {
        resolve(query);
      } else {
        reject('Query could not be loaded');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Query could not be loaded.',
            detailKey: 'Server is down',
          })
        );
      }
    });
  }
}
