<div class="flex justify-content-center">
  <div
    class="mainContainer shadow-3 border-1 border-300 surface-card border-round-lg w-28rem"
  >
    <form [formGroup]="registerForm" (ngSubmit)="onSubmitRegister()" novalidate>
      <div
        class="text-center"
        [class]="{ 'my-3': imagePathLogo, 'my-1': !imagePathLogo }"
      >
        <p-image [src]="imagePathLogo" class="w-8" alt=""></p-image>
      </div>
      <div
        class="text-600 text-2xl w-full border-bottom-1 border-top-1 border-300 p-2 mb-4 text-center"
      >
        {{ 'components.register.title' | translate | uppercase }}
      </div>
      <div
        class="px-5"
        [class]="{ 'mb-3': showTermsOfUse, 'mb-4': !showTermsOfUse }"
      >
        <h6 class="text-700">
          {{ 'entities.user.fields.email.label' | translate }}
        </h6>
        <span class="p-input-icon-left w-full"
          ><i class="bi bi-envelope"></i>
          <input
            placeholder="{{
              ('entities.user.fields.email.placeholder' | translate) + '...'
            }}"
            formControlName="email"
            id="email"
            autofocus
            autocomplete="email"
            type="email"
            class="w-full"
            pInputText
        /></span>
        <div
          class="flex flex-column mt-2"
          *ngIf="
            registerForm.controls.email.touched &&
            registerForm.controls.email.dirty
          "
        >
          <small
            class="p-error w-max"
            *ngIf="registerForm.controls.email.hasError('required')"
            >Email is required.</small
          >
          <small
            class="p-error w-max"
            *ngIf="registerForm.controls.email.hasError('email')"
            >Email is not valid.</small
          >
        </div>
      </div>
      <div class="flex align-items-center px-5 mb-4" *ngIf="showTermsOfUse">
        <div class="field-checkbox">
          <p-checkbox
            formControlName="termsOfUse"
            [binary]="true"
            inputId="termsOfUse"
          ></p-checkbox>
          <label for="termsOfUse" class="text-600 text-sm">{{
            'components.register.agree-terms.label' | translate
          }}</label>
          <a
            [style.pointer-events]="
              (isLoadingData.visibility | async) ? 'none' : 'auto'
            "
            routerLink="{{ termsOfUsePageRoute }}"
            class="underlineEffect text-sm blue-500 font-medium ml-1 text-right cursor-pointer"
            style="text-decoration: none"
            >{{ 'components.register.terms-link.label' | translate }}</a
          >
        </div>
      </div>
      <div class="px-5 mb-4 text-center">
        <button
          type="submit"
          [disabled]="!registerForm.valid || (isLoadingData.visibility | async)"
          pButton
          pRipple
          label="{{ 'components.register.button.label' | translate }}"
          icon="pi pi-user"
          class="w-full"
        ></button>
      </div>
      <div class="px-5 mb-3 text-center" *ngIf="showLoginLink">
        <label class="text-600 text-sm">{{
          'components.register.question-account.label' | translate
        }}</label>
        <a
          routerLink="{{ loginPageRoute }}"
          [style.pointer-events]="
            (isLoadingData.visibility | async) ? 'none' : 'auto'
          "
          class="underlineEffect text-sm blue-500 font-medium ml-1 text-right cursor-pointer"
          style="text-decoration: none"
          >{{ 'components.register.login-link.label' | translate }}</a
        >
      </div>
    </form>
  </div>
</div>
