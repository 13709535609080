/**
 * this class serves to have a configuration of
 *  what happens after a certain action is successfully executed
 */
export class ExecutedActionBehaviour {
  /**
   * reset table does a complete reset of the table(clean filters, paginations)
   */
  resetTable: boolean = false;

  /**
   * reload data
   */
  reloadData: boolean = true;

  /**
   * redirect to list
   */
  redirectToList: boolean = true;

  /**
   * change view mode to read
   */
  changeViewModeToRead: boolean = true;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<ExecutedActionBehaviour>) {
    Object.assign(this, init);
  }
}
