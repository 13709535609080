
<div *ngIf="form && configs != null" [formGroup]="form">
  <p>{{configs.basePathTranslation + '.' + configs.key + '.label' | translate}}</p>
  <p-listbox
    [form]="form"
    [setListBoxConfigs]="configs"
    [id]="configs.key"
    [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
    [formControlName]="configs.key"
    [filterPlaceHolder]="
        configs.basePathTranslation + '.' + configs.key + '.filterPlaceholder'
          | translate
      "
    (onChange)="onChanged($event)"
  ></p-listbox>

</div>
