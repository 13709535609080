import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { RadioButtonConfiguration } from './radio-button.model';

/**
 * component Radio button
 */
@Component({
  selector: 'prg-radio-button-field',
  templateUrl: './radio-button.component.html',
})
export class PrgRadioButtonComponent {
  /**
   * Radio Button Configs
   */
  @Input() configs: RadioButtonConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the output
   */
  public onChanged(event: any): void {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          event: event,
          value: event.value,
        })
      );
    }
  }
}
