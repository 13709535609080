import { Component, Input, OnInit } from '@angular/core';
import { Terms } from '../models/terms.model';
import { TermsService } from '../services/terms/terms.service';

/**
 * Terms Component
 */
@Component({
  selector: 'prg-terms',
  templateUrl: './prg-terms.component.html',
  styleUrls: ['./prg-terms.component.scss'],
})
export class PrgTermsComponent implements OnInit {
  /**
   * Terms and Condition list
   */
  public terms: Terms[] = [];

  /**
   * Terms & Conditions title. Default is Terms & Conditions
   */
  @Input() termsTitle: string = 'Terms & Conditions';
  /**
   * Terms & Conditions image.
   */
  @Input() termsImage: string = 'assets/images/Terms-and-Conditions-1.png';

  /**
   * constructor
   * @param {TermsService} termsService
   */
  constructor(private termsService: TermsService) {}

  /**
   * ngOnInit
   *
   * Fetch terms & conditions
   */
  async ngOnInit() {
    this.terms = this.termsService.getTermsSections('');
  }
}
