import { GridsterConfig } from 'angular-gridster2';
import { GridsterComponentInterface } from 'angular-gridster2/lib/gridster.interface';
import {
  displayGrids,
  PushDirections,
} from 'angular-gridster2/lib/gridsterConfig.interface';
import {
  GridsterItem,
  GridsterItemComponentInterface,
} from 'angular-gridster2/lib/gridsterItem.interface';

/**
 * Class PrgGridsterConfig to be used on dashboards options
 */
export class PrgGridsterConfig implements GridsterConfig {
  /**
   * Different types for layout for the grid.
   * @type {PrgGridType}
   */
  gridType?: PrgGridType = PrgGridType.Fit;
  /**
   * scale param to zoom in/zoom out
   * @type {number}
   */
  scale?: number = 1;
  /**
   * fixed col width for gridType: fixed
   * @type {number}
   */
  fixedColWidth?: number = 250;
  /**
   * fixed row height for gridType: fixed
   * @type {number}
   */
  fixedRowHeight?: number = 250;
  /**
   * keep the height from fixed gridType in mobile layout
   * @type {boolean}
   */
  keepFixedHeightInMobile?: boolean = false;
  /**
   * keep the width from fixed gridType in mobile layout
   * @type {boolean}
   */
  keepFixedWidthInMobile?: boolean = false;
  /**
   * sets grid size depending on content
   * @type {boolean}
   */
  setGridSize?: boolean = false;

  /**
   * use the width of the <body> element to determine when to switch to the mobile layout
   * @type {boolean}
   */
  useBodyForBreakpoint?: boolean = false;
  /**
   * compact items
   * @type {PrgCompactType}
   */
  compactType?: PrgCompactType = PrgCompactType.None;
  /**
   *  if the screen is not wider that this, remove the grid layout and stack the items
   * @type {number}
   */
  mobileBreakpoint?: number = 640;
  /**
   * allow items show in layers
   * @type {boolean}
   */
  allowMultiLayer?: boolean = false;
  /**
   * default layer index of an item in gridster
   * @type {number}
   */
  defaultLayerIndex?: number = 1;
  /**
   * max layer index of an item in gridster
   * @type {number}
   */
  maxLayerIndex?: number = 2;
  /**
   * base layer index of an item in gridster, final z-index should be baseLayerIndex + layerIndex
   * @type {number}
   */
  baseLayerIndex?: number = 1;
  /**
   * minimum amount of columns in the grid
   * @type {number}
   */
  minCols?: number = 1;
  /**
   * maximum amount of columns in the grid
   * @type {number}
   */
  maxCols?: number = 100;
  /**
   * minimum amount of rows in the grid
   * @type {number}
   */
  minRows?: number = 1;
  /**
   * maximum amount of rows in the grid
   * @type {number}
   */
  maxRows?: number = 100;
  /**
   * default width of an item in columns
   * @type {number}
   */
  defaultItemCols?: number = 1;
  /**
   * default height of an item in rows
   * @type {number}
   */
  defaultItemRows?: number = 1;
  /**
   * maximum item number of cols
   * @type {number}
   */
  maxItemCols?: number = 50;
  /**
   * maximum item number of rows
   * @type {number}
   */
  maxItemRows?: number = 50;
  /**
   * 	minimum item number of cols
   * @type {number}
   */
  minItemCols?: number = 1;
  /**
   * minimum item number of row
   * @type {number}
   */
  minItemRows?: number = 1;
  /**
   * minimum item area: cols * rows
   * @type {number}
   */
  minItemArea?: number = 1;
  /**
   * maximum item area: cols * rows
   * @type {number}
   */
  maxItemArea?: number = 2500;
  /**
   * add a number of extra empty rows at the end
   * @type {number}
   */
  addEmptyRowsCount?: number = 0;
  /**
   * row height ratio from column width for gridType: scrollVertical and scrollHorizontal
   * @type {number}
   */
  rowHeightRatio?: number = 1;

  /**
   * margin between grid items
   * @type {number}
   */
  margin?: number = 5;
  /**
   * if margins will apply to the sides of the container
   * @type {boolean}
   */
  outerMargin?: boolean = true;
  /**
   * override top outer margin for grid
   * @type {number | null}
   */
  outerMarginTop?: number | null = null;
  /**
   * override right outer margin for grid
   * @type {number | null}
   */
  outerMarginRight?: number | null = null;
  /**
   * override bottom outer margin for grid
   * @type {number | null}
   */
  outerMarginBottom?: number | null = null;
  /**
   * override left outer margin for grid
   * @type {number | null}
   */
  outerMarginLeft?: number | null = null;
  /**
   * use of transform positioning property
   * @type {boolean}
   */
  useTransformPositioning?: boolean = true;
  /**
   * Scroll sensitivity
   * @type {number | null}
   */
  scrollSensitivity?: number | null = 10;
  /**
   * scroll speed
   * @type {number}
   */
  scrollSpeed?: number = 20;
  /**
   * callback to call after grid has initialized
   * @type {(gridster: GridsterComponentInterface) => void}
   */
  initCallback?: (gridster: GridsterComponentInterface) => void;
  /**
   * callback to call after grid has destroyed
   * @type {(gridster: GridsterComponentInterface) => void}
   */
  destroyCallback?: (gridster: GridsterComponentInterface) => void;
  /**
   * callback to call after grid has changed size cols/rows
   * @type {(gridster: GridsterComponentInterface) => void}
   */
  gridSizeChangedCallback?: (gridster: GridsterComponentInterface) => void;
  /**
   * callback to call for each item when is changes x, y, rows, cols
   * @type {(item: PrgGridsterItem, itemComponent: GridsterItemComponentInterface) => void}
   */
  itemChangeCallback?: (
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface
  ) => void;
  /**
   * callback to call for each item when width/height changes
   * @type {(item: PrgGridsterItem, itemComponent: GridsterItemComponentInterface) => void}
   */
  itemResizeCallback?: (
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface
  ) => void;
  /**
   * callback to call for each item when is initialized
   * @type {(item: PrgGridsterItem, itemComponent: GridsterItemComponentInterface) => void}
   */
  itemInitCallback?: (
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface
  ) => void;
  /**
   * callback to call for each item when is removed
   * @type {(item: PrgGridsterItem, itemComponent: GridsterItemComponentInterface) => void}
   */
  itemRemovedCallback?: (
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface
  ) => void;
  /**
   * callback to call to validate item position/size. Return true if valid.
   * @type {(item: PrgGridsterItem) => boolean}
   */
  itemValidateCallback?: (item: PrgGridsterItem) => boolean;
  /**
   * PrgDraggable Object
   * @type {PrgDraggable}
   */
  draggable?: PrgDraggable = new PrgDraggable({});
  /**
   *  PrgResizable Object
   * @type {PrgResizable}
   */
  resizable?: PrgResizable = new PrgResizable({});
  /**
   * allow items to switch position if drop on top of another
   * @type {boolean}
   */
  swap?: boolean = true;
  /**
   * swap items while dragging and save new position
   * @type {boolean}
   */
  swapWhileDragging?: boolean = false;
  /**
   * push items when resizing and dragging
   * @type {boolean}
   */
  pushItems?: boolean = false;
  /**
   * disable push on drag
   * @type {boolean}
   */
  disablePushOnDrag?: boolean = false;
  /**
   * disable push on resize
   * @type {boolean}
   */
  disablePushOnResize?: boolean = false;
  /**
   * disable auto-position of items on conflict state
   * @type {boolean}
   */
  disableAutoPositionOnConflict?: boolean = false;
  /**
   * control the directions items are pushed
   * @type {PushDirections}
   */
  pushDirections?: PrgPushDirections = new PrgPushDirections({});
  /**
   * on resize of item will shrink adjacent items
   * @type {boolean}
   */
  pushResizeItems?: boolean = false;
  /**
   * display background grid of rows and columns
   * @type {displayGrids}
   */
  displayGrid?: PrgDisplayGrid = PrgDisplayGrid.Always;
  /**
   * disable the window on resize listener. This will stop grid to recalculate on window resize.
   * @type {boolean}
   */
  disableWindowResize?: boolean = false;
  /**
   * disable console log warnings about misplacement of grid items
   * @type {boolean}
   */
  disableWarnings?: boolean = false;
  /**
   * scroll to new items placed in a scrollable view
   * @type {boolean}
   */
  scrollToNewItems?: boolean = false;
  /**
   * enable/disable auto horizontal scrolling when on edge of grid
   * @type {boolean}
   */
  disableScrollHorizontal?: boolean = false;
  /**
   * enable/disable auto vertical scrolling when on edge of grid
   * @type {boolean}
   */
  disableScrollVertical?: boolean = false;
  /**
   * enable/disable boundary control while dragging items
   * @type {boolean}
   */
  enableBoundaryControl?: boolean = false;
  /**
   * enable empty cell click events
   * @type {boolean}
   */
  enableEmptyCellClick?: boolean = true;
  /**
   * enable empty cell context menu (right click) events
   * @type {boolean}
   */
  enableEmptyCellContextMenu?: boolean = false;
  /**
   * enable empty cell drop events
   * @type {boolean}
   */
  enableEmptyCellDrop?: boolean = false;
  /**
   * enable empty cell drag events
   * @type {boolean}
   */
  enableEmptyCellDrag?: boolean = true;
  /**
   * enable occupied cell drop events
   * @type {boolean}
   */
  enableOccupiedCellDrop?: boolean = false;
  /**
   * empty cell click callback
   * @type {(event: MouseEvent, item: PrgGridsterItem) => void}
   */
  emptyCellClickCallback?: (event: MouseEvent, item: PrgGridsterItem) => void;
  /**
   * empty cell context menu (right click) callback
   * @type {(event: MouseEvent, item: PrgGridsterItem) => void}
   */
  emptyCellContextMenuCallback?: (
    event: MouseEvent,
    item: PrgGridsterItem
  ) => void;
  /**
   * empty cell drag drop callback. HTML5 Drag & Drop
   * @type {(event: DragEvent, item: PrgGridsterItem) => void}
   */
  emptyCellDropCallback?: (event: DragEvent, item: PrgGridsterItem) => void;
  /**
   * empty cell drag and create item like excel cell
   * @type {(event: MouseEvent, item: PrgGridsterItem) => void}
   */
  emptyCellDragCallback?: (event: MouseEvent, item: PrgGridsterItem) => void;
  /**
   * limit empty cell drag max cols
   * @type {number}
   */
  emptyCellDragMaxCols?: number = 50;
  /**
   * limit empty cell drag max rows
   * @type {number}
   */
  emptyCellDragMaxRows?: number = 50;
  /**
   * ignore the gap between rows for items which span multiple rows (see #162, #224) only for gridType: fixed , verticalFixed, horizontalFixed
   * @type {boolean}
   */
  ignoreMarginInRow?: boolean = false;
  /**
   * direction type
   * @type {PrgDirTypes}
   */
  dirType?: PrgDirTypes = PrgDirTypes.LTR;
  /**
   * Can be access from this.options.api after the grid has initialized.
   * @type {{resize?: () => void, optionsChanged?: () => void, getNextPossiblePosition?: (newItem: PrgGridsterItem) => boolean, getFirstPossiblePosition?: (item: PrgGridsterItem) => PrgGridsterItem, getLastPossiblePosition?: (item: PrgGridsterItem) => PrgGridsterItem, getItemComponent?: (item: PrgGridsterItem) => (GridsterItemComponentInterface | undefined)}}
   */
  api?: {
    /**
     * call if size of container changes. Grid will auto resize on window resize
     */
    resize?: () => void;
    /**
     * call on change of options after initialization
     */
    optionsChanged?: () => void;
    /**
     * call to get a viable position for item. Returns true if found.
     * @param {PrgGridsterItem} newItem
     * @returns {boolean}
     */
    getNextPossiblePosition?: (newItem: PrgGridsterItem) => boolean;
    /**
     * call to get the first viable position for an item. Returns a copy of the item with the future position.
     * @param {PrgGridsterItem} item
     * @returns {PrgGridsterItem}
     */
    getFirstPossiblePosition?: (item: PrgGridsterItem) => PrgGridsterItem;
    /**
     * call to get a viable position for item. Returns a copy of the item with the future position.
     * @param {PrgGridsterItem} item
     * @returns {PrgGridsterItem}
     */
    getLastPossiblePosition?: (item: PrgGridsterItem) => PrgGridsterItem;
    /**
     * call to get a internal component for item. Returns a component item or undefined if not found.
     * @param {PrgGridsterItem} item
     * @returns {GridsterItemComponentInterface | undefined}
     */
    getItemComponent?: (
      item: PrgGridsterItem
    ) => GridsterItemComponentInterface | undefined;
  };

  /**
   * Other properties
   */
  [propName: string]: any;

  /**
   * Constructor
   * @param {Partial<PrgGridsterConfig>} init
   */
  constructor(init?: Partial<PrgGridsterConfig>) {
    Object.assign(this, init);
  }
}

/**
 * Enum to be used on property gridType of PrgGridsterConfig
 */
export enum PrgGridType {
  Fit = 'fit',
  ScrollVertical = 'scrollVertical',
  ScrollHorizontal = 'scrollHorizontal',
  Fixed = 'fixed',
  VerticalFixed = 'verticalFixed',
  HorizontalFixed = 'horizontalFixed',
}

/**
 * Enum to be used on property displayGrid of PrgGridsterConfig
 */
export enum PrgDisplayGrid {
  Always = 'always',
  OnDragAndResize = 'onDrag&Resize',
  None = 'none',
}

/**
 * Enum to be used on property compactType of PrgGridsterConfig
 */
export enum PrgCompactType {
  None = 'none',
  CompactUp = 'compactUp',
  CompactLeft = 'compactLeft',
  CompactUpAndLeft = 'compactUp&Left',
  CompactLeftAndUp = 'compactLeft&Up',
  CompactRight = 'compactRight',
  CompactUpAndRight = 'compactUp&Right',
  CompactRightAndUp = 'compactRight&Up',
  CompactDown = 'compactDown',
  CompactDownAndLeft = 'compactDown&Left',
  CompactLeftAndDown = 'compactLeft&Down',
  CompactDownAndRight = 'compactDown&Right',
  CompactRightAndDown = 'compactRight&Down',
}

/**
 * Enum to be used on property dirType of PrgGridsterConfig
 */
export enum PrgDirTypes {
  LTR = 'ltr',
  RTL = 'rtl',
}

/**
 * Class PrgDragBase
 */
export class PrgDragBase {
  /**
   * enable/disable draggable items
   * @type {boolean}
   */
  enabled?: boolean = true;
  /**
   * callback when dragging an item stops. Accepts Promise return to cancel/approve drag
   * @type {(item: PrgGridsterItem, itemComponent: GridsterItemComponentInterface, event: MouseEvent) => (Promise<any> | void)}
   */
  stop?: (
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface,
    event: MouseEvent
  ) => Promise<any> | void;
  /**
   * callback when dragging an item starts
   * @type {(item: PrgGridsterItem, itemComponent: GridsterItemComponentInterface, event: MouseEvent) => void}
   */
  start?: (
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface,
    event: MouseEvent
  ) => void;
  /**
   * milliseconds to delay the start of drag, useful for touch interaction
   * @type {number}
   */
  delayStart?: number = 0;

  /**
   * Constructor
   * @param {Partial<PrgDragBase>} init
   */
  constructor(init?: Partial<PrgDragBase>) {
    Object.assign(this, init);
  }
}

/**
 * Class PrgDraggable
 */
export class PrgDraggable extends PrgDragBase {
  /**
   * default content class to ignore the drag event from
   * @type {string}
   */
  ignoreContentClass?: string = 'gridster-item-content';
  /**
   *  if true drag will start only from elements from dragHandleClass
   * @type {boolean}
   */
  ignoreContent?: boolean = false;
  /**
   * drag event only from this class. If ignoreContent is true.
   * @type {string}
   */
  dragHandleClass?: string = 'drag-handle';
  /**
   * enable items drop over another, will work if swap and push is disabled
   * @type {boolean}
   */
  dropOverItems?: boolean = true;
  /**
   * callback when dragging an item drops over another item
   * @type {(source: PrgGridsterItem, target: PrgGridsterItem, grid?: GridsterComponentInterface) => void}
   */
  dropOverItemsCallback?: (
    source: PrgGridsterItem,
    target: PrgGridsterItem,
    grid?: GridsterComponentInterface
  ) => void;

  /**
   * Constructor
   * @param {Partial<PrgDraggable>} init
   */
  constructor(init?: Partial<PrgDraggable>) {
    super(new PrgDragBase({}));
    Object.assign(this, init);
  }
}

/**
 * Class PrgRezisable
 */
export class PrgResizable extends PrgDragBase {
  /**
   * resizable edges of an item
   * @type {{s: boolean, e: boolean, n: boolean, w: boolean, se: boolean, ne: boolean, sw: boolean, nw: boolean}}
   */
  handles?: PrgResizableHandles = new PrgResizableHandles({});

  /**
   * Constructor
   * @param {Partial<PrgResizable>} init
   */
  constructor(init?: Partial<PrgResizable>) {
    super(new PrgDragBase({}));
    Object.assign(this, init);
  }
}

/**
 * Class PrgPushDirections
 */
export class PrgPushDirections {
  /**
   * Push direction north
   * @type {boolean}
   */
  north: boolean = true;
  /**
   * Push direction east
   * @type {boolean}
   */
  east: boolean = true;
  /**
   * Push direction south
   * @type {boolean}
   */
  south: boolean = true;
  /**
   * Push direction west
   * @type {boolean}
   */
  west: boolean = true;

  /**
   * Constructor
   * @param {Partial<PrgPushDirections>} init
   */
  constructor(init?: Partial<PrgPushDirections>) {
    Object.assign(this, init);
  }
}

/**
 * Class PrgResizableHandles
 */
export class PrgResizableHandles {
  /**
   * south
   * @type {boolean}
   */
  s: boolean = true;
  /**
   * east
   * @type {boolean}
   */
  e: boolean = true;
  /**
   * north
   * @type {boolean}
   */
  n: boolean = true;
  /**
   * west
   * @type {boolean}
   */
  w: boolean = true;
  /**
   * southeast
   * @type {boolean}
   */
  se: boolean = true;
  /**
   * northeast
   * @type {boolean}
   */
  ne: boolean = true;
  /**
   * southwest
   * @type {boolean}
   */
  sw: boolean = true;
  /**
   * northwest
   * @type {boolean}
   */
  nw: boolean = true;

  /**
   * Constructor
   * @param {Partial<PrgResizableHandles>} init
   */
  constructor(init?: Partial<PrgResizableHandles>) {
    Object.assign(this, init);
  }
}

/**
 * Class PrgGridsterItem to be used on items of dashboards
 */
export class PrgGridsterItem implements GridsterItem {
  /**
   * x position if missing will auto position
   * @type {number}
   */
  x: number;
  /**
   * y position if missing will auto position
   * @type {number}
   */
  y: number;
  /**
   * number of rows if missing will use grid option defaultItemRows
   * @type {number}
   */
  rows: number;
  /**
   * number of columns if missing will use grid option defaultItemCols
   * @type {number}
   */
  cols: number;
  /**
   * layer index
   * @type {number}
   */
  layerIndex?: number;
  /**
   * initialization callback and has size > 0
   * @type {(item: PrgGridsterItem, itemComponent: GridsterItemComponentInterface) => void}
   */
  initCallback?: (
    item: PrgGridsterItem,
    itemComponent: GridsterItemComponentInterface
  ) => void;
  /**
   * override grid option draggable.enabled
   * @type {boolean}
   */
  dragEnabled?: boolean;
  /**
   * override grid option resizable.enabled
   * @type {boolean}
   */
  resizeEnabled?: boolean;
  /**
   * override grid option resizable.handles for this item
   * @type {PrgResizableHandles}
   */
  resizableHandles?: PrgResizableHandles = new PrgResizableHandles({
    s: null,
    e: null,
    n: null,
    w: null,
    se: null,
    ne: null,
    sw: null,
    nw: null,
  });
  /**
   * disable grid option compact for this item
   * @type {boolean}
   */
  compactEnabled?: boolean;
  /**
   * override grid option maxItemRows
   * @type {number}
   */
  maxItemRows?: number;
  /**
   * override grid option minItemRows
   * @type {number}
   */
  minItemRows?: number;
  /**
   * override grid option maxItemCols
   * @type {number}
   */
  maxItemCols?: number;
  /**
   * override grid option minItemCols
   * @type {number}
   */
  minItemCols?: number;
  /**
   * override grid option minItemArea
   * @type {number}
   */
  minItemArea?: number;
  /**
   * override grid option maxItemArea
   * @type {number}
   */
  maxItemArea?: number;

  /**
   * Other properties
   */
  [propName: string]: any;

  /**
   * Constructor
   * @param {Partial<PrgGridsterItem>} init
   */
  constructor(init?: Partial<PrgGridsterItem>) {
    Object.assign(this, init);
  }
}
