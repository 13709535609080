import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrgError } from '../../../../core/models/error.model';
import { PrgSucess } from '../../../../core/models/success.model';
import { IsLoadingDataService } from '../../../../core/services/is-loading-data/isloadingdata.service';
import { NotificationsService } from '../../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../../core/services/utility/utility.service';
import {
  LookupTable,
  LookupTableItem,
} from '../../../models/lookup-table.model';
import { AbstractLookupTableService } from '../../../services/lookup-table/abstract-lookup-table.service';
import { MockDatabase } from '../../database.mock';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * class MockLookupTableService
 */
export class MockLookupTableService extends AbstractLookupTableService {
  /**
   * default time in seconds
   */
  private sleepTimeSec = 0.5;

  /**
   * Construtor
   *
   * @param isLoadingDataService
   * @param notificationsService
   * @param utilityService
   * @param objectsUtilityService
   */
  constructor(
    private isLoadingDataService: IsLoadingDataService,
    private notificationsService: NotificationsService,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    @Inject(TranslateService) translateService: TranslateService,
    private arrayUtilityService: ArrayUtilityService
  ) {
    super(translateService);
  }

  /**
   * this method returns all the lookuptables
   *
   * @returns  Promise<LookupTable[]
   */
  public getAllLookupTableAsync(): Promise<LookupTable[]> {
    return new Promise<LookupTable[]>(async (resolve, reject) => {
      this.isLoadingDataService.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      this.isLoadingDataService.hide();

      if (this.isCacheValid()) {
        const lookupTables = this.getElementsList();
        resolve(lookupTables);
      } else {
      }
      const lookupTables = this.arrayUtilityService.clone(
        MockDatabase.LookupTable
      );
      if (lookupTables !== null && lookupTables.length > 0) {
        this.setToCache(lookupTables, 'name');

        resolve(lookupTables);
      } else {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'errorGetLookupTableList',
          })
        );
        reject(null);
      }
    });
  }

  /**
   * this method is responsible for saving a new lookupTable
   * or update an existing one
   *
   * @param lookupTable
   * @returns LookupTable
   */
  public saveLookupTableAsync(lookupTable: LookupTable): Promise<LookupTable> {
    return new Promise<LookupTable>(async (resolve, reject) => {
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);

      if (lookupTable.id) {
        const lookupTableToUpdate = MockDatabase.LookupTable.find(
          (ltAux) => ltAux.id === lookupTable.id
        );
        if (lookupTableToUpdate) {
          lookupTableToUpdate.description = lookupTable.description;
          lookupTableToUpdate.universalStateId = lookupTable.universalStateId;
          resolve(this.objectsUtilityService.cloneObject(lookupTableToUpdate));
          //set catch
          this.setToCache(
            this.objectsUtilityService.cloneObject(lookupTableToUpdate),
            'name'
          );

          this.notificationsService.successNotification(
            new PrgSucess({
              titleKey: 'success',
              detailKey: 'lookupTableSuccessAdded',
            })
          );
        } else {
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'errorAddLookupTable',
            })
          );
          reject(null);
        }
      } else {
        const newLookupTable: LookupTable = {
          id: this.utilityService.newGuid(),
          universalStateId: lookupTable.universalStateId,
          transactionId: null,
          operationId: null,
          name: lookupTable.name,
          label: null,
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          description: lookupTable.description,
          workspaceId: null,
          items: null,
        };

        const lookupTableList = MockDatabase.LookupTable;
        lookupTableList.unshift(newLookupTable);

        this.setToCache(
          this.objectsUtilityService.cloneObject(newLookupTable),
          'name'
        );

        resolve(this.objectsUtilityService.cloneObject(newLookupTable));

        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'success',
            detailKey: 'lookupTableSuccessAdded',
          })
        );
      }
    });
  }

  /**
   * this method is responsible for adding or
   * updating a set of lookup tables
   *
   * @param lookupTables
   * @returns LookupTable[]
   */
  public saveMultipleLookupTableAsync(
    lookupTables: LookupTable[]
  ): Promise<LookupTable[]> {
    return new Promise<LookupTable[]>(async (resolve, reject) => {
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      let savedLookupTable: LookupTable[] = [];
      lookupTables.forEach((lt) => {
        if (lt.id) {
          const lookupTableToUpdate = MockDatabase.LookupTable.find(
            (ltAux) => ltAux.id === lt.id
          );
          if (lookupTableToUpdate) {
            lookupTableToUpdate.description = lt.description;
            lookupTableToUpdate.universalStateId = lt.universalStateId;
            savedLookupTable.push(lookupTableToUpdate);
          }
        } else {
          const newLookupTable: LookupTable = {
            id: this.utilityService.newGuid(),
            universalStateId: lt.universalStateId,
            transactionId: null,
            operationId: null,
            name: lt.name,
            label: null,
            createdBy: null,
            modifiedBy: null,
            createdOn: null,
            modifiedOn: null,
            description: lt.description,
            workspaceId: null,
            items: null,
          };

          const lookupTableList = MockDatabase.LookupTable;
          lookupTableList.unshift(newLookupTable);
          savedLookupTable.push(newLookupTable);
        }
      });
      resolve(savedLookupTable);
      this.notificationsService.successNotification(
        new PrgSucess({
          titleKey: 'success',
          detailKey: 'sucessedsaveMultipleLookupTableAsync',
        })
      );
    });
  }

  /**
   * this method returns the items of a given lookup table
   *
   * @param id
   * @returns LookupTableItem[]
   */
  public getLookupTableItemsByLookupTableIdAsync(
    id: string
  ): Promise<LookupTableItem[]> {
    return new Promise<LookupTableItem[]>(async (resolve, reject) => {
      this.isLoadingDataService.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);

      const lookupTableItems = MockDatabase.LookupTableItems.filter(
        (item) => item.lookupTableId == id
      );

      resolve(lookupTableItems);
      this.isLoadingDataService.hide();
    });
  }

  /**
   * this method returns the items of a given lookup table
   *
   * @param id
   * @returns LookupTableItem[]
   */
  public getLookupTableItemsByLookupTableNameAsync(
    name: string
  ): Promise<LookupTableItem[]> {
    return new Promise<LookupTableItem[]>(async (resolve, reject) => {
      this.isLoadingDataService.show();
      const lookupTable = this.getElementByKey(name);
      if (
        this.isCacheValid() &&
        lookupTable != null &&
        lookupTable.items != null
      ) {
        resolve(lookupTable.items);
        this.isLoadingDataService.hide();
      } else {
        await this.utilityService.sleepSecAsync(this.sleepTimeSec);
        const lookupTable = MockDatabase.LookupTable.find(
          (lookupTable) => lookupTable.name === name
        );
        const lookupTableItems = MockDatabase.LookupTableItems.filter(
          (item) => item.lookupTableId == lookupTable.id
        );

        lookupTable.items = lookupTableItems;

        const lookupTableTranslated = await this.getTranslations(lookupTable);

        this.setToCache(lookupTableTranslated, 'name');

        resolve(lookupTableTranslated.items);
        this.isLoadingDataService.hide();
      }
    });
  }
  /**
   * this method is responsible for saving a new lookupTable
   * or update an existing one
   *
   * @param lookupTable
   * @returns LookupTable
   */
  public saveLookupTableItemAsync(
    lookupTableItem: LookupTableItem
  ): Promise<LookupTableItem> {
    return new Promise<LookupTableItem>(async (resolve, reject) => {
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);

      if (lookupTableItem.id) {
        const lookupTableItemToUpdate = MockDatabase.LookupTableItems.find(
          (ltiAux) => ltiAux.id === lookupTableItem.id
        );
        if (lookupTableItemToUpdate) {
          lookupTableItemToUpdate.description = lookupTableItem.description;
          lookupTableItemToUpdate.universalStateId =
            lookupTableItem.universalStateId;
          resolve(
            this.objectsUtilityService.cloneObject(lookupTableItemToUpdate)
          );
          this.notificationsService.successNotification(
            new PrgSucess({
              titleKey: 'success',
              detailKey: 'lookupTableSuccessAdded',
            })
          );
        } else {
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'errorAddLookupTable',
            })
          );
          reject(null);
        }
      } else {
        const newLookupTableItem: LookupTableItem = {
          id: this.utilityService.newGuid(),
          universalStateId: lookupTableItem.universalStateId,
          transactionId: null,
          operationId: null,
          name: lookupTableItem.name,
          label: null,
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          description: lookupTableItem.description,
          workspaceId: null,
          lookupTableId: lookupTableItem.lookupTableId,
        };

        const lookupTableList = MockDatabase.LookupTableItems;
        lookupTableList.unshift(newLookupTableItem);
        resolve(this.objectsUtilityService.cloneObject(newLookupTableItem));
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'success',
            detailKey: 'lookupTableItemSuccessAdded',
          })
        );
      }
    });
  }

  /**
   * this method is responsible for adding or
   * updating a set of lookup tables items
   *
   * @param lookupTablesItems
   * @returns LookupTableItems[]
   */
  public saveMultipleLookupTableItemsAsync(
    lookupTables: LookupTableItem[]
  ): Promise<LookupTableItem[]> {
    return new Promise<LookupTableItem[]>(async (resolve, reject) => {
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      let savedLookupTableItems: LookupTableItem[] = [];
      lookupTables.forEach((lt) => {
        if (lt.id) {
          const lookupTableItemToUpdate = MockDatabase.LookupTableItems.find(
            (ltAux) => ltAux.id === lt.id
          );
          if (lookupTableItemToUpdate) {
            lookupTableItemToUpdate.description = lt.description;
            lookupTableItemToUpdate.universalStateId = lt.universalStateId;
            savedLookupTableItems.push(lookupTableItemToUpdate);
          }
        } else {
          const newLookupTableItem: LookupTableItem = {
            id: this.utilityService.newGuid(),
            universalStateId: lt.universalStateId,
            transactionId: null,
            operationId: null,
            name: lt.name,
            label: null,
            createdBy: null,
            modifiedBy: null,
            createdOn: null,
            modifiedOn: null,
            description: lt.description,
            workspaceId: null,
            lookupTableId: lt.lookupTableId,
          };

          const lookupTableList = MockDatabase.LookupTableItems;
          lookupTableList.unshift(newLookupTableItem);
          savedLookupTableItems.push(newLookupTableItem);
        }
      });
      resolve(savedLookupTableItems);
      this.notificationsService.successNotification(
        new PrgSucess({
          titleKey: 'success',
          detailKey: 'sucessedsaveMultipleLookupTableAsync',
        })
      );
    });
  }

  /**
   * Get lookup table item by id
   * @param {string} lookUpTableItemId
   * @returns {Promise<LookupTableItem>}
   */
  public getLookUpTableItemByIdAsync(
    lookUpTableItemId: string
  ): Promise<LookupTableItem> {
    return new Promise<LookupTableItem>(async (resolve, reject) => {
      this.isLoadingDataService.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const lookUpTableItemsList = [...MockDatabase.LookupTableItems];
      this.isLoadingDataService.hide();
      let lookUpTableItem = lookUpTableItemsList.find((lookUpTableItem) => {
        return lookUpTableItem.id == lookUpTableItemId;
      });
      if (lookUpTableItem) {
        resolve(lookUpTableItem);
      } else {
        reject('');
      }
    });
  }

  /**
   *
   */
  public getMultipleLookupTableByName(
    lookupTableNames: string[]
  ): Promise<LookupTable[]> {
    return new Promise<LookupTable[]>(async (resolve, reject) => {
      const lookupTablesList: LookupTable[] = [];

      const lookUpTables = this.arrayUtilityService.clone(
        MockDatabase.LookupTable
      );
      const lookUpTableItems = this.arrayUtilityService.clone(
        MockDatabase.LookupTableItems
      );
      if (this.isCacheValid()) {
        lookupTableNames.forEach((name) => {
          let lookupTableAux: LookupTable = this.getElementByKey(name);

          if (!lookupTableAux) {
            lookupTableAux = lookUpTables.find((lt) => lt.name === name);

            if (lookupTableAux) {
              lookupTableAux.items = lookUpTableItems.filter(
                (li: LookupTableItem) => li.lookupTableId === lookupTableAux.id
              );
            }

            //TODO: error not found lookuptable
          } else if (!lookupTableAux.items) {
            lookupTableAux.items = lookUpTableItems.filter(
              (li: LookupTableItem) => li.lookupTableId === lookupTableAux.id
            );
          }
          lookupTablesList.push(lookupTableAux);
        });
      } else {
        lookupTableNames.forEach(async (name) => {
          const lookupTableAux = lookUpTables.find((lt) => lt.name === name);

          if (lookupTableAux) {
            lookupTableAux.items = lookUpTableItems.filter(
              (li: LookupTableItem) => li.lookupTableId === lookupTableAux.id
            );
            const lookupTableAuxTranslated = await this.getTranslations(
              lookupTableAux
            );

            lookupTablesList.push(lookupTableAuxTranslated);
          }
        });
      }
      resolve(lookupTablesList);
      this.setToCache(lookupTablesList, 'name');
    });
  }
}
