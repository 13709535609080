import { Inject, Injectable, InjectionToken } from "@angular/core";
import { PrgEntityTypesConfig } from "../../models/prg-entity-types-config";

/**
 * Inject token
 */
export const PRG_ENTITY_TYPES_CONFIG = new InjectionToken<PrgEntityTypesConfig>(
  "PRG_ENTITY_TYPES_CONFIG"
);

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class PrgEntityTypesConfigurationService {
  /**
   * constructor
   *
   * @param prgEntityTypesConfig
   */
  constructor(
    @Inject(PRG_ENTITY_TYPES_CONFIG)
    private prgEntityTypesConfig: PrgEntityTypesConfig
  ) {}
}
