import { BaseEntity } from '../../core/models/base-entity.model';

/**
 * HttpVerb string enum
 */
export enum HttpVerb {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

/**
 * EntityTypeProperty class that defines a dynamic
 * <br />property of an EntityType, which WILL BE
 * <br />COMPILED into the dll
 */
export class EntityTypeProperty extends BaseEntity {
  /**
   * Entity type workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Defines a label to be used in template and translations
   */
  public label: string;
  /**
   * Entity type id (foreign key)
   */
  public entityTypeId: string;
  /**
   * Gui Settings to be used by generic components
   * <br /> on the front-end. JSON stringified object
   */
  public guiSettings: string;
  /**
   * Lookup table item id for datatype (foreign key)
   */
  public dataTypeId: string;

  /**
   * this field is to indicate if
   * we are going to have an array of some type
   */
  public isArray: boolean = false;

  /**
   * Property order
   */
  public order: number;

  /**
   * group
   */
  public group?: string;

  /**
   * Property reference, used when DataType is:
   * <br />LookupTable or EntityType
   */
  public reference: string;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<EntityTypeProperty>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * EntityTypeAttribute class that defines an
 * <br />attribute of an EntityType, which WILL NOT
 * <br />BE COMPILED into the dll
 */
export class EntityTypeAttribute extends BaseEntity {
  /**
   * Entity type workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Defines a label to be used in template and translations
   */
  public label: string;
  /**
   * Entity type id (foreign key)
   */
  public entityTypeId: string;
  /**
   * Gui Settings to be used by generic components
   * <br /> on the front-end. JSON stringified object
   */
  public guiSettings: string;
  /**
   * Lookup table item id for datatype (foreign key)
   */
  public dataTypeId: string;
  /**
   * this field is to indicate if
   * we are going to have an array of some type
   */
  public isArray: boolean = false;
  /**
   * Attribute order
   */
  public order: number;

  /**group */
  public group?: string;

  /**
   * Attribute reference, used when DataType is:
   * <br />LookupTable or EntityType
   */
  public reference: string;

  /**constructor */
  constructor(init?: Partial<EntityTypeAttribute>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * EntityTypeOperation class that defines a
 * <br />custom operation for an EntityType,
 * <br />which WILL BE COMPILED into the dll
 */
export class EntityTypeOperation extends BaseEntity {
  /**
   * Entity type workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Defines a label to be used in template and translations
   */
  public label: string;
  /**
   * Entity type id (foreign key)
   */
  public entityTypeId: string;
  /**
   * Gui Settings to be used by generic components
   * <br /> on the front-end. JSON stringified object
   */
  public guiSettings: string;
  /**
   * Operation overrides an existing controller method
   */
  public override: boolean;
  /**
   * Array of imports to be imported by the controller
   */
  public imports: string[];
  /**
   * Source code to be executed for the given operation
   * <br />by the controller
   */
  public sourceCode: string;
  /**
   * Operation HttpVerb
   */
  public httpVerb: HttpVerb;

  /**
   * Operation order
   */
  public order: number = 0;

  /**constructor */
  constructor(init?: Partial<EntityTypeOperation>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * EntityTypeOperation class that defines a
 * <br />generic EntityType, its properties,
 * <br />attributes and operations
 */
export class EntityType extends BaseEntity {
  /**
   * state id
   */
  public stateId?: string;

  /**
   * Defines a label to be used in template and translations
   */
  public label: string;

  /**
   * Workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Gui Settings to be used by generic components
   * <br /> on the front-end. JSON stringified object
   */
  public guiSettings: string;
  /**
   * Group name to be used on the front-end for
   * scopes configuration grouping
   */

  //todo: change to lookup table item id
  public group: string;
  /**
   * Properties array
   */
  public properties: EntityTypeProperty[];
  /**
   * Attributes array
   */
  public attributes: EntityTypeAttribute[];
  /**
   * Operations array
   */
  public operations: EntityTypeOperation[];

  /**
   * Constructor
   * this constructor receives the fields sent by the app,
   * and creates an object with the fields sent and the values per default
   * @param init
   */
  constructor(init?: Partial<EntityType>) {
    super();
    Object.assign(this, init);
  }
}
