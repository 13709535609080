import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { PrgEntityTypesConfig } from '../../../entity-types/models/prg-entity-types-config';
import { PRG_ENTITY_TYPES_CONFIG } from '../../../entity-types/services/entity-types-configuration/prg-entity-types-configuration.service';
import { PrgMenuItem } from '../../../layouts/models/prg-menu-item';
import { ViewMode } from '../../models/constants/view-mode.enum';
import { FormGroupService } from '../../services/form-group/form-group.service';
import { UtilityService } from '../../services/utility/utility.service';
import {
  ActionOutput,
  BaseAction,
  DynamicFormActionOutput,
  DynamicFormFieldChangeOutput,
  FieldChangeOutput,
  FormStatus,
} from './models/base-action';
import { BaseField } from './models/base-field';

/**
 * Dynamic form component
 */
@Component({
  selector: 'prg-dynamic-form',
  templateUrl: './prg-dynamic-form.component.html',
  styleUrls: ['./prg-dynamic-form.component.scss'],
})
export class PrgDynamicFormComponent implements OnInit {
  /**
   * Tab menu items
   * @type {PrgMenuItem[]}
   */
  public tabMenuItemsDynamicForm: PrgMenuItem[] = [];

  /**
   * active tab menu
   */
  public activeTabMenuItemsDynamicForm: PrgMenuItem;

  /**
   * Context of class where dynamic form component is used
   * @type {any}
   */
  @Input() context: any = null;
  /**
   * fields aux
   */
  private _fields: BaseField[] = [];
  /**
   * fields aux
   */
  private _originalFields: BaseField[] = [];

  /**
   *array with the fields
   */
  @Input('fields') set fields(fields: BaseField[]) {
    this._fields = this.arrayUtilityService.clone(fields);
    this._originalFields = this.arrayUtilityService.clone(fields);
    this.form = this.formGroupService.toFormGroup(fields);
  }

  /**
   * get fields
   */
  get fields(): BaseField[] {
    return this._fields;
  }

  /**
   * actions aux
   */
  private _actions: BaseAction[] = [];
  /**
   *array with the actions
   */
  @Input('actions') set actions(actions: BaseAction[]) {
    this._actions = actions;
    this.createActionsArray();
  }

  /**
   * get actions
   */
  get actions(): BaseAction[] {
    return this._actions;
  }

  /**
   * entity aux
   */
  private _entity: any;

  /**
   * input entity
   */
  @Input('entity') set entity(entity: any) {
    if (entity) {
      this._entity = this.objectsUtilityService.cloneObject(entity);
      this._entityTemp = this.objectsUtilityService.cloneObject(entity);
      this.mapValueToFields(entity);
    }
  }

  /**
   * get entity
   */
  get entity(): any {
    return this._entity;
  }

  /**
   * Temporary entity to access fields values when changes occur
   * @type {any}
   * @private
   */
  private _entityTemp: any;

  /**
   * getter entity temp
   * @returns {any}
   */
  get entityTemp(): any {
    return this._entityTemp;
  }

  /**
   * view mode aux
   */
  private _viewMode: ViewMode;
  /**
   * input view mode
   */
  @Input('viewMode') set viewMode(viewMode: ViewMode) {
    this._viewMode = viewMode;
    this.fields.forEach((field) => {
      field.readonly = viewMode !== ViewMode.Edit;
      field.disabled = viewMode !== ViewMode.Edit;
    });
    this.fields = this.arrayUtilityService.clone(this.fields);
  }

  /**
   * get View Mode
   */
  get viewMode(): ViewMode {
    return this._viewMode;
  }

  /**
   * The grouping type of the form
   * @type {any}
   */
  @Input() formGroupType: any;

  /**
   * action output
   */
  @Output() actionOutput = new EventEmitter<DynamicFormActionOutput>();

  /**
   * Field output onChange
   * @type {EventEmitter<DynamicFormFieldChangeOutput>}
   */
  @Output() fieldChangeOutput =
    new EventEmitter<DynamicFormFieldChangeOutput>();

  /**
   * declare form group
   */
  public form!: FormGroup;

  /**
   * left actions
   */
  public leftActions: BaseAction[] = [];
  /**
   * middle actions
   */
  public middleActions: BaseAction[] = [];
  /**
   * right actions
   */
  public rightActions: BaseAction[] = [];

  /**
   * constructor
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {FormGroupService} formGroupService
   * @param {UtilityService} utilityService
   * @param {PrgEntityTypesConfig} entityConfigs
   */
  constructor(
    private objectsUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private formGroupService: FormGroupService,
    private utilityService: UtilityService,
    @Inject(PRG_ENTITY_TYPES_CONFIG) private entityConfigs: PrgEntityTypesConfig
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    if (this.formGroupType === 'tab') {
      this.createTabMenu();
    }
  }

  /**
   * this function divides the actions by their respective positions
   */
  private createActionsArray(): void {
    if (this._actions) {
      this._actions = this.arrayUtilityService.sortByProperty(
        this._actions,
        'order'
      );
      this.leftActions = this._actions.reduce((prev, curr) => {
        if (curr.toolbarSlot == 'left') {
          prev.push(curr);
        }
        return prev;
      }, []);
      this.middleActions = this._actions.reduce((prev, curr) => {
        if (curr.toolbarSlot == 'middle') {
          prev.push(curr);
        }
        return prev;
      }, []);
      this.rightActions = this._actions.reduce((prev, curr) => {
        if (curr.toolbarSlot == 'right') {
          prev.push(curr);
        }
        return prev;
      }, []);
    }
  }

  /**
   * this function fire the output
   * @param action
   */
  public onActionClicked(action: ActionOutput): void {
    if (this.viewMode !== ViewMode.Add) {
      this.mapFieldsToValue();
    }
    this.actionOutput.emit(
      new DynamicFormActionOutput({
        action: action.action,
        value: action.value,
        group: action.group,
        formEntity: this.entity,
        formStatus: this.form.status.toLowerCase() as FormStatus,
        isChanged: !this.form.pristine,
      })
    );
  }

  /**
   * this function maps the new values in the entity
   */
  private mapFieldsToValue(data: any = null): void {
    if (data == null) {
      data = this.entity;
    }
    Object.keys(this.form.getRawValue()).forEach((key: string) => {
      data[key] = this.form.controls[key].getRawValue();
    });
  }

  /**
   * this function maps the entity values in the fields
   * @param entity
   */
  private mapValueToFields(entity: any): void {
    this._fields = this.arrayUtilityService.clone(this._originalFields);

    this.fieldsReadonly();
    this._fields.forEach((field) => {
      if (entity[field.key]) {
        field.value = entity[field.key];
      }
    });

    this.form = this.formGroupService.toFormGroup(this.fields);
  }

  /**
   * this function depending on the view mode changes the read only and disabled value
   */
  private fieldsReadonly(): void {
    this._fields.forEach((field) => {
      field.readonly = this.viewMode !== ViewMode.Edit;
      field.disabled = this.viewMode !== ViewMode.Edit;
    });
  }

  /**
   * this function calls the service that does the expression eval for fields
   * @param {string} expression
   * @param {boolean} defaultValue
   * @param {string | null} formFieldGroup
   * @returns {boolean}
   */
  public evalExpression(
    expression: string,
    defaultValue: boolean = false,
    formFieldGroup: string | null = null
  ): boolean {
    if (
      this.formGroupType &&
      formFieldGroup != null &&
      formFieldGroup !== this.activeTabMenuItemsDynamicForm.id
    ) {
      return false;
    }
    if (!expression) return defaultValue;

    return this.utilityService.evalFunction(
      expression,
      this,
      this.context != null ? this.context : {}
    );
  }

  /**
   * this function fire the output for the fields
   * @param {FieldChangeOutput} field
   */
  public onFieldChanged(field: FieldChangeOutput) {
    // Set Fields to Value before emit, to reflect changes instantly

    let data = {};
    if (this.viewMode !== ViewMode.Add) {
      this.mapFieldsToValue(data);
    }
    this._entityTemp = { ...this._entityTemp, ...data };

    this.fieldChangeOutput.emit(
      new DynamicFormFieldChangeOutput({
        field: field.field,
        value: field.value,
        event: field.event,
        formEntity: this.entityTemp, //need this field to access all fields values of the form whenever there is a change in fields
      })
    );
  }

  /**
   * This function is responsible to change the active tab property of the class whenever a tab is change on form
   * @param event
   * @private
   */
  private onChangeTab(event: any): void {
    this.activeTabMenuItemsDynamicForm = event?.item;
  }

  /**
   * This function construct the tab menu base on property groupFieldsId of the fields
   * @private
   */
  private createTabMenu() {
    const tabGroups = this.fields.map((field) => {
      return field.groupFieldsId;
    });
    const tabGroupsUnique: string[] = this.arrayUtilityService.clone(
      Array.from(new Set(tabGroups))
    );

    tabGroupsUnique.map((value) => {
      this.tabMenuItemsDynamicForm.push({
        label: value,
        id: value,
        command: (event) => this.onChangeTab(event),
      });
    });
    this.activeTabMenuItemsDynamicForm = this.tabMenuItemsDynamicForm[0];
  }
}
