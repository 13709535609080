import { Directive, Input } from '@angular/core';
import { TriStateCheckbox } from 'primeng/tristatecheckbox';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class TriState Checkbox
 */
export class TriStateCheckboxConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.triStateCheckbox;

  /**
   *Name of the component.
   */
  public name: string = null;
  /**
   *Label of the checkbox.
   */
  public label: string = null;

  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;
  /**
   *Inline style of the component.
   */
  public style: object = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;

  /**
   *Specifies the icon for checkbox true value.
   */
  public checkboxTrueIcon: string = 'pi pi-check';
  /**
   *Specifies the icon for checkbox false value.
   */
  public checkboxFalseIcon: string = 'pi pi-times';

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<TriStateCheckboxConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setTriStateCheckboxConfigs]',
})
export class PrgSetTriStateCheckboxConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: TriStateCheckbox) {}

  /**
   * input Tri State Checkbox configs and assign configs to element
   */
  @Input() set setTriStateCheckboxConfigs(
    configs: TriStateCheckboxConfiguration
  ) {
    Object.assign(this.element, configs);
  }
}
