import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationItem } from '../../models/configuration-item.model';

/**
 * ConfigurationItemsService class that defines an
 * <br />abstract authentication service to
 * <br />be extended by the mock and real
 * <br />configuration items services
 */
export abstract class AbstractConfigurationItemsService {
  /**
   * Current configurationItems
   */
  protected configurationItemsSubject = new BehaviorSubject<
    ConfigurationItem[]
  >(null);

  /**
   * Setter for configurationItemsSubject
   *
   * @param {ConfigurationItem[]} value The ConfigurationItems array
   */
  protected setConfigurationItems(value: ConfigurationItem[]): void {
    this.configurationItemsSubject.next(value);
  }

  /**
   * Getter for configurationItemsSubject
   *
   * @returns The configurationItemsSubject as an observable
   */
  public getConfigurationItemsObservable(): Observable<ConfigurationItem[]> {
    return this.configurationItemsSubject.asObservable();
  }

  /**
   * Getter for configurationItemsSubject
   *
   * @returns The configurationItemsSubject current value
   */
  public getConfigurationItemsValue(): ConfigurationItem[] {
    return this.configurationItemsSubject.getValue();
  }
  /**
   * Get a list of all configuration items
   * @returns {Promise<ConfigurationItem[]>}
   */
  public abstract getAllConfigurationItemsAsync(): Promise<ConfigurationItem[]>;

  /**
   * Function responsible to update Config Items
   * @param configItemData
   * @returns {Promise<ConfigurationItem[]>}
   */
  public abstract updateConfigurationItemsAsync(
    configItemData: any
  ): Promise<ConfigurationItem[]>;

  /**
   * Get configuration items by path
   * @param {string} path
   * @returns {Promise<any>}
   */
  public abstract getConfigurationItemsByPathAsync(path: string): Promise<any>;
}
