import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ArrayUtilityService } from '../../../../../services/utility/array-utility.service';
import { FieldChangeOutput } from '../../../models/base-action';
import { AutoCompleteConfiguration } from './auto-complete.model';

/**
 * Component Auto Complete
 */
@Component({
  selector: 'prg-auto-complete-field',
  templateUrl: './auto-complete.component.html',
})
export class PrgAutoCompleteComponent {
  /**
   * Constructor
   * @param {ArrayUtilityService} arrayUtilityService
   */
  constructor(private arrayUtilityService: ArrayUtilityService) {}
  /**
   * Auto Complete Configs
   */
  @Input() configs: AutoCompleteConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;
  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the search for auto complete purpose
   */
  public search(event): void {
    let suggestions: any[] = [];
    let query = event.query;

    this.configs.dataToSearch.map((data) => {
      if (this.configs.field) {
        if (data[this.configs.field].toLowerCase().includes(query)) {
          suggestions.push(data);
        }
      } else {
        if (data.toLowerCase().includes(query)) {
          suggestions.push(data);
        }
      }
    });
    this.configs.suggestions = this.arrayUtilityService.clone(suggestions);
  }
}
