import { Injectable } from '@angular/core';
import clone from 'just-clone';
import { TreeNode } from 'primeng/api';

/**
 * injectable
 */
@Injectable({
  providedIn: 'root',
})
export class ArrayUtilityService {
  /**
   * this function  compare two arrays
   * @param array1
   * @param array2
   * @returns boolean
   */
  public compareTwoArray(array1: any[], array2: any[]): boolean {
    const arr1 = array1.slice().sort();
    return (
      arr1.length === array2.length &&
      array2
        .slice()
        .sort()
        .every(function (value, index) {
          return value === arr1[index];
        })
    );
  }

  /**
   * this service creates a treeNode grouped by a certain property
   * @param array
   * @param field
   * @returns TreeNode[]
   */
  public groupByField(array: any[], field: string): TreeNode[] {
    let organisedByGroup: Map<string, any[]> = new Map();
    let organizedTreeNode: TreeNode[] = [];

    if (!array || !array.length) {
      return organizedTreeNode;
    }

    array.forEach((element) => {
      if (organisedByGroup.has(element[field])) {
        organisedByGroup.get(element[field]).push({ data: element });
      } else {
        organisedByGroup.set(element[field], [{ data: element }]);
      }
    });

    organisedByGroup.forEach((value, key) => {
      let treeNodeAux: TreeNode = {};
      let dataAux = { name: key };

      treeNodeAux.data = dataAux;
      treeNodeAux.children = <TreeNode[]>value;
      treeNodeAux.expanded = true;
      organizedTreeNode.push(treeNodeAux);
    });

    return organizedTreeNode;
  }

  /**clone array
   *
   * @param arrayToClone
   * @returns cloned array
   */
  public clone(arrayToClone: any[]): any[] {
    return clone(arrayToClone);
  }

  /**
   *sortByProperty

   * @param arrayToSort
   * @param property
   * @returns
   */
  public sortByProperty(
    arrayToSort: any[],
    property: string,
    isAsc: boolean = true
  ): any[] {
    return this.clone(
      arrayToSort.sort((a, b) => {
        return this.compare(a[property], b[property], isAsc);
      })
    );
  }
  /**
   * aux function to sort items when it is static mode
   * @param a
   * @param b
   * @param isAsc
   * @returns
   */
  private compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
