<div class="field-checkbox">
    <p-toggleButton
      (onChange)="onButtonClicked($event)"
      [setToggleButtonActionConfigs]="configs"
      [inputId]="configs.key"
      [disabled]="!form.valid && configs.disableWhenFormInvalid!"
      [onLabel]="configs.hasLabel?(configs.translationBasePath + '.' + configs.key + '.onLabel' | translate):''"
      [offLabel]="configs.hasLabel?(configs.translationBasePath + '.' + configs.key + '.offLabel' | translate):''"
      ></p-toggleButton>
  <label for="{{configs.key}}">{{configs.translationBasePath + '.' + configs.key + '.label' | translate}}</label>
</div>


