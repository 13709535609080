import { PrgButtonComponent } from '../../dynamic-form-actions/actions/button/button.component';
import { PrgInputSwitchActionComponent } from '../../dynamic-form-actions/actions/input-switch-action/input-switch-action.component';
import { PrgSelectButtonActionComponent } from '../../dynamic-form-actions/actions/select-button-action/select-button-action.component';
import { PrgSpeedDialComponent } from '../../dynamic-form-actions/actions/speed-dial/speed-dial.component';
import { PrgSplitButtonComponent } from '../../dynamic-form-actions/actions/split-button/split-button.component';
import { PrgToggleButtonActionComponent } from '../../dynamic-form-actions/actions/toggle-button-action/toggle-button-action.component';

/**
 * list of types of action
 */
export const ActionTypes = {
  button: PrgButtonComponent,
  splitButton: PrgSplitButtonComponent,
  speedDial: PrgSpeedDialComponent,
  selectButton: PrgSelectButtonActionComponent,
  toggleButton: PrgToggleButtonActionComponent,
  inputSwitch: PrgInputSwitchActionComponent,
};
