<div class="flex justify-content-center">
  <div
    class="mainContainer shadow-3 border-1 border-300 surface-card border-round-lg w-28rem"
  >
    <form
      [formGroup]="requestPassForm"
      (ngSubmit)="onSubmitRequestPassword()"
      novalidate
    >
      <div
        class="text-600 text-2xl w-full border-bottom-1 border-top-1 border-300 p-2 my-4 text-center"
      >
        {{ 'components.request-password.title' | translate | uppercase }}
      </div>
      <div class="px-5 mb-5">
        <h6 class="text-700">
          {{ 'entities.user.fields.email.label' | translate }}
        </h6>
        <span class="p-input-icon-left w-full"
          ><i class="bi bi-envelope"></i>
          <input
            placeholder="{{
              ('entities.user.fields.email.placeholder' | translate) + '...'
            }}"
            formControlName="email"
            id="email"
            autofocus
            autocomplete="email"
            type="email"
            class="w-full"
            pInputText
        /></span>
      </div>
      <div class="px-5 text-center mb-3">
        <button
          type="submit"
          [disabled]="
            !requestPassForm.valid || (isLoadingData.visibility | async)
          "
          pButton
          pRipple
          label="{{ 'components.request-password.button.label' | translate }}"
          icon="pi pi-lock"
          class="w-full"
        ></button>
      </div>
      <div class="px-5 mb-3 text-center" *ngIf="showLoginLink">
        <a
          [style.pointer-events]="
            (isLoadingData.visibility | async) ? 'none' : 'auto'
          "
          routerLink="{{ loginPageRoute }}"
          class="underlineEffect text-sm blue-500 font-medium cursor-pointer"
          style="text-decoration: none"
          >{{ 'components.request-password.login-link.label' | translate }}</a
        >
      </div>
    </form>
  </div>
</div>
