import { Injectable, Inject } from "@angular/core";
// import jwt_decode from 'jwt-decode';

import { AbstractConfigurationItemsService } from "./abstract-configuration-items.service";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * REAL ConfigurationItemsService implementation
 */
export class ConfigurationItemsService extends AbstractConfigurationItemsService {
  /**
   * constructor
   */
  constructor() {
    super();
  }

  /**
   * Gets the real configuration items from
   * <br />the api and sets them on the
   * <br />configurationItemsSubject
   *
   * @returns Boolean promise, true when successful
   */
  public getConfigurationItemsAsync(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      resolve(false);
    });
  }
}
