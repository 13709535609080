import { InputNumberConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/input-number/input-number.model';

/**
 *  Constant that define the key , configuration settings and settings for bar chart component
 * @type {{staticSettings: string, configurationSettings: {fields: InputNumberConfiguration[]}, key: string}}
 */
export const PRG_BAR_CHART_SETTINGS = {
  key: 'Bar-chart',
  configurationSettings: {
    fields: [
      new InputNumberConfiguration({
        key: 'barChartProperty1',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'barChartProperty2',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'barChartProperty3',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'barChartProperty4',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'barChartProperty5',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'barChartProperty6',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'barChartProperty7',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
    ],
  },
  staticSettings: JSON.stringify({
    barChartProperty1: 1,
    barChartProperty2: 15,
    barChartProperty3: 30,
    barChartProperty4: 45,
    barChartProperty5: 120,
    barChartProperty6: 80,
    barChartProperty7: 152,
  }),
};
