import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ACTIONS_BASE_STRUCTURE } from '../../../../../core/components/dynamic-form/actions-default-structures/actions-base-structure';
import {
  BaseAction,
  BaseActionKey,
  DynamicFormActionOutput,
} from '../../../../../core/components/dynamic-form/models/base-action';
import { BaseField } from '../../../../../core/components/dynamic-form/models/base-field';
import { ViewMode } from '../../../../../core/models/constants/view-mode.enum';
import { PrgError } from '../../../../../core/models/error.model';
import { NotificationsService } from '../../../../../core/services/notifications/notifications.service';
import { ArrayUtilityService } from '../../../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { ENTITY_TYPE_ATTRIBUTE_DYNAMIC_FORM } from '../../../../dynamic-form-struct/entity-type-attribute-dynamic-form';
import { ENTITY_TYPE_DYNAMIC_FORM } from '../../../../dynamic-form-struct/entity-type-dynamic-form';
import { ENTITY_TYPE_OPERATION_DYNAMIC_FORM } from '../../../../dynamic-form-struct/entity-type-operation-dynamic-form';
import { ENTITY_TYPE_PROPERTY_DYNAMIC_FORM } from '../../../../dynamic-form-struct/entity-type-property-dynamic-form';
import {
  EntityType,
  EntityTypeAttribute,
  EntityTypeOperation,
  EntityTypeProperty,
} from '../../../../models/entity-type.model';
import { AbstractEntityTypeService } from '../../../../services/entity-types/abstract-entity-type.service';
import { MenuTab, MenuTabEntityType } from './models/menu-tab.module';

/**
 * entity type configuration component
 */
@Component({
  selector: 'prg-entity-type-configs',
  templateUrl: './prg-entity-type-configs.component.html',
  styleUrls: ['./prg-entity-type-configs.component.scss'],
})
export class PrgEntityTypeConfigsComponent implements OnInit {
  /**
   * constructor
   * @param entityTypeService
   * @param config
   * @param ref
   * @param notificationsService
   * @param arrayUtilityService
   * @param objectsUtilityService
   * @param utilityService
   */
  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private notificationsService: NotificationsService,
    private arrayUtilityService: ArrayUtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    private utilityService: UtilityService
  ) {}
  /**
   * entity type
   */
  public entityType: EntityType;

  /**
   * manu tab items
   */
  public menuTabItems: MenuTab[];

  /**
   * tab active item
   */
  public activeItem: MenuTab;

  /**
   * elements list (properties, attributes, operations)
   */
  public elements: any[] = [];

  /**
   * element selected
   */
  public elementsSelected: any[] = [];

  /**
   * entity type form fields
   */
  public entityTypeDynamicFormFields: BaseField[] =
    this.arrayUtilityService.clone(ENTITY_TYPE_DYNAMIC_FORM.fields);
  /**
   * entity type form actions
   */
  public entityTypeDynamicFormActions: BaseAction[] =
    this.arrayUtilityService.clone(ENTITY_TYPE_DYNAMIC_FORM.actions);

  /**
   * elements form fields (default is fields from properties)
   */
  public elementDynamicFormFields: BaseField[] = this.arrayUtilityService.clone(
    ENTITY_TYPE_PROPERTY_DYNAMIC_FORM.fields
  );

  /**
   * elements form actions
   */
  public elementDynamicFormActions: BaseAction[] =
    this.arrayUtilityService.clone(ACTIONS_BASE_STRUCTURE.actions);

  /**
   * entity type view mode
   */
  public entityTypeViewMode: ViewMode = ViewMode.Read;
  /**
   * elements view mode
   */
  public elementViewMode: ViewMode = ViewMode.Add;

  /**
   * diabled list
   */
  public disabledList: boolean = false;

  /**
   * debounce time
   */
  private debounceTime: number = 200;

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.menuTabItems = [
      {
        label: 'properties',
        icon: 'pi pi-fw pi-pencil',
        type: MenuTabEntityType.EntityTypeProperty,
        disabled: false,
      },
      {
        label: 'attributes',
        icon: 'pi pi-fw pi-pencil',
        type: MenuTabEntityType.EntityTypeAttribute,
        disabled: false,
      },
      {
        label: 'operations',
        icon: 'pi pi-fw pi-pencil',
        type: MenuTabEntityType.EntityTypeOperation,
        disabled: false,
      },
    ];

    this.activeItem = this.menuTabItems[0];

    this.entityType = this.config.data.entityType;
    this.entityTypeViewMode = this.config.data.viewMode;
    if (!this.entityType.id) {
      this.menuTabsItemsManage(null);
    }
    this.loadElements();
  }

  /**
   * this function receives the event
   * when a new element is selected in the list
   * @param event
   * @returns
   */
  public onSelectionChanged(event: any): void {
    if (event.value == null) {
      return;
    }
    this.elementsSelected = [event.value[event.value.length - 1]];
    this.elementViewMode = ViewMode.Read;
  }

  /**
   * this function receives the output referring to the actions of an entity
   * type and in function of the received action performs the corresponding actions
   * @param event
   */
  public async onEntityTypeActionOutputted(event: DynamicFormActionOutput) {
    this.menuTabsItemsManage(null);
    switch (event.action) {
      case BaseActionKey.Save:
        this.entityType = await this.entityTypeService.saveEntityTypeAsync(
          event.formEntity
        );
        this.menuTabsItemsManage(null, false);
        this.activeItem = this.menuTabItems[0];
        this.entityTypeViewMode = ViewMode.Read;

        break;
      case BaseActionKey.Cancel:
        this.entityTypeViewMode = ViewMode.Read;
        if (event.formEntity.id) {
          this.entityType = this.objectsUtilityService.cloneObject(
            this.entityType
          );
        } else {
          this.ref.close();
        }
        break;

      case BaseActionKey.Edit:
        this.entityTypeViewMode = ViewMode.Edit;
        break;
      default:
        break;
    }
  }

  /**
   * this function is responsible for receiving the changes from the selected tab and loading the respective data
   * @param event
   */
  public tabChange(event: MenuTab): void {
    this.activeItem = event;
    this.elementsSelected = [];
    this.elementViewMode = ViewMode.Add;
    this.loadElements();
  }

  /**
   * this function manages the status of the
   * tabs according to the parameters
   * it can activate or deactivate all tabs as well
   * as change the status of a single tab
   *
   * @param menuTabItem
   * @param status
   */
  private menuTabsItemsManage(menuTabItem: MenuTab, status: boolean = true) {
    this.menuTabItems.forEach((item) => {
      item.disabled = status;
    });
    if (menuTabItem) {
      const item = this.menuTabItems.find(
        (item) => (item.type = menuTabItem.type)
      );
      item.disabled = true;
    }
    this.menuTabItems = this.arrayUtilityService.clone(this.menuTabItems);
    this.activeItem = this.menuTabItems.find(
      (item) => item.type === this.activeItem.type
    );
  }

  /**
   * create empty element, depending on the tab selected
   */
  private addNewElement(): void {
    let newElement:
      | EntityTypeProperty
      | EntityTypeAttribute
      | EntityTypeOperation = null;
    if (this.entityType && this.activeItem) {
      this.disabledList = true;
      switch (this.activeItem.type) {
        case MenuTabEntityType.EntityTypeProperty:
          newElement = new EntityTypeProperty({
            entityTypeId: this.entityType.id,
            order: this.elements.length + 1,
          });
          break;
        case MenuTabEntityType.EntityTypeAttribute:
          newElement = new EntityTypeAttribute({
            entityTypeId: this.entityType.id,
            order: this.elements.length + 1,
          });
          break;
        case MenuTabEntityType.EntityTypeOperation:
          newElement = new EntityTypeOperation({
            entityTypeId: this.entityType.id,
          });
          break;

        default:
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'cantFindEntity',
            })
          );
          break;
      }
    }
    // add element to elements to array
    this.elements.push(newElement);
    //this.elements = this.arrayUtilityService.clone(this.elements);
    this.elementsSelected = [this.elements[this.elements.length - 1]];
  }

  /**
   * this function loads the elements according to the tab selected,
   *  and changes the form's data
   */
  private async loadElements(): Promise<void> {
    if (this.entityType && this.activeItem) {
      switch (this.activeItem.type) {
        case MenuTabEntityType.EntityTypeProperty:
          this.elementDynamicFormFields = this.arrayUtilityService.clone(
            ENTITY_TYPE_PROPERTY_DYNAMIC_FORM.fields
          );
          this.elements = this.arrayUtilityService.sortByProperty(
            await this.entityTypeService.getAllPropertiesByEntityTypeIdAsync(
              this.entityType.id
            ),
            'order'
          );
          break;
        case MenuTabEntityType.EntityTypeAttribute:
          this.elementDynamicFormFields = this.arrayUtilityService.clone(
            ENTITY_TYPE_ATTRIBUTE_DYNAMIC_FORM.fields
          );

          this.elements = this.arrayUtilityService.sortByProperty(
            await this.entityTypeService.getAllAttributesByEntityTypeIdAsync(
              this.entityType.id
            ),
            'order'
          );
          break;
        case MenuTabEntityType.EntityTypeOperation:
          this.elementDynamicFormFields = this.arrayUtilityService.clone(
            ENTITY_TYPE_OPERATION_DYNAMIC_FORM.fields
          );
          this.elements =
            await this.entityTypeService.getAllOperationsByEntityTypeIdAsync(
              this.entityType.id
            );
          break;

        default:
          this.notificationsService.errorNotification(
            new PrgError({
              titleKey: 'error',
              detailKey: 'cantAddNewElement',
            })
          );
          break;
      }
    }
  }

  /**
   * this function receives the output of an entity,
   * and treats each of the actions with the respective logic to be applied
   * @param event
   */
  public onEntityActionOutputted(event: DynamicFormActionOutput): void {
    switch (event.action) {
      case BaseActionKey.Save:
        this.saveNewElement(event.formEntity);
        this.menuTabsItemsManage(null, false);
        break;
      case BaseActionKey.Edit:
        this.disabledList = true;
        this.elementViewMode = ViewMode.Edit;
        this.menuTabsItemsManage(null, true);
        break;
      case BaseActionKey.Cancel:
        if (event.formEntity.id) {
          this.elementsSelected = this.arrayUtilityService.clone(
            this.elementsSelected
          );
          this.elementViewMode = ViewMode.Read;
        } else {
          this.elementViewMode = ViewMode.Add;
          this.elementsSelected = [];
          this.elements.splice(this.elements.length - 1, 1);
        }
        this.disabledList = false;
        this.menuTabsItemsManage(null, false);

        break;

      case BaseActionKey.Add:
        this.menuTabsItemsManage(null, true);
        this.elementViewMode = ViewMode.Edit;
        this.addNewElement();
        break;

      default:
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'actionNotFound',
          })
        );
        break;
    }
  }

  /**
   * this function is responsible for saving the new element added,
   * depending on the selected tab it calls the respective method
   * @param newElement
   */
  private async saveNewElement(newElement: any): Promise<void> {
    let addedElement: any;
    switch (this.activeItem.type) {
      case MenuTabEntityType.EntityTypeProperty:
        addedElement = await this.entityTypeService.saveEntityTypePropertyAsync(
          newElement
        );
        break;
      case MenuTabEntityType.EntityTypeAttribute:
        addedElement =
          await this.entityTypeService.saveEntityTypeAttributeAsync(newElement);
        break;
      case MenuTabEntityType.EntityTypeOperation:
        addedElement =
          await this.entityTypeService.saveEntityTypeOperationAsync(newElement);
        break;
      default:
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'tabNotFound',
          })
        );
        break;
    }

    if (!newElement.id) {
      this.elements.splice(this.elements.length - 1, 1);
    }
    this.elementViewMode = ViewMode.Read;

    this.elementsSelected = [];
    this.disabledList = false;
    await this.loadElements();

    const elementAux = this.elements.find(
      (element) => element.id === addedElement.id
    );
    this.elementsSelected = [elementAux];
  }

  /**
   * this function is responsible for updating
   * the elements in the database when they have their order changed
   */
  public reorderElements = this.utilityService.debounce(() => {
    let mapElements = new Map<string, any>();

    for (let i = 0; i < this.elements.length; i++) {
      this.elements[i].order = i + 1;
      mapElements.set(this.elements[i].id, { order: this.elements[i].order });
    }

    switch (this.activeItem.type) {
      case MenuTabEntityType.EntityTypeProperty:
        this.entityTypeService.updateEntityTypePropertiesAsync(mapElements);
        break;
      case MenuTabEntityType.EntityTypeAttribute:
        this.entityTypeService.updateEntityTypeAttributesAsync(mapElements);
        break;

      default:
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'error',
            detailKey: 'notUpdated',
          })
        );
        break;
    }
  }, this.debounceTime);
}
