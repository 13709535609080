import { Directive, Input } from '@angular/core';
import { SplitButton } from 'primeng/splitbutton';
import { PrgMenuItem } from '../../../../../../layouts/models/prg-menu-item';
import { BaseAction } from '../../../models/base-action';
import { ActionName } from '../../../models/constants/action-names';

/**
 * Split Button Model
 */
export class SplitButtonConfiguration extends BaseAction {
  /**
   * Control name
   */
  public controlName?: string = ActionName.SplitButton;
  /**
   * items
   */
  public items: PrgMenuItem[];

  /**
   *Target element to attach the overlay, valid values are body or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]=mydiv for a div element having #mydiv as variable name).
   */
  public appendTo: any = null;
  /**
   *Indicates the direction of the element.
   */
  public dir: string = null;
  /**
   *When present, it specifies that the component should be disabled.
   */
  public disabled: boolean = false;
  /**
   *Defines a string that labels the expand button for accessibility.
   */
  public expandAriaLabel: string = null;
  /**
   *Transition options of the hide animation.
   */
  public hideTransitionOptions: string = '195ms ease-in';
  /**
   *Name of the icon.
   */
  public icon: string = null;
  /**
   *Position of the icon, valid values are left and right.
   */
  public iconPos: string = 'left';
  /**
   *Text of the button.
   */
  public label: string = null;
  /**
   *Inline style of the overlay menu.
   */
  public menuStyle: string = null;
  /**
   *Style class of the overlay menu.
   */
  public menuStyleClass: string = null;
  /**
   *Transition options of the show animation.
   */
  public showTransitionOptions: string = '225ms ease-out';
  /**
   *Inline style of the component.
   */
  public style: string = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<SplitButtonConfiguration>) {
    super();
    Object.assign(this, init);
  }
}
/**
 * Directive
 */
@Directive({
  selector: '[setSplitButtonConfigs]',
})
export class PrgSplitButtonConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: SplitButton) {}
  /**
   * set button configs, assign configs received to element
   */
  @Input() set setSplitButtonConfigs(configs: SplitButtonConfiguration) {
    Object.assign(this.element, configs);
  }
}
