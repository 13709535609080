import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Role } from "../../../models/role.model";

/**
 * Modal create role
 */
@Component({
  selector: "prg-modal-create-role",
  templateUrl: "./prg-modal-create-role.component.html",
  styleUrls: ["./prg-modal-create-role.component.scss"],
})
export class PrgModalCreateRoleComponent {
  /**
   * list of roles to use as a basis for creating a new
   */
  public rolesSelected: Role[] = [];

  /**
   * name for the new role
   */
  public newRoleName: string;

  /**
   * constructor
   *
   * @param modalCtrl
   */
  constructor(private modalCtrl: ModalController) {}

  /**
   * receive output onAction
   *
   * @param roles
   */
  onSubmitRolesSelected(roles: Role[]) {
    this.rolesSelected = roles;
  }

  /**
   * cancel the creation of the new role
   * @returns
   */
  cancel() {
    return this.modalCtrl.dismiss(null, "cancel");
  }

  /**
   * create of the new role
   *
   * @returns
   */
  createRole() {
    return this.modalCtrl.dismiss(this.createNewRoleObject(), "confirm");
  }

  /**
   * Create new Role object
   *
   * @returns ROle
   */
  private createNewRoleObject(): Role {
    let newRole: Role = new Role();
    newRole.name = this.newRoleName;
    let scopesAux = [];
    if (this.rolesSelected.length > 0) {
      this.rolesSelected.forEach((element) => {
        if (element.scopes) {
          scopesAux = scopesAux.concat(element.scopes);
        }
      });
    }
    // remove repeats
    const setScopesAux = new Set(scopesAux);
    newRole.scopes = [...setScopesAux];

    return newRole;
  }
}
