/**
 * Class Pagination
 */
export class Pagination {
  /**
   * set the pagination type
   *
   * pages - the data is presented page by page
   *
   * infiniteLoading - the data is presented with scroll
   */
  type: PaginationType = PaginationType.pages;

  /**
   * number of items per page
   */
  totalRows: number = 3;

  /**
   * options for choosing items per page(by pages)
   */
  rowsPerPageOptions: number[] = [2, 3, 5, 10, 100];

  /**
   * Constructor
   * this constructor receives the fields sent by the app,
   * and creates an object with the fields sent and the values per default
   * @param init
   */
  constructor(init?: Partial<Pagination>) {
    Object.assign(this, init);
  }
}

export enum PaginationType {
  // eslint-disable-next-line no-unused-vars
  infiniteLoading = 'infiniteLoading',
  // eslint-disable-next-line no-unused-vars
  pages = 'pages',
}
