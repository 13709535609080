<div class="field-checkbox" *ngIf="form" [formGroup]="form">

  <p-inputSwitch
    (onChange)="onChanged($event)"
    [setInputSwitchConfigs]="configs"
    [formControlName]="configs.key"
    [inputId]="configs.key"
    [name]="configs.key + '.name' | translate"></p-inputSwitch>
  <label for="{{configs.key}}">{{configs.basePathTranslation + '.' + configs.key + '.label' | translate}}</label>
</div>

