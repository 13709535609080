import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DynamicDirectivesModule, DynamicModule } from 'ng-dynamic-component';
import { PrgSharedComponentsModule } from '../../../shared-components/shared-components.module';
import { PrgButtonComponent } from './dynamic-form-actions/actions/button/button.component';
import { PrgButtonConfigsDirective } from './dynamic-form-actions/actions/button/button.model';
import { PrgInputSwitchActionComponent } from './dynamic-form-actions/actions/input-switch-action/input-switch-action.component';
import { PrgSetInputSwitchActionConfigsDirective } from './dynamic-form-actions/actions/input-switch-action/input-switch-action.model';
import { PrgSelectButtonActionComponent } from './dynamic-form-actions/actions/select-button-action/select-button-action.component';
import { PrgSetSelectButtonActionConfigsDirective } from './dynamic-form-actions/actions/select-button-action/select-button-action.model';
import { PrgSpeedDialComponent } from './dynamic-form-actions/actions/speed-dial/speed-dial.component';
import { PrgSpeedDialConfigsDirective } from './dynamic-form-actions/actions/speed-dial/speed-dial.model';
import { PrgSplitButtonComponent } from './dynamic-form-actions/actions/split-button/split-button.component';
import { PrgSplitButtonConfigsDirective } from './dynamic-form-actions/actions/split-button/split-button.model';
import { PrgToggleButtonActionComponent } from './dynamic-form-actions/actions/toggle-button-action/toggle-button-action.component';
import { PrgSetToggleButtonActionConfigsDirective } from './dynamic-form-actions/actions/toggle-button-action/toggle-button-action.model';
import { PrgDynamicFormActionComponent } from './dynamic-form-actions/prg-dynamic-form-action.component';
import { PrgAutoCompleteComponent } from './dynamic-form-fields/fields/auto-complete/auto-complete.component';
import { PrgSetAutoCompleteConfigsDirective } from './dynamic-form-fields/fields/auto-complete/auto-complete.model';
import { PrgCascadeSelectComponent } from './dynamic-form-fields/fields/cascade-select/cascade-select..component';
import { PrgSetCascadeSelectConfigsDirective } from './dynamic-form-fields/fields/cascade-select/cascade-select..model';
import { PrgCheckboxComponent } from './dynamic-form-fields/fields/checkbox/checkbox.component';
import { PrgSetCheckboxConfigsDirective } from './dynamic-form-fields/fields/checkbox/checkbox.model';
import { PrgChipsComponent } from './dynamic-form-fields/fields/chips/chips.component';
import { PrgSetChipsConfigsDirective } from './dynamic-form-fields/fields/chips/chips.model';
import { PrgColorPickerComponent } from './dynamic-form-fields/fields/color-picker/color-picker.component';
import { PrgSetColorPickerConfigsDirective } from './dynamic-form-fields/fields/color-picker/color-picker.model';
import { PrgDateTimeComponent } from './dynamic-form-fields/fields/input-date-time/date-time.component';
import { PrgSetDateTimeConfigsDirective } from './dynamic-form-fields/fields/input-date-time/date-time.model';
import { PrgInputMaskComponent } from './dynamic-form-fields/fields/input-mask/input-mask.component';
import { PrgSetInputMaskConfigsDirective } from './dynamic-form-fields/fields/input-mask/input-mask.model';
import { PrgInputNumberComponent } from './dynamic-form-fields/fields/input-number/input-number.component';
import { PrgSetInputNumberConfigsDirective } from './dynamic-form-fields/fields/input-number/input-number.model';
import { PrgInputSwitchComponent } from './dynamic-form-fields/fields/input-switch/input-switch.component';
import { PrgSetInputSwitchConfigsDirective } from './dynamic-form-fields/fields/input-switch/input-switch.model';
import { PrgInputTextAreaComponent } from './dynamic-form-fields/fields/input-text-area/input-text-area.component';
import { PrgInputTextComponent } from './dynamic-form-fields/fields/input-text/input-text.component';
import { PrgKnobComponent } from './dynamic-form-fields/fields/knob/knob.component';
import { PrgSetKnobConfigsDirective } from './dynamic-form-fields/fields/knob/knob.model';
import { PrgListBoxComponent } from './dynamic-form-fields/fields/list-box/list-box.component';
import { PrgSetListBoxConfigsDirective } from './dynamic-form-fields/fields/list-box/list-box.model';
import { PrgMultiSelectComponent } from './dynamic-form-fields/fields/multiselect/multi-select.component';
import { PrgSetMultiselectConfigsDirective } from './dynamic-form-fields/fields/multiselect/multi-select.model';
import { PrgPasswordComponent } from './dynamic-form-fields/fields/password/password.component';
import { PrgSetInputPasswordConfigsDirective } from './dynamic-form-fields/fields/password/password.model';
import { PrgRadioButtonComponent } from './dynamic-form-fields/fields/radio-button/radio-button.component';
import { PrgSetRadioButtonConfigsDirective } from './dynamic-form-fields/fields/radio-button/radio-button.model';
import { PrgRatingComponent } from './dynamic-form-fields/fields/rating/rating.component';
import { PrgSetRatingConfigsDirective } from './dynamic-form-fields/fields/rating/rating.model';
import { PrgSelectButtonComponent } from './dynamic-form-fields/fields/select-button/select-button.component';
import { PrgSetSelectButtonConfigsDirective } from './dynamic-form-fields/fields/select-button/select-button.model';
import { PrgSelectComponent } from './dynamic-form-fields/fields/select/select.component';
import { PrgSetSelectConfigsDirective } from './dynamic-form-fields/fields/select/select.model';
import { PrgSliderComponent } from './dynamic-form-fields/fields/slider/slider.component';
import { PrgSetSliderConfigsDirective } from './dynamic-form-fields/fields/slider/slider.model';
import { PrgToggleButtonComponent } from './dynamic-form-fields/fields/toggle-button/toggle-button.component';
import { PrgSetToggleButtonConfigsDirective } from './dynamic-form-fields/fields/toggle-button/toggle-button.model';
import { PrgTriStateCheckboxComponent } from './dynamic-form-fields/fields/tri-state-checkbox/tri-state-checkbox.component';
import { PrgSetTriStateCheckboxConfigsDirective } from './dynamic-form-fields/fields/tri-state-checkbox/tri-state-checkbox.model';
import { PrgDynamicFormFieldComponent } from './dynamic-form-fields/prg-dynamic-form-field.component';
import { PrgDynamicFormComponent } from './prg-dynamic-form.component';

@NgModule({
  declarations: [
    PrgDynamicFormComponent,
    PrgDynamicFormFieldComponent,
    PrgDynamicFormActionComponent,
    PrgSetDateTimeConfigsDirective,
    PrgSetRadioButtonConfigsDirective,
    PrgSetSelectButtonConfigsDirective,
    PrgSetSliderConfigsDirective,
    PrgSetColorPickerConfigsDirective,
    PrgSetAutoCompleteConfigsDirective,
    PrgSetInputSwitchConfigsDirective,
    PrgSetKnobConfigsDirective,
    PrgSetCheckboxConfigsDirective,
    PrgSetInputNumberConfigsDirective,
    PrgSetTriStateCheckboxConfigsDirective,
    PrgSetCascadeSelectConfigsDirective,
    PrgSetRatingConfigsDirective,
    PrgSetInputMaskConfigsDirective,
    PrgSetSelectConfigsDirective,
    PrgSetInputPasswordConfigsDirective,
    PrgSetMultiselectConfigsDirective,
    PrgSetChipsConfigsDirective,
    PrgSetToggleButtonConfigsDirective,
    PrgSetListBoxConfigsDirective,
    PrgListBoxComponent,
    PrgInputNumberComponent,
    PrgSelectComponent,
    PrgToggleButtonComponent,
    PrgChipsComponent,
    PrgRatingComponent,
    PrgRadioButtonComponent,
    PrgAutoCompleteComponent,
    PrgInputSwitchComponent,
    PrgKnobComponent,
    PrgCascadeSelectComponent,
    PrgTriStateCheckboxComponent,
    PrgColorPickerComponent,
    PrgSliderComponent,
    PrgDateTimeComponent,
    PrgSelectButtonComponent,
    PrgInputMaskComponent,
    PrgInputTextComponent,
    PrgMultiSelectComponent,
    PrgPasswordComponent,
    PrgButtonComponent,
    PrgButtonConfigsDirective,
    PrgSplitButtonComponent,
    PrgSplitButtonConfigsDirective,
    PrgSpeedDialComponent,
    PrgSpeedDialConfigsDirective,
    PrgSelectButtonActionComponent,
    PrgSetSelectButtonActionConfigsDirective,
    PrgToggleButtonActionComponent,
    PrgSetToggleButtonActionConfigsDirective,
    PrgInputSwitchActionComponent,
    PrgSetInputSwitchActionConfigsDirective,
    PrgCheckboxComponent,
    PrgInputTextAreaComponent,
  ],
  imports: [
    PrgSharedComponentsModule,
    DynamicModule,
    DynamicDirectivesModule,
    HttpClientModule,
  ],
  exports: [
    PrgDynamicFormComponent,
    PrgInputTextComponent,
    PrgInputNumberComponent,
    PrgDynamicFormFieldComponent,
    PrgSelectComponent,
    PrgDateTimeComponent,
    PrgSetSelectConfigsDirective,
    PrgCheckboxComponent,
    PrgInputTextAreaComponent,
    PrgCascadeSelectComponent,
  ],
})
export class PrgCoreDynamicFormModule {}
