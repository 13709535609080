import { NgModule } from '@angular/core';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { PrgLayoutsEmptyComponent } from '../layouts/empty/prg-layouts-empty.component';
import { PrgTermsComponent } from './components/prg-terms.component';
import { termsConfig } from './prg-terms.module';

const standardRoutes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(standardRoutes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        let routes: Routes = [];
        routes.push({
          path: termsConfig.termsOfUseRoutingPath,
          // component: PrgLayoutsEmptyComponent,
          children: [
            {
              path: '',
              component: PrgTermsComponent,
            },
          ],
        });

        return [...routes, ...standardRoutes];
      },
      multi: true,
    },
  ],
})
export class PrgTermsRoutingModule {}
