import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaginationResponse } from '../../../core/models/pagination-response';
import { BaseCacheService } from '../../../core/services/cache-service/base-cache.service';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import {
  EntityType,
  EntityTypeAttribute,
  EntityTypeOperation,
  EntityTypeProperty,
} from '../../models/entity-type.model';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
export abstract class AbstractEntityTypeService extends BaseCacheService<EntityType> {
  /**
   * constructor
   * @param notificationsService notificationsService
   * @param translationService
   */
  constructor(
    protected notificationsService: NotificationsService,
    private translationService: TranslateService
  ) {
    super();
    this.cacheTimeMs = 5000;
  }

  /**
   * Get all Entity Types
   *
   * @Returns Entity types[]
   */
  public abstract getEntityTypeListAsync(): Promise<EntityType[]>;

  /**
   * Get entity type list with operation
   *
   *  * @Returns Entity types[]
   */

  public abstract getEntityTypeListWithOperationAsync(): Promise<EntityType[]>;

  /**
   * save entity type
   * @param entityType
   * @return Entity type
   */
  public abstract saveEntityTypeAsync(
    entityType: EntityType
  ): Promise<EntityType>;

  /**
   * get all operations of an entity
   * @param entityTypeId
   * @return EntityTypeOperation[]
   */
  public abstract getAllOperationsByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeOperation[]>;

  /**
   * add or update entity type operation
   * @param entityTypeOperation
   * @return EntityTypeOperation
   */
  public abstract saveEntityTypeOperationAsync(
    entityTypeOperation: EntityTypeOperation
  ): Promise<EntityTypeOperation>;

  /**
   * get all attributes of an entity
   * @param entityTypeId
   * @return EntityTypeAttribute[]
   */
  public abstract getAllAttributesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeAttribute[]>;

  /**
   * add or update entity type attribute
   * @param entityTypeAttribute
   * @return EntityTypeAttribute
   */
  public abstract saveEntityTypeAttributeAsync(
    entityTypeAttribute: EntityTypeAttribute
  ): Promise<EntityTypeAttribute>;

  /**
   * get all properties of an entity
   * @param entityTypeId
   * @return EntityTypeProperty[]
   */
  public abstract getAllPropertiesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeProperty[]>;

  /**
   * add or update entity type property
   * @param EntityTypeProperty
   * @return EntityTypeProperty
   */
  public abstract saveEntityTypePropertyAsync(
    entityTypeProperty: EntityTypeProperty
  ): Promise<EntityTypeProperty>;

  /**
   * update entity type properties using patch logic
   * @param entityTypesPropertiesToPatch
   * @return EntityTypeProperty[]
   */
  public abstract updateEntityTypePropertiesAsync(
    entityTypesPropertiesToPatch: Map<string, any>
  ): Promise<EntityTypeProperty[]>;

  /**
   * update entity type attributes using patch logic
   * @param entityTypesAttributesToPatch
   * @return EntityTypeAttribute[]
   */
  public abstract updateEntityTypeAttributesAsync(
    entityTypesAttributesToPatch: Map<string, any>
  ): Promise<EntityTypeAttribute[]>;

  /**
   * this service returns all the data (properties, attributes and operations)
   * of a given entity type (by entity name)
   * @param entityTypeName
   */
  public abstract getAllEntityTypeDataByName(
    entityTypeName: string
  ): Promise<EntityType>;

  /**
   * this service returns an array of elements
   * of the entity sent as a parameter,
   *  based on the filter it receives as a parameter.
   * @param entityTypeName
   * @param FilterGroup
   */
  public abstract getEntityTypeElements(
    entityTypeName: string,
    FilterGroup: any
  ): Promise<PaginationResponse>;

  /* this service get translations for all entity types,properties and attributes
   * @param {EntityType[] | EntityType} entities
   * @returns {Promise<EntityType[] | EntityType>}
   * @protected
   */
  protected async getTranslationsAsync(
    entities: EntityType[] | EntityType
  ): Promise<EntityType[] | EntityType> {
    return new Promise<EntityType[] | EntityType>((resolve, reject) => {
      if (Array.isArray(entities)) {
        //Translate Entities
        let entitiesType = <EntityType[]>entities;
        entitiesType.map(async (entityType: EntityType) => {
          entityType.label = await this.translationService
            .get('entities.' + entityType.name.toLowerCase() + '.label')
            .toPromise();

          if (entityType.operations) {
            entityType.operations.map(async (operation) => {
              operation.label = await this.translationService
                .get(
                  'entities.' +
                    entityType.name.toLowerCase() +
                    '.operations.' +
                    operation.name.toLowerCase() +
                    '.label'
                )
                .toPromise();
            });
          }
        });

        resolve(entitiesType);
      } else {
        //Translate properties and attributes of the entity
        let entity = <EntityType>entities;
        if (entity.properties) {
          entity.properties.map(async (property) => {
            property.label = await this.translationService
              .get(
                'entities.' +
                  entity.name.toLowerCase() +
                  '.fields.' +
                  property.name.toLowerCase() +
                  '.label'
              )
              .toPromise();
          });
        }
        if (entity.attributes) {
          entity.attributes.map(async (attribute) => {
            attribute.label = await this.translationService
              .get(
                'entities.' +
                  entity.name.toLowerCase() +
                  '.fields.' +
                  attribute.name.toLowerCase() +
                  '.label'
              )
              .toPromise();
          });
        }
        if (entity.operations) {
          entity.operations.map(async (operation) => {
            operation.label = await this.translationService
              .get(
                'entities.' +
                  entity.name.toLowerCase() +
                  '.operations.' +
                  operation.name.toLowerCase() +
                  '.label'
              )
              .toPromise();
          });
        }
        resolve(entity);
      }
    });
  }

  /**
   * Get all properties and attributes by entity type id
   * @param {string} entityTypeId The entity type id
   * @returns {Promise<EntityTypeProperty[]>}
   */
  public abstract getAllPropertiesAndAttributesByEntityTypeIdAsync(
    entityTypeId: string
  ): Promise<EntityTypeProperty[]>;

  /**
   * This service return a element from a entity type list by id
   * @param entityTypeName
   * @param elementId
   */
  public abstract getEntityTypeElementById(
    entityTypeName: string,
    elementId: string
  ): Promise<any>;

  /**
   * this service excute in database the operation received as parameter
   * @param entityName
   * @param operation
   * @param entity
   */
  public abstract executeAction(
    entityName: string,
    operation: EntityTypeOperation,
    entity: any
  ): Promise<any>;
}
