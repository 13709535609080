<div class="grid-layout">
  <div class="grid grid-nogutter">
  <div class="col grid-nogutter grid-layout " >
    <gridster    pDroppable="dd"   (onDrop)="onDropItemEmptyCell($event,gridsterComponent)" #gridsterComponent  [options]="dashboardOptions" >

    <gridster-item pDroppable="dd"    (onDrop)="OnDropItem($event,item,gridsterComponent)"   [item]="item" *ngFor="let item of dashboardItems" >

        <div class="relative" >
          <div class="container-buttons"  >
            <button    (click)="onDeleteItem($event,item)"  pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger mr-1"></button>
            <button (click)="onOpenItemOptions(item)"    pButton pRipple type="button" icon="pi pi pi-cog" class="ml-1 p-button-rounded p-button-info"></button>

            </div>
      <ndc-dynamic  [ndcDynamicInputs]="{itemId:item.id,itemSettings:item.settings}"  [ndcDynamicComponent]="item.item">

      </ndc-dynamic>
        </div>
    </gridster-item>
  </gridster>
  </div>
  <div class="col-fixed grid-nogutter ml-2 ">
    <aside class="shadow-5 " >
      <div  class="flex flex-column sideBarDashboard p-1" [@OnExpandSideBarDashboard]="menuDashboardExpanded?'open':'closed'">
      <div class="text-center mt-2">

        <button  tooltipPosition="bottom" [ngClass]="menuDashboardExpanded?'':'mb-2'" pTooltip="Dashboard Options"  (click)="openOptionsDashboard()" pButton pRipple type="button" icon="pi pi pi-cog" class="text-center p-button-rounded p-button-info"></button>
        <button tooltipPosition="bottom"  pTooltip="Clear All Items" [ngClass]="menuDashboardExpanded?'mx-3':'mb-2'" (click)="clearAllItems()"  pButton pRipple type="button" icon="pi pi-times" class="text-center p-button-rounded p-button-danger"></button>
        <button pTooltip="Save Dashboard" [ngClass]="menuDashboardExpanded?'':'mb-2'"   [disabled]="!dynamicFormComponent?.form?.valid" (click)="saveDashboard(dynamicFormComponent.entityTemp)"  pButton pRipple type="button" icon="pi pi-save" class="text-center p-button-rounded p-button-success"></button>
        <button  pTooltip="Select Item" [ngClass]="menuDashboardExpanded?'mx-2':'mb-2'"  *ngIf="!menuDashboardExpanded"  (click)="op.toggle($event)"  pButton pRipple type="button" icon="pi pi-search" class="text-center p-button-rounded p-button-info"></button>
        <p-overlayPanel  #op [dismissable]="true" [showCloseIcon]="true">
          <ng-template pTemplate>
            <p-table styleClass="tableOverlay"  [value]="componentsType" selectionMode="single" [(selection)]="componentTypeSelected"  [paginator]="true" [rows]="5" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th style="text-align: center" pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
                  <th  style="text-align: center">Image</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-componentType>
                <tr  (dblclick)="onAddItemFirstPossiblePosition(componentType)" [pSelectableRow]="rowData" pDraggable="dd" (onDragStart)="onDragItem(componentType)" >
                  <td      style="text-align: center">{{'dashboard.components.'+ componentType.name + '.label'|translate}}</td>
                  <td    style="height: auto;width: 150px"> <p-image [src]="'dashboard.components.'+ componentType.name + '.imageUrl'|translate"  class="w-2" alt=""></p-image></td>
                </tr>
              </ng-template>
            </p-table>
          </ng-template>
        </p-overlayPanel>
      </div>
        <section  [@OnExpandSideBarTextDashboard]="menuDashboardExpanded?'show-content':'hide-content'">
          <div class="pt-2 " [hidden]="!menuDashboardExpanded">


            <prg-dynamic-form #dynamicFormComponent [viewMode]="dashboardViewMode"
                              [fields]="dashboardDynamicFormFields"
                              [entity]="dashboard">
            </prg-dynamic-form>

            <p-table [paginator]="true"  selectionMode="single" [rows]="3" [value]="componentsType" responsiveLayout="scroll" [(selection)]="componentTypeSelected">
              <ng-template pTemplate="header">
                <tr>
                  <th style="text-align: center" pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
                  <th  style="text-align: center">Image</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-componentType>
                <tr [pSelectableRow]="rowData"  (dblclick)="onAddItemFirstPossiblePosition(componentType)"  pDraggable="dd" (onDragStart)="onDragItem(componentType)" >
                  <td      style="text-align: center">{{'dashboard.components.'+ componentType.name + '.label'|translate}}</td>
                  <td    style="height: auto;width: 150px"> <p-image [src]="'dashboard.components.'+ componentType.name + '.imageUrl'|translate"  class="w-2" alt=""></p-image></td>
                </tr>
              </ng-template>
            </p-table>

          </div>
        </section>

      <div class="flex flex-column pb-2 flex-grow-1 justify-content-end" [ngClass]="menuDashboardExpanded?'align-items-start':'align-items-center'">

        <button (click)=" onShowHideSideBarDetails()" pButton pRipple type="button" icon="{{menuDashboardExpanded?'pi pi-angle-double-right':'pi pi-angle-double-left'}} text-2xl" class="p-button-rounded p-button-text p-button-plain"></button></div>
      </div>
    </aside>
  </div>
  </div>

</div>


<!--"
"-->
