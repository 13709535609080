// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Validators } from '@angular/forms';
import { NgxLoggerLevel } from 'ngx-logger';
import { BaseField, ControlName, Pagination, TableConfigs } from 'prg-core-lib';

export const environment = {
  production: false,
  useMockServices: true,
  logLevel: NgxLoggerLevel.TRACE,
  serverLogLevel: NgxLoggerLevel.OFF,

  rolesScopesModuleConfigurations: {
    useMockServices: true,
    rolesScopesBasePath: 'roles-scopes',
    baseScopes: ['view', 'create', 'update', 'delete'],
    otherScopes: 'other',
  },
  authModuleConfigurations: {
    useMockServices: true,
    authRoutingBasePath: 'auth',
    authRoutingSignupPath: 'signup',
    authRoutingLoginPath: 'login',
    authRoutingRequestPasswordPath: 'request-password',
    authRoutingResetPasswordPath: 'reset-password',
    authRoutingLoginOkRedirectPath: '',
    termsOfUseRoutingPath: 'terms-of-use',
    authStorage: {
      storage: window.localStorage,
      keyStoredUserName: 'savedUserEmail',
      keyLoggedUser: 'loggedUser',
    },
  },
  termsModuleConfigurations: {
    useMockServices: true,
    baseTermsTranslationKey: 'components.terms-conditions',
    termsOfUseRoutingPath: 'terms-of-use',
  },

  dashboardModuleConfigurations: {
    useMockServices: true,
    dashboardRoutingBasePath: 'dashboard',
    dashboardCreateRoutingPath: 'create',
    dashboardDisplayRoutingPath: 'display',
    dashboardListRoutingPath: 'list',
    dashboardEditRoutingPath: 'edit',
  },

  configurationItemsModuleConfigurations: {
    useMockServices: true,
    configurationItemsRoutingBasePath: 'config-items',
    configurationItemsRoutingAdminPath: 'admin',
    configurationItemsRoutingUserPath: 'user-preferences',
  },

  dynamicFormsConfigurations: {
    useMockServices: true,
    dynamicFormsBasePath: 'dynamic-forms',
  },

  entityTypeConfigurations: {
    useMockServices: true,
    entityTypeBasePath: 'entity-types',
    lookupTableDataTypeItemId: '11',
    entityTypeDataTypeItemId: '12',
    entityTypeTableColumns: [
      {
        controlType: 'label',
        value: null,
        readonly: true,
        key: 'name',
        validators: [],
        basePathTranslation: 'entities',
      },
      {
        controlType: 'label',
        value: null,
        readonly: true,
        key: 'guiSettings',
        validators: [],
      },
      {
        controlType: 'label',
        value: null,
        readonly: true,
        key: 'universalStateId',
        validators: [],
      },
    ],

    specificEntityTypeListConfigs: new TableConfigs({
      defaultColumns: ['id', 'name', 'stock', 'expiryDate'],
      globalFilterFields: ['name', 'description'],
      pagination: new Pagination(),
      multiSortMeta: [
        { field: 'name', order: -1 },
        { field: 'stock', order: -1 },
      ],
    }),
  },
  lookupTableConfigurations: {
    useMockServices: true,
    lookupTableBasePath: 'lookup-table',
    lookupTableColumns: [
      new BaseField({
        controlName: ControlName.inputText,
        key: 'name',
        validators: [Validators.required],
        readonly: true,
      }),
      new BaseField({
        controlName: ControlName.inputText,
        key: 'description',
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      }),
      new BaseField({
        controlName: ControlName.inputText,
        key: 'universalStateId',
        validators: [Validators.required],
      }),
    ],
    lookupTableItemsColumns: [
      new BaseField({
        controlName: ControlName.inputText,
        key: 'name',
        validators: [Validators.required],
        readonly: true,
      }),
      new BaseField({
        controlName: ControlName.inputText,
        key: 'description',
        validators: [Validators.required],
      }),
      new BaseField({
        controlName: ControlName.inputText,
        key: 'universalStateId',
        validators: null,
      }),
    ],
  },
  queriesModuleConfigurations: {
    useMockServices: true,
    queriesRoutingPath: 'queries',
    queryTableColumns: [
      new BaseField({
        key: 'name',
        readonly: true,
        basePathTranslation: 'queries.fields',
      }),
      new BaseField({
        key: 'description',
        readonly: true,
        basePathTranslation: 'queries.fields',
      }),
      /*      new BaseField({
        key: 'isRaw',
        readonly: true,
        basePathTranslation: 'queries.fields',
      }),*/
      new BaseField({
        key: 'createdBy',
        readonly: true,
        basePathTranslation: 'queries.fields',
      }),
      /*    new BaseField({
        key: 'entityTypeId',
        readonly: true,
        basePathTranslation: 'queries.fields',
      }),*/
    ],
  },

  defaultRoutePath: 'lookup-table',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
