import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  template: '<ion-app><ion-router-outlet></ion-router-outlet></ion-app>',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private primeNGConfig: PrimeNGConfig,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.setupTranslateService();
  }

  private setupTranslateService() {
    /// Configure translation service
    /// {
    // TODO: Get languages and default language from configuration

    this.translateService.langs = ['pt', 'en'];
    const currentLanguage = this.translateService.langs.includes(
      navigator.language
    )
      ? navigator.language
      : this.translateService.langs[0];
    this.translateService.setDefaultLang(currentLanguage);
    this.translateService.use(currentLanguage);
    this.translateService.onLangChange.subscribe((event: any) => {
      this.translateService.get('primeng').subscribe((res) => {
        this.primeNGConfig.setTranslation(res);
      });
    });

    /// }
  }
}
