import { PrgMenuItem } from '../../models/prg-menu-item';

/**
 * Constant that defines the menu items for user menu
 */
export const USER_MENU_HEADER: PrgMenuItem[] = [
  {
    id: 'user-menu',
    items: [
      { separator: true },
      {
        icon: 'pi pi-user',
        id: 'my-account', //Key da tradução
        visibleString: '!!this.user',
      },
      {
        icon: 'pi pi-sign-out',
        commandString: 'this.onClickLogout',
        id: 'logout',
        visibleString: '!!this.user',
      },
      {
        icon: 'pi pi-sign-in',
        visibleString: '!this.user',
        id: 'login',
        routerLinkString:
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingLoginPath,]",
      },
    ],
  },
];
/**
 * Constant that defines the menu items for language menu on header
 */

export const LANGUAGE_MENU_HEADER: PrgMenuItem[] = [
  {
    id: 'language-menu',
    items: [
      { separator: true },
      {
        id: 'pt',
        commandString: '($event) => this.changeLanguage($event.item.id)',
      },
      {
        id: 'en',
        commandString: '($event) => this.changeLanguage($event.item.id)',
      },
    ],
  },
];
