import { DateTimeConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/input-date-time/date-time.model';
import { InputNumberConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/input-number/input-number.model';
import { InputTextConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/input-text/input-text.model';
import { ControlName } from '../../core/components/dynamic-form/models/constants/control-names';
import { ReferenceType } from '../../core/components/dynamic-form/models/constants/reference-type';
import { ToolbarSlot } from '../../core/components/dynamic-form/models/constants/toolbar-slots';
import { ExecutedActionBehaviour } from '../../core/models/executed-action-behaviourts';
import {
  ComponentType,
  ComponentTypeEnum,
  Dashboard,
  DashboardItem,
} from '../../dashboard/models/dashboard.model';
import {
  EntityType,
  EntityTypeAttribute,
  EntityTypeOperation,
  EntityTypeProperty,
  HttpVerb,
} from '../models/entity-type.model';

export interface Database {
  /**
   * Mock Entity Types
   */
  EntityTypes: EntityType[];
  /**
   * Mock Entity Types Operations
   */
  EntityTypesOperations: EntityTypeOperation[];
  /**
   * Mock Entity Types Attributes
   */
  EntityTypeAttribute: EntityTypeAttribute[];
  /**
   * Mock Entity Types Properties
   */
  EntityTypeProperty: EntityTypeProperty[];

  /**
   * Mock Product Entity Type
   */
  product: any[];
  dashboard: Dashboard[];
  componentType: ComponentType[];
}

/**
 * Mock Database
 */
export const MockDatabase: Database = {
  EntityTypes: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Customer',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      stateId: '1',
      workspaceId: '1',
      guiSettings: 'qwertty',
      group: 'System',
      properties: null,
      attributes: null,
      operations: null,
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'User',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      stateId: '1',
      workspaceId: '1',
      guiSettings: '12346',
      group: 'System',
      properties: null,
      attributes: null,
      operations: null,
    },
    {
      id: '3',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Refunds',
      createdBy: null,
      label: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      stateId: '1',
      workspaceId: '1',
      guiSettings:
        'new ModelQualquer({list : {pagination:false, columns: string[]} ,form:{} })',
      group: 'Store',
      properties: null,
      attributes: null,
      operations: null,
    },
    {
      id: '4',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Orders',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      stateId: '1',
      workspaceId: '1',
      guiSettings: 'nccnnc',
      group: 'Store',
      properties: null,
      attributes: null,
      operations: null,
    },
    {
      id: '5',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'product',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      stateId: '1',
      workspaceId: '1',
      guiSettings:
        'new ModelQualquer({list : {pagination:false, columns: BaseFields[]} ,form:{} })',
      group: 'Store',
      properties: null,
      attributes: null,
      operations: null,
    },

    {
      id: '6',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'dashboard',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      stateId: '1',
      workspaceId: '1',
      guiSettings:
        'new ModelQualquer({list : {pagination:false, columns: BaseFields[]} ,form:{} })',
      group: 'System',
      properties: null,
      attributes: null,
      operations: null,
    },
    {
      id: '7',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'dashboarditem',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      stateId: '1',
      workspaceId: '1',
      guiSettings:
        'new ModelQualquer({list : {pagination:false, columns: BaseFields[]} ,form:{} })',
      group: 'System',
      properties: null,
      attributes: null,
      operations: null,
    },
    {
      id: '8',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'componentType',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      stateId: '1',
      workspaceId: '1',
      guiSettings:
        'new ModelQualquer({list : {pagination:false, columns: BaseFields[]} ,form:{} })',
      group: 'System',
      properties: null,
      attributes: null,
      operations: null,
    },
  ],

  EntityTypeProperty: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'property1',
      createdBy: null,
      label: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      //referenceName
      reference: 'dataType',
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'property2',
      createdBy: null,
      label: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'number',
      dataTypeId: 'dataType.number',
      isArray: false,
      order: 3,
      reference: 'sis',
    },
    {
      id: '3',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'property3',
      createdBy: null,
      label: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings:
        "String(new InputTextConfiguration({disabled:true, visibleExpression:'sisisi'}))",
      dataTypeId: 'dataType.lookupTable',
      isArray: false,
      order: 3,
      reference: 'sis',
    },
    {
      id: '4',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'property4',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 4,
      reference: 'sis',
    },
    {
      id: '5',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'customername',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'customername',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: 'customer name',
    },
    {
      id: '6',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'customerId',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      label: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'customerId',
      dataTypeId: 'dataType.number',
      isArray: false,
      order: 1,
      reference: 'customerId',
    },
    {
      id: '7',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'dateofbirth',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      label: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'dateofbirth',
      dataTypeId: 'dataType.date',
      isArray: false,
      order: 1,
      reference: 'dateofbirth',
    },
    {
      id: '8',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'name',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      label: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'name',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: 'name',
    },
    {
      id: '9',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'userId',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'userId',
      dataTypeId: 'dataType.number',
      isArray: false,
      order: 1,
      reference: 'userId',
    },
    {
      id: '10',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'RegisterDate',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'RegisterDate',
      dataTypeId: 'dataType.date',
      isArray: false,
      order: 1,
      reference: 'RegisterDate',
    },
    {
      id: '99999991',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'id',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: null,
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: null,
    },
    {
      id: '99999992',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'name',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify(
        new InputTextConfiguration({
          readonly: false,
          key: 'name',
          validators: ['Validators.required'] as any,
          basePathTranslation: 'entities.product.fields',
        })
      ),
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 2,
      reference: null,
    },
    {
      id: '99999993',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'description',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify(
        new InputTextConfiguration({
          readonly: false,
          key: 'description',
          validators: ['Validators.maxLength(20)'] as any,
          basePathTranslation: 'entities.product.fields',
        })
      ),
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 2,
      reference: null,
    },
    {
      id: '99999994',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'expiryDate',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify(
        new DateTimeConfiguration({
          key: 'expiryDate',
          validators: ['Validators.required'] as any,
        })
      ),
      dataTypeId: 'dataType.date',
      isArray: true,
      order: 3,
      reference: null,
    },
    {
      id: '99999995',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'value',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify(
        new InputNumberConfiguration({
          readonly: false,
          key: 'value',
          validators: ['Validators.required'] as any,
        })
      ),
      dataTypeId: 'dataType.number',
      isArray: false,
      order: 4,
      reference: null,
    },

    {
      id: '99999996',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'stock',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify({
        controlName: ControlName.dropdown,
        referenceType: ReferenceType.LookupTable,
        referenceName: 'stockStatus',
        readonly: false,
        key: 'stock',
        optionLabel: 'label',
        optionValue: 'id',
        validators: ['Validators.required'] as any,
      }),
      dataTypeId: 'dataType.lookupTable',
      isArray: false,
      order: 4,
      reference: 'stockStatus',
    },
    {
      id: '9999996',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'stock',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify({
        controlName: ControlName.dropdown,
        referenceType: ReferenceType.LookupTable,
        referenceName: 'stockStatus',
        readonly: false,
        key: 'stock',
        optionLabel: 'label',
        optionValue: 'id',
        validators: ['Validators.required'] as any,
      }),
      dataTypeId: 'dataType.lookupTable',
      isArray: false,
      order: 4,
      reference: 'stockStatus',
    },
    {
      id: '70000',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'name',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '6',
      guiSettings: JSON.stringify(
        new InputTextConfiguration({
          readonly: false,
          key: 'name',
          validators: ['Validators.required'] as any,
          basePathTranslation: 'entities.product.fields', //mudar
        })
      ),
      dataTypeId: 'dataType.text',
      isArray: false,
      order: 1,
      reference: null, //referencia lookup
    },
    {
      id: '700001',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'id',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '6',
      guiSettings: null,
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: null,
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.entityType',
      entityTypeId: '6',
      guiSettings: 'asa',
      id: '225f5414-e317-4fd8-a6ed-d5cdd23e1e9c',
      isArray: true,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'items',
      operationId: null,
      order: 1,
      reference: 'DashboardItem',
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '6',
      guiSettings: 'sda',
      id: 'b8f206a8-44f6-41d0-853f-d3204fba1fa0',
      isArray: null,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'path',
      operationId: null,
      order: 1,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.default',
      entityTypeId: '6',
      guiSettings: 'ddd',
      id: '1a244e5d-6968-4c2e-b115-49b2d985d223',
      isArray: false,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'isPublic',
      operationId: null,
      order: 1,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '6',
      guiSettings: 'dddd',
      id: '805b41f8-a758-4b7e-8482-8c369aac37df',
      isArray: false,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'settings',
      operationId: null,
      order: 1,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '6',
      guiSettings: 'fd',
      id: 'd13ed5eb-ac5e-48dc-9e23-cda2637a41f6',
      isArray: true,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'entityTypeIds',
      operationId: null,
      order: 1,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '6',
      guiSettings: 'sdasd',
      id: 'b1631498-aad2-4871-8ba2-ffdfeeb0d235',
      isArray: null,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'createdBy',
      operationId: null,
      order: 8,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '8',
      guiSettings: 'hgfhghf',
      id: '9184e87c-2476-48c9-a867-9ece03c5bbbf',
      isArray: null,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'generalSettings',
      operationId: null,
      order: 1,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '8',
      guiSettings: 'asdasd',
      id: '6c9711c5-f885-4b9d-ae36-b9f989605ce6',
      isArray: null,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'imageUrl',
      operationId: null,
      order: 2,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '8',
      guiSettings: 'asdsfdf',
      id: '290aa8e8-65c3-4857-a75c-b0d367655d44',
      isArray: null,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'type',
      operationId: null,
      order: 3,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '8',
      guiSettings: 'asdsad',
      id: '1d058896-3622-4af8-82b6-f700097a7c3b',
      isArray: null,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'label',
      operationId: null,
      order: 4,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
    {
      createdBy: null,
      createdOn: null,
      dataTypeId: 'dataType.string',
      entityTypeId: '8',
      guiSettings: 'sdads',
      id: '777bbf19-d863-41d3-8454-faefef83d381',
      isArray: null,
      label: null,
      modifiedBy: null,
      modifiedOn: null,
      name: 'id',
      operationId: null,
      order: 5,
      reference: null,
      transactionId: null,
      universalStateId: '1',
      workspaceId: '1',
    },
  ],

  EntityTypeAttribute: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'attribute111',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      label: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: 'sis',
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'attribute2',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      label: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      dataTypeId: 'dataType.number',
      isArray: false,
      order: 3,
      reference: 'sis',
    },
    {
      id: '3',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'attribute3',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      dataTypeId: 'dataType.default',
      isArray: false,
      order: 3,
      reference: 'sis',
    },
    {
      id: '4',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'attribute4',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      label: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 4,
      reference: 'sis',
    },
    {
      id: '5',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'customeraddress',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'customeraddress',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: 'customeraddress',
    },
    {
      id: '6',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'VAT',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'VAT',
      dataTypeId: 'dataType.number',
      isArray: false,
      order: 1,
      reference: 'VAT',
    },
    {
      id: '7',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'city',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'city',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: 'city',
    },
    {
      id: '8',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'useraddress',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'useraddress',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: 'user address',
    },
    {
      id: '9',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'userdateofbirth',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      label: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'userdateofbirth',
      dataTypeId: 'dataType.date',
      isArray: false,
      order: 1,
      reference: 'user date of birth',
    },
    {
      id: '10',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'email',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      label: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '2',
      guiSettings: 'email',
      dataTypeId: 'dataType.string',
      isArray: false,
      order: 1,
      reference: 'email',
    },
    {
      id: '9999999',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'discount',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      label: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: null,
      dataTypeId: 'dataType.number',
      isArray: false,
      order: 5,
      reference: null,
    },
  ],

  EntityTypesOperations: [
    {
      id: '1',
      universalStateId: '1',
      label: null,
      transactionId: null,
      operationId: null,
      name: 'operation1',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'operation2',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
    {
      id: '3',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'operation3',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
    {
      id: '4',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'operation4',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
    {
      id: '5',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      label: null,
      name: 'operation5',
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '3',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
    {
      id: '6',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'save',
      createdBy: null,
      modifiedBy: null,
      label: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify({
        disableWhenFormInvalid: true,
        visibleExpression:
          'this.viewMode==="edit" && this.entity!= null && this.entity.id == null',
        key: 'save',
        toolbarSlot: ToolbarSlot.Left,
      }),
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Post,
      order: 0,
    },
    {
      id: '7',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'update',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify({
        key: 'update',
        disableWhenFormInvalid: true,
        visibleExpression:
          'this.viewMode==="edit" && this.entity!= null && this.entity.id != null',
        toolbarSlot: ToolbarSlot.Left,
        executedActionBehaviour: new ExecutedActionBehaviour(),
      }),
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Put,
      order: 0,
    },
    {
      id: '8',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'delete',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: JSON.stringify({
        visibleExpression: 'this.entity!= null && this.entity.id != null',
        key: 'delete',
        toolbarSlot: ToolbarSlot.Left,
        confirmationModal: 'message.delete-confirmation',
        executedActionBehaviour: new ExecutedActionBehaviour(),
      }),
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Delete,
      order: 0,
    },
    {
      id: '9',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'get',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: null,
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
    {
      id: '10',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'updatePut',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '5',
      guiSettings: null,
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Put,
      order: 0,
    },
    {
      id: '999282828',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'view',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'create',
      label: null,

      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },

    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'update',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'delete',
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      entityTypeId: '1',
      guiSettings: 'string',
      override: false,
      imports: null,
      sourceCode: 'string',
      httpVerb: HttpVerb.Get,
      order: 0,
    },
  ],

  product: [
    {
      id: '1',
      name: 'produto-1',
      description: 'produto-1-descrição',
      expiryDate: new Date(),
      value: 20,
      stock: 'stockStatus.available',
    },
    {
      id: '2',
      name: 'produto-2',
      description: 'produto-2-descrição',
      expiryDate: new Date(),
      value: 40,
      stock: 'stockStatus.unavailable',
    },
    {
      id: '3',
      name: 'produto-3',
      description: 'produto-3-descrição',
      expiryDate: new Date(),
      value: 15,
      stock: 'stockStatus.unavailable',
    },
    {
      id: '4',
      name: 'produto-4',
      description: 'produto-4-descrição',
      expiryDate: new Date(),
      value: 24,
      stock: 'stockStatus.available',
    },
    {
      id: '5',
      name: 'produto-5',
      description: 'produto-5-descrição',
      expiryDate: new Date(),
      value: 100,
      stock: 'stockStatus.available',
    },
    {
      id: '6',
      name: 'produto-6',
      description: 'produto-6-descrição',
      expiryDate: new Date(),
      value: 2,
      stock: 'stockStatus.available',
    },
    {
      id: '7',
      name: 'produto-7',
      description: 'produto-7-descrição',
      expiryDate: new Date(),
      value: 15.99,
      stock: 'stockStatus.unavailable',
    },
    {
      id: '8',
      name: 'produto-8',
      description: 'produto-8-descrição',
      expiryDate: new Date(),
      value: 92,
      stock: 'stockStatus.unavailable',

      attributes: {
        discount: 50,
      },
    },
    {
      id: '9',
      name: 'produto-9',
      description: 'produto-9-descrição',
      expiryDate: new Date(),
      value: 165,
      stock: 'stockStatus.available',
    },
    {
      id: '10',
      name: 'produto-10',
      description: 'produto-10-descrição',
      expiryDate: new Date(),
      value: 11,
      stock: 'stockStatus.available',
    },
  ],
  dashboard: [
    new Dashboard({
      name: 'MainDashboard',
      id: '1',
      path: 'Config/DashBoards/1',
      isPublic: true,
      settings: null,
      items: [
        new DashboardItem({
          universalStateId: '1',
          x: 1,
          y: 0,
          cols: 1,
          id: '1679938981323',
          rows: 1,
          dashboardId: '1',
          settings: null,
          componentType: 'Bar-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 0,
          y: 0,
          cols: 1,
          id: '1679931545',
          rows: 1,
          dashboardId: '1',
          settings: null,
          componentType: 'Table',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 0,
          y: 1,
          cols: 1,
          rows: 1,
          id: '1679995',
          dashboardId: '1',
          settings: null,
          componentType: 'Line-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 1,
          y: 1,
          cols: 1,
          rows: 1,
          dashboardId: '1',
          id: '1678975',
          settings: null,
          componentType: 'Table',
        }),
      ],
      entityTypeIds: ['1'],
      createdBy: '1',
    }),
    new Dashboard({
      name: 'SecondDashboard',
      id: '2',
      path: 'Config/DashBoards/2',
      isPublic: true,
      settings: null,
      items: [
        new DashboardItem({
          universalStateId: '1',
          x: 0,
          y: 0,
          cols: 2,
          rows: 1,
          id: '165455',
          dashboardId: '1',
          settings: null,
          componentType: 'Bar-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 0,
          y: 1,
          cols: 1,
          rows: 1,
          id: '16547775',
          dashboardId: '1',
          settings: null,
          componentType: 'Line-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 1,
          y: 1,
          cols: 1,
          rows: 1,
          dashboardId: '1',
          id: '1654754878775',
          settings: null,
          componentType: 'Table',
        }),
      ],
      entityTypeIds: ['1'],
      createdBy: '1',
    }),
    new Dashboard({
      universalStateId: '1',
      isPublic: true,
      id: '3',
      name: 'ThirdDashboard',
      createdBy: '2',
      entityTypeIds: ['1', '4'],
      items: [
        new DashboardItem({
          universalStateId: '1',
          x: 0,
          y: 0,
          cols: 1,
          rows: 1,
          dashboardId: '3',
          id: '1679933',
          settings: null,
          componentType: 'Bar-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 0,
          y: 1,
          cols: 1,
          rows: 1,
          id: '16799387851543',
          dashboardId: '3',
          settings: null,
          componentType: 'Bar-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 1,
          y: 0,
          cols: 1,
          rows: 1,
          id: '16799387851548',
          dashboardId: '3',
          settings: null,
          componentType: 'Bar-Chart',
        }),
      ],
      settings:
        '{"gridType":"fit","scale":1,"fixedColWidth":250,"fixedRowHeight":250,"keepFixedHeightInMobile":false,"keepFixedWidthInMobile":false,"setGridSize":false,"useBodyForBreakpoint":false,"compactType":"none","mobileBreakpoint":640,"allowMultiLayer":false,"defaultLayerIndex":1,"maxLayerIndex":2,"baseLayerIndex":1,"minCols":1,"maxCols":100,"minRows":1,"maxRows":100,"defaultItemCols":1,"defaultItemRows":1,"maxItemCols":50,"maxItemRows":50,"minItemCols":1,"minItemRows":1,"minItemArea":1,"maxItemArea":2500,"addEmptyRowsCount":0,"rowHeightRatio":1,"margin":5,"outerMargin":true,"useTransformPositioning":true,"scrollSensitivity":10,"scrollSpeed":20,"draggable.enabled":true,"draggable.delayStart":0,"draggable.ignoreContentClass":"gridster-item-content","draggable.ignoreContent":false,"draggable.dragHandleClass":"drag-handle","draggable.dropOverItems":false,"resizable.enabled":true,"resizable.delayStart":0,"resizable.handles.s":true,"resizable.handles.e":true,"resizable.handles.n":true,"resizable.handles.w":true,"resizable.handles.se":true,"resizable.handles.ne":true,"resizable.handles.sw":true,"resizable.handles.nw":true,"swap":true,"swapWhileDragging":false,"pushItems":false,"disablePushOnDrag":false,"disablePushOnResize":false,"disableAutoPositionOnConflict":false,"pushDirections.north":true,"pushDirections.east":true,"pushDirections.south":true,"pushDirections.west":true,"pushResizeItems":false,"displayGrid":"always","disableWindowResize":false,"disableWarnings":false,"scrollToNewItems":false,"disableScrollHorizontal":false,"disableScrollVertical":false,"enableBoundaryControl":false,"enableEmptyCellClick":true,"enableEmptyCellContextMenu":false,"enableEmptyCellDrop":false,"enableEmptyCellDrag":true,"enableOccupiedCellDrop":false,"emptyCellDragMaxCols":50,"emptyCellDragMaxRows":50,"ignoreMarginInRow":false,"dirType":"ltr"}',
    }),
    new Dashboard({
      universalStateId: '1',
      isPublic: true,
      id: '725cc79c-6e7e-4391-81cc-42de6ed8781e',
      name: 'FouthDashboard',
      entityTypeIds: ['1', '2'],
      items: [
        new DashboardItem({
          universalStateId: '1',
          x: 1,
          y: 0,
          cols: 1,
          rows: 1,
          id: '16799387851888',
          dashboardId: '725cc79c-6e7e-4391-81cc-42de6ed8781e',
          settings: null,
          componentType: 'Line-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 0,
          y: 0,
          cols: 1,
          id: '1679938785788',
          rows: 1,
          dashboardId: '725cc79c-6e7e-4391-81cc-42de6ed8781e',
          settings: null,
          componentType: 'Doughnut-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 0,
          y: 1,
          cols: 1,
          rows: 1,
          dashboardId: '725cc79c-6e7e-4391-81cc-42de6ed8781e',
          id: '1679938785788444',
          settings: null,
          componentType: 'Bar-Chart',
        }),
        new DashboardItem({
          universalStateId: '1',
          x: 1,
          y: 1,
          cols: 1,
          id: '2679938785788',
          rows: 1,
          dashboardId: '725cc79c-6e7e-4391-81cc-42de6ed8781e',
          settings: null,
          componentType: 'Table',
        }),
      ],
      createdBy: '2',
      settings:
        '{"gridType":"fit","scale":1,"fixedColWidth":250,"fixedRowHeight":250,"keepFixedHeightInMobile":false,"keepFixedWidthInMobile":false,"setGridSize":false,"useBodyForBreakpoint":false,"compactType":"none","mobileBreakpoint":640,"allowMultiLayer":false,"defaultLayerIndex":1,"maxLayerIndex":2,"baseLayerIndex":1,"minCols":1,"maxCols":100,"minRows":1,"maxRows":100,"defaultItemCols":1,"defaultItemRows":1,"maxItemCols":50,"maxItemRows":50,"minItemCols":1,"minItemRows":1,"minItemArea":1,"maxItemArea":2500,"addEmptyRowsCount":0,"rowHeightRatio":1,"margin":5,"outerMargin":true,"useTransformPositioning":true,"scrollSensitivity":10,"scrollSpeed":20,"draggable.enabled":true,"draggable.delayStart":0,"draggable.ignoreContentClass":"gridster-item-content","draggable.ignoreContent":false,"draggable.dragHandleClass":"drag-handle","draggable.dropOverItems":false,"resizable.enabled":true,"resizable.delayStart":0,"resizable.handles.s":true,"resizable.handles.e":true,"resizable.handles.n":true,"resizable.handles.w":true,"resizable.handles.se":true,"resizable.handles.ne":true,"resizable.handles.sw":true,"resizable.handles.nw":true,"swap":true,"swapWhileDragging":false,"pushItems":false,"disablePushOnDrag":false,"disablePushOnResize":false,"disableAutoPositionOnConflict":false,"pushDirections.north":true,"pushDirections.east":true,"pushDirections.south":true,"pushDirections.west":true,"pushResizeItems":false,"displayGrid":"always","disableWindowResize":false,"disableWarnings":false,"scrollToNewItems":false,"disableScrollHorizontal":false,"disableScrollVertical":false,"enableBoundaryControl":false,"enableEmptyCellClick":true,"enableEmptyCellContextMenu":false,"enableEmptyCellDrop":false,"enableEmptyCellDrag":true,"enableOccupiedCellDrop":false,"emptyCellDragMaxCols":50,"emptyCellDragMaxRows":50,"ignoreMarginInRow":false,"dirType":"ltr"}',
    }),
  ],
  componentType: [
    new ComponentType({
      id: '1',
      type: ComponentTypeEnum.BarChart,
      name: 'Bar Chart',
      imageUrl: '/assets/images/component_type_images/bar-chart.PNG',
      generalSettings: null,
    }),
    new ComponentType({
      id: '2',
      type: ComponentTypeEnum.LineChart,
      name: 'Line Chart',
      imageUrl: '/assets/images/component_type_images/line-chart.PNG',
      generalSettings: null,
    }),
    new ComponentType({
      id: '3',
      type: ComponentTypeEnum.DoughnutChart,
      name: 'Donut Chart',
      imageUrl: '/assets/images/component_type_images/donut-chart.PNG',
      generalSettings: null,
    }),
    new ComponentType({
      id: '4',
      type: ComponentTypeEnum.Table,
      name: 'Table',
      imageUrl: '/assets/images/component_type_images/table.PNG',
      generalSettings: null,
    }),
  ],
};
