import { Directive, Input } from '@angular/core';
import { CascadeSelect } from 'primeng/cascadeselect';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Cascade Select
 */
export class CascadeSelectConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName: string = ControlName.cascadeSelect;
  /**
   *An array of selectitems to display as the available options.
   */
  public options: any[] = null;
  /**
   *Property name or getter function to use as the label of an option.
   */
  public optionLabel: string = null;
  /**
   *Property name or getter function to use as the value of an option, defaults to the option itself when not defined.
   */
  public optionValue: string = null;
  /**
   *Property name or getter function to use as the label of an option group.
   */
  public optionGroupLabel: string = null;
  /**
   *Property name or getter function to retrieve the items of a group.
   */
  public optionGroupChildren: any[] = null;
  /**
   *Default text to display when no option is selected.
   */
  public placeholder: string = null;
  /**
   *When present, it specifies that the component should be disabled.
   */
  public disabled: boolean = false;
  /**
   *A property to uniquely identify an option.
   */
  public dataKey: string = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *Identifier of the underlying input element.
   */
  public inputId: string = null;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;
  /**
   *Id of the element or "body" for document where the overlay should be appended to.
   */
  public appendTo: string = null;
  /**
   *Inline style of the component.
   */
  public style: object = null;
  /**
   *Inline style of the overlay panel.
   */
  public panelStyle: object = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Style class of the overlay panel.
   */
  public panelStyleClass: string = null;
  /**
   *Label of the input for accessibility.
   */
  public inputLabel: string = null;
  /**
   *Defines a string that labels the input for accessibility.
   */
  public ariaLabel: string = null;
  /**
   *When enabled, a clear icon is displayed to clear the value.
   */
  public showClear: boolean = false;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<CascadeSelectConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setCascadeSelectConfigs]',
})
export class PrgSetCascadeSelectConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: CascadeSelect) {}

  /**
   * input Cascade Selected configs and assign configs to element
   */
  @Input() set setCascadeSelectConfigs(configs: CascadeSelectConfiguration) {
    Object.assign(this.element, configs);
  }
}
