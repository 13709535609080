import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';

import { PrgCreateRoleComponent } from './components/role-create/prg-create-role.component';
import { PrgModalCreateRoleComponent } from './components/role-create/prg-modal-create-role/prg-modal-create-role.component';
import { PrgSelectRoleComponent } from './components/roles-select/prg-select-role.component';
import { PrgScopesTableComponent } from './components/scopes-table/prg-scopes-table.component';
import { MockRolesScopesService } from './mock/services/roles-scopes/roles-scopes.service.mock';
import { PrgRolesScopesConfig } from './models/prg-roles-scopes-config';
import { PrgRolesScopesPage } from './pages/roles-scopes/prg-roles-scopes.page';
import { PrgRolesScopesRoutingModule } from './prg-roles-scopes-routing.module';
import { PRG_ROLES_SCOPES_CONFIG } from './services/roles-scopes-configuration/prg-roles-scopes-configuration.service';
import { AbstractRolesScopesService } from './services/roles-scopes/abstract-roles-scopes.service';
import { RolesScopesService } from './services/roles-scopes/roles-scopes.service';

@NgModule({
  declarations: [
    PrgRolesScopesPage,
    PrgSelectRoleComponent,
    PrgCreateRoleComponent,
    PrgScopesTableComponent,
    PrgModalCreateRoleComponent,
  ],
  imports: [PrgRolesScopesRoutingModule, PrgSharedComponentsModule],
  exports: [
    PrgSelectRoleComponent,
    PrgCreateRoleComponent,
    PrgScopesTableComponent,
  ],
})
export class PrgRolesScopesModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgRolesScopesConfig: PrgRolesScopesConfig
  ): ModuleWithProviders<PrgRolesScopesModule> {
    rolesScopesConfig = prgRolesScopesConfig;
    return {
      ngModule: PrgRolesScopesModule,
      providers: [
        {
          provide: PRG_ROLES_SCOPES_CONFIG,
          useValue: prgRolesScopesConfig,
        },
        {
          provide: AbstractRolesScopesService,
          useClass: prgRolesScopesConfig.useMockServices
            ? MockRolesScopesService
            : RolesScopesService,
        },
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the AuthCoreConfig object
 */
export let rolesScopesConfig: PrgRolesScopesConfig;
