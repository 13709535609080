import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../../../models/base-action';
import { ListBoxConfiguration } from './list-box.model';

/**
 * component List Box
 */
@Component({
  selector: 'prg-list-box-field',
  templateUrl: './list-box.component.html',
})
export class PrgListBoxComponent {
  /**
   * output
   */
  @Output() onValueChange = new EventEmitter<FieldChangeOutput>();

  /**
   * function that triggers the outpout
   */
  public onChanged(event: any) {
    if (!this.configs.disabledOutputEvent) {
      this.onValueChange.emit(
        new FieldChangeOutput({
          field: this.configs.key,
          value: event.value,
          event: event,
        })
      );
    }
  }
  /**
   * List box config
   */
  @Input() configs: ListBoxConfiguration;

  /**
   * Form
   */
  @Input() form: FormGroup;
}
