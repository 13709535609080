import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Query } from '../../models/query.model';
import { AbstractQueriesService } from './abstract-queries.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * REAL AbstractQueriesService implementation
 */
export class QueriesService extends AbstractQueriesService {
  /**
   * Function responsible to store a new query
   * @param {Query} newQuery
   * @returns {Promise<Query>}
   */
  public createQueryAsync(newQuery: Query): Promise<Query> {
    throw new Error('Method not implemented.');
  }

  /**
   * Function responsible to update a query
   * @param {string} queryId
   * @param {Query} queryUpdated
   * @returns {Promise<Query>}
   */
  public updateQueryAsync(
    queryId: string,
    queryUpdated: Query
  ): Promise<Query> {
    throw new Error('Method not implemented.');
  }

  /**
   * Constructor
   * @param {TranslateService} translateService
   */
  constructor(@Inject(TranslateService) translateService: TranslateService) {
    super(translateService);
  }

  /**
   * Get all Queries
   * @returns {Promise<Query[]>}
   */
  public getAllQueriesAsync(): Promise<Query[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Get query by Id
   * @param {string} queryId
   * @returns {Promise<Query>}
   */
  getQueryByIdAsync(queryId: string): Promise<Query> {
    throw new Error('Method not implemented.');
  }
}
