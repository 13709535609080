import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { GridsterModule } from 'angular-gridster2';
import { DynamicModule } from 'ng-dynamic-component';
import { ChartModule } from 'primeng/chart';
import { DragDropModule } from 'primeng/dragdrop';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PrgCoreDynamicFormModule } from '../core/components/dynamic-form/prg-core-dynamic-form.module';
import { PrgEntityTypesModule } from '../entity-types/prg-entity-types.module';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';
import { PrgDashboardDisplayComponent } from './components/dashboard-display/prg-dashboard-display.component';
import { PrgDashboardItemsOptionsComponent } from './components/dashboard-items-options/prg-dashboard-items-options.component';
import { PrgBarChartComponent } from './components/dashboard-items/bar-chart/prg-bar-chart.component';
import { PrgDoughnutChartComponent } from './components/dashboard-items/doughnut-chart/prg-doughnut-chart.component';
import { PrgLineChartComponent } from './components/dashboard-items/line-chart/prg-line-chart.component';
import { PrgTableComponent } from './components/dashboard-items/table/prg-table.component';
import { PrgDashboardListComponent } from './components/dashboard-list/prg-dashboard-list.component';
import { PrgDashboardOptionsComponent } from './components/dashboard-options/prg-dashboard-options.component';
import { PrgDashboardComponent } from './components/dashboard/prg-dashboard.component';
import { MockDashboardService } from './mock/services/dashboard.service.mock';
import { PrgDashboardConfig } from './models/prg-dashboard-config';
import { PrgDashboardRoutingModule } from './prg-dashboard-routing.module';
import { AbstractDashboardService } from './services/dashboard/abstract-dashboard.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { PRG_DASHBOARD_CONFIG } from './services/prg-dashboard-configuration/prg-dashboard-configuration.service';

/**
 * Constant of dynamic components to be used on dashboard
 * @type {(PrgBarChartComponent | PrgLineChartComponent | PrgDoughnutChartComponent | PrgTableComponent)[]}
 */
const dynamicsComponents = [
  PrgBarChartComponent,
  PrgLineChartComponent,
  PrgDoughnutChartComponent,
  PrgTableComponent,
];
/**
 * Module that handles Dashboard
 */
@NgModule({
  declarations: [
    PrgDashboardComponent,
    PrgDashboardDisplayComponent,
    PrgDashboardListComponent,
    PrgDashboardOptionsComponent,
    PrgDashboardItemsOptionsComponent,
    ...dynamicsComponents,
  ],
  imports: [
    PrgDashboardRoutingModule,
    PrgSharedComponentsModule,
    PrgCoreDynamicFormModule,
    GridsterModule,
    ChartModule,
    DynamicModule,
    OverlayPanelModule,
    DragDropModule,
    PrgEntityTypesModule,
  ],
  exports: [PrgDashboardComponent, PrgDashboardListComponent],
  entryComponents: [
    PrgDashboardOptionsComponent,
    PrgDashboardItemsOptionsComponent,
    ...dynamicsComponents,
  ],
})
export class PrgDashboardModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgDashboardConfig: PrgDashboardConfig
  ): ModuleWithProviders<PrgDashboardModule> {
    dashboardConfig = prgDashboardConfig;
    return {
      ngModule: PrgDashboardModule,
      providers: [
        {
          provide: PRG_DASHBOARD_CONFIG,
          useValue: prgDashboardConfig,
        },
        {
          provide: AbstractDashboardService,
          useClass: prgDashboardConfig.useMockServices
            ? MockDashboardService
            : DashboardService,
        },
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the DashboardCoreConfig object
 */
export let dashboardConfig: PrgDashboardConfig;
