import { Validators } from '@angular/forms';
import { InputNumberConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/input-number/input-number.model';
import { InputTextConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/input-text/input-text.model';

/**
 * Constant that define the key , configuration settings and settings for table component
 * @type {{staticSettings: string, configurationSettings: {fields: (InputNumberConfiguration | InputTextConfiguration)[]}, key: string}}
 */
export const PRG_TABLE_SETTINGS = {
  key: 'Table',
  configurationSettings: {
    fields: [
      new InputNumberConfiguration({
        key: 'tableProperty1',
        disabledOutputEvent: false,
        showButtons: true,
        allowEmpty: false,
      }),
      new InputNumberConfiguration({
        key: 'tableProperty2',
        disabledOutputEvent: false,
        showButtons: true,
        validators: [Validators.required],
      }),
      new InputTextConfiguration({
        key: 'tableProperty3',
        disabledOutputEvent: false,
      }),
    ],
  },
  staticSettings: JSON.stringify({
    tableProperty1: 1,
    tableProperty2: 10,
    tableProperty3: 15,
  }),
};
