import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { ActionOutput } from '../../../models/base-action';

import { ButtonConfiguration } from './button.model';

/**
 * component button
 */
@Component({
  selector: 'prg-button-action',
  templateUrl: './button.component.html',
})
export class PrgButtonComponent {
  /**
   * Button configs
   */
  @Input() configs: ButtonConfiguration;
  /**
   * form configs
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() actionClick = new EventEmitter<ActionOutput>();

  /**
   * function that triggers the outpout
   */
  public onButtonClicked(): void {
    this.actionClick.emit(new ActionOutput({ action: this.configs.key }));
  }
}
