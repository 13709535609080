import { TranslateService } from '@ngx-translate/core';
import { BaseCacheService } from '../../../core/services/cache-service/base-cache.service';
import { LookupTable, LookupTableItem } from '../../models/lookup-table.model';

/**
 * AbstractLookupTableService class that defines an
 * <br />abstract lookup tables service to
 * <br />be extended by the mock and real
 * <br />lookup tables services
 */
export abstract class AbstractLookupTableService extends BaseCacheService<LookupTable> {
  /** constructor */
  constructor(private translationService: TranslateService) {
    super();
  }

  /**
   * Get all Lookup Tables
   */
  public abstract getAllLookupTableAsync(): Promise<LookupTable[]>;

  /**
   * this method is responsible for saving a new lookupTable
   * or update an existing one
   *
   * @param lookupTable
   * @returns LookupTable
   */
  public abstract saveLookupTableAsync(
    lookupTable: LookupTable
  ): Promise<LookupTable>;

  /**
   * this method is responsible for adding or
   * updating a set of lookup tables
   *
   * @param lookupTables
   * @returns LookupTable[]
   */
  public abstract saveMultipleLookupTableAsync(
    lookupTables: LookupTable[]
  ): Promise<LookupTable[]>;

  /**
   * Get lookup table items by lookup table id
   * @param id
   */
  public abstract getLookupTableItemsByLookupTableIdAsync(
    id: string
  ): Promise<LookupTableItem[]>;

  /**
   * Get lookup table items by lookup table name
   * @param name
   */
  public abstract getLookupTableItemsByLookupTableNameAsync(
    name: string
  ): Promise<LookupTableItem[]>;

  /**
   * this method is responsible for saving a new lookupTableItem
   * or update an existing one
   * @param lookupTableItem
   */
  public abstract saveLookupTableItemAsync(
    lookupTableItem: LookupTableItem
  ): Promise<LookupTableItem>;

  /**
   * this method is responsible for adding or
   * updating a set of lookup tables items
   *
   * @param lookupTablesItems
   * @returns LookupTableItems[]
   */
  public abstract saveMultipleLookupTableItemsAsync(
    lookupTables: LookupTableItem[]
  ): Promise<LookupTableItem[]>;

  /**
   * Get lookup table item by id
   * @param {string} lookUpTableItemId
   * @returns {Promise<LookupTableItem>}
   */
  public abstract getLookUpTableItemByIdAsync(
    lookUpTableItemId: string
  ): Promise<LookupTableItem>;

  /**
   * this service get translations for all lookup table items
   *
   * @param lookupTable
   * @returns
   */
  protected async getTranslations(
    lookupTable: LookupTable
  ): Promise<LookupTable> {
    return new Promise<LookupTable>((resolve, reject) => {
      lookupTable.items.map(async (item) => {
        item.label = await this.translationService
          .get(
            'lookup-tables.' +
              lookupTable.name +
              '.items.' +
              lookupTable.name +
              '.' +
              item['name'] +
              '.name'
          )
          .toPromise();
        item.description = await this.translationService
          .get(
            'lookup-tables.' +
              lookupTable.name +
              '.items.' +
              lookupTable.name +
              '.' +
              item['name'] +
              '.description'
          )
          .toPromise();
      });
      resolve(lookupTable);
    });
    return lookupTable;
  }

  /**
   *
   */
  public abstract getMultipleLookupTableByName(
    lookupTableNames: string[]
  ): Promise<LookupTable[]>;
}
