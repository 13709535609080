import { Inject, Injectable, InjectionToken } from "@angular/core";
import { PrgCoreConfig } from "../../models/prg-core-config";

/**
 * Inject token
 */
export const PRG_CORE_CONFIG = new InjectionToken<PrgCoreConfig>(
  "PRG_CORE_CONFIG"
);

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * PrgCoreConfigurationService class definition
 * <br />that specifies the properties that the
 * <br />application can send to the lib for
 * <br />configuration purposes
 */
export class PrgCoreConfigurationService {
  /**
   * Constructor
   *
   * @param prgCoreConfig
   */
  constructor(@Inject(PRG_CORE_CONFIG) private prgCoreConfig: PrgCoreConfig) {}
}
