<div *ngIf="form"  [formGroup]="form">

 <p>{{
   configs.basePathTranslation + '.' + configs.key + '.label' | translate
   }}</p>

  <p-inputNumber

    [placeholder]="
configs.basePathTranslation + '.' + configs.key + '.placeholder'
| translate
"
    [setInputNumberConfigs]="configs"
    [inputId]="configs?.key"
    [formControlName]="configs?.key"
    [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"

    [name]="configs?.key + '.name' | translate"
(ngModelChange)="onChanged($event)"

  >
  </p-inputNumber>
</div
>

