import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { PrgCoreDynamicFormModule } from '../core/components/dynamic-form/prg-core-dynamic-form.module';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';
import { PrgTermsComponent } from './components/prg-terms.component';
import { PrgTermsConfig } from './models/prg-terms-config';
import { PrgTermsRoutingModule } from './prg-terms-routing.module';
import { PRG_TERMS_CONFIG } from './services/prg-terms-configuration/prg-terms-configuration.service';

/**
 * Module that handles terms of use
 */
@NgModule({
  declarations: [PrgTermsComponent],
  imports: [
    PrgSharedComponentsModule,
    PrgTermsRoutingModule,
    PrgCoreDynamicFormModule,
  ],
  exports: [PrgTermsComponent],
})
export class PrgTermsModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgTermsConfig: PrgTermsConfig
  ): ModuleWithProviders<PrgTermsModule> {
    termsConfig = prgTermsConfig;

    return {
      ngModule: PrgTermsModule,
      providers: [
        {
          provide: PRG_TERMS_CONFIG,
          useValue: prgTermsConfig,
        },
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the TermsCoreConfig object, use ONLY in routing module
 * for everything else, use dependency injection through the injector
 */
export let termsConfig: PrgTermsConfig;
