import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';
import { AbstractAuthService } from '../../../auth/services/auth/abstract-auth.service';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { PrgMenuItem } from '../../models/prg-menu-item';
import { MainLayoutService } from '../../services/main-layout.service';
import { SIDEBAR_MENU } from '../menus-structure/sidebar-menu-structure';

//TODO: It's missing a way to save user and language preferences (Session / Locale Storage)
/**
 * Component decorator
 */
@Component({
  selector: 'prg-sidebar',
  templateUrl: './prg-sidebar.component.html',
  styleUrls: ['./prg-sidebar.component.scss'],
  //Sidebar Animations
  animations: [
    trigger('OnExpandSideBar', [
      state(
        'open',
        style({
          width: '100%',
        })
      ),
      state(
        'closed',
        style({
          width: '70px',
        })
      ),
      transition('open => closed', [
        group([
          query(':self', [animate('0s')]),
          query('@OnExpandSideBarText', [animateChild()]),
        ]),
      ]),
      transition('closed => open', [
        group([
          query(':self', [animate('0.2s')]),
          query('@OnExpandSideBarText', [animateChild()]),
        ]),
      ]),
    ]),
    trigger('OnExpandSideBarText', [
      // ...
      state(
        'show-content',
        style({
          opacity: '1',
        })
      ),
      state(
        'hide-content',
        style({
          opacity: '0',
          overflow: 'hidden',
        })
      ),
      transition('show-content => hide-content', [animate('0s')]),
      transition('hide-content => show-content', [animate('0.2s 0.3s')]),
    ]),
  ],
})
/**
 * Sidebar Component
 */
export class PrgSidebarComponent implements OnInit, OnDestroy {
  /**
   * The current state of sidebar menu. Expanded is true and collapsed is false.
   *
   * Default is false.
   * @type {boolean}
   */
  public mainMenuExpanded = false;
  /**
   * Menu Items data related to the expanded menu
   * @type {MenuItem[]}
   */
  public mainMenu: PrgMenuItem[];
  /**
   * Menu Items data related to the collapsed menu
   * @type {MenuItem[]}
   */
  public mainMenuIcons: PrgMenuItem[];
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * The original Menu data
   * @type {PrgMenuItem[]}
   * @private
   */
  @Input() private mainMenuOriginal: PrgMenuItem[] =
    this.arrayUtility.clone(SIDEBAR_MENU);
  /**
   * Currently log user
   * @type {User}
   * @private
   */
  private user: User;

  /**
   * Defines if header is displayed or not.
   * @type {boolean}
   */
  public headerDisplay: boolean = true;

  /**
   * Constructor
   * @param {MainLayoutService} mainLayoutService mainLayoutService
   * @param {ArrayUtilityService} arrayUtility
   * @param {TranslateService} translateService
   * @param {AuthService} authService
   * @param {ObjectsUtilityService} objectsUtilityService
   */
  constructor(
    public mainLayoutService: MainLayoutService,
    private arrayUtility: ArrayUtilityService,
    private translateService: TranslateService,
    private authService: AbstractAuthService,
    private objectsUtilityService: ObjectsUtilityService
  ) {}

  /**
   * ngOnInit
   *
   * This method is responsible for:
   *
   * Get the saved state of sidebar in local storage.
   *
   * Verify if are any changes on language selected through on observable
   *
   * @returns {Promise<void>}
   */
  public async ngOnInit(): Promise<void> {
    let savedStateSideBar = JSON.parse(
      localStorage.getItem('savedStateSideBar')
    );
    if (savedStateSideBar) {
      this.mainMenuExpanded = savedStateSideBar;
    }

    this.subscription.push(
      this.authService.getLoggedUserObservable().subscribe(async (user) => {
        if (user) {
          this.user = this.objectsUtilityService.cloneObject(user);
        } else {
          this.user = null;
        }
        await this.getMainMenuItems();
      })
    );

    await this.getMainMenuItems();
    this.subscription.push(
      this.translateService.onLangChange.subscribe(async () => {
        await this.getMainMenuItems();
      })
    );
    this.subscription.push(
      this.mainLayoutService
        .getDisplayHeaderStateObservable()
        .subscribe((display) => {
          this.headerDisplay = display;
        })
    );
  }

  /**
   * This method is responsible for calling the functions that will build the menus, one with labels and icons and other with only icons
   * @returns {Promise<void>}
   * @private
   */
  private async getMainMenuItems(): Promise<void> {
    this.mainMenu = this.arrayUtility.clone(
      this.mainLayoutService.mapMenuItemsHelpers(this.mainMenuOriginal, this)
    );
    this.mainMenu = this.arrayUtility.clone(
      await this.mainLayoutService.getSideBarMenuItemsWithLabelsAsync(
        this.mainMenu,
        true
      )
    );
    this.mainMenuIcons = this.arrayUtility.clone(
      this.mainLayoutService.mapMenuItemsHelpers(this.mainMenuOriginal, this)
    );
    this.mainMenuIcons = this.arrayUtility.clone(
      await this.mainLayoutService.getSideBarMenuItemsWithLabelsAsync(
        this.mainMenuIcons,
        false
      )
    );
  }

  /**
   * ngOnDestroy
   *
   * Unsubscribe all the observables
   */
  public ngOnDestroy(): void {
    this.subscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * This function is responsible to save the current state of the sidebar menu (expanded - true or collapsed - false) in local storage
   * @returns {Promise<void>}
   */
  public async onShowHideSideBarDetails(): Promise<void> {
    localStorage.removeItem('savedStateSideBar');
    this.mainMenuExpanded = !this.mainMenuExpanded;
    localStorage.setItem(
      'savedStateSideBar',
      JSON.stringify(this.mainMenuExpanded)
    );
    this.mainLayoutService.setSideBarState(this.mainMenuExpanded);
  }
}
