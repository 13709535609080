<div class="field-select-button" *ngIf="form" [formGroup]="form">
  <p>{{configs.basePathTranslation + '.' + configs.key + '.label' | translate}}</p>
<p-selectButton
  (onChange)="onChanged($event)"
  [setSelectButtonConfigs]="configs"
  [formControlName]="configs.key"
  [id]="configs.key"
  [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }">
</p-selectButton>
</div>
