import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { PrimeNGConfig } from 'primeng/api';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { PrgAuthConfig } from '../../models/prg-auth-config';
import { AbstractAuthService } from '../../services/auth/abstract-auth.service';
import { PRG_AUTH_CONFIG } from '../../services/prg-auth-configuration/prg-auth-configuration.service';

/**
 * The form request password component
 */
@Component({
  selector: 'prg-form-request-password',
  templateUrl: './prg-form-request-password.component.html',
  styleUrls: ['./prg-form-request-password.component.scss'],
})
export class PrgFormRequestPasswordComponent implements OnInit, OnDestroy {
  /**
   * Shows/hide the login link.  Default true.
   */
  @Input() showLoginLink: boolean = true;
  /**
   * The default route for login page
   */
  @Input() loginPageRoute: string =
    '/' +
    this.authRoutes.authRoutingBasePath +
    '/' +
    this.authRoutes.authRoutingLoginPath;

  /**
   * Property of the class with type Form Group binding to the request password form
   */
  public requestPassForm: FormGroup;

  /**
   * constructor
   * @param logger
   * @param authService
   * @param primengConfig
   * @param authRoutes
   * @param isLoadingData isLoadingData
   */
  constructor(
    private logger: NGXLogger,
    private authService: AbstractAuthService,
    private primengConfig: PrimeNGConfig,
    @Inject(PRG_AUTH_CONFIG) private authRoutes: PrgAuthConfig,
    public isLoadingData: IsLoadingDataService
  ) {}

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.logger.debug('REQUEST PASSWORD PAGE ON INIT');
    this.primengConfig.ripple = true;
    this.initFormRequestPass();
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.logger.debug('REQUEST PASSWORD PAGE ON DESTROY');
  }

  /**
   * Initiate the request password form and its form controls with validators
   */
  private initFormRequestPass(): void {
    this.requestPassForm = new FormGroup(
      {
        email: new FormControl(null, [Validators.required, Validators.email]),
      },
      { updateOn: 'change' }
    );
  }

  /**
   * Form submission
   */
  public async onSubmitRequestPassword(): Promise<void> {
    if (this.requestPassForm.invalid) {
      return;
    }
    const userEmail = this.requestPassForm.controls.email.value;
    this.requestPassForm.disable();
    try {
      if (await this.authService.requestResetPasswordAsync(userEmail)) {
        this.requestPassForm.reset();
        // TODO: Falta reencaminhar o user após pedido do reset da password .Rota??
      }
    } catch (error) {
      this.logger.debug(error);
    }
    this.requestPassForm.enable();
  }
}
