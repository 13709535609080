<div *ngIf="form && configs != null" [formGroup]="form">
  <p>{{configs.basePathTranslation + '.' + configs.key + '.name' | translate}}</p>
  <p-multiSelect
    display="chip"
    [form]="form"
    (onChange)="onChanged($event)"
    [options]="configs.options"
    [setMultiSelectConfigs]="configs"
    [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
    [formControlName]="configs.key"
    [placeholder]="
        configs.basePathTranslation + '.' + configs.key + '.placeholder'
          | translate
      "
    [name]="configs.key + '.name' | translate"
  ></p-multiSelect>
</div>
