import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { PrgLayoutsModule } from '../layouts/prg-layouts.module';
import { PrgCoreDynamicFormModule } from './components/dynamic-form/prg-core-dynamic-form.module';
import { PrgDynamicFormComponent } from './components/dynamic-form/prg-dynamic-form.component';
import { PrgCoreConfig } from './models/prg-core-config';
import { AbstractConfigurationItemsService } from './services/configuration-items/abstract-configuration-items.service';
import { ConfigurationItemsService } from './services/configuration-items/configuration-items.service';
import { MockConfigurationItemsService } from './services/configuration-items/configuration-items.service.mock';
import { PRG_CORE_CONFIG } from './services/prg-core-configuration/prg-core-configuration.service';

/**
 * Lib Core (main) module, must provide the correct
 * implementation of its services, as well as the lib
 * configurations object
 */
@NgModule({
  declarations: [],
  imports: [PrgLayoutsModule, PrgCoreDynamicFormModule],
  exports: [PrgLayoutsModule, PrgDynamicFormComponent],
})
export class PrgCoreModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgCoreConfig: PrgCoreConfig
  ): ModuleWithProviders<PrgCoreModule> {
    coreConfig = prgCoreConfig;
    return {
      ngModule: PrgCoreModule,
      providers: [
        {
          provide: PRG_CORE_CONFIG,
          useValue: prgCoreConfig,
        },
        {
          provide: AbstractConfigurationItemsService,
          useClass: prgCoreConfig.useMockServices
            ? MockConfigurationItemsService
            : ConfigurationItemsService,
        },
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the PrgCoreConfig object
 */
export let coreConfig: PrgCoreConfig;
