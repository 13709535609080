/**
 * class MenuTab
 */
export class MenuTab {
  /**
   * label
   */
  label: string;
  /**
   * icon
   */
  icon: string;
  /**
   * type
   */
  type: MenuTabEntityType;
  /**
   * disabled
   */
  disabled: boolean = false;
}

export enum MenuTabEntityType {
  EntityTypeProperty = 'EntityTypeProperty',
  EntityTypeAttribute = 'EntityTypeAttribute',
  EntityTypeOperation = 'EntityTypeOperation',
}
