import { Component } from '@angular/core';

/**
 * Register page
 */
@Component({
  selector: 'prg-auth-register',
  templateUrl: 'prg-auth-register.page.html',
  styleUrls: ['prg-auth-register.page.scss'],
})
export class PrgAuthRegisterPage {}
