<span *ngIf="form" [formGroup]="form">
  <span class="p-float-label">
  <p-chips
    [setChipsConfigs]="configs"
    [formControlName]="configs.key"
    [inputId]="configs?.key"
    [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
    (onAdd)="onChanged($event)"
    [placeholder]="
        configs.basePathTranslation + '.' + configs.key + '.placeholder'
          | translate
      "
  ></p-chips>
      <label for="{{configs.key}}">{{
        configs.basePathTranslation + '.' + configs.key + '.label' | translate
        }}</label>

  </span>
</span>
