import { NgModule } from '@angular/core';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { PrgLayoutsEmptyComponent } from '../layouts/empty/prg-layouts-empty.component';
import { PrgLayoutsHeaderSidebarComponent } from '../layouts/pages/prg-layouts-header-sidebar';
import { PrgRolesScopesPage } from './pages/roles-scopes/prg-roles-scopes.page';
import { rolesScopesConfig } from './prg-roles-scopes.module';

/**
 * Roles and Scopes routing module routes
 */
const standardRoutes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(standardRoutes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        let routes: Routes = [];
        routes.push({
          path: rolesScopesConfig.rolesScopesBasePath,
          component: PrgLayoutsHeaderSidebarComponent,
          children: [
            {
              path: '',
              component: PrgRolesScopesPage,
            },
          ],
        });

        return [...routes, ...standardRoutes];
      },
      multi: true,
    },
  ],
})
export class PrgRolesScopesRoutingModule {}
