import { Component, Input } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { InputPasswordConfiguration } from './password.model';

/**
 * component input password
 */
@Component({
  selector: 'prg-password-field',
  templateUrl: './password.component.html',
})
export class PrgPasswordComponent {
  /**
   * Input password configs
   */
  @Input() configs: InputPasswordConfiguration;

  /**
   * form
   */
  @Input() form: FormGroup;
}
