import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActionOutput } from '../../../models/base-action';
import { ToggleButtonActionConfiguration } from './toggle-button-action.model';

/**
 * Toggle Button Action Component
 */
@Component({
  selector: 'prg-toggle-button-action',
  templateUrl: './toggle-button-action.component.html',
})
export class PrgToggleButtonActionComponent {
  /**
   * ToggleButton Action config
   */
  @Input() configs: ToggleButtonActionConfiguration;
  /**
   * Form
   */
  @Input() form: FormGroup;

  /**
   * output
   */
  @Output() actionClick = new EventEmitter<ActionOutput>();

  /**
   * function that triggers the outpout
   */
  public onButtonClicked(event: any): void {
    this.actionClick.emit(
      new ActionOutput({ action: this.configs.key, value: event.checked })
    );
  }
}
