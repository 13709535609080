import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule } from 'ngx-logger';
import {
  PrgAuthModule,
  PrgConfigurationItemsModule,
  PrgCoreModule,
  PrgDashboardModule,
  PrgEntityTypesModule,
  PrgLookupTableModule,
  PrgQueriesModule,
  PrgRolesScopesModule,
  PrgTermsModule,
} from 'prg-core-lib';

import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export const HTTP_LOADER_FACTORY = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/locale/', '.json');

const USE_MOCK_SERVICES =
  !environment.production && environment.useMockServices;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    LoggerModule.forRoot({
      // serverLoggingUrl: `${environment.apiUrl}api/logs`,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
      disableConsoleLogging: false,
    }),
    TranslateModule.forRoot(),
    PrgCoreModule.forRoot({
      useMockServices: USE_MOCK_SERVICES,
    }),
    PrgRolesScopesModule.forRoot(environment.rolesScopesModuleConfigurations),
    PrgAuthModule.forRoot(environment.authModuleConfigurations),
    PrgTermsModule.forRoot(environment.termsModuleConfigurations),
    PrgEntityTypesModule.forRoot(environment.entityTypeConfigurations),
    PrgQueriesModule.forRoot(environment.queriesModuleConfigurations),
    PrgLookupTableModule.forRoot(environment.lookupTableConfigurations),
    PrgDashboardModule.forRoot(environment.dashboardModuleConfigurations),
    PrgConfigurationItemsModule.forRoot(
      environment.configurationItemsModuleConfigurations
    ),
    AppRoutingModule,
    PrgCoreModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HTTP_LOADER_FACTORY,
        deps: [HttpClient],
      },
    }).providers,
    MessageService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
  exports: [PrgAuthModule],
})
export class AppModule {}
