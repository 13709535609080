import { Directive, Input } from '@angular/core';
import { ToggleButton } from 'primeng/togglebutton';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class ToggleButton
 */
export class ToggleButtonConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.toggleButton;
  /**
   *Enable inline label of the toggle button.
   */
  public hasLabel: boolean = false;
  /**
   *Label for the on state.
   */
  public onLabel: string = null;
  /**
   *Label for the off state.
   */
  public offLabel: string = null;
  /**
   *Icon for the on state.
   */
  public onIcon: string = 'pi pi-check';
  /**
   *Icon for the off state.
   */
  public offIcon: string = 'pi pi-times';
  /**
   *Position of the icon, valid values are "left" and "right".
   */
  public iconPos: string = 'left';
  /**
   *Inline style of the element.
   */
  public style: string = null;
  /**
   *Style class of the element.
   */
  public styleClass: string = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<ToggleButtonConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setToggleButtonConfigs]',
})
export class PrgSetToggleButtonConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: ToggleButton) {}
  /**
   * input ToggleButton configs and assign configs to element
   */
  @Input() set setToggleButtonConfigs(configs: ToggleButtonConfiguration) {
    Object.assign(this.element, configs);
  }
}
