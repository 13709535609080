import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { BaseField } from '../../components/dynamic-form/models/base-field';

/**
 * injectable
 */
@Injectable({
  providedIn: 'root',
})
export class FormGroupService {
  /**constructor */
  constructor() {}

  /**
   * this function is responsible for creating a formGroup[]
   * with the respective values and validations for each field of the object
   * @param objects
   * @param columnsData
   * @returns
   */
  toFormGroupMultipleObjects(
    objects: any[],
    columnsData: BaseField[]
  ): FormGroup[] {
    const objectProperties: string[] = Object.getOwnPropertyNames(objects[0]);

    let formGroupArray: FormGroup[] = [];
    objects.forEach((object) => {
      const formGroupAux = new FormGroup(
        this.getFormGroup(objectProperties, columnsData)
      );
      formGroupAux.setValue(object);
      formGroupArray.push(formGroupAux);
    });

    return formGroupArray;
  }

  /**
   * this function is responsible for creating a formGroup
   * with the respective values and validations for a given object
   *
   * @param object
   * @param columnsData
   * @returns
   */
  toFormGroupOneObject(object: any, columnsData: BaseField[]): FormGroup {
    const objectProperties: string[] = Object.getOwnPropertyNames(object);

    const formGroup = new FormGroup(
      this.getFormGroup(objectProperties, columnsData)
    );
    formGroup.setValue(object);

    return formGroup;
  }

  /**
   * this function returns an object with the
   * required fields with its formControl
   *
   * @param objectProperties
   * @param columnsData
   * @returns any
   */
  getFormGroup(objectProperties: string[], columnsData: BaseField[]): any {
    let objectWithValidators = {};

    objectProperties.forEach((property) => {
      const column = columnsData.find((column) => column.key === property);
      let validators: ValidatorFn[] | null = null;
      if (column) {
        validators = column.validators;
      }
      objectWithValidators[property] = new FormControl(null, validators);
    });
    return objectWithValidators;
  }
  /**
   * this function recive fields and transform it in a form group
   * @param fields
   * @returns FormGroup
   */
  toFormGroup(fields: BaseField[]): FormGroup {
    const group: any = {};
    fields.forEach((field) => {
      group[field.key] = new FormControl(field.value, field.validators);
    });

    return new FormGroup(group);
  }
}
