import { Validators } from '@angular/forms';
import { CheckboxConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/checkbox/checkbox.model';
import { MultiSelectConfiguration } from '../../../../core/components/dynamic-form/dynamic-form-fields/fields/multiselect/multi-select.model';
import { BaseField } from '../../../../core/components/dynamic-form/models/base-field';
import { ControlName } from '../../../../core/components/dynamic-form/models/constants/control-names';
import { ReferenceType } from '../../../../core/components/dynamic-form/models/constants/reference-type';

/**
 * Constant that defines the fields and actions for the dynamic form dashboard
 */
export const DASHBOARD_DYNAMIC_FORM = {
  fields: [
    new BaseField({
      controlName: ControlName.inputText,
      value: null,
      key: 'name',
      validators: [Validators.required],
      basePathTranslation: '',
      disabledOutputEvent: false,
    }),
    new CheckboxConfiguration({
      key: 'isPublic',
      basePathTranslation: '',
      disabledOutputEvent: false,
    }),
    new MultiSelectConfiguration({
      referenceType: ReferenceType.EntityType,
      key: 'entityTypeIds',
      placeholder: 'Select Entity',
      value: null,
      validators: [Validators.required],
      optionLabel: 'label',
      optionValue: 'id',
      showClear: true,
      basePathTranslation: '',
      disabledOutputEvent: false,
    }),
  ],
  /*actions: [
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Save,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Cancel,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Edit,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='read'",
    }),
  ],*/
};
