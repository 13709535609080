<div class="grid grid-nogutter">
  <div class="col-fixed grid-nogutter">
    <p-tree [(selection)]="selectedNode"  [filter]="true" filterPlaceholder="Search Item..." selectionMode="single"
            (onNodeSelect)="onSelectItem($event.node)" layout="vertical" [value]="configItemsTree"></p-tree>
  </div>
 <div class="col grid-nogutter px-2 " *ngIf="initForm">
   <p [ngClass]="['text-center' ,'bg-blue-50' ,'text-3xl' ,'border-round', 'mb-2', 'py-3', 'w-full', 'border-300', 'border-1' ,'shadow-1', 'mt-0' ,'text-700']"  >{{titleForm + '.label'|translate|uppercase}}</p>

   <prg-dynamic-form [viewMode]="configItemsViewMode" [actions]="configItemsDynamicFormActions"
                     [fields]="configItemsDynamicFormFields" [entity]="entityConfigItems"
                     (actionOutput)="onActionConfigItems($event)">
   </prg-dynamic-form>
 </div>
</div>

