import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AbstractAuthService } from '../../../auth/services/auth/abstract-auth.service';
import { BaseField } from '../../../core/components/dynamic-form/models/base-field';
import { ViewMode } from '../../../core/models/constants/view-mode.enum';
import { ArrayUtilityService } from '../../../core/services/utility/array-utility.service';
import { ObjectsUtilityService } from '../../../core/services/utility/objects-utility.service';
import { PrgQueriesConfig } from '../../models/prg-queries-config';
import { Query } from '../../models/query.model';
import { PRG_QUERIES_CONFIG } from '../../services/prg-queries-configuration/prg-queries-configuration.service';
import { AbstractQueriesService } from '../../services/queries/abstract-queries.service';
import { PrgQueryModalComponent } from '../query-modal/prg-query-modal.component';

/**
 * Queries Table Component
 */
@Component({
  selector: 'prg-queries-table',
  templateUrl: './prg-queries-table.component.html',
  styleUrls: ['./prg-queries-table.component.scss'],
})
export class PrgQueriesTableComponent implements OnInit, OnDestroy {
  /**
   * treeData
   */
  public treeData: TreeNode[];
  /**
   * Queries Table columns
   */
  public queriesTableColumns: BaseField[] =
    this.prgQueriesConfig.queryTableColumns;
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   */
  public subscription: Subscription[] = [];

  /**
   * Property of type DynamicDialogRef to control the dialog
   */
  private querySelectedDialogRef!: DynamicDialogRef;
  /**
   * Currently logged user
   */
  private loggedUserId: string;

  /**
   * Queries List
   */
  private queries: Query[];

  /**
   * Constructor
   * @param {AbstractQueriesService} queriesService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {AbstractAuthService} userService
   * @param {PrgQueriesConfig} prgQueriesConfig
   * @param {DialogService} dialogService dialogService
   */
  constructor(
    private queriesService: AbstractQueriesService,
    private arrayUtilityService: ArrayUtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    private userService: AbstractAuthService,
    @Inject(PRG_QUERIES_CONFIG) private prgQueriesConfig: PrgQueriesConfig,
    public dialogService: DialogService
  ) {}

  /**
   * ngOnInit
   */
  public async ngOnInit(): Promise<void> {
    await this.getAllQueries();
  }

  /**
   * ngOnDestroy
   *
   * Unsubscribe all subscriptions
   */
  public ngOnDestroy(): void {
    this.subscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscription = [];
  }

  /**
   * Get queries list (async)
   *
   * Function responsible to fetch all queries from api to populate table
   * @private
   */

  private async getAllQueries(): Promise<void> {
    this.queries = await this.queriesService.getAllQueriesAsync();
    this.createTreeNode();
  }

  /**
   * Creation of Tree Node Object
   *
   * This function is responsible to group queries by creator  (logged user and others)
   * to organize query data for the table tree
   * @private
   */
  private createTreeNode(): void {
    this.queries.forEach((query: Query) => {
      if (this.userService.getLoggedUser()) {
        this.loggedUserId = this.userService.getLoggedUser().id;

        if (query.createdBy === this.loggedUserId) {
          query['group'] = 'myQueries';
        } else {
          query['group'] = 'otherQueries';
        }
      }
    });

    this.treeData = this.arrayUtilityService.groupByField(
      this.queries,
      'group'
    );
  }

  /**
   * Function responsible to open dialog when a query is clicked.
   *
   * It also manages the data that comes from dialog when it is closed.
   *
   * @param {Query} query the selected query
   */
  public openQueryDialog(query: Query): void {
    let queryAux: Query;

    if (query) {
      queryAux = this.objectsUtilityService.cloneObject(query);
    } else {
      queryAux = new Query();
    }

    this.querySelectedDialogRef = this.dialogService.open(
      PrgQueryModalComponent,
      {
        header: queryAux ? queryAux.name : 'New Query',
        width: '80%',
        height: '90vh',
        baseZIndex: 10000,
        data: {
          query: queryAux,
          viewMode: queryAux.id ? ViewMode.Read : ViewMode.Edit,
        },
      }
    );
    this.subscription.push(
      this.querySelectedDialogRef.onClose.subscribe((newData) => {
        this.subscription.push(
          this.queriesService
            .getQueriesChangedObservable()
            .subscribe((queries) => {
              if (newData && queries) {
                this.queries = this.arrayUtilityService.clone(queries);
                this.createTreeNode();
              }
              this.querySelectedDialogRef.destroy();
            })
        );
      })
    );
  }
}
