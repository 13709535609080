/**
 * Class ItemsData
 */
export class PaginationResponse {
  /**
   * array of items
   */
  items: any[] = [];

  /**
   * page being sent
   */
  currentPage!: number;

  /**
   * total items that exist in bd
   */
  totalItems!: number;

  /**
   * Constructor
   * this constructor receives the fields sent by the app,
   * and creates an object with the fields sent and the values per default
   * @param init
   */
  constructor(init?: Partial<PaginationResponse>) {
    Object.assign(this, init);
  }
}
