import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Table } from 'primeng/table';
import { BaseField } from '../../../../../core/components/dynamic-form/models/base-field';
import { FormGroupService } from '../../../../../core/services/form-group/form-group.service';
import { IsLoadingDataService } from '../../../../../core/services/is-loading-data/isloadingdata.service';
import { ObjectsUtilityService } from '../../../../../core/services/utility/objects-utility.service';
import { LookupTableItem } from '../../../../models/lookup-table.model';
import { PrgLookupTableConfig } from '../../../../models/prg-lookup-table-config';
import { PRG_LOOKUP_TABLE_CONFIG } from '../../../../services/lookup-table-configuration/prg-lookup-table-configuration.service';
import { AbstractLookupTableService } from '../../../../services/lookup-table/abstract-lookup-table.service';

/**
 * Lookup table items component
 */
@Component({
  selector: 'prg-lookup-table-items-table',
  templateUrl: './prg-lookup-table-items-table.component.html',
  styleUrls: ['./prg-lookup-table-items-table.component.scss'],
})
export class PrgLookupTableItemsTableComponent {
  /**
   * View Child table
   */
  @ViewChild(Table) private dataTableItems: Table;

  /**
   * Lookup Table ID
   */
  @Input() lookupTableId: string;

  /**
   * Lookup Table Items
   */
  private _lookupTableItems: LookupTableItem[];
  @Input('lookupTableItems') set lookupTableItems(items: LookupTableItem[]) {
    this.lookupTableItemsForm = this.formBuilder.group({
      lookupTableItemsDetails: this.formBuilder.array([]),
    });
    if (items) {
      this._lookupTableItems = items;
      this.populateFormGroup();
    }
  }

  /**
   * Output with item saved
   */
  @Output() savedItems = new EventEmitter<string>();

  /**
   *  new Lookup Table Id Prefix
   */
  public newLookupTableItemIdPrefix = 'newLookupTable:';

  /**
   * Lookup table form group
   */
  public lookupTableItemsForm: FormGroup;

  /**
   * Table columns
   */
  public lookupTableItemsColumns: BaseField[] =
    this.prgLookupTableConfig.lookupTableItemsColumns;

  /**
   * Map to save temporarily the items in edit mode
   */
  private clonedLookupTableItems: Map<string, LookupTableItem> = new Map<
    string,
    LookupTableItem
  >();

  /**
   * auxiliary variable to help define the id of the new lookuptables
   */
  private newLookupTableItemIdCount: number = 0;

  /**
   * construtor
   *
   * @param prgLookupTableConfig prgLookupTableConfig
   * @param lookupTableService
   * @param isLoadingDataService isLoadingDataService
   * @param formBuilder
   * @param formGroupService
   * @param objectsUtilityService
   */
  constructor(
    @Inject(PRG_LOOKUP_TABLE_CONFIG)
    public prgLookupTableConfig: PrgLookupTableConfig,
    private lookupTableService: AbstractLookupTableService,
    public isLoadingDataService: IsLoadingDataService,
    private formBuilder: FormBuilder,
    private formGroupService: FormGroupService,
    private objectsUtilityService: ObjectsUtilityService
  ) {}

  /**
   * Return lookup table form array
   */
  get lookupTableItemsDetails(): FormArray {
    return this.lookupTableItemsForm.get(
      'lookupTableItemsDetails'
    ) as FormArray;
  }

  /**
   * return lookup table control for given lookup table item
   * @param lookupTableItemId
   * @returns Control
   */
  public getLookupTableItemControlById(
    lookupTableItemId: string
  ): AbstractControl {
    return this.lookupTableItemsDetails.controls.find(
      (control) => control.value.id === lookupTableItemId
    );
  }

  /**
   * this function is responsible for adding a new row
   * to the table for the user to configure the new lookuptableItem
   *
   */
  public addNewLookupTableItem(): void {
    const newLookupTableItem: LookupTableItem = {
      id: `${this.newLookupTableItemIdPrefix}${this.newLookupTableItemIdCount}`,
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: null,
      label: null,
      createdBy: null,
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: null,
      description: null,
      lookupTableId: this.lookupTableId,
    };

    this.newLookupTableItemIdCount++;

    // add new element to form Array
    this.lookupTableItemsDetails.insert(
      0,
      this.formGroupService.toFormGroupOneObject(
        newLookupTableItem,
        this.lookupTableItemsColumns
      )
    );

    this.lookupTableItemsForm.markAllAsTouched();

    this.dataTableItems.editingRowKeys[newLookupTableItem.id] = true;
  }

  /**
   * this function is responsible for creating a
   * clone of a lookup Table item that is selected for editing
   *
   * @param lookupTable
   */
  public onRowEditInit(item: LookupTableItem) {
    this.clonedLookupTableItems.set(item.id, { ...item });
  }

  /**
   * this function is responsible for saving a lookup table item,
   * either an update or adding a new lookup table item to the database
   *
   * @param lookupTable
   */
  public async onRowEditSave(item: LookupTableItem) {
    const lookupTableItemToSave = this.objectsUtilityService.cloneObject(item);
    if (lookupTableItemToSave.id.includes(this.newLookupTableItemIdPrefix)) {
      lookupTableItemToSave.id = null;
    }

    this.lookupTableService
      .saveLookupTableItemAsync(lookupTableItemToSave)
      .then((response) => {
        this.getLookupTableItemControlById(item.id).setValue(response);
        delete this.dataTableItems.editingRowKeys[item.id];
        this.clonedLookupTableItems.delete(item.id);

        this.savedItems.emit(this.lookupTableId);
      });
  }

  /**
   * this function is responsible for restoring the data
   * that was there before starting the edit if the
   * lookup table item is already in the database,
   * otherwise it just deletes the row that was temporarily created
   *
   * @param lookupTable
   */
  public onRowEditCancel(item: LookupTableItem) {
    if (item.id.includes(this.newLookupTableItemIdPrefix)) {
      this.lookupTableItemsDetails.removeAt(
        this.getLookupTableItemControlIndexById(item.id)
      );
    } else {
      const lookupTableItemOrignal = this.clonedLookupTableItems.get(item.id);
      this.getLookupTableItemControlById(item.id).setValue(
        lookupTableItemOrignal
      );
    }
  }

  /**
   * this function is responsible for saving
   * all active edits (new lines or updating existing ones)
   */
  public async saveAllChanges(): Promise<void> {
    let lookupTablesItemsToSave: LookupTableItem[] = [];
    Object.getOwnPropertyNames(this.dataTableItems.editingRowKeys).forEach(
      (element) => {
        if (element.includes(this.newLookupTableItemIdPrefix)) {
          const lookupTableAux = this.objectsUtilityService.cloneObject(
            this.getLookupTableItemControlById(element).value
          );
          lookupTableAux.id = null;
          lookupTablesItemsToSave.push(lookupTableAux);
        } else {
          lookupTablesItemsToSave.push(
            this.objectsUtilityService.cloneObject(
              this.getLookupTableItemControlById(element).value
            )
          );
        }
      }
    );
    this.lookupTableService
      .saveMultipleLookupTableItemsAsync(lookupTablesItemsToSave)
      .then((response) => {
        response.forEach((slt) => {
          const lookupTableById = this.getLookupTableItemControlById(slt.id);
          if (lookupTableById) {
            lookupTableById.setValue(slt);
          } else {
            this.lookupTableItemsDetails.push(
              this.formGroupService.toFormGroupOneObject(
                slt,
                this.lookupTableItemsColumns
              )
            );
          }
        });

        this.clonedLookupTableItems.clear();
        this.deleteAllTempLookupTable();
        this.dataTableItems.editingRowKeys = {};

        this.savedItems.emit(this.lookupTableId);
      });
  }

  /**
   * this function is responsible for discarding
   * all active edits, and resetting the data that
   * was set before starting the edit
   */
  public discardAllChanges(): void {
    Object.getOwnPropertyNames(this.dataTableItems.editingRowKeys).forEach(
      (element) => {
        if (element.includes(this.newLookupTableItemIdPrefix)) {
          this.lookupTableItemsDetails.removeAt(
            this.getLookupTableItemControlIndexById(element)
          );
        } else {
          this.getLookupTableItemControlById(element).setValue(
            this.clonedLookupTableItems.get(element)
          );
        }
      }
    );
    this.clonedLookupTableItems.clear();
    this.dataTableItems.editingRowKeys = {};
  }

  /**
   * this function is responsible for sorting a given column
   * @param event
   */
  public customSort(event: any) {
    event.data.sort((data1, data2) => {
      let value1 = data1.value[event.field];
      let value2 = data2.value[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  /**
   * return lookup table item control for given lookup table item id
   * @param lookupTableItemId
   * @returns number
   */
  private getLookupTableItemControlIndexById(
    lookupTableItemId: string
  ): number {
    return this.lookupTableItemsDetails.controls.findIndex(
      (control) => control.value.id === lookupTableItemId
    );
  }

  /**
   * Populate data into Form
   */
  private populateFormGroup() {
    const aux = this.formGroupService.toFormGroupMultipleObjects(
      this._lookupTableItems,
      this.lookupTableItemsColumns
    );

    aux.forEach((a) => {
      this.lookupTableItemsDetails.push(a);
    });
  }

  /**
   * this function is an auxiliary function that is used in the [saveAllChanges]{@link #saveAllChanges}
   *  function to delete all temporary rows that will be added later
   */
  private deleteAllTempLookupTable(): void {
    const tempLookupTable = this.lookupTableItemsDetails.controls.filter(
      (control) => control.value.id.includes(this.newLookupTableItemIdPrefix)
    );
    if (tempLookupTable.length > 0) {
      tempLookupTable.forEach((tlt) => {
        this.lookupTableItemsDetails.removeAt(
          this.getLookupTableItemControlIndexById(tlt.value.id)
        );
      });
    }
  }
}
