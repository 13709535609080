import { Directive, Input } from '@angular/core';
import { Button } from 'primeng/button';
import { BaseAction } from '../../../models/base-action';
import { ActionName } from '../../../models/constants/action-names';

/**
 * class ButtonConfiguration
 */
export class ButtonConfiguration extends BaseAction {
  /**
   * Control name
   */
  public controlName?: string = ActionName.Button;

  /**
   *Used to define a string that autocomplete attribute the current element.
   */
  public ariaLabel: string = null;

  /**
   *Value of the badge.
   */
  public badge: string = null;

  /**
   *Style class of the badge.
   */
  public badgeClass: string = null;

  /**
   *When present, it specifies that the component should be disabled.
   */
  public disabled: boolean = false;

  /**
   *Name of the icon.
   */
  public icon: string = null;

  /**
   *Position of the icon, valid values are left and right.
   */
  public iconPos: string = 'left';

  /**
   *Text of the button.
   */
  public label: string = null;

  /**
   *Whether the button is in loading state.
   */
  public loading: boolean = false;

  /**
   *Icon to display in loading state.
   */
  public loadingIcon: string = 'pi pi-spinner pi-spin';

  /**
   *Inline style of the element.
   */
  public style: string = null;

  /**
   *Style class of the element.
   */
  public styleClass: string = null;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<ButtonConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setButtonConfigs]',
})
export class PrgButtonConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: Button) {}

  /**
   * set button configs, assign configs received to element
   */
  @Input() set setButtonConfigs(configs: ButtonConfiguration) {
    Object.assign(this.element, configs);
  }
}
