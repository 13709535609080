<header class="shadow-5 "  >
  <div class="grid grid-nogutter p-1" style="height: 70px" *ngIf="displayHeader">
    <div class="col-2 flex justify-content-center ">
      <img [src]="imagePathLogo"  style="width: 80%;height: 90%">
    </div>
    <div class="col flex align-items-center justify-content-end pr-6">
      <p-chip pTooltip="{{'components.header.user-menu.label'|translate}}" tooltipPosition="top" class="cursor-pointer px-3" (click)="menuUser.toggle($event)"
              label="{{user?user.name:('components.header.user-menu.anonymous-user'|translate)}}" image="{{imagePathUser?imagePathUser:imagePathUserDefault}}"
              styleClass="mr-2"></p-chip>
      <p-menu [showTransitionOptions]="'0.3s'" [hideTransitionOptions]="'0.3s'" #menuUser [popup]="true"
              [model]="menuItemsUser"></p-menu>
      <button pTooltip="{{'components.header.language-menu.label'|translate}}" tooltipPosition="top" (click)="menuLanguage.toggle($event)"
              pButton pRipple type="button" icon="bi bi-globe text-2xl"
              class="px-3 p-button-rounded p-button-text p-button-plain "></button>
      <p-menu [showTransitionOptions]="'0.3s'" [hideTransitionOptions]="'0.3s'" #menuLanguage [popup]="true"
              [model]="menuItemsLanguage"></p-menu>
    </div>
  </div>
</header>
