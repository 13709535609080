/**
 * Class that define the common properties that will be used for each component on dashboard
 */
export class BaseDashboardComponent {
  /**
   * The key of the component
   * @type {string}
   */
  static key: string;
  /**
   * Configurations settings for the component
   * @type {any}
   */
  static configurationSettings: any = { fields: [] };
  /**
   * The default values for the configurations settings
   * @type {string}
   */
  static staticSettings: string;
}
