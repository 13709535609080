import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthStorage } from '../../models/prg-auth-config';
// import jwt_decode from 'jwt-decode';
import { AbstractAuthService } from './abstract-auth.service';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * REAL AbstractAuthService implementation
 */
export class AuthService extends AbstractAuthService {
  private readonly baseUrl: string = 'http://localhost:8080';
  /**
   * constructor
   * @param {AuthStorage} authStorage
   * @param {HttpClient} http
   */
  constructor(
    @Inject('AUTHSTORAGE') authStorage: AuthStorage,
    private http: HttpClient
  ) {
    super(authStorage);
  }

  /**
   * User login (Async)
   *
   * @param {string} email The user e-mail
   * @param {string} password The user password
   * @param {boolean} storeUserName Store username
   * @returns Boolean promise, true when successful
   */
  public loginAsync(
    email: string,
    password: string,
    storeUserName: boolean
  ): Promise<boolean> {
    return firstValueFrom(this.http.get<boolean>(this.baseUrl));
  }

  /**
   * User logout (Async)
   *
   * @returns Boolean promise, true when successful
   */
  public logoutAsync(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.setLoggedUser(null);
      resolve(true);
    });
  }
  /**
   * User sign up (Async)
   *
   * @param {string} email The user e-mail
   * @returns Boolean promise, true when successful
   */
  public signupAsync(email: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  /**
   * Request to reset the password (Async)
   *
   * @param {string} email The user e-mail
   * @returns Boolean promise, true when successful
   */
  public requestResetPasswordAsync(email: string): Promise<boolean> {
    return Promise.resolve(false);
  }
  /**
   * Getter for token reset password (Async)
   *
   * @param {string} tokenReset The token reset password
   * @returns Boolean promise, true when successful
   */
  public getTokenResetPasswordAsync(tokenReset: string): Promise<boolean> {
    return Promise.resolve(false);
  }
  /**
   * Method for reset password (Async)
   *
   * @param {string} newPassword The new password
   * @param {string} tokenReset The token reset password
   * @returns Boolean promise, true when successful
   */
  public resetPasswordAsync(
    newPassword: string,
    tokenReset: string
  ): Promise<boolean> {
    return Promise.resolve(false);
  }
}
