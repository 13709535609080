import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputMaskConfiguration } from './input-mask.model';

/**
 * component Input Mask
 */
@Component({
  selector: 'prg-input-mask-field',
  templateUrl: './input-mask.component.html',
})
export class PrgInputMaskComponent {
  /**
   * Input Mask Configs
   */
  @Input() configs: InputMaskConfiguration;
  /**
   * form
   */
  @Input() form: FormGroup;
}
