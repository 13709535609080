<main>
  <router-outlet></router-outlet>
  <p-toast
    [showTransitionOptions]="'350ms'"
    [hideTransitionOptions]="'350ms'"
    [showTransformOptions]="'translateY(-100%)'"
    key="message"
    position="top-center"
    appendTo="body"
  ></p-toast>
  <p-confirmDialog
    [style]="{ width: '50vw' }"
    rejectButtonStyleClass="p-button-text"
    key="confirmationDialog"
    appendTo="body"
    [dismissableMask]="true"
  ></p-confirmDialog>

  <p-progressSpinner
    *ngIf="spinnerVisible"
    class="spinnerPNG"
    [style]="{ width: '120px', height: '120px' }"
  ></p-progressSpinner>
  <div class="mainContainer"></div>
</main>
