import { ValidatorFn } from '@angular/forms';

/**
 * Class BaseField
 */
export class BaseField {
  /**
   * defines the element to be used
   * should be prg-input or prg-select
   * default is prg-input
   */
  public controlName?: string;

  /**
   * value
   */
  public value?: any;

  /**
   * read only
   */
  public readonly?: boolean = false;

  /**
   * disabled
   */
  public disabled?: boolean = false;

  /**
   * visible expression
   */
  public visibleExpression?: string;

  /**
   * enable expression
   */
  public enableExpression?: string;

  /**
   * mandatory field, is used like formcontrolname
   */
  public key!: string;

  /**
   * path to translation
   */

  public basePathTranslation?: string = null;

  /**
   * array with validations
   *
   * @example
   *  validators: [Validators.required, Validators.minLength(6)],
   */
  public validators?: ValidatorFn[];

  /**
   * Disabled output event
   * @type {boolean}
   */
  public disabledOutputEvent?: boolean = true;

  /**
   * Property that identifies the group that aggregates multiple fields
   * @type {string}
   */
  public groupFieldsId?: string;

  /**
   * Constructor
   * this constructor receives the fields sent by the app,
   * and creates an object with the fields sent and the values per default
   * @param init
   */
  constructor(init?: Partial<BaseField>) {
    Object.assign(this, init);
  }
}
