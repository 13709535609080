import { Directive, Input } from '@angular/core';
import { Chips } from 'primeng/chips';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class Chips
 */
export class ChipsConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.chips;

  /**
   *Name of the property to display on a chip.
   */
  public field: string = null;
  /**
   *Maximum number of entries allowed.
   */
  public max: number = null;
  /**
   *Inline style of the element.
   */
  public style: string = null;
  /**
   *Style class of the element.
   */
  public styleClass: string = null;
  /**
   *Advisory information to display on input.
   */
  public placeholder: string = null;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;
  /**
   *Whether to allow duplicate values or not.
   */
  public allowDuplicate: boolean = true;
  /**
   *Inline style of the input field.
   */
  public inputStyle: string = null;
  /**
   *Style class of the input field.
   */
  public inputStyleClass: string = null;
  /**
   *Whether to add an item on tab key press.
   */
  public addOnTab: boolean = false;
  /**
   *Whether to add an item when the input loses focus.
   */
  public addOnBlur: boolean = false;
  /**
   *Separator char to add an item when pressed in addition to the enter key. Currently only possible value is ","
   */
  public separator: string = ',';
  /**
   *When enabled, a clear icon is displayed to clear the value.
   */
  public showClear: boolean = false;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<ChipsConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setChipsConfigs]',
})
export class PrgSetChipsConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: Chips) {}

  /**
   * input Chips configs and assign configs to element
   */
  @Input() set setChipsConfigs(configs: ChipsConfiguration) {
    Object.assign(this.element, configs);
  }
}
