import { Inject, Injectable, InjectionToken } from "@angular/core";
import { PrgRolesScopesConfig } from "../../models/prg-roles-scopes-config";

/**
 * Inject token
 */
export const PRG_ROLES_SCOPES_CONFIG = new InjectionToken<PrgRolesScopesConfig>(
  "PRG_ROLES_SCOPES_CONFIG"
);

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * PrgRolesScopesConfigurationService class definition
 * <br />that specifies the properties that the
 * <br />application can send to the lib for
 * <br />configuration purposes
 */
export class PrgRolesScopesConfigurationService {
  /**
   * Constructor
   *
   * @param prgRolesScopesConfig
   */
  constructor(
    @Inject(PRG_ROLES_SCOPES_CONFIG)
    private prgRolesScopesConfig: PrgRolesScopesConfig
  ) {}
}
