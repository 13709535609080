import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UIChart } from 'primeng/chart';
import { filter, Subscription } from 'rxjs';
import { ObjectsUtilityService } from '../../../../core/services/utility/objects-utility.service';
import { UtilityService } from '../../../../core/services/utility/utility.service';
import { AbstractDashboardService } from '../../../services/dashboard/abstract-dashboard.service';
import { BaseDashboardComponent } from '../base-dashboard-component';
import { PRG_BAR_LINE_SETTINGS } from './prg-line-chart-settings';

/**
 * Line Chart Component
 */
@Component({
  selector: 'prg-line-chart',
  templateUrl: './prg-line-chart.component.html',
  styleUrls: ['./prg-line-chart.component.scss'],
})
export class PrgLineChartComponent
  extends BaseDashboardComponent
  implements OnInit, OnDestroy
{
  /**
   *  Data for this chart
   * @type {any}
   */
  public basicData: any;
  /**
   * Options for this chart
   * @type {any}
   */
  public basicOptions: any;
  /**
   * Height of the chart
   * @type {string}
   */
  public height: string;
  /**
   * Width of the chart
   * @type {string}
   */
  public width: string;
  /**
   * The item id used on dashboard
   */
  @Input('itemId') itemId;

  /**
   * The custom settings for this component
   * @param itemSettings
   */
  @Input('itemSettings') set itemSettings(itemSettings: any) {
    if (itemSettings != null) {
      this._itemSettings = this.objectsUtilityService.cloneObject(
        this.utilityService.guiSettingToObject(itemSettings)
      );
    }
  }

  /**
   * aux settings
   * @private
   */
  private _itemSettings;

  /**
   * Getter for item settings
   * @returns {any}
   */
  get itemSettings(): any {
    return this._itemSettings;
  }

  /**
   * The chart element from template
   * @type {UIChart}
   */
  @ViewChild('chart') chart: UIChart;
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * Constructor
   * @param {AbstractDashboardService} dashboardService
   * @param {UtilityService} utilityService
   * @param {ObjectsUtilityService} objectsUtilityService
   */
  constructor(
    private dashboardService: AbstractDashboardService,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService
  ) {
    super();
    PrgLineChartComponent.key = PRG_BAR_LINE_SETTINGS.key;
    PrgLineChartComponent.staticSettings = PRG_BAR_LINE_SETTINGS.staticSettings;

    PrgLineChartComponent.configurationSettings =
      PRG_BAR_LINE_SETTINGS.configurationSettings;
  }

  /**
   * ngOnInit
   */
  ngOnInit() {
    if (this._itemSettings == null) {
      this._itemSettings = this.objectsUtilityService.cloneObject(
        this.utilityService.guiSettingToObject(
          PrgLineChartComponent.staticSettings
        )
      );
    }

    this._itemSettings = Object.values(this._itemSettings);

    this.basicData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: this._itemSettings,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.4,
        },
        {
          label: 'Second Dataset',
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderColor: '#FFA726',
          tension: 0.4,
        },
      ],
    };

    this.basicOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: '#495057',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
        y: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
      },
    };
    this.subscription.push(
      this.dashboardService
        .getDashboardItemsResizeObservable()
        .pipe(filter((item) => item?.item?.id === this.itemId))
        .subscribe((item) => {
          this.height = (item.height - item.height * 0.05).toString() + 'px';
          this.width = (item.width - item.width * 0.02).toString() + 'px';
          if (this.chart?.chart) {
            this.chart.chart.canvas.parentNode.style.height =
              (item.height - item.height * 0.05).toString() + 'px';
            this.chart.chart.canvas.parentNode.style.width =
              (item.width - item.width * 0.02).toString() + 'px';
            this.chart.reinit();
          }
        })
    );
  }
  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}
