import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Role } from "../../models/role.model";
import { AbstractRolesScopesService } from "../../services/roles-scopes/abstract-roles-scopes.service";
import { PrgModalCreateRoleComponent } from "./prg-modal-create-role/prg-modal-create-role.component";

/**
 * Create role component
 */
@Component({
  selector: "prg-create-role",
  templateUrl: "./prg-create-role.component.html",
  styleUrls: ["./prg-create-role.component.scss"],
})
export class PrgCreateRoleComponent {
  /**
   * Input placeholder
   */
  @Input() placeholder: string = "";

  /**
   * Button label
   */
  @Input() buttonLabel!: string;

  /**
   * newRoleName
   */
  newRoleName: string;

  /**
   * output if the newRole was added successfully
   */
  @Output() addedNewRole = new EventEmitter<Role>();

  /**
   * constructor
   *
   * @param rolesScopesService
   */
  constructor(
    private rolesScopesService: AbstractRolesScopesService,
    private modalCtrl: ModalController
  ) {}

  /**
   * Create role, this function call service method [createRoleAsync]{@link /classes/AbstractRolesScopesService.html#createRoleAsync}
   */

  public createRole(role: Role) {
    this.rolesScopesService.createRoleAsync(role).then((response) => {
      if (response) {
        this.newRoleName = "";
        this.addedNewRole.emit(response);
      } else {
        this.addedNewRole.emit(null);
      }
    });
  }

  /**
   * Function to open modal
   *
   * if click to save role call method [createRole]{@link #createRole}
   */
  async openModalCreateRole(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: PrgModalCreateRoleComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "confirm") {
      this.createRole(data);
    }
  }
}
