import { NgModule } from '@angular/core';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { PrgLayoutsEmptyComponent } from '../layouts/empty/prg-layouts-empty.component';
import { PrgLayoutsHeaderSidebarComponent } from '../layouts/pages/prg-layouts-header-sidebar';
import { PrgLookupTablePage } from './pages/prg-lookup-table.page';
import { lookupTableConfig } from './prg-lookup-table.module';

/**
 * lookup tables routing module routes
 */
const standardRoutes: Routes = [];
@NgModule({
  imports: [RouterModule.forChild(standardRoutes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        let routes: Routes = [];
        routes.push({
          path: lookupTableConfig.lookupTableBasePath,
          component: PrgLayoutsHeaderSidebarComponent,
          children: [
            {
              path: '',
              component: PrgLookupTablePage,
            },
          ],
        });

        return [...routes, ...standardRoutes];
      },
      multi: true,
    },
  ],
})
export class PrgLookupTableRoutingModule {}
