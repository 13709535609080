import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { PrgCoreDynamicFormModule } from '../core/components/dynamic-form/prg-core-dynamic-form.module';
import { PrgSharedComponentsModule } from '../shared-components/shared-components.module';
import { PrgQueriesTableComponent } from './components/queries-table/prg-queries-table.component';
import { PrgQueryModalComponent } from './components/query-modal/prg-query-modal.component';
import { MockQueriesService } from './mock/services/queries.service.mock';
import { PrgQueriesConfig } from './models/prg-queries-config';
import { PrgQueriesPage } from './pages/prg-queries.page';
import { PrgQueriesRoutingModule } from './prg-queries-routing.module';
import { PRG_QUERIES_CONFIG } from './services/prg-queries-configuration/prg-queries-configuration.service';
import { AbstractQueriesService } from './services/queries/abstract-queries.service';
import { QueriesService } from './services/queries/queries.service';

@NgModule({
  declarations: [
    PrgQueriesTableComponent,
    PrgQueryModalComponent,
    PrgQueriesPage,
    PrgQueryModalComponent,
  ],
  imports: [
    PrgQueriesRoutingModule,
    PrgSharedComponentsModule,
    PrgCoreDynamicFormModule,
  ],
  exports: [],
  providers: [DialogService],
})
export class PrgQueriesModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }

  static forRoot(
    prgQueriesConfig: PrgQueriesConfig
  ): ModuleWithProviders<PrgQueriesModule> {
    queriesConfig = prgQueriesConfig;
    return {
      ngModule: PrgQueriesModule,
      providers: [
        {
          provide: PRG_QUERIES_CONFIG,
          useValue: prgQueriesConfig,
        },
        {
          provide: AbstractQueriesService,
          useClass: prgQueriesConfig.useMockServices
            ? MockQueriesService
            : QueriesService,
        },
      ],
    };
  }
}

/**
 * This variable allows you to access all the injectors on the entire app
 */
export let appInjector: Injector;

/**
 * Exports the QueriesCoreConfig object
 */
export let queriesConfig: PrgQueriesConfig;
