import { HttpClient } from '@angular/common/http';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dashboard } from '../../models/dashboard.model';
import {
  PrgGridsterConfig,
  PrgGridsterItem,
} from '../../models/prg-gridster-config';

/**
 * AbstractQueriesService class that defines an
 * <br />abstract dashboard service to
 * <br />be extended by the mock and real
 * <br />dashboard services
 */

export abstract class AbstractDashboardService {
  /**
   * Constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}
  /**
   * Detect changes on dashboard options made on prg-dashboard-options.component
   */
  protected dashboardOptionsChangesSubject =
    new BehaviorSubject<GridConfigsAndItems>(null);

  /**
   * Detect changes on the size of dashboardItems
   * @type {BehaviorSubject<GridsterItemComponentInterface>}
   * @protected
   */
  protected dashboardItemsResizeSubject =
    new BehaviorSubject<GridsterItemComponentInterface>(null);

  /**
   * Detect changes on the list of dashboards
   * @type {BehaviorSubject<Dashboard[]>}
   * @protected
   */
  protected dashboardListUpdatedSubject = new BehaviorSubject<Dashboard[]>(
    null
  );

  /**
   * Setter for dashboardOptionsChangesSubject
   * @param {GridConfigsAndItems} value
   */
  public setDashboardOptionsChanges(value: GridConfigsAndItems): void {
    this.dashboardOptionsChangesSubject.next(value);
  }

  /**
   * Getter for dashboardOptionsChangesSubject
   * @returns {Observable<GridConfigsAndItems>}
   */
  public getDashboardOptionsChangesObservable(): Observable<GridConfigsAndItems> {
    return this.dashboardOptionsChangesSubject.asObservable();
  }

  /**
   * Getter for dashboardOptionsChangesSubject
   * @returns {GridConfigsAndItems}
   */
  public getDashboardOptionsChangesValue(): GridConfigsAndItems {
    return this.dashboardOptionsChangesSubject.getValue();
  }

  /**
   * Setter for dashboardItemsResizeSubject
   * @param {GridsterItemComponentInterface} value
   */
  public setDashboardItemsResize(value: GridsterItemComponentInterface): void {
    this.dashboardItemsResizeSubject.next(value);
  }

  /**
   * Getter for dashboardItemsResizeSubject
   * @returns {Observable<GridsterItemComponentInterface>}
   */
  public getDashboardItemsResizeObservable(): Observable<GridsterItemComponentInterface> {
    return this.dashboardItemsResizeSubject.asObservable();
  }

  /**
   * Getter for dashboardItemsResizeSubject
   * @returns {GridsterItemComponentInterface}
   */
  public getDashboardItemsResizeValue(): GridsterItemComponentInterface {
    return this.dashboardItemsResizeSubject.getValue();
  }

  /**
   * Setter for dashboardListUpdatedSubject
   * @param {Dashboard[]} value
   */
  protected setDashboardListUpdated(value: Dashboard[]): void {
    this.dashboardListUpdatedSubject.next(value);
  }

  /**
   * Getter for dashboardListUpdatedSubject
   * @returns {Observable<Dashboard[]>}
   */
  public getDashboardListUpdatedObservable(): Observable<Dashboard[]> {
    return this.dashboardListUpdatedSubject.asObservable();
  }

  /**
   * Getter for dashboardListUpdatedSubject
   * @returns {Dashboard[]}
   */
  public getDashboardListUpdatedValue(): Dashboard[] {
    return this.dashboardListUpdatedSubject.getValue();
  }

  /**
   * Function responsible to store a new Dashboard
   * @param {Dashboard} newDashboard
   * @returns {Promise<Dashboard>}
   */
  public abstract createDashboardAsync(
    newDashboard: Dashboard
  ): Promise<Dashboard>;

  /**
   * Get dashboard by Id
   * @param {string} dashboardId
   * @returns {Promise<Dashboard>}
   */
  public abstract getDashboardByIdAsync(
    dashboardId: string
  ): Promise<Dashboard>;

  /**
   * Get dashboards by User Logged
   * @returns {Promise<Dashboard[]>}
   */
  public abstract getDashboardsByUserAsync(): Promise<Dashboard[]>;

  /**
   * Function responsible to update a dashboard
   * @param {string} dashboardId
   * @param {Dashboard} dashboard
   * @returns {Promise<Dashboard>}
   */
  public abstract updateDashboardAsync(
    dashboardId: string,
    dashboard: Dashboard
  ): Promise<Dashboard>;
}

export type GridConfigsAndItems = {
  gridsterConfig: PrgGridsterConfig;
  gridsterItems: PrgGridsterItem[];
};
