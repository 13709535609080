import { BaseEntity } from '../../core/models/base-entity.model';

/**
 * QueryOrderType enum
 */

export enum QueryOrderType {
  Ascending,
  Descending,
}

/**
 * QueryFilterExpression enum
 */
export enum QueryFilterExpression {
  And = 'And',
  Or = 'Or',
}

/**
 * QueryFilterOperation enum
 */
export enum QueryFilterOperation {
  EqualTo = 'EqualTo',
  NotEqualTo = 'NotEqualTo',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  Between = 'Between',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  EndsWith = 'EndsWith',
  In = 'In',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
  IsNotNull = 'IsNotNull',
  IsNotNullNorWhiteSpace = 'IsNotNullNorWhiteSpace',
  IsNull = 'IsNull',
  IsNullOrWhiteSpace = 'IsNullOrWhiteSpace',
  StartsWith = 'StartsWith',
  NotIn = 'NotIn',
}

/**
 * QueryFilterOperationByType class that defines allowed filters operations
 * for a datatype through static properties
 */
export class QueryFilterOperationByType {
  /**
   * Static property to define filter operations for data type "default"
   */
  static default = [
    QueryFilterOperation.IsNull,
    QueryFilterOperation.IsEmpty,
    QueryFilterOperation.IsNotEmpty,
    QueryFilterOperation.IsNotNull,
  ];
  /**
   * Static property to define filter operations for data type "string"
   */
  static string = [
    ...this.default,
    QueryFilterOperation.Contains,
    QueryFilterOperation.DoesNotContain,
    QueryFilterOperation.StartsWith,
    QueryFilterOperation.EndsWith,
  ];
  /**
   * Static property to define filter operations for data type "date"
   */
  static date = [...this.default, QueryFilterOperation.Between];
  /**
   * Static property to define filter operations for data type "number"
   */
  static number = [
    ...this.default,
    QueryFilterOperation.Between,
    QueryFilterOperation.EqualTo,
    QueryFilterOperation.NotEqualTo,
    QueryFilterOperation.LessThan,
    QueryFilterOperation.LessThanOrEqualTo,
    QueryFilterOperation.GreaterThan,
    QueryFilterOperation.GreaterThanOrEqualTo,
  ];

  /**
   * Function that returns the number of fields required for a query filter operation
   * send by parameter
   * @param queryFilterOperation
   */
  public numberOfFields(queryFilterOperation: QueryFilterOperation): number {
    return queryFilterOperation === QueryFilterOperation.Between ? 2 : 1;
  }
}

/**
 * QueryColumn class that defines query results'
 * <br />properties and order
 */
export class QueryColumn extends BaseEntity {
  /**
   * query id (foreign key)
   */
  public queryId: string;
  /**
   * Workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Column numeric order
   */
  public order: number;
  /**
   * Property name
   */
  public propertyName: string;

  /**
   * Constructor
   */
  constructor(init?: Partial<QueryColumn>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * QueryFilter class that defines filters
 * <br/>for non-raw queries
 */
export class QueryFilter extends BaseEntity {
  /**
   * query id (foreign key)
   */
  public queryId: string;
  /**
   * Workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Starts a group before this filter
   * <br />(opens parenthesis)
   */
  public startGroup: boolean;
  /**
   * Filter property name
   */
  public propertyName: string;
  /**
   * Filter value
   */
  public value: any;
  /**
   * Filter additional value for operations that
   * <br />require it, e.g. Between
   */
  public value2: string;
  /**
   * Filter operation
   */
  public filterOperation: QueryFilterOperation = QueryFilterOperation.Contains;
  /**
   * Filter expression
   */
  public filterExpression: QueryFilterExpression = QueryFilterExpression.And;

  /**
   * Constructor
   * @param {Partial<QueryFilter>} init
   */
  constructor(init?: Partial<QueryFilter>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * QueryOrder class that defines the query
 * <br />ordenation
 */
export class QueryOrder extends BaseEntity {
  /**
   * query id (foreign key)
   */
  public queryId: string;

  /**
   * Workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Property name
   */
  public propertyName: string;
  /**
   * Order type
   */
  public orderType: QueryOrderType;

  /**
   * Constructor
   */
  constructor(init?: Partial<QueryOrder>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Query class that defines a generic query
 * <br />to be executed on the database
 */
export class Query extends BaseEntity {
  /**
   *  Entity type id (foreign key)
   */
  public entityTypeId: string;
  /**
   * Workspace id (foreign key)
   */
  public workspaceId: string;
  /**
   * Is raw (sql command) query
   */
  public isRaw: boolean = true;
  /**
   * Raw sql command to be executed
   * <br />if isRaw is true
   */
  public raw: string;
  /**
   * Query filters array for non-raw queries
   */
  public filters: QueryFilter[];
  /**
   * Query orders array for non-raw queries
   */
  public orders: QueryOrder[];
  /**
   * Query columns array for non-raw queries
   */
  public columns: QueryColumn[];
  /**
   * Query page size (LIMIT) for non-raw queries
   */
  public pageSize: number;
  /**
   * Query page index for non-raw queries
   */
  public pageIndex: number;

  /**
   * A description of the query
   */
  public description: string;

  /**
   * Constructor
   */
  constructor(init?: Partial<Query>) {
    super();
    Object.assign(this, init);
  }
}
