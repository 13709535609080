import { Validators } from '@angular/forms';
import { InputTextAreaConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/input-text-area/input-text-area.model';
import {
  BaseAction,
  BaseActionKey,
} from '../../core/components/dynamic-form/models/base-action';
import { BaseField } from '../../core/components/dynamic-form/models/base-field';
import { ActionName } from '../../core/components/dynamic-form/models/constants/action-names';
import { ControlName } from '../../core/components/dynamic-form/models/constants/control-names';
import { ToolbarSlot } from '../../core/components/dynamic-form/models/constants/toolbar-slots';

/**
 * Constant that defines the fields and actions for the dynamic form query raw
 */
export const QUERIES_RAW_DYNAMIC_FORM = {
  fields: [
    new BaseField({
      controlName: ControlName.inputText,
      value: null,
      key: 'name',
      validators: [Validators.required],
      basePathTranslation: 'queries.fields',
    }),
    new InputTextAreaConfiguration({
      controlName: ControlName.inputTextArea,
      value: null,
      key: 'description',
      validators: [Validators.required],
      basePathTranslation: 'queries.fields',
      cols: 100,
      rows: 2,
      autoResize: true,
    }),
    new InputTextAreaConfiguration({
      controlName: ControlName.inputTextArea,
      value: null,
      key: 'raw',
      validators: [Validators.required],
      basePathTranslation: 'queries.fields',
      cols: 100,
      rows: 2,
      autoResize: true,
    }),
  ],
  actions: [
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Save,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Cancel,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Edit,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Right,
      visibleExpression: "this.viewMode==='read'",
    }),
  ],
};
