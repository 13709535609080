<form [formGroup]="lookupTableForm">
  <div formArrayName="lookupTableFormArray">
    <p-table
      [value]="lookupTableFormArray.controls"
      dataKey="value.id"
      responsiveLayout="scroll"
      editMode="row"
      (sortFunction)="customSort($event)"
      [customSort]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem"></th>
          <ng-container *ngFor="let column of lookupTableColumns">
            <th
              [pSortableColumn]="column.key"
              [pSortableColumnDisabled]="
                isLoadingDataService.visibility | async
              "
            >
              {{
                'components.lookup-table.columns.' + column.key + '.label'
                  | translate
              }}
              <p-sortIcon [field]="column.key"></p-sortIcon>
            </th>
          </ng-container>

          <th style="width: 15rem">
            <p-button
              type="button"
              icon="pi pi-plus-circle"
              (click)="newLookupTable()"
              [disabled]="isLoadingDataService.visibility | async"
            ></p-button>
            <p-button
              type="button"
              icon="pi pi-save"
              [disabled]="
                (isLoadingDataService.visibility | async) ||
                lookupTableFormArray.status === 'INVALID'
              "
              (click)="saveAllChanges()"
            ></p-button>
            <p-button
              type="button"
              icon="pi pi-trash"
              [disabled]="isLoadingDataService.visibility | async"
              (click)="discardAllChanges()"
            ></p-button>
          </th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-editing="editing"
        let-ri="rowIndex"
        let-expanded="expanded"
      >
        <tr [pEditableRow]="rowData" [formGroupName]="ri">
          <td>
            <button
              type="button"
              pButton
              pRipple
              *ngIf="!rowData.value.id.includes(newLookupTableIdPrefix)"
              [pRowToggler]="rowData"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              (click)="expandLookupTable(rowData.value)"
            ></button>
          </td>
          <ng-container *ngFor="let column of lookupTableColumns">
            <td pEditableColumn *ngIf="editing; else elseBlock">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input
                    *ngIf="
                      !column.readonly ||
                        rowData.value.id.includes(newLookupTableIdPrefix);
                      else notEditableColumn
                    "
                    [placeholder]="'placeholder' + column.key | translate"
                    pInputText
                    type="text"
                    [formControlName]="column.key"
                  />
                  <ng-template pTemplate="input" #notEditableColumn>
                    {{ rowData.value[column.key] | translate }}
                  </ng-template>
                </ng-template>
              </p-cellEditor>
            </td>
            <!-- Fixes bug that allows user to edit row without first clicking on the edit button-->
            <ng-template #elseBlock>
              <td>{{ rowData.value[column.key] | translate }}</td>
            </ng-template>
          </ng-container>

          <td style="text-align: center">
            <button
              *ngIf="!editing"
              pButton
              pRipple
              type="button"
              pInitEditableRow
              icon="pi pi-pencil"
              (click)="onRowEditInit(rowData.value)"
              class="p-button-rounded p-button-text"
            ></button>

            <button
              *ngIf="editing"
              [disabled]="
                getLookupTableControlById(rowData.value.id).status !== 'VALID'
              "
              pButton
              pRipple
              type="button"
              pSaveEditableRow
              icon="pi pi-check-circle"
              (click)="onRowEditSave(rowData.value)"
              class="p-button-rounded p-button-text p-button-success p-mr-2"
            ></button>
            <button
              *ngIf="editing"
              pButton
              pRipple
              type="button"
              pCancelEditableRow
              icon="pi pi-times-circle"
              (click)="onRowEditCancel(rowData.value)"
              class="p-button-rounded p-button-text p-button-danger"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-lookupTable>
        <tr style="background: lime !important">
          <td colspan="7">
            <div class="p-3">
              <prg-lookup-table-items-table
                [lookupTableItems]="lookupTable.value.items"
                [lookupTableId]="lookupTable.value.id"
                (savedItems)="itemsSaved($event)"
              ></prg-lookup-table-items-table>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr style="background: white !important" class="emptyInfo">
          <td colspan="6">
            <p-progressSpinner
              *ngIf="isLoadingDataService.visibility | async; else elseBlock"
              [style]="{ width: '50px', height: '50px' }"
            ></p-progressSpinner>
            <ng-template #elseBlock>
              {{ 'withoutLookuptable' | translate }}</ng-template
            >
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
