import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldChangeOutput } from '../models/base-action';
import { ControlName } from '../models/constants/control-names';
import { ControlTypes } from '../models/constants/control-types';

/**
 * Dynamic form field component
 */
@Component({
  selector: 'prg-dynamic-form-field',
  templateUrl: './prg-dynamic-form-field.component.html',
  styleUrls: ['./prg-dynamic-form-field.component.scss'],
})
export class PrgDynamicFormFieldComponent {
  /**
   * receives a field
   */
  @Input() field!: any;
  /**
   * receive the formGroup
   */
  @Input() form!: FormGroup;

  /**
   * Emit a new FieldChangeOutput whenever a field value changes
   */
  @Output() onChange = new EventEmitter<FieldChangeOutput>();

  /**
   * output to dynamic component
   */
  outputs = {
    onValueChange: (fieldChangeOutput: FieldChangeOutput) => {
      this.onChange.emit(fieldChangeOutput);
    },
  };

  /**
   * fields type
   * @type {{inputNumber: PrgInputNumberComponent, inputTextArea: PrgInputTextAreaComponent, checkbox: PrgCheckboxComponent, inputText: PrgInputTextComponent, inputDateTime: PrgDateTimeComponent, inputPassword: PrgPasswordComponent, dropdown: PrgSelectComponent, multiSelect: PrgMultiSelectComponent}}
   */
  public fieldTypes = ControlTypes;

  /**
   * @returns boolean if question is valid or not
   */
  public get isValid() {
    return this.form.controls[this.field.key].valid;
  }

  /**
   * @returns string with error
   */
  public get errors() {
    return this.form.controls[this.field.key].errors;
  }

  /**
   * get control
   * @returns control
   */
  public get formControl(): any {
    return this.form.controls[this.field.key];
  }

  /**
   * get control name
   * @return controlname
   */
  public get controlNames(): typeof ControlName {
    return ControlName;
  }
}
