import { Directive, Input } from '@angular/core';
import { Checkbox } from 'primeng/checkbox';
import { BaseField } from '../../../models/base-field';
import { ControlName } from '../../../models/constants/control-names';

/**
 * Class CheckBox
 */
export class CheckboxConfiguration extends BaseField {
  /**
   * control name
   */
  public controlName?: string = ControlName.checkbox;

  /**
   *Name of the checkbox group.
   */
  public name: string = null;
  /*  /!**
   *Value of the checkbox.
   *!/
  public value: any = null;*/ //Property already on Base Field
  /**
   *Label of the checkbox.
   */
  public label: string = null;
  /*
  /!**
   *When present, it specifies that the element should be disabled.
   *!/
  public disabled: boolean = false;*/ //Property already on Base Field
  /**
   *Allows to select a boolean value instead of multiple values.
   */
  public binary: boolean = true;
  /**
   *Index of the element in tabbing order.
   */
  public tabindex: number = null;
  /**
   *Identifier of the focus input to match a label defined for the component.
   */
  public inputId: string = null;
  /**
   *Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   */
  public ariaLabelledBy: string = null;
  /**
   *Used to define a string that labels the input element.
   */
  public ariaLabel: string = null;
  /**
   *Inline style of the component.
   */
  public style: object = null;
  /**
   *Style class of the component.
   */
  public styleClass: string = null;
  /**
   *Style class of the label.
   */
  public labelStyleClass: string = null;
  /**
   *Icon class of the checkbox icon.
   */
  public checkboxIcon: string = 'pi pi-check';
  /*  /!**
   *When present, it specifies that the component cannot be edited.
   *!/
  public readonly: boolean = false;*/ //Property already on Base Field
  /**
   *When present, it specifies that checkbox must be checked before submitting the form.
   */
  public required: boolean = false;
  /**
   *Value in checked state.
   */
  public trueValue: any;
  /**
   *Value in unchecked state.
   */
  public falseValue: any;

  /**
   *An array of objects to display as dynamic values.Mandatory field.
   */
  public options: any[] = null;

  /**
   *Name of the value field of an option. Default 'value'.
   */
  public optionValue: string = 'value';

  /**
   *Name of the key field of an option.Default 'key'.Also used to bring the translation of the field
   */
  public optionKey: string = 'key';

  /**
   * Allow multiple checkbox selection. On false , whenever a checkbox is checked the others became disabled.
   */
  public allowMultipleCheckBoxSelection: boolean = false;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<CheckboxConfiguration>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Directive
 */
@Directive({
  selector: '[setCheckboxConfigs]',
})
export class PrgSetCheckboxConfigsDirective {
  /**
   * constructor
   * @param element
   */
  constructor(private element: Checkbox) {}
  /**
   * input checkbox configs and assign configs to element
   */
  @Input() set setCheckboxConfigs(configs: CheckboxConfiguration) {
    Object.assign(this.element, configs);
  }
}
