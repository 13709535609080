import { FilterMatchMode } from 'primeng/api';

export enum FilterExpressions {
  And,
  Or,
}

/**
 * Map primeng expressions to out expressions
 */
export const MapPrimeNgExpressionsToFilterExpressions: Map<
  string,
  FilterExpressions
> = new Map<string, FilterExpressions>([
  ['and', FilterExpressions.And],
  ['or', FilterExpressions.Or],
]);

export enum FilterOperations {
  EqualTo,
  NotEqualTo,
  GreaterThan,
  GreaterThanOrEqualTo,
  LessThan,
  LessThanOrEqualTo,
  Between,
  Contains,
  DoesNotContain,
  EndsWith,
  In,
  IsEmpty,
  IsNotEmpty,
  IsNotNull,
  IsNotNullNorWhiteSpace,
  IsNull,
  IsNullOrWhiteSpace,
  StartsWith,
  NotIn,
}

/**
 * Map primeng operations to out filter operations
 */
export const MapPrimeNgFilterOperationsToFilterOperations: Map<
  FilterMatchMode,
  FilterOperations
> = new Map<FilterMatchMode, FilterOperations>([
  [FilterMatchMode.STARTS_WITH, FilterOperations.StartsWith],
  [FilterMatchMode.CONTAINS, FilterOperations.Contains],
  [FilterMatchMode.NOT_CONTAINS, FilterOperations.DoesNotContain],
  [FilterMatchMode.ENDS_WITH, FilterOperations.EndsWith],
  [FilterMatchMode.EQUALS, FilterOperations.EqualTo],
  [FilterMatchMode.NOT_EQUALS, FilterOperations.NotEqualTo],
  [FilterMatchMode.IN, FilterOperations.In],
  [FilterMatchMode.LESS_THAN, FilterOperations.LessThan],
  [FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterOperations.LessThanOrEqualTo],
  [FilterMatchMode.GREATER_THAN, FilterOperations.GreaterThan],
  [
    FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    FilterOperations.GreaterThanOrEqualTo,
  ],
  [FilterMatchMode.BETWEEN, FilterOperations.Between],
  [FilterMatchMode.IS, FilterOperations.EqualTo],
  [FilterMatchMode.IS_NOT, FilterOperations.NotEqualTo],
  [FilterMatchMode.BEFORE, FilterOperations.LessThan],
  [FilterMatchMode.AFTER, FilterOperations.GreaterThan],
  [FilterMatchMode.DATE_IS, FilterOperations.EqualTo],
  [FilterMatchMode.DATE_IS_NOT, FilterOperations.NotEqualTo],
  [FilterMatchMode.DATE_BEFORE, FilterOperations.LessThan],
  [FilterMatchMode.DATE_AFTER, FilterOperations.GreaterThan],
]);

/**
 * enum to convert our datatypes to those supported by primeng
 */
export enum PrimeNgTypeToOurTypes {
  'dataType.string' = 'text',
  'dataType.number' = 'numeric',
  'dataType.date' = 'date',
}

/**
 * Filter operations in string format
 */
export enum FilterOperationsString {
  'EqualTo' = FilterOperations.EqualTo,
  'NotEqualTo' = FilterOperations.NotEqualTo,
  'GreaterThan' = FilterOperations.GreaterThan,
  'GreaterThanOrEqualTo' = FilterOperations.GreaterThanOrEqualTo,
  'LessThan' = FilterOperations.LessThan,
  'LessThanOrEqualTo' = FilterOperations.LessThanOrEqualTo,
  'Between' = FilterOperations.Between,
  'Contains' = FilterOperations.Contains,
  'DoesNotContain' = FilterOperations.DoesNotContain,
  'EndsWith' = FilterOperations.EndsWith,
  'In' = FilterOperations.In,
  'IsEmpty' = FilterOperations.IsEmpty,
  'IsNotEmpty' = FilterOperations.IsNotEmpty,
  'IsNotNull' = FilterOperations.IsNotNull,
  'IsNotNullNorWhiteSpace' = FilterOperations.IsNotNullNorWhiteSpace,
  'IsNull' = FilterOperations.IsNull,
  'IsNullOrWhiteSpace' = FilterOperations.IsNullOrWhiteSpace,
  'StartsWith' = FilterOperations.StartsWith,
  'NotIn' = FilterOperations.NotIn,
}

export enum OrderTypes {
  Ascending,
  Descending,
}

/**
 * Map primeng sort order to ou orderTypes
 */
export const PrimeNgSortToOrderTypeMap: Map<number, OrderTypes> = new Map<
  number,
  OrderTypes
>([
  [-1, OrderTypes.Descending],
  [1, OrderTypes.Ascending],
]);

export class Filter {
  public propertyName: string;
  public value: any;
  public value2: string;
  public filterOperation: FilterOperations = FilterOperations.Contains;
  public filterExpression: FilterExpressions = FilterExpressions.And;
  public startGroup: boolean;
}
export class Order {
  public propertyName: string;
  public orderType: OrderTypes;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<Order>) {
    Object.assign(this, init);
  }
}

export class FilterGroup {
  public filterCollections: Filter[];
  public orderCollection: Order[];
  public pageSize: number | undefined;
  public pageIndex: number | undefined;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<FilterGroup>) {
    Object.assign(this, init);
  }
}
export class FilterCollection {
  public order: number;
  public filter: Filter[];
}
export class GenericFilter {
  public order: number;
  public propertyName: string;
  public placeholder: string;
  public propertyType: string;
  public propertyTranslation?: string;
  public defaultValue?: any;
  public possibleValues?: any[];
  public propertyValue?: any;
  public filterOperation: FilterOperations = FilterOperations.Contains;
  public filterExpression: FilterExpressions = FilterExpressions.And;
  public lookupTableName?: string;
  public isStartGroup = false;
  public visible? = true;
}
