import { Inject, Injectable, InjectionToken } from "@angular/core";
import { PrgAuthConfig } from "../../models/prg-auth-config";

/**
 * Inject token
 */
export const PRG_AUTH_CONFIG = new InjectionToken<PrgAuthConfig>(
  "PRG_AUTH_CONFIG"
);

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * PrgCoreConfigurationService class definition
 * <br />that specifies the properties that the
 * <br />application can send to the lib for
 * <br />configuration purposes
 */
export class PrgAuthConfigurationService {
  /**
   * Constructor
   *
   * @param prgAuthConfig
   */
  constructor(@Inject(PRG_AUTH_CONFIG) private prgAuthConfig: PrgAuthConfig) {}
}
