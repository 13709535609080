import {
  Query,
  QueryFilter,
  QueryFilterExpression,
  QueryFilterOperation,
} from '../models/query.model';

/**
 * Database interface that defines a mock
 * <br />database to be used while developing
 * <br />and/or testing the front-end.
 */
export interface QueriesBD {
  /**
   * Mock queries
   */
  Queries: Query[];
}

/**
 * MockDatabase object to be used while developing
 * <br /> and/or testing the front-end
 */
export const QueriesBDMOCK: QueriesBD = {
  Queries: [
    {
      id: '1',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Query1',
      createdBy: '1',
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      isRaw: true,
      raw: 'select * from batatas',
      filters: null,
      columns: null,
      orders: null,
      pageSize: 2,
      pageIndex: 1,
      entityTypeId: null,
      description: 'Description of the Query 1',
    },
    {
      id: '2',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Query2',
      createdBy: '2',
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      isRaw: true,
      raw: 'select * from bananas',
      filters: null,
      columns: null,
      orders: null,
      pageSize: 2,
      pageIndex: 1,
      entityTypeId: null,
      description: 'Description of the Query 2',
    },
    {
      id: '3',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Query3',
      createdBy: '2',
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      isRaw: false,
      raw: null,
      description: 'Description of the Query 3',
      filters: [
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'customername',
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
          workspaceId: '1',
          filterExpression: QueryFilterExpression.And,
          filterOperation: QueryFilterOperation.Contains,
          queryId: '2',
          propertyName: 'customername',
          startGroup: true,
          value: 'João',
          value2: null,
        },
        {
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'customerId',
          workspaceId: '1',
          filterExpression: QueryFilterExpression.And,
          filterOperation: QueryFilterOperation.EqualTo,
          queryId: '2',
          propertyName: 'customerId',
          startGroup: true,
          value: 15,
          value2: null,
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
        },
      ],
      columns: null,
      orders: null,
      pageSize: 2,
      pageIndex: 1,
      entityTypeId: '1',
    },
    {
      id: '4',
      universalStateId: '1',
      transactionId: null,
      operationId: null,
      name: 'Query4',
      createdBy: '1',
      modifiedBy: null,
      createdOn: null,
      modifiedOn: null,
      workspaceId: '1',
      isRaw: false,
      raw: null,
      description: 'Description of the Query 4',
      filters: [
        new QueryFilter({
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'city',
          workspaceId: '1',
          filterExpression: QueryFilterExpression.And,
          filterOperation: QueryFilterOperation.DoesNotContain,
          queryId: '4',
          propertyName: 'city',
          startGroup: true,
          value: 'Porto',
          value2: null,
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
        }),
        new QueryFilter({
          id: '1',
          universalStateId: '1',
          transactionId: null,
          operationId: null,
          name: 'VAT',
          workspaceId: '1',
          filterExpression: QueryFilterExpression.And,
          filterOperation: QueryFilterOperation.EqualTo,
          queryId: '4',
          propertyName: 'VAT',
          startGroup: true,
          value: 510588966,
          value2: null,
          createdBy: null,
          modifiedBy: null,
          createdOn: null,
          modifiedOn: null,
        }),
      ],
      columns: null,
      orders: null,
      pageSize: 2,
      pageIndex: 1,
      entityTypeId: '1',
    },
  ],
};
