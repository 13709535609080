import { Injectable } from "@angular/core";
import { ContextGenericService } from "./generic-context.service";

/**
 * Injectable
 */
@Injectable()
/**
 * App context service, provides a way to store information
 * <br />in the context of an app that can be used in its components
 */
export class ContextAppService extends ContextGenericService {
  /**
   * constructor
   */
  constructor() {
    super();
  }
}
