import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  FilterExpressionToTranslate,
  FilterOperationByTypeToTranslate,
} from '../../models/query-table-filter.model';
import {
  Query,
  QueryFilterExpression,
  QueryFilterOperation,
} from '../../models/query.model';

/**
 * AbstractQueriesService class that defines an
 * <br />abstract queries service to
 * <br />be extended by the mock and real
 * <br />queries services
 */
export abstract class AbstractQueriesService {
  /**
   * Current Queries list
   */
  protected queriesChangedSubject = new BehaviorSubject<Query[]>(null);

  /**
   * Constructor
   * @param {TranslateService} translationService
   * @protected
   */
  protected constructor(private translationService: TranslateService) {}

  /**
   * Get all Queries
   * @returns a Promise type Query[]
   */
  public abstract getAllQueriesAsync(): Promise<Query[]>;

  /**
   * Function responsible to update a query
   * @param {string} queryId
   * @param {Query} queryUpdated
   * @returns {Promise<Query>}
   */
  public abstract updateQueryAsync(
    queryId: string,
    queryUpdated: Query
  ): Promise<Query>;

  /**
   * Getter for queriesChangedSubject
   * @returns {Observable<Query[]>} the queriesChangedSubject as an observable
   */
  public getQueriesChangedObservable(): Observable<Query[]> {
    return this.queriesChangedSubject.asObservable();
  }

  /**
   * Getter for queriesChangedSubject
   * @returns {Query[]} the queriesChangedSubject current value
   */
  public getQueriesChanged(): Query[] {
    return this.queriesChangedSubject.getValue();
  }

  /**
   * A setter queriesChangedSubject
   * @param {Query[]} queries
   * @protected
   */
  protected setQueriesChanged(queries: Query[]): void {
    this.queriesChangedSubject.next(queries);
  }

  /**
   * Function responsible to store a new query
   * @param {Query} newQuery
   * @returns {Promise<Query>}
   */
  public abstract createQueryAsync(newQuery: Query): Promise<Query>;

  /**
   * Function responsible to translate Filters Expressions
   * @param {string[]} filterExpression
   * @returns {Promise<FilterExpressionToTranslate[]>}
   */
  public async getFilterExpressionTranslationsAsync(
    filterExpression: string[]
  ): Promise<FilterExpressionToTranslate[]> {
    return new Promise<FilterExpressionToTranslate[]>(
      async (resolve, reject) => {
        let filterExpressionToTranslate: FilterExpressionToTranslate[] = [];
        filterExpression.map(async (filter) => {
          filterExpressionToTranslate.push({
            expression: await (<QueryFilterExpression>filter),
            label: await this.translationService
              .get('filters.filter-expression.' + filter + '.label')
              .toPromise(),
          });
        });
        resolve(filterExpressionToTranslate);
      }
    );
  }

  /**
   * Function responsible to translate Filters Operators
   * @param {string[]} filterOperators
   * @returns {Promise<FilterOperationByTypeToTranslate[]>}
   */
  public async getFilterOperationsTranslationsAsync(
    filterOperators: string[]
  ): Promise<FilterOperationByTypeToTranslate[]> {
    return new Promise<FilterOperationByTypeToTranslate[]>(
      async (resolve, reject) => {
        let filterExpressionToTranslate: FilterOperationByTypeToTranslate[] =
          [];
        filterOperators.map(async (filter) => {
          filterExpressionToTranslate.push({
            operators: await (<QueryFilterOperation>filter),
            label: await this.translationService
              .get('filters.filter-operators.' + filter + '.label')
              .toPromise(),
          });
        });

        resolve(filterExpressionToTranslate);
      }
    );
  }

  /**
   * Get query by Id
   * @param {string} queryId
   * @returns {Promise<Query>}
   */
  public abstract getQueryByIdAsync(queryId: string): Promise<Query>;
}
