import { Inject, Injectable } from '@angular/core';
import { PrgError } from '../../../core/models/error.model';
import { PrgSucess } from '../../../core/models/success.model';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { AuthStorage } from '../../models/prg-auth-config';
import { User } from '../../models/user.model';
import { AbstractAuthService } from '../../services/auth/abstract-auth.service';
import { UsersBDMOCk } from '../database.mock';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * MOCK AbstractAuthService implementation
 */
export class MockAuthService extends AbstractAuthService {
  /**
   * default time in seconds
   */
  private sleepTimeSec = 2;

  /**
   * constructor
   * @param authStorage
   * @param notificationsService
   * @param utilityService
   * @param isLoadingData
   */
  constructor(
    @Inject('AUTHSTORAGE') authStorage: AuthStorage,
    private notificationsService: NotificationsService,
    private utilityService: UtilityService,
    private isLoadingData: IsLoadingDataService
  ) {
    super(authStorage);
  }

  /**
   * User login (Async)
   *
   * @param {string} email The user e-mail
   * @param {string} password The user password
   * @param {boolean} storeUserName Store username
   * @returns Boolean promise, true when successful
   */
  public loginAsync(
    email: string,
    password: string,
    storeUserName: boolean
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const user = UsersBDMOCk.Users.find(
        (u) => u.email === email || u.name === email
      );
      this.isLoadingData.hide();
      if (user && password === user.password) {
        this.setLoggedUser(user);
        if (storeUserName) {
          this.setStoredUserName(email);
        } else {
          this.removeStoredUserName();
        }
        resolve(true);
      } else {
        reject('Authentication has failed');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Login Error.',
            detailKey: 'Invalid e-mail or password',
          })
        );
      }
    });
  }

  /**
   * User logout (Async)
   *
   * @returns Boolean promise, true when successful
   */
  public logoutAsync(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const checkUserLogOut = await this.baseLogoutAsync();
      if (checkUserLogOut) {
        resolve(true);
      }
    });
  }

  /**
   * User sign up (Async)
   *
   * @returns Boolean promise, true when successful
   */
  public signupAsync(email: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const checkUser = UsersBDMOCk.Users.find((u) => u.email === email);
      this.isLoadingData.hide();
      if (!checkUser) {
        let user = new User();
        user.name = email;
        user.id = this.utilityService.newGuid();
        user.token = null;
        user.email = email;
        UsersBDMOCk.Users.push(user);
        resolve(true);
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'Registration completed.',
            detailKey: 'An email as been sent with a temporary password.',
          })
        );
      } else {
        reject('Registration has failed.');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Registration Error.',
            detailKey: `The email ${email} already exists in database`,
          })
        );
      }
    });
  }

  /**
   * Request to reset the password (Async)
   *
   * @param {string} email The user e-mail
   * @returns Boolean promise, true when successful
   */
  public requestResetPasswordAsync(email: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const checkUser = UsersBDMOCk.Users.find((u) => u.email === email);
      this.isLoadingData.hide();
      if (checkUser) {
        resolve(true);
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'Request Password completed.',
            detailKey: 'An email as been sent with a link to reset password.',
          })
        );
      } else {
        reject('Request Password Failed');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Request Password Failed',
            detailKey: `The email ${email} doesn't exist in database.`,
          })
        );
      }
    });
  }

  /**
   * Getter for token reset password (Async)
   *
   * @param {string} tokenReset The token reset password
   * @returns Boolean promise, true when successful
   */
  public getTokenResetPasswordAsync(tokenReset: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const checkUser = UsersBDMOCk.Users.find(
        (u) => u.tokenResetPassword === tokenReset
      );
      if (checkUser) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Method for reset password (Async)
   *
   * @param {string} newPassword The new password
   * @param {string} tokenReset The token reset password
   * @returns Boolean promise, true when successful
   */
  public resetPasswordAsync(
    newPassword: string,
    tokenReset: string
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.isLoadingData.show();
      await this.utilityService.sleepSecAsync(this.sleepTimeSec);
      const checkUser = UsersBDMOCk.Users.find(
        (u) => u.tokenResetPassword === tokenReset
      );
      this.isLoadingData.hide();
      if (checkUser) {
        checkUser.password = newPassword;
        resolve(true);
        this.notificationsService.successNotification(
          new PrgSucess({
            titleKey: 'New Password',
            detailKey: 'Password Changed Successfully',
          })
        );
      } else {
        reject('The password change operation has failed.');
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: 'Password Error.',
            detailKey: `The password change operation has failed.`,
          })
        );
      }
    });
  }
}
