import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { PrimeNGConfig } from 'primeng/api';
import { IsLoadingDataService } from '../../../core/services/is-loading-data/isloadingdata.service';
import { PrgAuthConfig } from '../../models/prg-auth-config';
import { AbstractAuthService } from '../../services/auth/abstract-auth.service';
import { PRG_AUTH_CONFIG } from '../../services/prg-auth-configuration/prg-auth-configuration.service';

/**
 * Form Login Component
 */
@Component({
  selector: 'prg-form-login',
  templateUrl: './prg-form-login.component.html',
  styleUrls: ['./prg-form-login.component.scss'],
})
export class PrgFormLoginComponent implements OnInit, OnDestroy {
  /**
   * Shows/hide the checkbox to save username on local storage. Default true.
   */
  @Input() showStoreUserName: boolean = true;

  /**
   * Shows/hide the reset password link.  Default true.
   */
  @Input() showResetPasswordLink: boolean = true;
  /**
   * Shows/hide sign up link.  Default true.
   */
  @Input() showSignUpRoute: boolean = true;

  /**
   * The route for signup page
   */
  @Input() signUpPageRoute: string =
    '/' +
    this.authRoutes.authRoutingBasePath +
    '/' +
    this.authRoutes.authRoutingSignupPath;

  /**
   * The route to redirect after login success
   */
  @Input() loginOkRedirectLink: string =
    '/' + this.authRoutes.authRoutingLoginOkRedirectPath;

  /**
   * The route for request new password
   */
  @Input() resetPasswordPageRoute: string =
    '/' +
    this.authRoutes.authRoutingBasePath +
    '/' +
    this.authRoutes.authRoutingRequestPasswordPath;

  /**
   * Property of the class with type string which bind to image logo form.
   */
  @Input() imagePathLogo: string = '';

  /**
   * Shows/hide password on form. Default false.
   */
  public showPassword: boolean = false;

  /**
   * Property of the class with type Form Group which bind to the register form
   */
  public loginForm: FormGroup;
  /**
   * Property of the class with type boolean which bind to the checkbox "Remember me"
   */
  public checkedStoreUserName: boolean = false;

  // GCR: strings devem ser pelicas ('') em vez de aspas (""). está configurad no pretier devemos correr um comando para correr o pretier em todos os ficheiros

  /**
   * constructor
   * @param {NGXLogger} logger logger
   * @param {AbstractAuthService} authService authService
   * @param {PrimeNGConfig} primengConfig
   * @param {PrgAuthConfig} authRoutes
   * @param {Router} router
   * @param {IsLoadingDataService} isLoadingData isLoadingData
   */
  constructor(
    public logger: NGXLogger,
    public authService: AbstractAuthService,
    private primengConfig: PrimeNGConfig,
    @Inject(PRG_AUTH_CONFIG) private authRoutes: PrgAuthConfig, // GCR: usar sempre o authConfig diretamente sem DI | Resp : Dá um erro de dependencia circular ao utilizar o authConfig
    private router: Router,
    public isLoadingData: IsLoadingDataService
  ) {}

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.logger.debug('LOGIN FORM ON INIT');
    this.initFormLogin();
    this.authService.getStoredUserNameObservable().subscribe((userEmail) => {
      if (userEmail) {
        this.loginForm.controls['email'].setValue(userEmail);
        this.checkedStoreUserName = true;
      }
    });
    this.primengConfig.ripple = true;
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.logger.debug('LOGIN FORM ON DESTROY');
  }

  /**
   * Initiate the login form and its form controls with validators
   */
  private initFormLogin(): void {
    this.loginForm = new FormGroup(
      {
        email: new FormControl(null, [Validators.required]),
        check: new FormControl(),
        password: new FormControl(null, [Validators.required]),
        storeUserName: new FormControl(null),
      },
      { updateOn: 'change' }
    );
  }

  /**
   * Show/Hide the password on login form
   */

  public onShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Form submission
   */
  public async onSubmitLogin(): Promise<void> {
    if (this.loginForm.invalid) {
      return;
    }

    const userEmail = this.loginForm.controls.email.value;
    const userPassword = this.loginForm.controls.password.value;
    const storeUserName = this.loginForm.controls.storeUserName.value;
    this.loginForm.disable();

    try {
      if (
        await this.authService.loginAsync(
          userEmail,
          userPassword,
          storeUserName
        )
      ) {
        this.loginForm.reset();
        await this.router.navigate([this.loginOkRedirectLink]);
      }
    } catch (error) {
      this.logger.debug(error);
    }
    this.loginForm.enable();
  }
}
