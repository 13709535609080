import { CheckboxConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/checkbox/checkbox.model';
import { InputNumberConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/input-number/input-number.model';
import { SelectConfiguration } from '../../core/components/dynamic-form/dynamic-form-fields/fields/select/select.model';
import { ControlName } from '../../core/components/dynamic-form/models/constants/control-names';
import { ReferenceType } from '../../core/components/dynamic-form/models/constants/reference-type';
import { ConfigurationItem } from '../models/configuration-item.model';

/**
 * Database interface that defines a mock
 * <br />database to be used while developing
 * <br />and/or testing the front-end.
 */
export interface Database {
  /**
   * Mock configuration items
   */
  ConfigurationItems: ConfigurationItem[];
}
/**
 * MockDatabase object to be used while developing
 * <br /> and/or testing the front-end
 */
export const MockDatabase: Database = {
  ConfigurationItems: [
    {
      id: '1',
      name: 'language',
      createdBy: '1',
      path: 'Config-Items:User-Preferences:Defaults',
      value: 'language.portuguese',
      guiSettings: JSON.stringify(
        new SelectConfiguration({
          controlName: ControlName.dropdown,
          referenceType: ReferenceType.LookupTable,
          referenceName: 'language',
          key: 'language',
          optionLabel: 'label',
          optionValue: 'id',
          basePathTranslation: 'lookup-tables',
          showClear: true,
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '2',
      name: 'language',
      createdBy: '2',
      path: 'Config-Items:User-Preferences:pedrocouto@gmail.com',
      value: 'language.english',
      guiSettings: JSON.stringify(
        new SelectConfiguration({
          referenceType: ReferenceType.LookupTable,
          referenceName: 'language',
          key: 'language',
          optionLabel: 'label',
          optionValue: 'id',
          basePathTranslation: 'lookup-tables',
          showClear: true,
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '3',
      name: 'theme',
      createdBy: '1',
      path: 'Config-Items:User-Preferences:Defaults',
      value: 'theme.lara-light-blue',
      guiSettings: JSON.stringify(
        new SelectConfiguration({
          referenceType: ReferenceType.LookupTable,
          referenceName: 'theme',
          key: 'theme',
          optionLabel: 'label',
          optionValue: 'id',
          basePathTranslation: 'lookup-tables',
          showClear: true,
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '4',
      name: 'theme',
      createdBy: '3',
      path: 'Config-Items:User-Preferences:pedrolima@gmail.com',
      value: 'theme.bootstrap4-dark-purple',
      guiSettings: JSON.stringify(
        new SelectConfiguration({
          referenceType: ReferenceType.LookupTable,
          referenceName: 'theme',
          key: 'theme',
          optionLabel: 'label',
          optionValue: 'id',
          basePathTranslation: 'lookup-tables',
          showClear: true,
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '5',
      name: 'theme',
      createdBy: '4',
      path: 'Config-Items:User-Preferences:luis@gmail.com',
      value: 'theme.rhea',
      guiSettings: JSON.stringify(
        new SelectConfiguration({
          referenceType: ReferenceType.LookupTable,
          referenceName: 'theme',
          key: 'theme',
          optionLabel: 'label',
          optionValue: 'id',
          basePathTranslation: 'lookup-tables',
          showClear: true,
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '7',
      name: 'RequiredLength',
      createdBy: '1',
      path: 'Config-Items:Identity:Password',
      value: 5,
      guiSettings: JSON.stringify(
        new InputNumberConfiguration({
          key: 'RequiredLength',
          min: 1,
          max: 10,
          showButtons: true,
          validators: ['Validators.required'] as any,
          basePathTranslation: 'config-items.identity.password',
          showClear: true,
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '9',
      name: 'RequiredUniqueChars',
      createdBy: '1',
      path: 'Config-Items:Identity:Password',
      value: '1',
      guiSettings: JSON.stringify(
        new InputNumberConfiguration({
          key: 'RequiredUniqueChars',
          min: 1,
          max: 10,
          showButtons: true,
          validators: ['Validators.required'] as any,
          basePathTranslation: 'config-items.identity.password',
          showClear: true,
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '8',
      name: 'RequireLowercase',
      createdBy: '1',
      path: 'Config-Items:Identity:Password',
      value: true,
      guiSettings: JSON.stringify(
        new CheckboxConfiguration({
          key: 'RequireLowercase',
          basePathTranslation: 'config-items.identity.password',
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },

    {
      id: '6',
      name: 'RequireDigit',
      createdBy: '1',
      path: 'Config-Items:Identity:Password',
      value: false,
      guiSettings: JSON.stringify(
        new CheckboxConfiguration({
          key: 'RequireDigit',
          basePathTranslation: 'config-items.identity.password',
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '10',
      name: 'RequireUppercase',
      createdBy: '1',
      path: 'Config-Items:Identity:Password',
      value: true,
      guiSettings: JSON.stringify(
        new CheckboxConfiguration({
          key: 'RequireUppercase',
          basePathTranslation: 'config-items.identity.password',
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '11',
      name: 'RequireNonAlphanumeric',
      createdBy: '1',
      path: 'Config-Items:Identity:Password',
      value: true,
      guiSettings: JSON.stringify(
        new CheckboxConfiguration({
          key: 'RequireNonAlphanumeric',
          validators: ['Validators.required'] as any,
          basePathTranslation: 'config-items.identity.password',
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
    {
      id: '12',
      name: 'AllowSelfRegister',
      createdBy: '1',
      path: 'Config-Items:Identity',
      value: true,
      guiSettings: JSON.stringify(
        new CheckboxConfiguration({
          key: 'AllowSelfRegister',
          basePathTranslation: 'config-items.identity',
        })
      ),
      readonly: false,
      modifiedOn: null,
      modifiedBy: null,
      createdOn: null,
      operationId: null,
      transactionId: null,
      universalStateId: null,
    },
  ],

  /* TODO: MAP Identity CONFIG TO CONFIGURATION ITEMS
    "Identity": {
      "DefaultEnabledUser": true, // user will be enabled on register
      "AllowSelfRegister": true,
      "LockoutEnabled": true,
      "CreateWorkspaceOnUserRegister": true,
      "SignIn": {
        "RequireEnabledUser": false,
        "RequireConfirmedAdmin": false,
        "RequireConfirmedEmail": true,
        "RequireConfirmedPhoneNumber": false
      },
      "Lockout": {
        "LockoutTimeSpan": "1.00:00:00" //lock out for 1 day
      },
      "Password": {
        "RequireDigit": true,
        "RequiredLength": 7,
        "RequireLowercase": true,
        "RequiredUniqueChars": 1,
        "RequireUppercase": true,
        "RequireNonAlphanumeric": true
      },
      "Callbacks": {
        "BaseUrl": "http://localhost:8100",
        "LoginUrl": "/auth/login",
        "ConfirmedAccountUrl": "/auth/confirm",
        "ResetPasswordUrl": "/auth/reset",
        "UnsubscribeUrl": "/auth/unsubscribe"
      },
      "Token": {
        "Audience": "Prg.Framework.Api.Audience",
        "Issuer": "Prg.Framework.Api.Issuer",
        "Seconds": 86400, // 24 hours
        "RefreshDuration": 24 // 24 hours
      }
    }
  */
};
