import { ExecutedActionBehaviour } from '../../../models/executed-action-behaviourts';
import { ActionName } from './constants/action-names';

/**
 * class action
 */
export class BaseAction {
  /**
   * defines the element to be used
   * default is button
   */
  public controlName?: string = ActionName.Button;

  /**
   * with {@link #isDisabled} set to true we use this
   * field to enable or disable the button depending
   * on the validation state of the form
   */
  public disableWhenFormInvalid?: boolean = false;

  /**
   *  action indicator, to be able to tell which action should be executed
   */
  public key?: string;

  /**
   * translation base path
   */
  public translationBasePath?: string = 'buttons';

  /**
   * this property is only relevant for actions that go into a
   * toolbar, it indicates the slot (position) in which the
   * action should be inserted
   *
   * options: end, start
   */
  public toolbarSlot?: any = 'left';

  /**
   * value where the action will be executed
   */
  public value?: any[];

  /**
   * visible expression
   */
  public visibleExpression?: string;

  /**
   * enable expression
   */
  public enableExpression?: string;

  /**
   * order
   */
  public order: number = 0;

  /**
   * executedActionBehaviour
   */
  public executedActionBehaviour: ExecutedActionBehaviour;

  /**
   * Constructor
   * this constructor receives the fields sent by the app,
   * and creates an object with the fields sent and the values per default
   * @param init
   */
  constructor(init?: Partial<BaseAction>) {
    Object.assign(this, init);
  }
}

/**
 * Action output
 */
export class ActionOutput {
  /**
   * action key
   */
  action?: string;

  /**
   * event
   * @type {any}
   */
  event?: any;

  /**
   * Group of a button to be able to identify them when an action happen on grouped action buttons
   * @type {string}
   */
  group?: string;

  /**
   * value
   */
  value?: any;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<ActionOutput>) {
    Object.assign(this, init);
  }
}

/**
 * Field output
 */
export class FieldChangeOutput {
  /**
   * field
   */
  field: string;

  /**
   * event
   */
  event: any;

  /**
   *value
   */
  value: any;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<FieldChangeOutput>) {
    Object.assign(this, init);
  }
}

/**
 * Dynamic Form Action output
 */
export class DynamicFormActionOutput extends ActionOutput {
  /**
   * entity resulting from the form
   */
  formEntity: any;
  /**
   * form status
   */
  formStatus: FormStatus;
  /**
   * form is changed
   */
  isChanged: boolean;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<DynamicFormActionOutput>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Dynamic Form Field Change output
 */
export class DynamicFormFieldChangeOutput extends FieldChangeOutput {
  /**
   * entity resulting from the form
   */
  formEntity: any;

  /**
   * constructor
   * @param init
   */
  constructor(init?: Partial<DynamicFormFieldChangeOutput>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Actions input type
 */
export enum ActionInputType {
  Submit = 'submit',
  Cancel = 'cancel',
  Reset = 'reset',
  Delete = 'delete',
  Linear = 'linear',
}

/**
 * form status
 */
export enum FormStatus {
  Valid = 'valid',
  Invalid = 'invalid',
  Disabled = 'disabled',
}

/**
 * base actions keys
 */
export enum BaseActionKey {
  Edit = 'edit',
  Add = 'add',
  Reset = 'reset',
  Delete = 'delete',
  Save = 'save',
  Cancel = 'cancel',
}
