import { BehaviorSubject, Observable } from "rxjs";
import { ConfigurationItem } from "../../models/configuration-item.model";

/**
 * AbstractConfigurationItemsService class that defines an
 * <br />abstract configuration items service to
 * <br />be extended by the mock and real
 * <br />authentication services
 */
export abstract class AbstractConfigurationItemsService {
  /**
   * Current configurationItems
   */
  protected configurationItemsSubject = new BehaviorSubject<
    ConfigurationItem[]
  >(null);
  /**
   * Gets the configuration items
   *
   * @returns Boolean promise, true when successful
   */
  public abstract getConfigurationItemsAsync(): Promise<boolean>;

  /**
   * Setter for configurationItemsSubject
   *
   * @param {ConfigurationItem[]} value The ConfigurationItems array
   */
  protected setConfigurationItems(value: ConfigurationItem[]): void {
    this.configurationItemsSubject.next(value);
  }

  /**
   * Getter for configurationItemsSubject
   *
   * @returns The configurationItemsSubject as an observable
   */
  public getConfigurationItemsObservable(): Observable<ConfigurationItem[]> {
    return this.configurationItemsSubject.asObservable();
  }

  /**
   * Getter for configurationItemsSubject
   *
   * @returns The configurationItemsSubject current value
   */
  public getConfigurationItemsValue(): ConfigurationItem[] {
    return this.configurationItemsSubject.getValue();
  }
}
