<span *ngIf="form" class="p-float-label" [formGroup]="form">
  <span class="p-float-label">
    <input
      pInputText
      type="text"
      [formControlName]="configs?.key"
      [id]="configs?.key"
      [attr.disabled]="configs.disabled?configs.disabled:null"
      [readonly]="configs.readonly"
      [ngClass]="{ 'p-filled': form.controls[configs?.key]?.value !== '' }"
      [placeholder]="
        configs.basePathTranslation + '.' + configs.key + '.placeholder'
          | translate
      "
      [name]="configs.key + '.name' | translate"
      (ngModelChange)="debounceOnChanged($event)"


    />
    <label for="{{configs.key}}">{{
      configs.basePathTranslation + '.' + configs.key + '.label' | translate
    }}</label>
  </span>
</span>
